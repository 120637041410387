// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#chartdiv {
    width: 100%;
    height: 100%;
    min-height: 500px;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/funnel-chart/funnel-chart.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":["#chartdiv {\r\n    width: 100%;\r\n    height: 100%;\r\n    min-height: 500px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
