import { HttpInterceptorFn } from '@angular/common/http';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const currentUserString = localStorage.getItem('currentUser');

  if (currentUserString) {
    const currentUser = JSON.parse(currentUserString);

    if (currentUser && currentUser.id) {
      const modifiedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.id}`
        }
      });
      return next(modifiedReq);
    } else {
    }
  } else {
  }

  return next(req);
};
