import { ChangeDetectorRef, Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleStackColumnWithlineComponent } from "../multiple-stack-column-withline/multiple-stack-column-withline.component";
import { NewOverviewService } from '../Services/new-OverviewService/new-overview.service';

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface DataContext {
  breakdown: { category: string, value: number }[];
}

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    CommonModule,
    MultipleStackColumnWithlineComponent,
  ],
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css'],
})
export class TestComponent implements OnInit, OnDestroy {
  private root!: am5.Root;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.initializeChart();
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  initializeChart(): void {
    // Create root element
    let root = am5.Root.new(this.el.nativeElement.querySelector("#chartdiv"));

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Set up data
    let data = [
        {
            category: "Critical",
            value: 20,
            sliceSettings: { fill: am5.color(0xdc4534) },
            breakdown: [
                { category: "Pillow", value: 29 },
                { category: "Hellow", value: 40 },
                { category: "ofnsdj", value: 11 },
                { category: "yreyhhjb", value: 9 }
            ]
        },
        {
            category: "Acceptable",
            value: 1,
            sliceSettings: { fill: am5.color(0xd7a700) },
            breakdown: [
                { category: "A", value: 22 },
                { category: "B", value: 30 },
                { category: "C", value: 11 },
                { category: "D", value: 10 }
            ]
        },
        {
            category: "Good",
            value: 120,
            sliceSettings: { fill: am5.color(0x68ad5c) },
            breakdown: [
                { category: "E", value: 60 },
                { category: "F", value: 35 },
                { category: "G", value: 15 },
                { category: "H", value: 10 }
            ]
        }
    ];

    // Sort data in descending order based on value
    data.sort((a, b) => b.value - a.value);

    // Create container
    let container = root.container.children.push(am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.horizontalLayout
    }));

    // Column chart setup
    let columnChart = container.children.push(am5xy.XYChart.new(root, {
        width: am5.percent(50),
        panX: false,
        panY: false,
        layout: root.verticalLayout
    }));

    let yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 30,  // Ensure enough space for labels
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
    });
    
    // Configure Y-axis labels
    yRenderer.labels.template.setAll({
        fontSize: 12,
        fill: root.interfaceColors.get("text"),
        paddingRight: 10,
        visible: true,  // Ensure labels are visible
        text: "{category}"  // Use category field for labels
    });

    let yAxis = columnChart.yAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {})
    }));
    yRenderer.grid.template.setAll({ location: 1 });

    let xAxis = columnChart.xAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, { strokeOpacity: 0.1 })
    }));

    let columnSeries = columnChart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "value",
        categoryYField: "category",
        tooltip: am5.Tooltip.new(root, {
            labelText: "{categoryY}: {valueX}"
        })
    }));

    // Configure column labels
    columnSeries.columns.template.setAll({
        tooltipText: "{categoryY}: {valueX}",
        strokeOpacity: 0,
        cornerRadiusTR: 5,
        cornerRadiusBR: 5
    });

    // Add labels inside the columns
    columnSeries.bullets.push(function() {
        return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 0.5,
            sprite: am5.Label.new(root, {
                centerX: am5.p50,
                centerY: am5.p50,
                text: "{categoryY}",
                populateText: true,
                fontSize: 12,
                fill: root.interfaceColors.get("alternativeText")
            })
        });
    });

    // Create funnel chart
    let funnelChart = container.children.push(
        am5percent.SlicedChart.new(root, {
            width: am5.percent(50),
            layout: root.verticalLayout
        })
    );

    // Create funnel series
    let funnelSeries = funnelChart.series.push(am5percent.FunnelSeries.new(root, {
        alignLabels: false,
        orientation: "vertical",
        valueField: "value",
        categoryField: "category",
        bottomRatio: 1,
    }));

    // Style the funnel slices
    funnelSeries.slices.template.setAll({
        templateField: "sliceSettings",
        strokeOpacity: 0,
        fillOpacity: 1,
        interactive: true
    });

    // Add labels with correct positioning
    funnelSeries.labels.template.setAll({
        text: "{category}: {value}",
        position: "absolute",
        x: 10,
        centerY: am5.percent(50),
        fontSize: 12,
        fill: root.interfaceColors.get("text")
    });

    let currentSlice: any;
    funnelSeries.slices.template.events.on("click", (ev) => {
        const slice = ev.target;
        const dataItem = slice.dataItem;

        if (dataItem && dataItem.dataContext) {
            const dataContext = dataItem.dataContext as DataContext;
            const fill = slice.get("fill");

            // Set column colors based on slice selection
            columnSeries.columns.template.setAll({
                fill: fill,
                stroke: fill
            });

            // Update column chart with breakdown data
            if (dataContext.breakdown) {
                columnSeries.data.setAll(dataContext.breakdown);
                yAxis.data.setAll(dataContext.breakdown);
            }

            // Update legends for both charts
            this.updateLegends(funnelSeries, columnSeries);
        }
    });

    // Set legends for both charts
    let funnelLegend = funnelChart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15
    }));
    funnelLegend.data.setAll(funnelSeries.dataItems);

    let columnLegend = columnChart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      marginTop: 15,
      nameField: "category" // Ensure the name field is bound to 'category'
    }));
    
    columnLegend.data.setAll(columnSeries.dataItems);
    

    // Set initial data
    funnelSeries.data.setAll(data);
    columnSeries.data.setAll(data[0].breakdown);
    yAxis.data.setAll(data[0].breakdown);

    // Add animation
    funnelSeries.appear();
    columnSeries.appear();

    this.root = root;
}

// Method to update legends based on selected slice
updateLegends(funnelSeries: any, columnSeries: any): void {
    // Update funnel chart legend
    const funnelLegend = funnelSeries.chart.children.getIndex(1);
    if (funnelLegend) {
        funnelLegend.data.setAll(funnelSeries.dataItems);
    }

    // Update column chart legend
    const columnLegend = columnSeries.chart.children.getIndex(1);
    if (columnLegend) {
        columnLegend.data.setAll(columnSeries.dataItems);
    }
}
}