import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';

@Component({
  selector: 'app-malaria-tree',
  standalone: true,
  imports: [CommonModule, TreeTableModule],
  templateUrl: './malaria-tree.component.html',
  styleUrl: './malaria-tree.component.css',
})
export class MalariaTreeComponent {
  @Input() dataSource: TreeNode[] = [];
  @Input() cols: any[] = [];
  tableData: any = [];
  constructor() {
    this.tableData = this.dataSource;
  }
  ngOnInit(): void {
    this.tableData = this.dataSource;
  }
}
