import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeTableModule } from 'primeng/treetable';
import { TreeNode } from 'primeng/api';
import { ContentObserver } from '@angular/cdk/observers';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, MatSortModule, Sort} from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';


@Component({
  selector: 'app-tree-table',
  standalone: true,
  imports: [CommonModule, TreeTableModule,MatTableModule, MatPaginatorModule, MatSortModule,],
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.css']
})
export class TreeTableComponent implements OnInit,AfterViewInit{
  @Input() data: TreeNode[] = [];
  @Input() cols: any[] = [];
  totalRecords: number = 0;
  pageSize: number = 5;
  currentPage: number = 0;
  paginatedData: TreeNode[] = [];

  displayedColumns: string[] = [
    'position', 
    'score',
    'state', 
    'district', 
    'block', 
    'chwName', 
    'newMomCoverage', 
    'householdCoverage', 
    'childCoverage', 
    'childDiarrheaCoverage', 
    'pneumoniaCoverage', 
    'followUp', 
    'missedFollowUp', 
    'regularfollowup', 
    'lowAwarenessFollowUp', 
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  private _liveAnnouncer = inject(LiveAnnouncer);


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    
  }
  ngOnInit(): void {
  
    console.log("THis is the data got", this.data)
    console.log("THIs is the cols", this.cols)
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }


  // comment for paginator 
  // onPageChange(event: PageEvent) {
  //   this.pageSize = event.pageSize;
  //   this.currentPage = event.pageIndex;
  //   this.paginateData();
  // }

  // paginateData() {
  //   const start = this.currentPage * this.pageSize;
  //   const end = start + this.pageSize;
  //   this.paginatedData = this.data.slice(start, end);
  // }
}
export interface PeriodicElement {
  position: number;                    
  state: string;                        
  district: string;                      
  block: string;                       
  chwName: string;                     
  newMomCoverage: number;              
  householdCoverage: number;           
  childCoverage: number;               
  childDiarrheaCoverage: number;       
  pneumoniaCoverage: number;           
  followUp: number;                   
  missedFollowUp: number;              
  score: number;
                       
}
const ELEMENT_DATA: PeriodicElement[] = [
  {
    position: 1,
    state: 'State 1',
    district: 'District 1',
    block: 'Block 1',
    chwName: 'CHW 1',
    newMomCoverage: 75,
    householdCoverage: 85,
    childCoverage: 60,
    childDiarrheaCoverage: 50,
    pneumoniaCoverage: 45,
    followUp: 30,
    missedFollowUp: 5,
    score: 80
  },
  {
    position: 2,
    state: 'State 2',
    district: 'District 2',
    block: 'Block 2',
    chwName: 'CHW 2',
    newMomCoverage: 35,
    householdCoverage: 35,
    childCoverage: 30,
    childDiarrheaCoverage: 30,
    pneumoniaCoverage: 35,
    followUp: 20,
    missedFollowUp:25,
    score: 20
  },
  {
    position: 3,
    state: 'State 3',
    district: 'District 3',
    block: 'Block 3',
    chwName: 'CHW 3',
    newMomCoverage: 72,
    householdCoverage: 82,
    childCoverage: 62,
    childDiarrheaCoverage: 52,
    pneumoniaCoverage: 42,
    followUp: 32,
    missedFollowUp: 15,
    score: 80
  },
  {
    position: 4,
    state: 'State 4',
    district: 'District 4',
    block: 'Block 4',
    chwName: 'CHW 4',
    newMomCoverage: 45,
    householdCoverage: 45,
    childCoverage: 64,
    childDiarrheaCoverage: 40,
    pneumoniaCoverage: 44,
    followUp: 40,
    missedFollowUp: 4,
    score: 40
  },
  {
    position: 5,
    state: 'State 5',
    district: 'District 5',
    block: 'Block 5',
    chwName: 'CHW 5',
    newMomCoverage: 55,
    householdCoverage: 55,
    childCoverage: 54,
    childDiarrheaCoverage: 45,
    pneumoniaCoverage: 54,
    followUp: 45,
    missedFollowUp: 5,
    score: 45
  },
  {
    position: 6,
    state: 'State 6',
    district: 'District 6',
    block: 'Block 6',
    chwName: 'CHW 6',
    newMomCoverage: 65,
    householdCoverage: 86,
    childCoverage: 66,
    childDiarrheaCoverage: 60,
    pneumoniaCoverage: 65,
    followUp: 36,
    missedFollowUp: 6,
    score: 86
  },
  {
    position: 7,
    state: 'State 7',
    district: 'District 7',
    block: 'Block 7',
    chwName: 'CHW 7',
    newMomCoverage: 77,
    householdCoverage: 75,
    childCoverage: 60,
    childDiarrheaCoverage: 57,
    pneumoniaCoverage: 75,
    followUp: 37,
    missedFollowUp: 7,
    score: 87
  },
  
 
];