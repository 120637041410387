import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';

@Component({
  selector: 'app-birth-space-tree',
  standalone: true,
  imports: [CommonModule, TreeTableModule],
  templateUrl: './birth-space-tree.component.html',
  styleUrl: './birth-space-tree.component.css',
})
export class BirthSpaceTreeComponent implements OnInit {
  @Input() dataSource: TreeNode[] = [];
  @Input() cols: any[] = [];
  tableData: any = [];
  constructor() {
    this.tableData = this.dataSource;
   
  }
  ngOnInit(): void {
    this.tableData = this.dataSource;

  }
}
