import { Component } from '@angular/core';
import { PieChartComponent } from "../pie-chart/pie-chart.component";
import { DonutChartGeneratorComponent } from "../donut-chart-generator/donut-chart-generator.component";
import { SingleColumnChartGeneratedComponent } from "../single-column-chart-generated/single-column-chart-generated.component";
import { FamilyPlanningStackChartHorizontalComponent } from "../family-planning-stack-char-horizontal/family-planning-stack-char-horizontal.component";
import { HorizontalMultiColumnGenerateComponent } from '../horizontal-multi-column-generate/horizontal-multi-column-generate.component';
import { DonutChartComponent } from "../donut-chart/donut-chart.component";


//Interface for Absorbent type usage
interface absorbentTypeUsageSectionInterface{
  name: string;
  value: number;
  color?: string;
}
interface SingleDataPoint {
  year: string;
  value: number;
  color?: string; // Optional for custom colors
}


@Component({
  selector: 'app-new-hygiene-and-health',
  standalone: true,
  imports: [PieChartComponent, DonutChartGeneratorComponent, SingleColumnChartGeneratedComponent, FamilyPlanningStackChartHorizontalComponent, HorizontalMultiColumnGenerateComponent, DonutChartComponent],
  templateUrl: './new-hygiene-and-health.component.html',
  styleUrl: './new-hygiene-and-health.component.css'
})
export class NewHygieneAndHealthComponent {

  chartIdForAge2: string = 'chartIdForAge';  // Dynamic chart ID
  chartIdForAgedsdsdd: string = 'chartIdForAgedsdsdd';  // Dynamic chart ID
  //char generate based on age 
  chartDataForAgeWise2 = [
    { age: 'July', injectable: 12.18, iuc: 23.56, emergency: 5.07 },
    { age: 'August', injectable: 5.78, iuc: 11.18, emergency: 5.91 },
    { age: 'September', injectable: 0.82, iuc: 1.67, emergency: 7.5 },
    { age: 'October', injectable: 28.57, iuc: 57.14, emergency: 0 },
  ];

   // Array to store  Absorbent type usage data
   absorbentTypeUsageSectionData: absorbentTypeUsageSectionInterface[] = [
    { name: 'Sanitary Napkins', value: 45, color: '#FF5733' },    // Orange-red color
    { name: 'Cloths', value: 30, color: '#33FF57' },              // Bright green color
    { name: 'Sanitary Napkins & Cloths both', value: 20, color: '#3357FF' },  // Blue color
    { name: 'Others', value: 5, color: '#FF33A6' }                // Pink color
  ];
  // Array to store  disposal of used pads / Cloth
  disposalOfUsedPadsClothData: absorbentTypeUsageSectionInterface[] = [
    { name: 'Flush them in latrine', value: 45, color: '#FF5733' },         // Orange-red color
    { name: 'Throw in the dustbin', value: 30, color: '#33FF57' },          // Bright green color
    { name: 'Burry it in field', value: 20, color: '#3357FF' },             // Blue color
    { name: 'Throw in the field', value: 10, color: '#FF33A6' },            // Pink color
    { name: 'Throw in the pond/stream Others', value: 8, color: '#FF5733' }, // Orange-red color
    { name: 'Other', value: 5, color: '#33FF57' }                           // Bright green color
  ];
   firstChartData = [
    { country: 'Yes', sales: 501.9 },
    { country: 'No', sales: 301.9 },
  ];

  secondChartData = [
    { country: 'Germany', sales: 165.8 },
    { country: 'Australia', sales: 139.9 },
    { country: 'Austria', sales: 128.3 },
  ];
    // Define your data array
    chartData: SingleDataPoint[] = [
      { year: '1-2 times', value: 30, color: '#74D680' },
      { year: '3-4 times', value: 50, color: '#378B29' },
      { year: '5-6 times', value: 80, color: '#F2994A' },
      { year: '7-8 times', value: 60, color: '#F04590' },
      { year: '9-10 times', value: 20, color: '#F04590' },
    ];
  
    legendName: string = 'Frequency'; // Example legend name
    chartId: string = 'chartDiv';
  
  gujratData: number[] = [75, 82, 68, 93, 88, 79];
  maharashtraData: number[] = [70, 85, 77, 91, 82, 86];
  rajasthanData: number[] = [65, 78, 72, 84, 76, 80];
  investmentsData: number[] = [65, 78, 72, 84, 76, 80];
  
  colors = {
    incomeColor: '#4CAF50',
    expensesColor: '#FFC107',
    savingsColor: '#2196F3',
    investmentsColor: '#FF107G',
  };
 
  months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
}
