import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment/environment.local';


@Injectable({
  providedIn: 'root',
})
export class CountFoodAwarenessService {

  private readonly endpoint = `${environment.apiUrl}MotherCommonQues`;

  constructor(private http: HttpClient) {}
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });
  getCountFoodAwareness(params: any): Observable<any> {
    const url =
    `${this.endpoint}/count-food-awareness` +
    '?params=' +
    JSON.stringify(params); 
  return this.http.get(url, { headers: this.headers });
  }
}
