import { Component, OnInit, NgZone, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-pyramid-chart',
  standalone: true,
  templateUrl: './pyramid-chart.component.html',
  styleUrls: ['./pyramid-chart.component.css']
})
export class PyramidChartComponent implements OnInit {
  private root: any;

  constructor(
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.createChart();
      });
    }
  }

  createChart() {
    import('@amcharts/amcharts5').then((am5) => {
      import('@amcharts/amcharts5/percent').then((am5percent) => {
        import('@amcharts/amcharts5/themes/Animated').then((am5themes_Animated) => {
          // Create root element
          this.root = am5.Root.new("pyramidChartDiv");
  
          // Set themes
          this.root.setThemes([am5themes_Animated.default.new(this.root)]);
  
          // Remove amCharts logo
          this.root._logo?.dispose();
  
          // Create chart
          let chart = this.root.container.children.push(
            am5percent.SlicedChart.new(this.root, {
              layout: this.root.verticalLayout
            })
          );
  
          // Create pyramid series
          let series = chart.series.push(am5percent.PyramidSeries.new(this.root, {
            orientation: "vertical",
            valueField: "value",
            categoryField: "category",
            bottomRatio: 1,
            calculateAggregates: true,
            valueFormat: "#",
          }));
  
          // Set data
          series.data.setAll([
            { value: 300, category: "LI @ HH Level" },
            { value: 400, category: "LI @ CL" },
            { value: 500, category: "Total Registration" }
          ]);
  
          // Add labels
          series.labels.template.set("visible", true);
          series.labels.template.setAll({
            text: "{category}: {value}",
            oversizedBehavior: "wrap"
          });
  
          // Add legend
          let legend = chart.children.push(am5.Legend.new(this.root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            marginTop: 15,
            marginBottom: 15
          }));
  
          legend.labels.template.setAll({
            fontSize: 12,
            fontWeight: "400"
          });
  
          legend.valueLabels.template.setAll({
            fontSize: 12,
            fontWeight: "300"
          });
  
          legend.data.setAll(series.dataItems);
  
          // Set legend value formatter
          series.set("legendValueText", "{value}");
  
          // Play initial series animation
          series.appear(1000, 100);
        });
      });
    });
  }
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        if (this.root) {
          this.root.dispose();
        }
      });
    }
  }
}