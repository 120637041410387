// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add styles for full-screen mode */
.full-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .full-screen-container {
    position: relative;
    width: 90%;
    height: 90%;
    background: white;
    border-radius: 10px;
  }
  
  .pdf-viewer-container {
    border: 1px solid #e5e7eb; /* Light gray border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .pdf-viewer-container:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/app/algorithm/algorithm.component.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,oBAAoB;IAC1D,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB,EAAE,sBAAsB;IACjD,wCAAwC;IACxC,+BAA+B;EACjC;;EAEA;IACE,sBAAsB,EAAE,gCAAgC;EAC1D","sourcesContent":["/* Add styles for full-screen mode */\r\n.full-screen-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: rgba(0, 0, 0, 0.8); /* Dark background */\r\n    z-index: 9999;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  \r\n  .full-screen-container {\r\n    position: relative;\r\n    width: 90%;\r\n    height: 90%;\r\n    background: white;\r\n    border-radius: 10px;\r\n  }\r\n  \r\n  .pdf-viewer-container {\r\n    border: 1px solid #e5e7eb; /* Light gray border */\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    transition: transform 0.2s ease;\r\n  }\r\n  \r\n  .pdf-viewer-container:hover {\r\n    transform: scale(1.02); /* Slight zoom effect on hover */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
