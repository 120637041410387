import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthenticationService } from '../Services/authentication.service';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, HttpClientModule,MatInputModule,
    MatIconModule,
    MatFormFieldModule],  
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;

  constructor(
    private fb: FormBuilder, // Inject FormBuilder for form building
    private authService: AuthenticationService,
    private router: Router
  ) {
    
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({ // Initialize loginForm with FormBuilder
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  onSubmit(): void {
    if (this.loginForm.valid) { // Check if form is valid
      const credentials = this.loginForm.value; // Get form values
      this.authService.login(credentials).subscribe(
        success => {
          if (success) {
            console.log('Login successful!');
            localStorage.setItem('currentUser',JSON.stringify(success))
            this.router.navigate(['/app-new-dashboard']); // Navigate to dashboard on success
          } else {
            console.log('Login failed!');
          }
        },
        error => {
          console.error('Login error', error); // Handle login error
        }
      );
    }
  }
}