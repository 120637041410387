import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { isPlatformBrowser } from '@angular/common';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

interface ChartDataPoint {
  name: string;
  value: number;
  color?: string;
}

@Component({
  selector: 'app-pie-chart',
  standalone: true,
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css'],
})
export class PieChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() chartId!: string;
  @Input() data: ChartDataPoint[] = [];
  legendData: { name: string; percentage: number; color?: string }[] = [];

  private chart?: am5percent.PieChart;
  private root?: am5.Root;
  private series?: am5percent.PieSeries;
  private exporting?: am5plugins_exporting.Exporting;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.createChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isPlatformBrowser(this.platformId) && changes['data'] && !changes['data'].firstChange) {
      this.updateChartData();
    }
  }

  ngOnDestroy(): void {
    this.disposeChart();
  }

  private createChart(): void {
    this.root = am5.Root.new(this.chartId);
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.root._logo?.dispose();
    // Create the PieChart
    this.chart = this.root.container.children.push(
      am5percent.PieChart.new(this.root, {
        layout: this.root.verticalLayout,
      })
    );
    this.series = this.chart.series.push(
      am5percent.PieSeries.new(this.root, {
        valueField: 'value',
        categoryField: 'name',
        fillField: 'color',
      })
    );

    this.series.data.setAll(this.data);

    this.series.labels.template.setAll({
      text: '{name}: {value.percent.formatNumber("#.0") }%',
      radius: 8,
      fontSize: 12,
      fill: am5.color(0x000000),
      textType: 'adjusted',
    });

    // Configure ticks (lines connecting labels to slices)
    this.series.ticks.template.setAll({
      forceHidden: false,  // Show ticks (the lines)
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: am5.color(0x000000)
    });

    this.series.appear(1000, 100);

    // Update legend after setting data
    this.updateLegendData(this.series.dataItems);

    // Create vertical legend
    const verticalLegend = this.chart.children.push(
      am5.Legend.new(this.root, {
        centerY: am5.percent(100),
        y: am5.percent(100),
        layout: this.root.gridLayout,
        marginTop: 20,
      })
    );

    verticalLegend.labels.template.setAll({
      fontSize: 13,
      fontWeight: '700',
    });

    verticalLegend.data.setAll(this.series.dataItems);

    // Initialize exporting
    this.exporting = am5plugins_exporting.Exporting.new(this.root, {
      filePrefix: 'funnel-chart',
      dataSource: this.data,
      pdfOptions: {
        addURL: false,
        disabled: false,
      },
      csvOptions: {
        addColumnNames: true,
        separator: ',',
      },
    });

    // Create and set up the export menu
    const exportMenu = am5plugins_exporting.ExportingMenu.new(this.root, {
      exporting: this.exporting, // Link the menu to the exporting instance
    });
  }
  private updateChartData(): void {
    if (this.series) {
      this.series.data.setAll(this.data);
      this.updateLegendData(this.series.dataItems);
    }
  }

  private updateLegendData(dataItems: any[]): void {
    const totalItem = this.data.find(item => item.name === 'Total');
    const totalValue = totalItem ? totalItem.value : 0; // Use 0 if no 'Total' found
    this.legendData = dataItems
      .filter(item => item.get('name') !== 'Total') // Filter out 'Total'
      .map(item => ({
        name: item.get('name'),
        percentage: totalValue > 0 ? (item.get('value') / totalValue) * 100 : 0,
        color: item.get('color'),
      }));
  }
  private disposeChart(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
