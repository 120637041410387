import { Component, OnInit, NgZone, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { FilterComponent } from '../filter/filter.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { CountFoodAwarenessService } from '../Services/count-food-awareness.service';

// interface IndicatorData {
//   name: string;
//   value: number;
//   description: string;
// }

interface TableData {
    blockName: string;
    diarrhoeaCases: number;
    treatedAtFacility: string;
    beneficiaryStatus: string;
    treatedAtHome: string;
    husbandName: string;

}
@Component({
  selector: 'app-food-health',
  standalone: true,
  imports: [CommonModule, FilterComponent, MatTableModule,  MatIconModule,
    MatPaginatorModule,MatTableModule, MatSortModule,MatSort],
  templateUrl: './food-health.component.html',
  styleUrl: './food-health.component.css'
})
export class FoodHealthComponent {
  isFilterVisible: boolean = false; // Variable to control visibility
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  //21 sept
  stateId = [];
districtId = [];
blockId = [];
chwId = [];
currentUser: any;
indicators: Array<{ name: string, value: number }> = [];
params = {};  

  // indicators: IndicatorData[] = [
  //   { name: 'IFA compliance', value: 75, description: '% women with early registration of pregnancy' },
  //   { name: 'Calcium compliance', value: 82, description: '% women with completed ANC as per schedule' },
  //   { name: 'Practice Diet Diversity', value: 68, description: '% women who had institutional delivery' },
  //   { name: 'Adequate food intake', value: 91, description: '% women received ANC in different facilities' },
  // ];

  // tableData: TableData[] = [
  //   { blockName: 'Pataudi', diarrhoeaCases: 10, treatedAtFacility: '6', beneficiaryStatus: 'LM', treatedAtHome: '4', husbandName: 'Saroj Kumar' },
  //     { blockName: 'Manesar',diarrhoeaCases: 20, treatedAtFacility: '11', beneficiaryStatus: 'LM', treatedAtHome: '9', husbandName: 'Saroj Kumar' },
  //     { blockName: 'Jind', diarrhoeaCases: 11, treatedAtFacility: '9', beneficiaryStatus: 'LM', treatedAtHome: '2', husbandName: 'Saroj Kumar' },
  //     { blockName: 'Kaithal', diarrhoeaCases: 22, treatedAtFacility: '16', beneficiaryStatus: 'LM', treatedAtHome: '6', husbandName: 'Saroj Kumar' },
  //     { blockName: 'uaithal', diarrhoeaCases: 9, treatedAtFacility: '6', beneficiaryStatus: 'LM', treatedAtHome: '3', husbandName: 'Saroj Kumar' },
  //     { blockName: 'yaithal', diarrhoeaCases: 19, treatedAtFacility: '12', beneficiaryStatus: 'LM', treatedAtHome: '7', husbandName: 'Saroj Kumar' },
  // ];

  // dataSource: MatTableDataSource<TableData>;
  // dataSource2: MatTableDataSource<TableData> = new MatTableDataSource(this.tableData); // Added dataSource2 initialization

  private root!: am5.Root;

  constructor(private zone: NgZone,private countFoodAwarenessService: CountFoodAwarenessService) {
    // this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngOnInit(): void {
    this.getDataFromLocalStorage();
  }

  ngAfterViewInit(): void {
    // this.dataSource2.paginator = this.paginator;
    // this.dataSource2.sort = this.sort;
    this.zone.runOutsideAngular(() => {
      this.createChart();
    });
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
// Method to toggle the visibility of the filter component
toggleFilterVisibility(): void {
  this.isFilterVisible = !this.isFilterVisible;
}
  createChart() {
    this.root = am5.Root.new("chartdiv");
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    let chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true,
      paddingLeft: 0,
      paddingRight: 1
    }));

    let cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
    cursor.lineY.set("visible", false);

    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fontSize:10
    });

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
      maxDeviation: 0.3,
      categoryField: "name",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(this.root, {})
    }));

    let yRenderer = am5xy.AxisRendererY.new(this.root, {
      strokeOpacity: 0.1
    });

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      maxDeviation: 0.3,
      renderer: yRenderer
    }));

    let series = chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: "Series 1",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      sequencedInterpolation: true,
      categoryXField: "name",
      tooltip: am5.Tooltip.new(this.root, {
        labelText: "{valueY}"
      })
    }));

    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
    series.columns.template.adapters.add("fill", (fill, target) => {
      const colors = chart.get("colors");
      return colors ? colors.getIndex(series.columns.indexOf(target)) : fill;
    });
    
    series.columns.template.adapters.add("stroke", (stroke, target) => {
      const colors = chart.get("colors");
      return colors ? colors.getIndex(series.columns.indexOf(target)) : stroke;
    });
    

    xAxis.data.setAll(this.indicators);
    series.data.setAll(this.indicators);

    series.appear(1000);
    chart.appear(1000, 100);
  }


  onFilterChange(filterValues: any): void {
    // Set the params based on filter values
    let params: any = {
      stateId: filterValues.state_Id ? filterValues.state_Id : undefined,
      districtId: filterValues.district_Id ? filterValues.district_Id : undefined,
      blockId: filterValues.block_Id ? filterValues.block_Id : undefined,
      chwId: filterValues.chw_Id ? filterValues.chw_Id : undefined,
      userLevel: this.currentUser?.user.designation // Ensure currentUser is initialized
    };
  
    // Conditionally add date range if available
    if (filterValues.from_date && filterValues.to_date) {
      params['fromDate'] = filterValues.from_date;
      params['toDate'] = filterValues.to_date;
    }
  
    // Call the service with the params
    this.getAllData(params);
  }

  getDataFromLocalStorage(): void {
    let allUser = localStorage.getItem('currentUser');
    
    if (allUser) {
      this.currentUser = JSON.parse(allUser); 
      if (this.currentUser.user.designation === 'SM') {
        this.stateId = this.currentUser.user['stateId'];
      } else if (this.currentUser.user.designation === 'DL') {
        this.stateId = this.currentUser.user['stateId'];
        this.districtId = this.currentUser.user['districtId'];
      } else if (this.currentUser.user.designation === 'BO') {
        this.stateId = this.currentUser.user['stateId'];
        this.districtId = this.currentUser.user['districtId'];
        this.blockId = this.currentUser.user['blockId'];
      } else if (this.currentUser.user.designation === 'CHW') {
        this.stateId = this.currentUser.user['stateId'];
        this.districtId = this.currentUser.user['districtId'];
        this.blockId = this.currentUser.user['blockId'];
        this.chwId = this.currentUser.user['chwId'];
      }
    }
  
    // Initialize params based on user's access level
    this.params = {
      stateId: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      districtId: Array.isArray(this.districtId) ? this.districtId : [this.districtId],
      blockId: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      chwId: Array.isArray(this.chwId) ? this.chwId : [this.chwId],
      userLevel: this.currentUser?.user.designation
    };
  
    this.getAllData(this.params);
  }

  getAllData(paramsValue: any): void {
    this.countFoodAwarenessService.getCountFoodAwareness(paramsValue).subscribe(
      (response) => {
        const data = response.data;
  
        // Map API response to the Indicator format
        this.indicators = [
          // { name: 'Total Mothers', value: data.totalMotherCommonQues},
          { name: 'Mothers aware on Diet diversity', value: data.foodAwarenessPercentage },
          // { name: 'Pregnant Women', value: data.totalMotherPW },
          { name: 'IFA Compliance', value: data.ifaDailyBasisPercentage },
          { name: 'Calcium Compliance', value: data.vitaminCConsumedPercentage }
        ];
  
        console.log(this.indicators); // Debugging output
      },
      (error) => {
        console.error('Error fetching food awareness data:', error);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  export(): void {
    // Implement export functionality
  }
}