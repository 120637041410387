import { Component, OnInit, NgZone, AfterViewInit, OnDestroy, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CommonModule } from '@angular/common';
import { FilterComponent } from '../filter/filter.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { YogaMentalHealthService } from '../Services/yoga-mental-health.service';
import { ServiceYogaMentalHealthService } from '../Services/service-yoga-mental-health.service';
import { environment } from '../../environment/environment.local';
import { HttpClient } from '@angular/common/http';
import { MalariaTreeComponent } from '../components/malaria-tree/malaria-tree.component';
import { TreeNode } from 'primeng/api';
import { StackedColumnChartComponent } from '../stacked-column-chart/stacked-column-chart.component';

interface ApiResponse {
  result: {
    totalCount: number;
    yogaCount: number;
    mentalStressCount: number;
    govtHelplineCount: number;
  };
}

interface IndicatorData {
  name: string;
  value: number;
  description: string;
}

interface TableData {
  firstName: string;
  age: number;
  mobile: string;
  beneficiaryStatus: string;
  fatherName: string;
  husbandName: string;
}

 interface StackedDataPoint {
  year: string;
  la: number;
  cd: number;
  colorla?: string;
  colorcd?: string;
}

@Component({
  selector: 'app-yoga-and-mental-health',
  standalone: true,
  imports: [
    CommonModule,
    FilterComponent,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MalariaTreeComponent,
    StackedColumnChartComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './yoga-and-mental-health.component.html',
  styleUrl: './yoga-and-mental-health.component.css',
})
export class YogaAndMentalHealthComponent
  implements OnInit, AfterViewInit, OnDestroy
{
   // Input to control visibility of tables
   @Input() showAndHideTablesOfYogaAndMentalHealth: boolean = true;
  isFilterVisible: boolean = false; // Variable to control visibility
  stateId = [];
  districtId = [];
  blockId = [];
  chwId = [];
  currentUser: any;
  params = {};
  colorla: string = '#77E4C8';
  colorcd: string = '#63aaf5';
  indicators: IndicatorData[] = [];

  tableData: TableData[] = [
    {
      firstName: 'Girsomnath',
      age: 20,
      mobile: '10',
      beneficiaryStatus: 'LM',
      fatherName: '10',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Yamunanagar',
      age: 25,
      mobile: '20',
      beneficiaryStatus: 'LM',
      fatherName: '5',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Veraval',
      age: 40,
      mobile: '20',
      beneficiaryStatus: 'LM',
      fatherName: '20',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Karnal',
      age: 30,
      mobile: '21',
      beneficiaryStatus: 'LM',
      fatherName: '9',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Hisar',
      age: 39,
      mobile: '23',
      beneficiaryStatus: 'LM',
      fatherName: '6',
      husbandName: 'Saroj Kumar',
    },
  ];

  dataSource: MatTableDataSource<TableData>;
  displayedColumns2: string[] = ['sn', 'name', 'age', 'fatherName', 'mobile'];
  dataSource2: MatTableDataSource<TableData> = new MatTableDataSource(
    this.tableData
  );

  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  private apiUrl = environment.apiUrl;
  YogaTreeData: any;
  stackedChartData: StackedDataPoint[] = [];

  treeColumnsYoga = [
    { field: 'name', header: 'Name' },
    { field: 'isAwareOfGovtHelpLine', header: 'Aware Of Govt HelpLine' },
    { field: 'isAnyMentalStress', header: 'Any Mental Stress' },
    { field: 'isAwareOfYoga', header: 'Aware Of Yoga' },
  ];

  villageId = [];

  constructor(
    private zone: NgZone,
    private yogamentalhealthservice: YogaMentalHealthService,
    private yogahealthservice: ServiceYogaMentalHealthService,
    private http: HttpClient
  ) {
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngOnInit(): void {
     let allUser = localStorage.getItem('currentUser');

     if (allUser) {
       let currentUser: any = JSON.parse(allUser);

       if (currentUser.user.designation === 'SM') {
         this.stateId = currentUser.user['stateId'];
       } else if (currentUser.user.designation === 'DL') {
         this.stateId = currentUser.user['stateId'];
         this.districtId = currentUser.user['districtId'];
       } else if (currentUser.user.designation === 'BO') {
         this.stateId = currentUser.user['stateId'];
         this.districtId = currentUser.user['districtId'];
         this.blockId = currentUser.user['blockId'];
       } else if (currentUser.user.designation === 'CHW') {
         this.stateId = currentUser.user['stateId'];
         this.districtId = currentUser.user['districtId'];
         this.blockId = currentUser.user['blockId'];
         this.villageId = currentUser.user['villageId'];
       }
     }
     let currentUser = localStorage.getItem('currentUser');
     let parsedUser = currentUser ? JSON.parse(currentUser) : null;

     this.params = {
       state_Id: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
       district_Id: Array.isArray(this.districtId)
         ? this.districtId
         : [this.districtId],
       block_Id: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
       village_Id: Array.isArray(this.villageId)
         ? this.villageId
         : [this.villageId],
       userLevel: parsedUser?.user?.designation,
     };
    this.lowAwarenessVsCouncil();
    this.getYogaAndMentalTreeData(this.params);
    this.getAllData({});
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.createChart();
    });
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
  // Method to toggle the visibility of the filter component
  toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }
  getYogaAndMentalTreeData(params: any) {
    this.http
      .get<any>(
        `${this.apiUrl}/MotherCommonQues/getYogaAndMentalTreeData` +
          '?params=' +
          JSON.stringify(params)
      )
      .subscribe(
        (res) => {
          console.log('response from getYogaAndMentalTreeData', res);
          this.YogaTreeData = this.transformData(res.data);
        },
        (err) => {
          console.error('Error on getYogaAndMentalTreeData', err);
        }
      );
  }

  transformData(input: any): TreeNode[] {
    const transform = (node: any, level: string, index: number): TreeNode => {
      const { parent, ...restNode } = node;

      const newNode: TreeNode = {
        key: `${level}-${index}`,
        data: { ...restNode },
        children: [],
      };

      if (node.districts) {
        newNode.children = node.districts.map((district: any, i: number) =>
          transform(district, 'district', i)
        );
      } else if (node.blocks) {
        newNode.children = node.blocks.map((block: any, i: number) =>
          transform(block, 'block', i)
        );
      } else if (node.chwList || node.chwlist) {
        if (node.chwList) {
          newNode.children = node.chwList.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        } else {
          newNode.children = node.chwlist.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        }
      }

      return newNode;
    };

    return input.map((item: any, index: number) =>
      transform(item, 'state', index)
    );
  }

  createChart() {
    try {
      const chartDiv = document.getElementById('chartdiv');
      if (!chartDiv) {
        console.error('Chart container not found');
        return;
      }

      this.root = am5.Root.new('chartdiv');
      this.root.setThemes([am5themes_Animated.new(this.root)]);

      this.chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: true,
          panY: true,
          wheelX: 'panX',
          wheelY: 'zoomX',
          pinchZoomX: true,
          paddingLeft: 0,
          paddingRight: 1,
        })
      );

      let cursor = this.chart.set('cursor', am5xy.XYCursor.new(this.root, {}));
      cursor.lineY.set('visible', false);

      let xRenderer = am5xy.AxisRendererX.new(this.root, {
        minGridDistance: 30,
        minorGridEnabled: true,
      });

      xRenderer.labels.template.setAll({
        rotation: 0,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
        fontSize: 10,
      });

      let xAxis = this.chart.xAxes.push(
        am5xy.CategoryAxis.new(this.root, {
          maxDeviation: 0.3,
          categoryField: 'name',
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(this.root, {}),
        })
      );

      let yRenderer = am5xy.AxisRendererY.new(this.root, {
        strokeOpacity: 0.1,
      });

      let yAxis = this.chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          maxDeviation: 0.3,
          renderer: yRenderer,
        })
      );

      // Create series for value
      let valueSeries = this.chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: 'Value',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          categoryXField: 'name',
          tooltip: am5.Tooltip.new(this.root, {
            labelText: '{name}: {valueY}',
          }),
        })
      );

      valueSeries.columns.template.setAll({
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
        strokeOpacity: 0,
      });

      this.chart.appear(1000, 100);
    } catch (error) {
      console.error('Error creating chart:', error);
    }
  }

  updateChart() {
    try {
      if (
        this.chart &&
        this.chart.xAxes.length > 0 &&
        this.chart.series.length > 0
      ) {
        let xAxis = this.chart.xAxes.getIndex(
          0
        ) as am5xy.CategoryAxis<am5xy.AxisRenderer>;
        let valueSeries = this.chart.series.getIndex(0) as am5xy.ColumnSeries;

        if (xAxis && valueSeries) {
          xAxis.data.setAll(this.indicators);
          valueSeries.data.setAll(this.indicators);
        }
      }
    } catch (error) {
      console.error('Error updating chart:', error);
    }
  }

  onFilterChange(filterValues: any): void {
    let params: any = {
      stateId: filterValues.state_Id ? filterValues.state_Id : undefined,
      districtId: filterValues.district_Id
        ? filterValues.district_Id
        : undefined,
      blockId: filterValues.block_Id ? filterValues.block_Id : undefined,
      chwId: filterValues.chw_Id ? filterValues.chw_Id : undefined,
      userLevel: this.currentUser?.user.designation,
    };

    if (filterValues.from_date && filterValues.to_date) {
      params['fromDate'] = filterValues.from_date;
      params['toDate'] = filterValues.to_date;
    }

    this.getAllData(params);
    this.getYogaAndMentalTreeData(filterValues);
  }

  getAllData(paramsValue: any): void {
    this.yogamentalhealthservice
      .getCountAwarenessForYogaMentalHealth(paramsValue)
      .subscribe(
        (response: ApiResponse) => {
          const result = response.result;
          console.log(`This is my data ${JSON.stringify(result)}`);

          this.indicators = [
            // {
            //   name: 'Total Mothers',
            //   value: result.totalCount,
            //   description: '',
            // },
            { name: 'Yoga Count', value: result.yogaCount, description: '' },
            // { name: 'Mental Stress Count', value: result.mentalStressCount, description: '' },
            {
              name: 'Govt Helpline Count',
              value: result.govtHelplineCount,
              description: '',
            },
          ];

          console.log(this.indicators);
          this.updateChart();
        },
        (error) => {
          console.error('Error fetching yoga and mental health data:', error);
        }
      );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  lowAwarenessVsCouncil(): void {
    this.yogahealthservice.getAwarenessCounts().subscribe(
      (res) => {
        console.log('response=====>', res);
        this.stackedChartData = this.transformStackedChartData(res.data);
        console.log('stackedChartData=====>', this.stackedChartData);
      },
      (err) => {
        console.error('Error on getLowAwarenessVsCounsellingDone', err);
      }
    );
  }

  transformStackedChartData(data: any[]): StackedDataPoint[] {
    return data.map((item) => ({
      year: item.year,
      la: item.la,
      cd: item.cd,
    }));
  }
}