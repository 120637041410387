import { Component, OnInit, OnDestroy, Input, SimpleChange, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-chw-graph',
  standalone: true,
  imports: [],
  templateUrl: './chw-graph.component.html',
  styleUrl: './chw-graph.component.css',
})
export class ChwGraphComponent implements OnChanges {
  @Input() Data!: any[];
  constructor(private cdr: ChangeDetectorRef) {}
  private root!: am5.Root;
  private colorPalette: string[] = [
    '#4682B4',
    '#6A5ACD',
    '#2F4F4F',
    '#708090',
    '#556B2F',
    '#8B4513',
    '#B22222',
    '#A52A2A',
    '#800080',
    '#3CB371',
    '#008080',
    '#8B0000',
    '#D2691E',
    '#C71585',
    '#191970',
    '#800000',
    '#9932CC',
    '#6A5ACD',
    '#FF6347',
    '#20B2AA',
    '#FFD700',
    '#FF4500',
    '#FF1493',
    '#1E90FF',
    '#FF8C00',
    '#FF4500',
    '#32CD32',
    '#B0C4DE',
    '#C0C0C0',
    '#4B0082',
  ];

  ngOnInit(): void {
    // Create root element
    this.root = am5.Root.new('chartdiv');

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Create chart
    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        pinchZoomX: false,
        pinchZoomY: false,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );

    // Add cursor
    let cursor = chart.set('cursor', am5xy.XYCursor.new(this.root, {}));
    cursor.lineY.set('visible', false);

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
    });

    xRenderer.grid.template.setAll({
      location: 1,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0.3,
        categoryField: 'CHWname',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(this.root, {
      strokeOpacity: 0.1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      })
    );

    // Create series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'score',
        sequencedInterpolation: true,
        categoryXField: 'CHWname',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{valueY}',
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
      width: am5.percent(40),
    });

    series.columns.template.adapters.add('fill', (fill, target) => {
      const index = series.columns.indexOf(target);
      const color = this.colorPalette[index % this.colorPalette.length];
      return am5.color(color);
    });

    // series.columns.template.adapters.add('stroke', (stroke, target) => {
    //   return chart.get('colors')!.getIndex(series.columns.indexOf(target));
    // });

    const sortedData = this.sortByScoreDescending(this.Data);

    xAxis.data.setAll(sortedData);
    series.data.setAll(sortedData);

    // Make stuff animate on load
    series.appear(1000);
    chart.appear(1000, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['Data'] && changes['Data'].currentValue) {
      console.log('Graph data updated in the graph component:', this.Data);

      const sortedData = this.sortByScoreDescending(this.Data);

      if (this.root) {
        const chart = this.root.container.children.getIndex(0) as
          | am5xy.XYChart
          | undefined;
        if (chart) {
       
          const xAxis = chart.xAxes.getIndex(
            0
          ) as am5xy.CategoryAxis<am5xy.AxisRenderer>;
          const series = chart.series.getIndex(0) as am5xy.ColumnSeries;

          if (xAxis && series) {
            xAxis.data.setAll(sortedData);
            series.data.setAll(sortedData);
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
  
    if (this.root) {
      this.root.dispose();
    }
  }

  sortByScoreDescending(arr: any) {
    return arr.sort((a: any, b: any) => b.score - a.score);
  }
}
