import { Component, OnInit, OnDestroy, Input, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

@Component({
  selector: 'app-funnel-chart',
  standalone: true,
  templateUrl: './funnel-chart.component.html',
  styleUrls: ['./funnel-chart.component.css']
})
export class FunnelChartComponent implements OnInit, OnDestroy, OnChanges {
  private root: am5.Root | undefined;
  private series: am5percent.FunnelSeries | undefined;
  private exporting: am5plugins_exporting.Exporting | undefined;

  @Input() chartData: { value: number; category: string }[] = [];
  @Input() containerId!: string;
  @Input() colors: string[] = [];
  @Output() itemClick = new EventEmitter<{ index: number }>();

  ngOnInit(): void {
    if (!this.containerId || !this.chartData || this.chartData.length === 0) {
      console.error('Container ID or chart data is missing.');
      return;
    }

    setTimeout(() => {
      this.initChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.root && this.series) {
      if (changes['chartData']) {
        this.updateChartData();
      }
      if (changes['colors'] && !changes['colors'].firstChange) {
        this.updateColors();
      }
    }
  }

  private initChart(): void {
    if (this.root) {
      this.root.dispose();
    }

    this.root = am5.Root.new(this.containerId);
    this.root._logo?.dispose();
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    const chart = this.root.container.children.push(
      am5percent.SlicedChart.new(this.root, {
        layout: this.root.verticalLayout,
      })
    );

    this.series = chart.series.push(
      am5percent.FunnelSeries.new(this.root, {
        alignLabels: false,
        orientation: 'vertical',
        valueField: 'value',
        categoryField: 'category',
      })
    );

    this.series.labels.template.setAll({
      text: '{category}: {value}',
    });

    this.series.slices.template.setAll({
      tooltipText: '{category}: {value}',
    });

    this.series.slices.template.events.on('click', (ev) => {
      const dataItem = ev.target.dataItem as am5.DataItem<am5percent.IFunnelSeriesDataItem>;
      if (dataItem) {
        const index = this.series?.dataItems.indexOf(dataItem);
        if (index !== undefined && index >= 0) {
          this.onItemClicked(index);
        }
      }
    });

    this.series.data.setAll(this.chartData);
    this.applyColors();

    const legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 15,
        marginBottom: 15,
      })
    );

    // Create export button container
    const buttonContainer = chart.children.push(
      am5.Container.new(this.root, {
        dx: -125,
        paddingTop: 5,
        layout: this.root.horizontalLayout,
        x: am5.p100,
        y: 0,
      })
    );

    // Create export button
    // const exportButton = buttonContainer.children.push(
    //   am5.Button.new(this.root, {
    //     paddingTop: 5,
    //     paddingBottom: 5,
    //     paddingLeft: 10,
    //     paddingRight: 10,
    //     label: am5.Label.new(this.root, {
    //       text: "Export"
    //     })
    //   })
    // );

    // Initialize exporting
    this.exporting = am5plugins_exporting.Exporting.new(this.root, {
      filePrefix: "funnel-chart",
      dataSource: this.chartData,
      pdfOptions: {
        addURL: false,
        disabled: false
      },
      csvOptions: {
        addColumnNames: true,
        separator: ",",
      },
    });

    // Create and set up the export menu
    const exportMenu = am5plugins_exporting.ExportingMenu.new(this.root, {
      exporting: this.exporting, // Link the menu to the exporting instance
    });

    this.series.appear(1000, 100);
  }

  private applyColors(): void {
    if (!this.series || !this.colors || this.colors.length === 0) {
      return;
    }

    this.series.slices.template.setAll({
      fillGradient: undefined,
      strokeWidth: 0,
    });

    this.chartData.forEach((_, index) => {
      const color = this.colors[index % this.colors.length];
      if (color) {
        const dataItem = this.series?.dataItems[index];
        if (dataItem) {
          dataItem.set('fill', am5.color(color));
        }
      }
    });
  }

  private updateChartData(): void {
    if (this.series) {
      this.series.data.setAll(this.chartData);
      this.applyColors();
    }
  }

  private updateColors(): void {
    this.applyColors();
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  onItemClicked(index: number): void {
    this.itemClick.emit({ index });
  }
}
