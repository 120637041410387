import { Component, OnInit, NgZone, AfterViewInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { FilterComponent } from '../filter/filter.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { StackedColumnChartComponent } from "../stacked-column-chart/stacked-column-chart.component";
import { TreeTableComponent } from "../tree-table/tree-table.component";
import { TreeTablePageComponent } from "../tree-table-page/tree-table-page.component";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environment/environment.local';
import { ContentObserver } from '@angular/cdk/observers';
import { AncTreeComponent } from '../components/anc-tree/anc-tree.component';
import { TreeNode } from 'primeng/api';


interface IndicatorData {
  name: string;
  value: number;
  description: string;
}

interface TableData {
    blockName: string;
    diarrhoeaCases: number;
    treatedAtFacility: string;
    beneficiaryStatus: string;
    treatedAtHome: string;
    husbandName: string;

}

@Component({
  selector: 'app-anc',
  standalone: true,
  imports: [
    CommonModule,
    FilterComponent,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSort,
    MatSortModule,
    StackedColumnChartComponent,
    TreeTableComponent,
    TreeTablePageComponent,
    AncTreeComponent,
  ], // Include CommonModule here
  templateUrl: './anc.component.html',
  styleUrls: ['./anc.component.css'],
})
export class ANCComponent implements OnInit, AfterViewInit, OnDestroy {
  //hide anc table from mother-six-pillar-dashboard
  @Input() showAndHideTablesOfAnc: boolean = true;
  

  isFilterVisible: boolean = false; // Variable to control visibility
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  stackedChartData: any[] = [];
  treeAncCount: any;
  indicators: IndicatorData[] = [
    {
      name: 'Early Registration',
      value: 75,
      description: '% women with early registration of pregnancy',
    },
    {
      name: 'ANC Compliance',
      value: 82,
      description: '% women with completed ANC as per schedule',
    },
    {
      name: 'ANC Access',
      value: 91,
      description: '% women received ANC in different facilities',
    },
    {
      name: 'ANC Awareness',
      value: 87,
      description: '% women aware of having 4 ANC during pregnancy',
    },
    {
      name: 'ANC Importance',
      value: 94,
      description: '% women having positive attitude towards ANC',
    },
  ];

  tableData: TableData[] = [
    {
      blockName: 'uaithal',
      diarrhoeaCases: 9,
      treatedAtFacility: '6',
      beneficiaryStatus: 'LM',
      treatedAtHome: '3',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Kaithal',
      diarrhoeaCases: 22,
      treatedAtFacility: '16',
      beneficiaryStatus: 'LM',
      treatedAtHome: '6',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Manesar',
      diarrhoeaCases: 20,
      treatedAtFacility: '11',
      beneficiaryStatus: 'LM',
      treatedAtHome: '9',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Pataudi',
      diarrhoeaCases: 10,
      treatedAtFacility: '6',
      beneficiaryStatus: 'LM',
      treatedAtHome: '4',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'yaithal',
      diarrhoeaCases: 19,
      treatedAtFacility: '12',
      beneficiaryStatus: 'LM',
      treatedAtHome: '7',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Jind',
      diarrhoeaCases: 11,
      treatedAtFacility: '9',
      beneficiaryStatus: 'LM',
      treatedAtHome: '2',
      husbandName: 'Saroj Kumar',
    },
  ];

  stateId = [];
  districtId = [];
  blockId = [];
  villageId = [];

  treeColumnsANC = [
    // {
    //   field: 'Id',
    //   header: 'id',
    // },
    {
      field: 'name',
      header: 'Name',
    },
    {
      field: 'pregnancyRegMonth',
      header: 'Early Registration',
    },
    {
      field: 'ancReceivedCount',
      header: 'ANC Compliance',
    },
    {
      field: 'isPregnancyReg',
      header: 'ANC Access',
    },
    {
      field: 'ancImpOptions',
      header: 'ANC Awareness',
    },
    {
      field: 'ancImportanceAndAwareness',
      header: 'ANC Importance',
    },
  ];
  params = {};

  dataSource: MatTableDataSource<TableData>;
  displayedColumns2: string[] = [
    'sn',
    'blockName',
    'diarrhoeaCases',
    'treatedAtHome',
    'treatedAtFacility',
  ];
  dataSource2: MatTableDataSource<TableData> = new MatTableDataSource(
    this.tableData
  ); // Added dataSource2 initialization

  private root!: am5.Root;
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private zone: NgZone) {
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngOnInit(): void {
    let allUser = localStorage.getItem('currentUser');

    if (allUser) {
      let currentUser: any = JSON.parse(allUser);

      if (currentUser.user.designation === 'SM') {
        this.stateId = currentUser.user['stateId'];
      } else if (currentUser.user.designation === 'DL') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
      } else if (currentUser.user.designation === 'BO') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
      } else if (currentUser.user.designation === 'CHW') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
        this.villageId = currentUser.user['villageId'];
      }
    }
    let currentUser = localStorage.getItem('currentUser');
    let parsedUser = currentUser ? JSON.parse(currentUser) : null;

    this.params = {
      state_Id: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      district_Id: Array.isArray(this.districtId)
        ? this.districtId
        : [this.districtId],
      block_Id: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      village_Id: Array.isArray(this.villageId)
        ? this.villageId
        : [this.villageId],
      userLevel: parsedUser?.user?.designation,
    };

    this.fetchANCData(this.params);
    this.stackedChartData = [
      { year: 'ORS', la: 65, cd: 65 },
      { year: 'ZINC', la: 75, cd: 55 },
      { year: 'DIARRHOEA', la: 75, cd: 55 },
    ];
    // this.dataSource2=this.tableData
    // this.dataSource2.paginator = this.paginator;
    this.getANCHierarchyData(this.params);
  }
  // Method to toggle the visibility of the filter component
  toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }
  fetchANCData(params: any): void {
    this.http
      .get<{ data: IndicatorData[] }>(
        `${this.apiUrl}AncFollowUps/anc-data` +
          '?params=' +
          JSON.stringify(params)
      )
      .subscribe(
        (response) => {
          this.indicators = response.data; // Update indicators with data from the API
        },
        (error) => {
          console.error('Error fetching ANC data:', error);
        }
      );
  }

  getANCHierarchyData(params: any): void {
    console.log('this is the params passing', params);

 

    this.http
      .get<any>(
        `${this.apiUrl}AncFollowUps/getANCHierarchyData`   +'?params=' +  JSON.stringify(params)
      )
      .subscribe(
        (res: any) => {
          this.treeAncCount = this.transformData(res);
        },
        (err: any) => {
          console.log('error occurred', err);
        }
      );
  }

  ngAfterViewInit(): void {
    this.dataSource2.paginator = this.paginator;
    this.dataSource2.sort = this.sort;
    this.zone.runOutsideAngular(() => {
      this.createChart();
    });
  }

  transformData(input: any): TreeNode[] {
    const transform = (node: any, level: string, index: number): TreeNode => {
      const { parent, ...restNode } = node;

      const newNode: TreeNode = {
        key: `${level}-${index}`,
        data: { ...restNode },
        children: [],
      };

      if (node.districts) {
        newNode.children = node.districts.map((district: any, i: number) =>
          transform(district, 'district', i)
        );
      } else if (node.blocks) {
        newNode.children = node.blocks.map((block: any, i: number) =>
          transform(block, 'block', i)
        );
      } else if (node.chwList || node.chwlist) {
        if (node.chwList) {
          newNode.children = node.chwList.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        } else {
          newNode.children = node.chwlist.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        }
      }

      return newNode;
    };

    const result = input.map((item: any, index: number) =>
      transform(item, 'state', index)
    );

    return result;
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  createChart() {
    this.root = am5.Root.new('chartdiv');
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );

    let cursor = chart.set('cursor', am5xy.XYCursor.new(this.root, {}));
    cursor.lineY.set('visible', false);

    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fontSize: 10,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0.3,
        categoryField: 'name',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(this.root, {
      strokeOpacity: 0.1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      })
    );

    let series = chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        sequencedInterpolation: true,
        categoryXField: 'name',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{valueY}',
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
    });
    series.columns.template.adapters.add('fill', (fill, target) => {
      const colors = chart.get('colors');
      return colors ? colors.getIndex(series.columns.indexOf(target)) : fill;
    });

    series.columns.template.adapters.add('stroke', (stroke, target) => {
      const colors = chart.get('colors');
      return colors ? colors.getIndex(series.columns.indexOf(target)) : stroke;
    });

    xAxis.data.setAll(this.indicators);
    series.data.setAll(this.indicators);

    series.appear(1000);
    chart.appear(1000, 100);
  }

  onFilterChange(filterValues: any): void {
    // Implement filter logic here
    let params: any = {
      stateId: filterValues.state_Id ? filterValues.state_Id : undefined,
      districtId: filterValues.district_Id
        ? filterValues.district_Id
        : undefined,
      blockId: filterValues.block_Id ? filterValues.block_Id : undefined,
      chwId: filterValues.chw_Id ? filterValues.chw_Id : undefined,
      //userLevel: this.currentUser?.user.designation,
    };

    if (filterValues.from_date && filterValues.to_date) {
      params['fromDate'] = filterValues.from_date;
      params['toDate'] = filterValues.to_date;
    }

    this.fetchANCData(params);
    this.getANCHierarchyData(params);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  export(): void {
    // Implement export functionality
  }
}