import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TargetService } from '../Services/target.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-team-performance-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './team-performance-table.component.html',
  styleUrl: './team-performance-table.component.css',
})
export class TeamPerformanceTableComponent {
  data: any[] = [];

  constructor(
    private targetService: TargetService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  september: any[] = [];
  october: any[] = [];
  november: any[] = [];

  sepetemberTableData: any[] = [];
  octoberTableData: any[] = [];
  novemberTableData: any[] = [];
  monthlyData: any[] = [];
  showDistrictWiseTable: boolean = false;
  typewise: any;
  showBlockWiseTable: boolean = false;
  showStateWiseTable: boolean = false;

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.typewise = params.get('typewise');
      console.log(this.typewise, '..............');
    });

    if (this.typewise == 'blockwise') {
      this.showBlockWiseTable = true;
      this.getBlockWiseData();
    } else if (this.typewise == 'statewise') {
      this.showStateWiseTable = true;
      this.getStateWiseData();
    } else if (this.typewise == 'districtwise') {
      this.showDistrictWiseTable = true;
      this.getDistrictWiseData();
    }
    console.log('showStateWiseTable', this.showStateWiseTable);
    this.cdr.markForCheck();
  }

  getBlockWiseData() {
    this.targetService
      .getTargetVsAchievementBlockWiseTable({
        groupBy: 'blockwise-monthwise',
      })
      .subscribe((res: any) => {
        console.log('response i got for block', res.data);
        for (let data of res.data) {
          if (data.month == 9) {
            this.september.push(data);
          } else if (data.month == 10) {
            this.october.push(data);
          } else if (data.month == 11) {
            this.november.push(data);
          }
        }

        this.sepetemberTableData = this.transformApiResponse(this.september);
        this.octoberTableData = this.transformApiResponse(this.october);
        this.novemberTableData = this.transformApiResponse(this.november);

        this.monthlyData = [
          {
            monthName: 'September 2024',
            tableData: this.sepetemberTableData,
          },
          {
            monthName: 'October 2024',
            tableData: this.octoberTableData,
          },
          {
            monthName: 'November 2024',
            tableData: this.novemberTableData,
          },
        ];
      });
  }

  getStateWiseData() {
    console.log('inside statewise');
    this.targetService
      .getTargetVsAchievementTable({ groupBy: 'statewise' })
      .subscribe((res: any) => {
        console.log('response i got for state', res.data);

        // Clear previous data
        this.september = [];
        this.october = [];
        this.november = [];

        for (let data of res.data) {
          if (data.month == 9) {
            this.september.push(data);
          } else if (data.month == 10) {
            this.october.push(data);
          } else if (data.month == 11) {
            this.november.push(data);
          }
        }

        this.sepetemberTableData = this.transformStateWiseApiResponse(
          this.september
        );
        this.octoberTableData = this.transformStateWiseApiResponse(
          this.october
        );
        this.novemberTableData = this.transformStateWiseApiResponse(
          this.november
        );

        this.monthlyData = [
          {
            monthName: 'September 2024',
            tableData: this.sepetemberTableData,
          },
          {
            monthName: 'October 2024',
            tableData: this.octoberTableData,
          },
          {
            monthName: 'November 2024',
            tableData: this.novemberTableData,
          },
        ];

        this.showStateWiseTable = true;
        this.cdr.markForCheck();
      });
  }

  getDistrictWiseData() {
    this.targetService
      .getTargetVsAchievementDistrictWiseTable({
        groupBy: 'districtwise-monthwise',
      })
      .subscribe((res: any) => {
        console.log('response i got', res.data);
        for (let data of res.data) {
          if (data.month == 9) {
            this.september.push(data);
          } else if (data.month == 10) {
            this.october.push(data);
          } else if (data.month == 11) {
            this.november.push(data);
          }
        }

        this.sepetemberTableData = this.transformDistrictWiseApiResponse(
          this.september
        );
        this.octoberTableData = this.transformDistrictWiseApiResponse(
          this.october
        );
        this.novemberTableData = this.transformDistrictWiseApiResponse(
          this.november
        );

        this.monthlyData = [
          {
            monthName: 'September 2024',
            tableData: this.sepetemberTableData,
          },
          {
            monthName: 'October 2024',
            tableData: this.octoberTableData,
          },
          {
            monthName: 'November 2024',
            tableData: this.novemberTableData,
          },
        ];
      });
  }

  // Transform function as a method with type annotations
  transformApiResponse(data: any[]): any[] {
    const transformedData: { [key: string]: any } = {};

    // Iterate over each state
    data.forEach((blockData: any) => {
      const blockName: string = blockData.block;
      const indicators: any[] = blockData.indicators;

      // Iterate over each indicator for the current state
      indicators.forEach((indicatorData: any) => {
        const indicatorName: string = indicatorData.indicator;

        // Initialize the indicator row if it doesn't exist
        if (!transformedData[indicatorName]) {
          transformedData[indicatorName] = {
            indicator: indicatorName,
            Sakri: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Malegaon: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Rajsamand: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Kumbhalgarh: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Bhavnagar: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },

            Palitana: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Mahuva: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Talala: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Veraval: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Una: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
          };
        }

        // Fill in the data for the current state
        transformedData[indicatorName][blockName] = {
          targetActual: Math.round(indicatorData.targetActual),
          achieved: Math.round(indicatorData.achieved),
          weightage: indicatorData.weightage,
          percentageAchieved: indicatorData.percentageAchieved,
        };
      });
    });

    // Convert the object into an array of rows
    return Object.values(transformedData);
  }

  // Transform function as a method with type annotations
  transformStateWiseApiResponse(data: any[]): any[] {
    const transformedData: { [key: string]: any } = {};

    // Iterate over each state
    data.forEach((stateData: any) => {
      const stateName: string = stateData.state;
      const indicators: any[] = stateData.indicators;

      // Iterate over each indicator for the current state
      indicators.forEach((indicatorData: any) => {
        const indicatorName: string = indicatorData.indicator;

        // Initialize the indicator row if it doesn't exist
        if (!transformedData[indicatorName]) {
          transformedData[indicatorName] = {
            indicator: indicatorName,
            Maharashtra: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Gujarat: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Rajasthan: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
          };
        }

        // Fill in the data for the current state
        transformedData[indicatorName][stateName] = {
          targetActual: indicatorData.targetActual.toFixed(2),
          achieved: indicatorData.achieved.toFixed(2),
          weightage: indicatorData.weightage,
          percentageAchieved: indicatorData.percentageAchieved,
        };
      });
    });

    // Convert the object into an array of rows
    return Object.values(transformedData);
  }

  // Transform function as a method with type annotations
  transformDistrictWiseApiResponse(data: any[]): any[] {
    const transformedData: { [key: string]: any } = {};

    // Iterate over each state
    data.forEach((districtData: any) => {
      const districtName: string = districtData.district;
      const indicators: any[] = districtData.indicators;

      // Iterate over each indicator for the current state
      indicators.forEach((indicatorData: any) => {
        const indicatorName: string = indicatorData.indicator;

        // Initialize the indicator row if it doesn't exist
        if (!transformedData[indicatorName]) {
          transformedData[indicatorName] = {
            indicator: indicatorName,
            Dhule: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Washim: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Bhavnagar: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Girsomnath: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
            Rajsamand: {
              targetActual: '',
              achieved: '',
              weightage: '',
              percentageAchieved: '',
            },
          };
        }

        // Fill in the data for the current state
        transformedData[indicatorName][districtName] = {
          targetActual: Math.round(indicatorData.targetActual),
          achieved: Math.round(indicatorData.achieved),
          weightage: indicatorData.weightage,
          percentageAchieved: indicatorData.percentageAchieved,
        };
      });
    });

    // Convert the object into an array of rows
    return Object.values(transformedData);
  }

  goBack() {
    window.history.back();
  }
}
