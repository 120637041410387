import { Component, AfterViewInit, OnDestroy, Input } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';


@Component({
  selector: 'app-donut-chart-generator',
  standalone: true,
  templateUrl: './donut-chart-generator.component.html',
  styleUrls: ['./donut-chart-generator.component.css']
})
export class DonutChartGeneratorComponent implements AfterViewInit, OnDestroy {
  @Input() chartId!: string;
  @Input() chartData!: { country: string; sales: number }[];
  @Input() colors: string[] = []; // Receive dynamic colors
  private exporting?: am5plugins_exporting.Exporting;


  private root!: am5.Root;

  ngAfterViewInit() {
    this.root = am5.Root.new(this.chartId);
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    
     // Disable amCharts logo
    this.root._logo?.dispose();

    let chart = this.root.container.children.push(am5percent.PieChart.new(this.root, {
      radius: am5.percent(90),
      innerRadius: am5.percent(50),
      layout: this.root.verticalLayout // Set layout to vertical
    }));

    let series = chart.series.push(am5percent.PieSeries.new(this.root, {
      name: 'Series',
      valueField: 'sales',
      categoryField: 'country',
      fillField: 'color' // Link the color field for dynamic colors
    }));

    // Map the chart data with colors
    this.chartData = this.chartData.map((item, index) => ({
      ...item,
      color: am5.color(this.colors[index % this.colors.length]) // Apply colors dynamically
    }));

    series.data.setAll(this.chartData);

    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);

    series.slices.template.set('strokeOpacity', 0);
    series.slices.template.set('fillGradient', am5.RadialGradient.new(this.root, {
      stops: [{
        brighten: -0.8
      }, {
        brighten: -0.8
      }, {
        brighten: -0.5
      }, {
        brighten: 0
      }, {
        brighten: -0.5
      }]
    }));

    // Configure legend to be responsive
    let legend = chart.children.push(am5.Legend.new(this.root, {
      centerX: am5.percent(50), // Center the legend horizontally
      x: am5.percent(50),
      layout: am5.VerticalLayout.new(this.root, {}) // Use GridLayout for responsiveness with default settings
    }));

    // Legend styles
    legend.labels.template.setAll({
      maxWidth: 130,
      width: 150,
      oversizedBehavior: 'wrap', // Allow legend labels to wrap
      fontSize: 12,
      fontWeight: "500",
      textAlign: "left", // Left-align text in legend labels
      paddingRight: 5, // Add some space between the label and the logo
  
    });

    // Adjust value label styles
    legend.valueLabels.template.setAll({
      textAlign: 'right',
      marginLeft: -20, // Reduce space between legend names and values
   
    });

    // Set legend data
    legend.data.setAll(series.dataItems);

// Initialize exporting
this.exporting = am5plugins_exporting.Exporting.new(this.root, {
  filePrefix: 'funnel-chart',
  dataSource: this.chartData,
  pdfOptions: {
    addURL: false,
    disabled: false,
  },
  csvOptions: {
    addColumnNames: true,
    separator: ',',
  },
});

// Create and set up the export menu
const exportMenu = am5plugins_exporting.ExportingMenu.new(this.root, {
  exporting: this.exporting, // Link the menu to the exporting instance
});

    series.appear(1000, 100);
  }

  ngOnDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  }
}
