import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../../Services/authentication.service';

@Component({
  selector: 'app-food-health-impact',
  standalone: true,
  imports: [],
  templateUrl: './food-health-impact.component.html',
  styleUrls: ['./food-health-impact.component.css']
})
export class FoodHealthImpactComponent implements OnInit {
  safePbiUrl: SafeResourceUrl | undefined;
  baseUrl: string = 'https://app.powerbi.com/view?r=eyJrIjoiY2FmMzZkYzMtOWM0OS00NzI2LTgzODMtNzAzYjc0OWU4NjE4IiwidCI6ImZkMzBmNTBkLTViNzItNDc2OC04NTBmLWNmOTAwMzhjOWJmOSJ9';
  currentUser: any;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    // Parse currentUser from localStorage
    const storedUser = localStorage.getItem('currentUser');
    this.currentUser = storedUser ? JSON.parse(storedUser) : null;

    if (this.currentUser?.user.designation === 'Admin') {
      this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl);
    } else if (this.currentUser?.user?.id) {
      const userId = this.currentUser.user.id;

      this.authService.getPowerBi({ "where": { "userId": userId } }).subscribe(
        (response: any) => {
          if (response?.length > 0 && response[0].WHO7) {
            console.log(response);
            this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response[0].WHO7);
          } else {
            this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl); // Fallback URL
          }
        },
        (error:any) => {
          console.error('Error fetching Power BI URL:', error);
          this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl);
        }
      );
    } else {
      this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl);
    }
  }
}
