import { HTTP_INTERCEPTORS, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservedValueOf } from 'rxjs';
import { environment } from '../../environment/environment.local';
export const apiEndPoint = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class OverviewDashboardService {
  // B- 16 september
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  constructor(private http: HttpClient) {}

  // B- 16 september
  getChildPneumoniaFilter(filter: any): Observable<any> {
    const obj = {
      designation: filter.designation,
      stateId: filter.state_Id,
      blockId: filter.block_Id,
      districtId: filter.district_Id,
      villId: filter.village_Id,
      type: filter.type,
      fromDate: filter.from_date,
      toDate: filter.to_date,
    };
    const url =
      `${apiEndPoint}/ChildPneumonia/getChildPneumoniaCount` +
      '?params=' +
      JSON.stringify(obj);
    return this.http.get(url, { headers: this.headers });
  }

  getOverviewGraphData():Observable<any>{
     const url =  `${apiEndPoint}/videoCouncels/getOverviewGraphData` 
     return this.http.get(url, { headers: this.headers }); 
  }

  getChildDiarrheaCount(): Observable<any> {
    // return this.http.get(
    //   'http://localhost:3000/api/ChildDiarrheas/getChildDiarrheaCount'
    // );
    const url = `${apiEndPoint}/ChildDiarrheas/getChildDiarrheaCount`
    return this.http.get(url, { headers: this.headers });
  }

  getOtherIndicatorData():Observable<any>{
    const url =  `${apiEndPoint}/ChildRegs/all-stats` 
    return this.http.get(url, { headers: this.headers }); 
 }

  getChildDiarrheaFIltered(params: any): Observable<any> {
    // return this.http.get(
    //   'http://localhost:3000/api/ChildDiarrheas/getChildDiarrheaCount',
    //   { params }
    // );
    const url = `${apiEndPoint}/ChildDiarrheas/getChildDiarrheaCount`
    return this.http.get(url, { headers: this.headers });
  }
  getMotherRegData(params: any): Observable<any> {
    // Serializing params into a JSON string
    const httpParams = new HttpParams().set('params', JSON.stringify(params));

    // Making the HTTP GET request with the serialized parameters
    return this.http.get<any>(
      `${apiEndPoint}/MotherRegs/getMotherAndChildrenCount`,
      { params: httpParams }
    );
  }

  getHHListingData(params: any): Observable<any> {
    const httpParams = new HttpParams().set('params', JSON.stringify(params));
    return this.http.post<any[]>(
      `${apiEndPoint}/HHListings/getHHListingMemberAndChildCount`,
      { params: httpParams }
    );
    
  }
  getAvailabilityAtFacility(params: any): Observable<any> {
    const httpParams = new HttpParams().set('params', JSON.stringify(params));
    return this.http.post<any[]>(
      `${apiEndPoint}/HealthAssessmentForms/getAvailabilityGraph`,
      { params: httpParams }
    );    
  }
}
