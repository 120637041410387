import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../environment/environment.local"; // Corrected import


@Injectable({
  providedIn: "root"
})
export class VillageService {
  private readonly endpoint = `${environment.apiUrl}villages`;

  constructor(private http: HttpClient) {}

  getVillages(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set(
      'filter',
      JSON.stringify(params)
    );

    return this.http.get<any[]>(this.endpoint, { params: httpParams })
      .pipe(tap(response => response)); // Consider handling the response or any side effects
  }

  update(request: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/villages`, request)
      .pipe(tap(response => response)); // Consider handling the response or any side effects
  }
}
