import { AfterViewInit, ChangeDetectorRef, Component, inject, OnInit, ViewChild } from '@angular/core';
import { TreeTableComponent } from '../tree-table/tree-table.component';
import { TreeTableDataService } from '../Services/tree-data.service';
import { TreeNode } from 'primeng/api';
import { ContentObserver } from '@angular/cdk/observers';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { FilterComponent } from "../filter/filter.component";
import { TreeTablePageComponent } from "../tree-table-page/tree-table-page.component";
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PopupWeightageFormComponent } from '../popup-weightage-form/popup-weightage-form.component';
import { AuthenticationService } from '../Services/authentication.service'
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { TargetService } from '../Services/target.service';
import { ColumnChartComponent } from '../column-chart/column-chart.component';
import { ChwGraphComponent } from '../chw-graph/chw-graph.component';

@Component({
  selector: 'app-team-tracker',
  standalone: true,
  imports: [
    TreeTableComponent,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FilterComponent,
    TreeTablePageComponent,
    MatPaginator,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    DecimalPipe,
    ColumnChartComponent,
    ChwGraphComponent,
  ],
  templateUrl: './team-tracker.component.html',
  styleUrl: './team-tracker.component.css',
})
export class TeamTrackerComponent implements OnInit, AfterViewInit {
  isFilterVisible: boolean = false; // Variable to control visibility
  teamTrackerData!: TreeNode[];
  teamTrackerCols: any[] = [];
  currentUser: any;
  teamData: any;
  teamToDisplay: any;
  currentDate = new Date();

  toDate = `${this.currentDate.getFullYear()}-${String(
    this.currentDate.getMonth() + 1
  ).padStart(2, '0')}-${String(this.currentDate.getDate()).padStart(2, '0')}`;

  fromDate = `2024-09-01`;

  dataFromBackend = {
    'Salma Pathan': 2.3,
    'Anjana Valvi': 1.69,
    'Hira Valvi': 1.42,
    'Vaishali Bagul': 0.45,
    'Kalgi Bagul': 0.24,
    'Kalpana Vasave': 0.7,
    'Vaishnavi Narwade': 0.39,
    'Shital Nagale': 0.5,
    'Mohini Uike': 0.46,
    'Shubha Ingle': 0.39,
    'Kajal Rathod': 0.51,
    'Jaideep Shrimali': 0.29,
    'Rohit Paliwal': 0.36,
    'Kanku Purbia': 0.51,
    'Meena Paliwal': 1.64,
    'Manju Meghwal': 0.81,
    'Hemlata Joshi': 0.8,
    'Bhagyavanti Sen': 0.23,
    'Renuka Gohil': 0.8,
    'Ami Chauhan': 1.41,
    'Vilas Shiyal': 0.78,
    'Aksha Darjada': 0.54,
    'Sumera Majgul': 1.82,
    'Hansa Jethva': 1.26,
    'Hetal Makwana': 0.98,
    'Asmita Vasave': 0.83,
  };

  readonly dialog = inject(MatDialog);

  pageSize: number = 5;
  currentPage: number = 0;
  totalRecords: number = 0;
  paginatedData: TreeNode[] = [];
  displayedColumns: string[] = [
    'stateName',
    'districtName',
    'blockName',
    'name',
    'householdCoverage',
    'childCoverage',
    'child0To2Count',
    'child2To6Count',
    'diarrheaCases',
    'diarrheaFollowUpCoverage',
    'pneumoniaCases',
    'pneumoniaFollowUpCoverage',
    'childVaccination',
    'totalMotherReg',
    'motherPWCount',
    'newMomsCount',
    'motherMeetingCount',
    'ancFollowUpCount',
    'VHSND',
    'lowAwareness',
    'score',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  targetVsAchivementStateWiseMonthwise: any;
  targetVsAchivementCHWWiseMonthwise: any;
  targetVsAchivementDistrictWiseMonthwise: any;
  targetVsAchivementBlockWiseMonthwise: any;
  targetVsAchivementCHWWise: any;
  statewiseColors: string[] = ['#CC5A4A', '#4ACC5A', '#4A5ACC'];
  blockwiseColors: string[] = ['#CC5A4A', '#4ACC5A', '#4A5ACC'];
  districtwiseColors: string[] = [
    '#CC5A4A',
    '#CC5A4A',
    '#4ACC5A',
    '#4ACC5A',
    '#4A5ACC',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  chwTableData: any[] = [];
  private _liveAnnouncer = inject(LiveAnnouncer);

  isPopupOpen = false;
  name = '';
  email = '';

  sortOrder: { [key: string]: boolean } = {
    stateName: true,
    districtName: true,
    blockName: true,
    chw: true,
  };

  constructor(
    private authenticationService: AuthenticationService,
    private targetService: TargetService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadData();

    this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'statewise-monthwise',
      })
      .subscribe((res) => {
        this.targetVsAchivementStateWiseMonthwise = res;
        console.log(
          'state wise data',
          this.targetVsAchivementStateWiseMonthwise
        );
      });
    this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'chwwise-monthwise',
      })
      .subscribe((res) => {
        this.targetVsAchivementCHWWiseMonthwise = res;
        console.log(
          'chw month wise data',
          this.targetVsAchivementCHWWiseMonthwise
        );
      });

    this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'districtwise-monthwise',
      })
      .subscribe((res) => {
        this.targetVsAchivementDistrictWiseMonthwise = res;
        console.log(
          'district wise data',
          this.targetVsAchivementDistrictWiseMonthwise
        );
      });

    this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'blockwise-monthwise',
      })
      .subscribe((res) => {
        console.log('Result New.................block', res);
        this.targetVsAchivementBlockWiseMonthwise = res;
        console.log(
          'block wise data',
          this.targetVsAchivementBlockWiseMonthwise
        );

        console.log(
          'targetVsAchivementBllockWiseMonthwise',
          this.targetVsAchivementBlockWiseMonthwise
        );
      });

    // for chw wise table

    this.targetService
      .getTargetVsAchievementCHWWiseTable({
        group: 'chwwise-monthwise',
      })
      .subscribe(
        (res: any) => {
          this.chwTableData = res.data;
          console.log('this.chwTableData', this.chwTableData);
          this.targetVsAchivementCHWWise = res.graph;
          console.log('CHW GRAPH DATA', this.targetVsAchivementCHWWise);
        },
        (err) => {
          console.log('error in the api', err);
        }
      );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }
  openDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    this.dialog.open(PopupWeightageFormComponent, {
      width: '80vw',
      maxWidth: '80vw',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openPopup(): void {
    this.isPopupOpen = true;
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }

  submitForm(): void {
    this.closePopup();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.paginateData();
  }

  paginateData() {
    const start = this.currentPage * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedData = this.teamTrackerData.slice(start, end);
  }

  loadData() {
    console.log('Loading team tracker data...');
    const params = {};
    this.authenticationService
      .getTeamTracker(params)
      .pipe(
        catchError((error) => {
          console.error('Error fetching team tracker data:', error);
          if (error.status === 401) {
            console.error(
              'Authorization error. Please check if you are logged in and have the correct permissions.'
            );
          }
          return of([]);
        })
      )
      .subscribe((response: any) => {
        console.log('Team tracker data received:', response);
        if (Array.isArray(response.result)) {
          const transformedData = this.transformData(response.result);
          this.dataSource.data = transformedData;
          console.log('Transformed data:', transformedData);
        } else {
          console.error('Unexpected response format:', response);
          this.dataSource.data = [];
        }
      });
  }

  transformData(data: any[]): any[] {
    return data.map((item) => ({
      name: item.name,
      stateName: item.stateName,
      districtName: item.districtName,
      blockName: item.blockName,
      householdCoverage: item.householdCoverage,
      motherPWCount: item.motherPWCount,
      totalMotherReg: item.totalMotherReg,
      newMomsCount: item.newMomsCount,
      childCoverage: item.childCoverage,
      child0To2Count: item.child0To2Count,
      child2To6Count: item.child2To6Count,
      diarrheaCases: item.diarrheaCases,
      diarrheaFollowUpCoverage: item.diarrheaFollowUpCoverage,
      pneumoniaCases: item.pneumoniaCases,
      pneumoniaFollowUpCoverage: item.pneumoniaFollowUpCoverage,
      childVaccination: item.childVaccination,
      motherMeetingCount: item.motherMeetingCount,
      ancFollowUpCount: item.ancFollowUpCount,
      targetData: item.targetData,
    }));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  calculatePercentage(value: number, target: number): number {
    return target > 0 ? (value / target) * 100 : 0;
  }

  expoeting() {
    window.open(
      window.location.origin + '/teamPerformance/statewise',
      '_blank'
    );
  }
  targetvsAchievementTableDistrictWise() {
    window.open(
      window.location.origin + '/teamPerformance/districtwise',
      '_blank'
    );
  }

  targetvsachievementblockwise() {
    window.open(
      window.location.origin + '/teamPerformance/blockwise',
      '_blank'
    );
  }

  onFilterChange(formattedValues: any) {
    console.log('Received formatted values:', formattedValues);
    if (formattedValues.fromDate && formattedValues.toDate)
    { 
          this.fromDate = formattedValues.fromDate;
          this.toDate = formattedValues.toDate;
    }
     
    const convertedValues = {
      ...formattedValues,
      fromDate: formattedValues.fromDate
        ? new Date(formattedValues.fromDate).getTime()
        : null,
      toDate: formattedValues.toDate
        ? new Date(formattedValues.toDate).getTime()
        : null,
    };

    console.log('Converted Values with Timestamps:', convertedValues);

    this.targetService
      .getTargetVsAchievementCHWWiseTable({
        group: 'chwwise-monthwise',
        ...convertedValues,
      })
      .subscribe(
        (res: any) => {
          this.chwTableData = res.data;
          console.log('this.chwTableData', this.chwTableData);
          this.targetVsAchivementCHWWise= res.graph
          console.log('updated filtered graph', this.targetVsAchivementCHWWise);
              this.cdr.detectChanges();
          
  
        },
        (err) => {
          console.log('error in the api', err);
        }
      );
  }

  sortTable(column: string): void {
    const order = this.sortOrder[column] ? 1 : -1;

    this.chwTableData.sort((a: any, b: any) => {
      const valueA = a[column] !== undefined ? a[column] : '';
      const valueB = b[column] !== undefined ? b[column] : '';

      if (column === 'score') {
        return (Number(valueA) - Number(valueB)) * order;
      } else {
        const valueAString = valueA.toString().toLowerCase();
        const valueBString = valueB.toString().toLowerCase();

        if (valueAString < valueBString) {
          return -1 * order;
        }
        if (valueAString > valueBString) {
          return 1 * order;
        }
        return 0;
      }
    });

    this.sortOrder[column] = !this.sortOrder[column];
  }
}
