import { Injectable } from '@angular/core';
import { environment } from '../../environment/environment.local';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceYogaMentalHealthService {

  private readonly endpoint = `${environment.apiUrl}Mothercommonques`;
  constructor(private http: HttpClient) {}
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });
  getCountMalariaAwareness(params: any): Observable<any> {
    const url =
    `${this.endpoint}/count-malaria-awareness` +
    '?params=' +
    JSON.stringify(params); 
  return this.http.get(url, { headers: this.headers });
  }

  getAwarenessCounts(): Observable<any> {
    const url =
    `${this.endpoint}/getAwarenessCounts`
    return this.http.get(url);
  }
}
