// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading-class{
    font-size: 25px !important;
    margin-bottom: -5px !important;
    margin-top: 10px !important;
    color: black;
    background-color: #b8e5c9;
    padding-left: 10px;
}
.heading-class2{
    background-color: #c4b5fd;
    color: black;
    font-size: 25px !important;
    margin-bottom: -25px !important;
    margin-top: 15px !important;
    padding-left: 10px;
    margin: 15px;
}
.sub-heading-class{
    font-size: 22px !important;
    margin-bottom: -5px !important;
}
.sub-heading-class2{
    font-size: 22px !important;
    margin-bottom: -5px !important;
    padding-left: 10px;
}
.sub-heading-para{
    font-size: 20px !important;
}
.sub-heading-para2{
    font-size: 20px !important;
    padding-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/community-intervention/community-intervention.component.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,8BAA8B;IAC9B,2BAA2B;IAC3B,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,0BAA0B;IAC1B,+BAA+B;IAC/B,2BAA2B;IAC3B,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,0BAA0B;IAC1B,8BAA8B;AAClC;AACA;IACI,0BAA0B;IAC1B,8BAA8B;IAC9B,kBAAkB;AACtB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,0BAA0B;IAC1B,kBAAkB;AACtB","sourcesContent":[".heading-class{\r\n    font-size: 25px !important;\r\n    margin-bottom: -5px !important;\r\n    margin-top: 10px !important;\r\n    color: black;\r\n    background-color: #b8e5c9;\r\n    padding-left: 10px;\r\n}\r\n.heading-class2{\r\n    background-color: #c4b5fd;\r\n    color: black;\r\n    font-size: 25px !important;\r\n    margin-bottom: -25px !important;\r\n    margin-top: 15px !important;\r\n    padding-left: 10px;\r\n    margin: 15px;\r\n}\r\n.sub-heading-class{\r\n    font-size: 22px !important;\r\n    margin-bottom: -5px !important;\r\n}\r\n.sub-heading-class2{\r\n    font-size: 22px !important;\r\n    margin-bottom: -5px !important;\r\n    padding-left: 10px;\r\n}\r\n.sub-heading-para{\r\n    font-size: 20px !important;\r\n}\r\n.sub-heading-para2{\r\n    font-size: 20px !important;\r\n    padding-left: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
