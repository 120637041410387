import { Component, OnInit } from '@angular/core';
import { CommunityInterventionService } from '../Services/community-intervention.service';
import { CommunityInterventionStackGraphComponent } from "../community-intervention-stack-graph/community-intervention-stack-graph.component";
import { ClusterCommunityInterventionStackChartComponent } from "../cluster-community-intervention-stack-chart/cluster-community-intervention-stack-chart.component";

// Define structure for monthly regional data
interface RegionalMonthlyData {
  monthName: string;
  Gujarat: number;
  Rajasthan: number;
  Maharashtra: number;
}

// Define structure for chart data with month and region values
interface ChartDatabst {
  month: string;
  [key: string]: string | number;
}

// Configuration for chart series including names, fields, and colors
interface SeriesConfig {
  name: string;
  field: string;
  color: string;
}

@Component({
  selector: 'app-community-intervention',
  standalone: true,
  imports: [
    CommunityInterventionStackGraphComponent,
    ClusterCommunityInterventionStackChartComponent
  ],
  templateUrl: './community-intervention.component.html',
  styleUrls: ['./community-intervention.component.css'],
})
export class CommunityInterventionComponent implements OnInit {

  // Default chart colors for the graphs
  chartColors = ['#ef9b13', '#ece804', '#94d6ea'];

  // Arrays to hold data for each type of community intervention
  motherMeetingTotalData: ChartDatabst[] = [];
  motherMeetingCoverageData: ChartDatabst[] = [];
  VHSNDTotalData: ChartDatabst[] = [];
  VHSNDCoverageData: ChartDatabst[] = [];
  nukkadNatakData: ChartDatabst[] = [];
  nukkadNatakCoverage: ChartDatabst[] = [];

  // Configurations for each region in the chart
  seriesConfig: SeriesConfig[] = [
    { name: "Gujarat", field: "Gujarat", color: "#ef9b13" },
    { name: "Rajasthan", field: "Rajasthan", color: "#ece804" },
    { name: "Maharashtra", field: "Maharashtra", color: "#94d6ea" }
  ];

  constructor(private motherservice: CommunityInterventionService) {}

  // Lifecycle hook for component initialization
  ngOnInit(): void {
    this.initializeData();
  }

  // Initialize data loading for all community interventions
  private initializeData(): void {
    this.fetchMotherMeetingData();
    this.fetchVhsndData();
    this.fetchNukkadNatakData();
  }

  /**
   * Fetches total and coverage data for Mother Meetings
   * and maps it into the required chart format.
   */
  private fetchMotherMeetingData(): void {
    this.motherservice.getMotherMeetingsTotalData().subscribe(response => {
      this.motherMeetingTotalData = this.mapMeetingData(response.data);
    });
    this.motherservice.getMotherMeetingsCoverageData().subscribe(response => {
      this.motherMeetingCoverageData = this.mapMeetingData(response.data);
    });
  }

  /**
   * Fetches total and coverage data for VHSND
   * and maps it into the required chart format.
   */
  private fetchVhsndData(): void {
    this.motherservice.VHSNDTotalData().subscribe(response => {
      this.VHSNDTotalData = this.mapMeetingData(response.data);
    });
    this.motherservice.getMotherMeetingsCoverageData().subscribe(response => {
      this.VHSNDCoverageData = this.mapMeetingData(response.data);
    });
  }

  /**
   * Fetches total and coverage data for Nukkad Natak
   * and maps it into the required chart format.
   */
  private fetchNukkadNatakData(): void {
    this.motherservice.getNukkadNatakTotalData().subscribe(response => {
      this.nukkadNatakData = this.mapMeetingData(response.data);
    });
    this.motherservice.getNukkadNatakCoverageData().subscribe(response => {
      this.nukkadNatakCoverage = response?.data ?? [];
    });
  }

  /**
   * Maps raw monthly data into chart-compatible format.
   * @param data - array of RegionalMonthlyData from the API
   * @returns - array of ChartDatabst formatted for charts
   */
  private mapMeetingData(data: RegionalMonthlyData[]): ChartDatabst[] {
    return data.map(item => ({
      month: item.monthName,
      Gujarat: item.Gujarat,
      Rajasthan: item.Rajasthan,
      Maharashtra: item.Maharashtra
    }));
  }
}
