import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';

@Component({
  selector: 'app-anc-tree',
  standalone: true,
  imports: [CommonModule, TreeTableModule],
  templateUrl: './anc-tree.component.html',
  styleUrl: './anc-tree.component.css',
})
export class AncTreeComponent {
  @Input() dataSource: TreeNode[] = [];
  @Input() cols: any[] = [];
  tableData: any = [];
  constructor() {
    this.tableData = this.dataSource;
 
  }
  ngOnInit(): void {
    this.tableData = this.dataSource;

  }
}
