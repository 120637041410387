import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';

@Component({
  selector: 'app-diarrhea-count-tree-table',
  standalone: true,
  imports: [CommonModule, TreeTableModule],
  templateUrl: './diarrhea-count-tree-table.component.html',
  styleUrl: './diarrhea-count-tree-table.component.css',
})
export class DiarrheaCountTreeTableComponent implements OnInit, OnChanges {
  @Input() dataSource: TreeNode[] = [];
  @Input() cols: any[] = [];
  tableData: TreeNode[] = [];

  constructor() {}

  ngOnInit(): void {
    this.updateTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {
      this.updateTableData();
    }
  }

  private updateTableData(): void {
    this.tableData = this.dataSource;
  }

  onNodeExpand(event: any): void {
    console.log('Node expanded:', event.node);
  }

  onNodeCollapse(event: any): void {
    console.log('Node collapsed:', event.node);
  }
}
