import { Component, inject, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FilterComponent } from '../filter/filter.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AuthenticationService } from '../Services/authentication.service';
import { PopupWeightageFormComponent } from '../popup-weightage-form/popup-weightage-form.component';
import { TreeNode } from 'primeng/api';
import { catchError, of } from 'rxjs';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environment/environment.local';

interface IndicatorData {
  name: string;
  value: number;
  //description: string;
}
interface graphStateData {
  name: string;
  value: number;
  description: string;
}
interface graphDistrictData {
  name: string;
  value: number;
  description: string;
}
@Component({
  selector: 'app-team-performance',
  standalone: true,
  imports: [MatTableModule, MatPaginatorModule, MatSortModule, FilterComponent, MatPaginator, MatFormFieldModule, MatInputModule, FormsModule, MatIconModule, MatButtonModule, MatDividerModule, CommonModule, MatMenuModule, MatDialogModule, DecimalPipe],
  templateUrl: './team-performance.component.html',
  styleUrl: './team-performance.component.css'
})
export class TeamPerformanceComponent {
  // Input to control visibility of tables
  @Input() showTables: boolean = true;
  isFilterVisible: boolean = false; // Variable to control visibility
  teamTrackerData!: TreeNode[];
  graphStateData: graphStateData[] = [
    {
      name: 'Maharashtra',
      value:0,
      description: 'Maharashtra',
    },
    {
      name: 'Rajasthan',
      value:0,
      description: 'Rajasthan',
    },{
      name: 'Gujarat',
      value:0,
      description: 'Gujarat',
    },
  ];
  graphDistrictData: graphDistrictData[] = [
    {
      name: 'Dhule',
      value:0,
      description: 'Dhule',
    },
    {
      name: 'Washim',
      value:0,
      description: 'Washim',
    },{
      name: 'Rajsamand',
      value:0,
      description: 'Rajsamand',
    },{
      name: 'Bhavnagar',
      value:0,
      description: 'Bhavnagar',
    },{
      name: 'Girsomnath',
      value:0,
      description: 'Girsomnath',
    }
  ];

  private root!: am5.Root;
  private root1!: am5.Root;
  indicators: IndicatorData[] = [];
  indicators1: IndicatorData[] = [];
  teamTrackerCols: any[] = [];
  currentUser: any;
  teamData: any;
  teamToDisplay: any;

  readonly dialog = inject(MatDialog);
  private apiUrl = environment.apiUrl;
  pageSize: number = 5;
  currentPage: number = 0;
  totalRecords: number = 0;
  paginatedData: TreeNode[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  dataSourceDistrict: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    'stateName', 'mTarget', 'totalPercentageStateWise',
    'totalChildRegs','ChRegPercentage', 'totalMotherReg', 'MoRegPercentage', 'mmMeeting',
   'mmMeetingPercentage', 'FUP'
 ];
 displayedColumnsDistrict: string[] = [
  'districtName', 'mTarget','totalPercentageDistrictWise',
  'totalChildRegs','ChRegPercentage', 'totalMotherReg', 'MoRegPercentage', 'mmMeeting',
 'mmMeetingPercentage', 'FUP'
];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort ) sort!: MatSort;
  @ViewChild(MatPaginator) paginator1!: MatPaginator;
  @ViewChild(MatSort) sort1!: MatSort;
  
  
  private _liveAnnouncer = inject(LiveAnnouncer);

  isPopupOpen = false;
  name = '';
  email = '';

  constructor(private authenticationService: AuthenticationService,private http: HttpClient) { }
  params = {};
  ngOnInit() {
    this.loadData();
    this.loadDataDistrictWise();
     //this.getStateWiseGraphData(this.params);
     //this.getDistrictWiseGraphData(this.params);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSourceDistrict.paginator = this.paginator1;
    this.dataSourceDistrict.sort = this.sort1;
    this.getStateWiseGraphData(this.params);
     this.getDistrictWiseGraphData(this.params);
    this.createChart();
    this.createChartDistrict();
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
// Method to toggle the visibility of the filter component
toggleFilterVisibility(): void {
  this.isFilterVisible = !this.isFilterVisible;
}

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(PopupWeightageFormComponent, {
      width: '80vw',
      maxWidth: '80vw',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openPopup(): void {
    this.isPopupOpen = true;
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }

  submitForm(): void {
    console.log('Name:', this.name);
    console.log('Email:', this.email);
    this.closePopup();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.paginateData();
  }

  paginateData() {
    const start = this.currentPage * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedData = this.teamTrackerData.slice(start, end);
  }

  loadData() {
    const params={}
     this.authenticationService.getTeamPerformanceStateWise(params)
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            console.error('Authorization error. Please check if you are logged in and have the correct permissions.');
          }
          return of([]);
        })
      ).subscribe((response: any) => {
        if (Array.isArray(response.result)) {
          const transformedData = this.transformData(response.result);
          transformedData.sort((a, b) => b.totalPercentageStateWise - a.totalPercentageStateWise);
          this.dataSource.data = transformedData;
        } else {
          this.dataSource.data = [];
        }
      });
      
  }

        transformData(data: any[]): any[] {
          return data.map((item) => ({
            stateName: item.stateName,
            mTarget : item.mTarget,
            totalChildRegs : item.totalChildRegs,
            ChRegPercentage : item.ChRegPercentage,
            totalMotherReg : item.totalMotherReg,
            MoRegPercentage :item.MoRegPercentage,
            mmMeeting :item.mmMeeting,
            mmMeetingPercentage:item.mmMeetingPercentage,
            totalFollowUpDue : item.totalFollowUpDue,
            totalFollowUpDone  :item.totalFollowUpDone,
            FUP : item.totalFollowUpDone / item.totalFollowUpDue * 100,
            totalPercentageStateWise : item.totalPercentageStateWise
          }));
        }

        loadDataDistrictWise(){
          
          const params={}
          this.authenticationService.getTeamPerformanceDistrictWise(params)
            .pipe(
              catchError(error => {
                if (error.status === 401) {
                  console.error('Authorization error. Please check if you are logged in and have the correct permissions.');
                }
                return of([]);
              })
            ).subscribe((response: any) => {
              if (Array.isArray(response.result)) {
                const transformedData = this.transformDataDistrictWise(response.result);
                transformedData.sort((a, b) => b.totalPercentageDistrictWise - a.totalPercentageDistrictWise);
                this.dataSourceDistrict.data = transformedData;
              } else {
                this.dataSourceDistrict.data = [];
              }
            });
            
        }
      
              transformDataDistrictWise(data: any[]): any[] {
                return data.map((item) => ({
                  districtName: item.districtName,
                  mTarget : item.mTarget,
                  totalChildRegs : item.totalChildRegs,
                  ChRegPercentage : item.ChRegPercentage,
                  totalMotherReg : item.totalMotherReg,
                  MoRegPercentage :item.MoRegPercentage,
                  mmMeeting :item.mmMeeting,
                  mmMeetingPercentage:item.mmMeetingPercentage,
                  totalFollowUpDue : item.totalFollowUpDue,
                  totalFollowUpDone  :item.totalFollowUpDone,
                  FUP : item.totalFollowUpDone / item.totalFollowUpDue * 100,
                  totalPercentageDistrictWise : item.totalPercentageDistrictWise
                }));
              }
              getStateWiseGraphData(params :any){
                
                this.http.post<any[]>(`${this.apiUrl}MotherRegs/teamPerformanceStateWise` , JSON.stringify(params))
                    .subscribe(
                      (res: any) => {
                       
                        this.graphStateData.forEach((graphStateItem) => {
                          const matchingIndicatorState = res.result.find(
                            (indicator1: any) => indicator1.name === graphStateItem.name
                          );
                          
                          if (matchingIndicatorState) {
                            let FinalVal = 0;
                            if(matchingIndicatorState.value == null ||matchingIndicatorState.value == undefined){
                              FinalVal = 0;
                            }else{
                              FinalVal = matchingIndicatorState.value;
                            }
                            graphStateItem.value = FinalVal;
                          }
                        });
                        this.updateChartData();
                      },
                      (err) => {
                        console.log('Error from getStateGraphData', err);
                      }
                    );
                }

                updateChartData() {
                  if (this.root1) {
                    const chart = this.root1.container.children.getIndex(0) as am5xy.XYChart;
              
                    if (chart) {
                      const xAxis = chart.xAxes.getIndex(0);
                      const series = chart.series.getIndex(0);
                      this.graphStateData.sort((a, b) => b.value - a.value);
                      if (xAxis && series) {
                        xAxis.data.setAll(this.graphStateData);
                        series.data.setAll(this.graphStateData);
                      } else {
                        console.log('Unable to update chart: xAxis or series not found.');
                      }
                    } else {
                      console.log('Chart not found.');
                    }
                  }
                }
                createChart() {
                  this.root1 = am5.Root.new('chartdivState');
                  this.root1.setThemes([am5themes_Animated.new(this.root1)]);
          
                  const chart = this.root1.container.children.push(
                    am5xy.XYChart.new(this.root1, {
                      panX: true,
                      panY: true,
                      wheelX: 'panX',
                      wheelY: 'zoomX',
                      pinchZoomX: true,
                      paddingLeft: 0,
                      paddingRight: 1,
                    })
                  );
                
                  const cursor = chart.set('cursor', am5xy.XYCursor.new(this.root1, {}));
                  cursor.lineY.set('visible', false);
                
                  const xRenderer = am5xy.AxisRendererX.new(this.root1, {
                    minGridDistance: 20,
                    minorGridEnabled: true,
                  });
                
                  xRenderer.labels.template.setAll({
                    rotation: -90,
                    centerY: am5.p50,
                    centerX: am5.p100,
                    paddingRight: 20,
                    paddingLeft: 20,
                    fontSize: 12,
                  });
                
                  const xAxis = chart.xAxes.push(
                    am5xy.CategoryAxis.new(this.root1, {
                      maxDeviation: 0.3,
                      categoryField: 'name',
                      renderer: xRenderer,
                      tooltip: am5.Tooltip.new(this.root1, {}),
                    })
                  );
                
                  const yRenderer = am5xy.AxisRendererY.new(this.root1, {
                    strokeOpacity: 0.1,
                  });
                
                  const yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(this.root1, {
                      maxDeviation: 0.3,
                      renderer: yRenderer,
                    })
                  );
                
                  const series = chart.series.push(
                    am5xy.ColumnSeries.new(this.root1, {
                      name: 'Series 1',
                      xAxis: xAxis,
                      yAxis: yAxis,
                      valueYField: 'value',
                      sequencedInterpolation: true,
                      categoryXField: 'name',
                      tooltip: am5.Tooltip.new(this.root1, {
                        labelText: '{valueY}',
                      }),
                    })
                  );
                
                  series.columns.template.setAll({
                    cornerRadiusTL: 5,
                    cornerRadiusTR: 5,
                    strokeOpacity: 0,
                    width: am5.percent(30),
                  });
                
                  // Set custom colors (red, yellow, green) using am5.Color
                  const colors = [
                    am5.color("#018749"), // Celtic FC Green
                    am5.color("#E4D00A"), // Amber
                    am5.color("#5F9EA0")  // Cadet Blue
                ];
                
                
                  series.columns.template.adapters.add('fill', (fill, target) => {
                    return colors[series.columns.indexOf(target) % colors.length];  // Use am5.Color objects
                  });
                             
                  series.columns.template.adapters.add('stroke', (stroke, target) => {
                    return colors[series.columns.indexOf(target) % colors.length];  // Use am5.Color objects
                  });
                
                  xAxis.data.setAll(this.indicators);
                  series.data.setAll(this.indicators);
                
                  series.appear(1000);
                  chart.appear(1000, 100);
                }
                
                

              getDistrictWiseGraphData(params :any){
                 
                    this.http.post<any[]>(`${this.apiUrl}MotherRegs/teamPerformanceDistrictWise` , JSON.stringify(params)
                    )
                    .subscribe(
                      (res: any) => {
              
                        this.graphDistrictData.forEach((graphItem) => {
                          const matchingIndicatorDistrict = res.result.find(
                            (indicator: any) => indicator.name === graphItem.name
                          );
                        
                          if (matchingIndicatorDistrict) {
                            let FinalVal = 0;
                            if(matchingIndicatorDistrict.value == null ||matchingIndicatorDistrict.value == undefined){
                              FinalVal = 0;
                            }else{
                              FinalVal = matchingIndicatorDistrict.value;
                            }
                            graphItem.value = FinalVal;
                          }
                        });
              
                        this.updateChartDistrictData();
                      },
                      (err) => {
                        console.log('Error from getDistrictGraphData', err);
                      }
                    );
                }

                updateChartDistrictData() {
                  if (this.root) {
                    const chart = this.root.container.children.getIndex(0) as am5xy.XYChart;
              
                    if (chart) {
                      const xAxis = chart.xAxes.getIndex(0);
                      const series = chart.series.getIndex(0);
                      this.graphDistrictData.sort((a, b) => b.value - a.value);
                      if (xAxis && series) {
                        xAxis.data.setAll(this.graphDistrictData);
                        series.data.setAll(this.graphDistrictData);
                      } else {
                        console.log('Unable to update chart: xAxis or series not found.');
                      }
                    } else {
                      console.log('Chart not found.');
                    }
                  }
                }
              

              
                createChartDistrict() {
                  this.root = am5.Root.new('chartdivDistrict');
                  this.root.setThemes([am5themes_Animated.new(this.root)]);
                
                  let chart = this.root.container.children.push(
                    am5xy.XYChart.new(this.root, {
                      panX: true,
                      panY: true,
                      wheelX: 'panX',
                      wheelY: 'zoomX',
                      pinchZoomX: true,
                      paddingLeft: 0,
                      paddingRight: 1,
                    })
                  );
                
                  let cursor = chart.set('cursor', am5xy.XYCursor.new(this.root, {}));
                  cursor.lineY.set('visible', false);
                
                  let xRenderer = am5xy.AxisRendererX.new(this.root, {
                    minGridDistance: 20,
                    minorGridEnabled: true,
                  });
                
                  xRenderer.labels.template.setAll({
                    rotation: -90,
                    centerY: am5.p50,
                    centerX: am5.p100,
                    paddingRight: 15,
                    fontSize: 12,
                  });
                
                  let xAxis = chart.xAxes.push(
                    am5xy.CategoryAxis.new(this.root, {
                      maxDeviation: 0.3,
                      categoryField: 'name',
                      renderer: xRenderer,
                      tooltip: am5.Tooltip.new(this.root, {}),
                    })
                  );
                
                  let yRenderer = am5xy.AxisRendererY.new(this.root, {
                    strokeOpacity: 0.1,
                  });
                
                  let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(this.root, {
                      maxDeviation: 0.3,
                      renderer: yRenderer,
                    })
                  );
                
                  let series = chart.series.push(
                    am5xy.ColumnSeries.new(this.root, {
                      name: 'Series 1',
                      xAxis: xAxis,
                      yAxis: yAxis,
                      valueYField: 'value',
                      sequencedInterpolation: true,
                      categoryXField: 'name',
                      tooltip: am5.Tooltip.new(this.root, {
                        labelText: '{valueY}',
                      }),
                    })
                  );
                
                  series.columns.template.setAll({
                    cornerRadiusTL: 5,
                    cornerRadiusTR: 5,
                    strokeOpacity: 0,
                    width: am5.percent(30),
                  });
                
                  // Set custom colors (5 different colors)
                  const colors = [
                    am5.color("#018749"),  // Light blue
                    am5.color("#018749"),  // Yellow
                    am5.color("#E4D00A"),  // Orange
                    am5.color("#E4D00A"),  // Red
                    am5.color("#5F9EA0")   // Purple
                  ];
                
                  // Use colors in fill and stroke adapters
                  series.columns.template.adapters.add('fill', (fill, target) => {
                    return colors[series.columns.indexOf(target) % colors.length];  // Cycle through colors
                  });
                
                  series.columns.template.adapters.add('stroke', (stroke, target) => {
                    return colors[series.columns.indexOf(target) % colors.length];  // Cycle through colors
                  });
                
                  xAxis.data.setAll(this.indicators1);
                  series.data.setAll(this.indicators1);
                
                  series.appear(1000);
                  chart.appear(1000, 100);
                }
                
            
}
