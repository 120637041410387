// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  mat-radio-button { 
    margin:10px;
  }`, "",{"version":3,"sources":["webpack://./src/app/beneficiary-tracker/beneficiary-tracker.component.css"],"names":[],"mappings":";EACE;IACE,WAAW;EACb","sourcesContent":["\r\n  mat-radio-button { \r\n    margin:10px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
