// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* tbody .p-treetable-tbody tr td:first-child {
    width: 700px !important;
} */

table>tbody.p-treetable-tbody>tr>td:first-child {
    width: 700px !important;
}
.tree-table {
    width: 100%; 
}
.header-row, .body-row {
    display: flex;
}
.header-cell, .body-cell {
    flex: 1;
    padding: 8px; 
    border: 1px solid #000000; 
}

.header-cell {
    font-size: 1.05rem;
    color: #000000;
    background-color: rgba(128, 0, 128, 0.3); 
    font-weight: 400;
}
.body-cell {
color: #393738 !important;
    background-color: #fff9fc; 
    font-weight: 400;
}
.header-cell::first-letter {
    text-transform: capitalize !important;
}
.body-row:hover .body-cell {
    background-color: #dde0e2 ; /* Blue hover effect */

}`, "",{"version":3,"sources":["webpack://./src/app/components/pneumonia-count-tree-table/pneumonia-count-tree-table.component.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,uBAAuB;AAC3B;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB;AACA;IACI,OAAO;IACP,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,wCAAwC;IACxC,gBAAgB;AACpB;AACA;AACA,yBAAyB;IACrB,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,qCAAqC;AACzC;AACA;IACI,0BAA0B,EAAE,sBAAsB;;AAEtD","sourcesContent":["/* tbody .p-treetable-tbody tr td:first-child {\r\n    width: 700px !important;\r\n} */\r\n\r\ntable>tbody.p-treetable-tbody>tr>td:first-child {\r\n    width: 700px !important;\r\n}\r\n.tree-table {\r\n    width: 100%; \r\n}\r\n.header-row, .body-row {\r\n    display: flex;\r\n}\r\n.header-cell, .body-cell {\r\n    flex: 1;\r\n    padding: 8px; \r\n    border: 1px solid #000000; \r\n}\r\n\r\n.header-cell {\r\n    font-size: 1.05rem;\r\n    color: #000000;\r\n    background-color: rgba(128, 0, 128, 0.3); \r\n    font-weight: 400;\r\n}\r\n.body-cell {\r\ncolor: #393738 !important;\r\n    background-color: #fff9fc; \r\n    font-weight: 400;\r\n}\r\n.header-cell::first-letter {\r\n    text-transform: capitalize !important;\r\n}\r\n.body-row:hover .body-cell {\r\n    background-color: #dde0e2 ; /* Blue hover effect */\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
