// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .small-font-table .name-column {
  width: 200px;
  min-width: 200px;
}
::ng-deep .small-font-table .centered-column {
  text-align: center; 
}

::ng-deep .small-font-table {
  font-size: 0.8rem;
}

::ng-deep .small-font-table .p-treetable {
  border: 1px solid #dee2e6;
}

::ng-deep .small-font-table .p-treetable .p-treetable-thead > tr > th,
::ng-deep .small-font-table .p-treetable .p-treetable-tbody > tr > td {
  font-size: 0.8rem;
  border: 1px solid #0b0b0b;
  border-width: 0 0 1px 0;
}

::ng-deep .small-font-table .p-treetable .p-treetable-thead > tr > th:last-child,
::ng-deep .small-font-table .p-treetable .p-treetable-tbody > tr > td:last-child {
  border-right-width: 1px;
}

::ng-deep .small-font-table .p-treetable .p-treetable-thead > tr > th.left-aligned-header {
  text-align: left !important;
}

/* If you want to right-align the cell content as well, uncomment the following */
::ng-deep .small-font-table .p-treetable .p-treetable-tbody > tr > td {
  text-align: justify;
}
.table-responsive {
  overflow-x: auto; /* Enables horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/tree-table/tree-table.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,iBAAiB;EACjB,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;;EAEE,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA,iFAAiF;AACjF;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB,EAAE,iCAAiC;EACnD,iCAAiC,EAAE,uCAAuC;EAC1E,WAAW;AACb","sourcesContent":["::ng-deep .small-font-table .name-column {\r\n  width: 200px;\r\n  min-width: 200px;\r\n}\r\n::ng-deep .small-font-table .centered-column {\r\n  text-align: center; \r\n}\r\n\r\n::ng-deep .small-font-table {\r\n  font-size: 0.8rem;\r\n}\r\n\r\n::ng-deep .small-font-table .p-treetable {\r\n  border: 1px solid #dee2e6;\r\n}\r\n\r\n::ng-deep .small-font-table .p-treetable .p-treetable-thead > tr > th,\r\n::ng-deep .small-font-table .p-treetable .p-treetable-tbody > tr > td {\r\n  font-size: 0.8rem;\r\n  border: 1px solid #0b0b0b;\r\n  border-width: 0 0 1px 0;\r\n}\r\n\r\n::ng-deep .small-font-table .p-treetable .p-treetable-thead > tr > th:last-child,\r\n::ng-deep .small-font-table .p-treetable .p-treetable-tbody > tr > td:last-child {\r\n  border-right-width: 1px;\r\n}\r\n\r\n::ng-deep .small-font-table .p-treetable .p-treetable-thead > tr > th.left-aligned-header {\r\n  text-align: left !important;\r\n}\r\n\r\n/* If you want to right-align the cell content as well, uncomment the following */\r\n::ng-deep .small-font-table .p-treetable .p-treetable-tbody > tr > td {\r\n  text-align: justify;\r\n}\r\n.table-responsive {\r\n  overflow-x: auto; /* Enables horizontal scrolling */\r\n  -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
