import { Component, AfterViewInit } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { FamilyPlanningStackChartComponent } from "../family-planning-stack-chart/family-planning-stack-chart.component";
import { CommonModule } from '@angular/common';
import { FamilyPlanningStackChartHorizontalComponent } from "../family-planning-stack-char-horizontal/family-planning-stack-char-horizontal.component";
import { StepChartComponent } from "../step-chart/step-chart.component";
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';


type DropdownKey = 'dropdown1' | 'dropdown2'; // Define valid keys for dropdownOpen

@Component({
  selector: 'app-family-planning-usage-and-awareness',
  standalone: true,
  templateUrl: './family-planning-usage-and-awareness.component.html',
  styleUrls: ['./family-planning-usage-and-awareness.component.css'],
  imports: [FamilyPlanningStackChartComponent, CommonModule, FamilyPlanningStackChartHorizontalComponent, StepChartComponent,MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule]
})

export class FamilyPlanningUsageAndAwarenessComponent  {

  chartIdForAge: string = 'chartIdForAge';  // Dynamic chart ID
  chartIdForStateWise: string = 'chartIdForStateWise';  // Dynamic chart ID
  selectedIndicatorForAgeWise: string = '';
  selectedDistrictWise: string = '';
  selectedOptions: string[] = [];
  selectedmonth: string[] = [];
  dropdownOpen = { dropdown1: false, dropdown2: false };

 // Define your tile data here
 motherStats = [
  { title: 'Total Mothers', percentage: '100%' },
  { title: 'Using Any Modern Method', percentage: '60%' },
  { title: 'Using Spacing Method', percentage: '30%' }
];

// month wise chart creation
chartDataForMonthWise = [
  { "month": "January", "injectable": 30, "iuc": 10, "emergency": 20, "weekly": 10, "daily": 10, "condoms": 10 },
  { "month": "February", "injectable": 5, "iuc": 5, "emergency":10, "weekly": 20, "daily": 10, "condoms": 10 },
  { "month": "March", "injectable": 10, "iuc": 5, "emergency": 5, "weekly": 10, "daily": 5, "condoms": 5 },
  { "month": "April", "injectable": 10, "iuc": 30, "emergency": 3.0, "weekly": 1.0, "daily": 3.4, "condoms": 2.3 },
  { "month": "May", "injectable": 30, "iuc": 30, "emergency": 30, "weekly": 0.8, "daily": 3.2, "condoms": 1.8 },
  { "month": "June", "injectable": 10, "iuc": 30, "emergency": 30, "weekly": 1.3, "daily": 3.7, "condoms": 2.4 },
  { "month": "July", "injectable": 10, "iuc": 1.4, "emergency": 2.9, "weekly": 30, "daily": 3.5, "condoms": 2.1 },
  { "month": "August", "injectable": 10, "iuc": 1.5, "emergency": 3.0, "weekly": 30, "daily": 3.6, "condoms": 2.2 },
  { "month": "September", "injectable": 3.1, "iuc": 1.6, "emergency": 2.8, "weekly": 1.0, "daily": 3.4, "condoms": 2.0 },
  { "month": "October", "injectable": 2.8, "iuc": 1.4, "emergency": 30, "weekly": 0.9, "daily": 3.1, "condoms": 1.7 },
  { "month": "November", "injectable": 30, "iuc": 1.5, "emergency": 2.9, "weekly": 30, "daily": 3.5, "condoms": 2.3 },
  { "month": "December", "injectable": 3.2, "iuc": 1.6, "emergency": 30, "weekly": 1.2, "daily": 3.7, "condoms": 2.4 }
];

//table data for month wise values 
 tableDataforMonthDetails = [
  {
    title: '50',
    values: [20, 30, 25, 32, 15, 43, 33, 17, 19, 23, 25, 24]
  }
  // You can add more rows if needed
];
 tableDataforMonthDetails2 = [
  {
    // title: '50',
    values: [1611, 1844, 1077]  // target values of Gujrat , maharastra, rajasthan
  }
  // You can add more rows if needed
];
// Month headings
months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
months2 = ['Total Mothers of Gujarat', 'Total Mothers of Rajasthan', 'Total Mothers of Maharashtra'];
 
//char generate based on age 
  chartDataForAgeWise = [
    { age: '15-24', injectable: 12.18, iuc: 23.56, emergency: 5.07, weekly: 11.67, daily: 33.28, condoms: 53.73 },
    { age: '25-34', injectable: 5.78, iuc: 11.18, emergency: 5.91, weekly: 13.45, daily: 38.88, condoms: 54.37 },
    { age: '35-44', injectable: 0.82, iuc: 1.67, emergency: 7.53, weekly: 15.48, daily: 31.79, condoms:52.71 },
    { age: '45-54', injectable: 28.57, iuc: 57.14, emergency: 0, weekly: 0, daily: 57.14, condoms: 57.14 },
    { age: '55-64', injectable: 0, iuc: 0, emergency: 0, weekly: 0, daily: 0, condoms: 0 },
 
  ];
  chartDataForStateWise = [
    { age: 'Gujarat', injectable: 8.13, iuc: 19.67, emergency: 0.68, weekly: 5.95, daily: 20.11, condoms: 57.79 },
    { age: 'Rajasthan', injectable: 52.92, iuc: 48.09, emergency: 14.67, weekly: 27.76, daily: 74.18, condoms: 62.2 },
    { age: 'Maharashtra', injectable: 0.01, iuc: 19.79, emergency: 4.93, weekly: 10.52, daily: 29.60, condoms: 46.09 },
  ];
  
  indicatorsAgeWise = [
    { value: 'Injectable', name: 'Injectable' },
    { value: 'IUC', name: 'IUC' },
    { value: 'Emergency', name: 'Emergency' },
    { value: 'Weekly', name: 'Weekly' },
    { value: 'Daily', name: 'Daily' },
    { value: 'Condoms', name: 'Condoms' },
  ];
  indicatorsDistrictWise = [
    { value: 'Injectable', name: 'Injectable' },
    { value: 'IUC', name: 'IUC' },
    { value: 'Emergency', name: 'Emergency' },
    { value: 'Weekly', name: 'Weekly' },
    { value: 'Daily', name: 'Daily' },
    { value: 'Condoms', name: 'Condoms' },
  ];

  options: string[] = ['Bhavnagar', 'Dhule', 'Girsomnath', 'Washim','Rajsamand'];
  ageList: string[] = [
    '15-24', 
    '25-34', 
    '35-44', 
    '45-54', 
    '55-64', 
    '65-74', 
    '75-84', 
  ];  

  chartDataBasedOnDistrictAndIndicators = [
    { name: "15-24", value: 96 },
    { name: "25-34", value: 65 },
    { name: "35-44", value: 43 },
    { name: "45-54", value: 55 },
    { name: "55-64", value: 15 },
    { name: "65-74", value: 76 },
    { name: "75-84", value: 32 }
  ];
  chartDataBasedOnDistrict = [
    { name: "Bhavnagar", value: 93 },
    { name: "Dhule", value: 44 },
    { name: "Girsomnath", value: 65 },
    { name: "Washim", value: 77 },
    { name: "Rajsamand", value: 88 }  
];


  toggleDropdown(dropdown: DropdownKey) { // Use the DropdownKey type
    this.dropdownOpen[dropdown] = !this.dropdownOpen[dropdown];
  }
  toggleOption(option: string) {
    const index = this.selectedOptions.indexOf(option);
    index === -1 ? this.selectedOptions.push(option) : this.selectedOptions.splice(index, 1);
  }

  toggleItem(item: string) {
    const index = this.selectedmonth.indexOf(item);
    index === -1 ? this.selectedmonth.push(item) : this.selectedmonth.splice(index, 1);
  }
}