import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-food-health-impact',
  standalone: true,
  imports: [],
  templateUrl: './food-health-impact.component.html',
  styleUrl: './food-health-impact.component.css'
})
export class FoodHealthImpactComponent {
  safePbiUrl: SafeResourceUrl;
  
  constructor(private sanitizer: DomSanitizer) {
    // Sanitize the Power BI URL to safely embed it
    const baseUrl = 'https://app.powerbi.com/view?r=eyJrIjoiZTNiZDIzMmUtOTM1NS00NTYyLTk0NTgtNTVkNDA1MDQwNDEzIiwidCI6ImRkNjMwMGViLTRmZmUtNDc1Mi1hYThiLTNjNTQ1YzczZTA3MCJ9';
                    //  https://app.powerbi.com/view?r=eyJrIjoiNjNhMjNlOTMtNGNmMC00NWEyLTgzYWUtYzQ0ZTVmMGJhOTI3IiwidCI6ImRkNjMwMGViLTRmZmUtNDc1Mi1hYThiLTNjNTQ1YzczZTA3MCJ9
    // const urlWithParams = `${baseUrl}&pageName=ReportSection1`; // Change ReportSection1 to match your actual first page name
    
    this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(baseUrl);
  }
}
