import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
} from '@angular/forms';
import { StateService } from '../Services/HierarchyService/state-service.service';
import { DistrictService } from '../Services/HierarchyService/district-service.service';
import { BlockService } from '../Services/HierarchyService/block-service.service';
import { VillageService } from '../Services/HierarchyService/village-service.service';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AuthenticationService } from '../Services/authentication.service';
import { DatePipe } from '@angular/common';
import { OverviewDashboardService } from '../Services/overview-dashboard.service';
import moment from 'moment'; // Import Moment.js
import { FormService } from '../Services/form.service';
import { HHListingService } from '../Services/FormService/hhlisting.service';
import * as XLSX from 'xlsx';
import { consumerPollProducersForChange } from '@angular/core/primitives/signals';
import { ActivatedRoute } from '@angular/router';

interface Approval {
  value: boolean;
  viewValue: string;
}
interface SelectValues {
  value: boolean;
  viewValue: string;
}

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRadioModule,
    FormsModule,
    MatButtonToggleModule,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements OnInit {
  @Output() downloadIndicatorChanged = new EventEmitter<boolean>();
  showDatePickers: boolean = false;
  filterForm: FormGroup;
  @ViewChild('allSelected') private allSelected!: MatOption;
  @ViewChild('alSelected') private alSelected!: MatOption;
  @Output() filterChange = new EventEmitter<any>();
  forms: any[] = [];

  states: any[] = [];
  districts: any[] = [];
  blocks: any[] = [];
  chws: any[] = [];
  villages: any[] = [];
  currentUser: any;
  fromDate: Date | any[] = [];
  toDate: Date | any[] = [];
  showText: string = 'Select date';
  isDefaultDateRange = false;
  selectedDateRange: string = 'default';
  byDefaultChecked: boolean = true;
  isApproval: boolean = false;

  dateForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });

  approvalData = new FormGroup({
    isApproval: new FormControl(),
  });

  approval: Approval[] = [
    { value: true, viewValue: 'Approved' },
    { value: false, viewValue: 'All' },
  ];

  selectOptions: SelectValues[] = [
    { value: true, viewValue: 'Diarrhoea' },
    { value: true, viewValue: 'Pneumonia' },
    { value: true, viewValue: 'WHO 7 Point' },
    { value: true, viewValue: 'Mothers' },
    { value: true, viewValue: 'Meetings' },
  ];
  viewType = '';
  calType: 'year' | 'month' = 'month';
  activeDate = new Date();
  quarterStartDate: Date | null = null;
  quarterStartDate1!: Date;
  quarterStartDate2!: Date;
  quarterStartDate3!: Date;
  quarterStartDate4!: Date;
  quarterEndDate: Date | null = null;
  quarterEndDate1: Date | null = null;
  quarterEndDate2: Date | null = null;
  quarterEndDate3: Date | null = null;
  quarterEndDate4: Date | null = null;
  selectedValue!: string;
  showFormDropDown: boolean = false;

  constructor(
    private fb: FormBuilder,
    private blockService: BlockService,
    private districtService: DistrictService,
    private stateService: StateService,
    private villageService: VillageService,
    private chwService: AuthenticationService,
    private formService: FormService,
    private changeDetector: ChangeDetectorRef,
    private datePipe: DatePipe,
    private overviewDashboardService: OverviewDashboardService,
    private HHListingService: HHListingService,
    private activatedRoute: ActivatedRoute
  ) {
    this.filterForm = this.fb.group({
      stateId: [[]], // Initialize as empty array
      districtId: [[]], // Initialize as empty array
      blockId: [[]], // Initialize as empty array
      fromDate: [''],
      formId: [''],
      toDate: [''],
      fromTimestamp: [0],
      toTimestamp: [0],
    });
    this.dateForm = this.fb.group({
      fromDate: [''],
      toDate: [''],
    });
    this.approvalData = this.fb.group({
      isApproval: [''],
    });
  }

  ngOnInit() {
    this.loadForms();
    const userData = localStorage.getItem('currentUser');
    if (userData) {
      this.currentUser = JSON.parse(userData);
      this.initializeFormForUserAccess(this.currentUser);
    }
    this.filterForm.valueChanges.subscribe((values) => {
      this.onClose(values);
    });

    // to get the current route
    this.activatedRoute.url.subscribe((segments) => {
      const currentRoute = segments.map((segment) => segment.path).join('/');
      console.log('Current Route:', currentRoute);
      if (currentRoute == 'line-list') {
        this.showFormDropDown = true;
        console.log('showFormDropDown', this.showFormDropDown);
      }
    });
  }

  initializeFormForUserAccess(user: any) {
    const { designation, stateId, districtId, blockId, chwId } = user.user;

    if (designation === 'Admin') {
      this.loadStates();
    } else if (designation === 'SM') {
      this.filterForm.patchValue({ stateId: [stateId] });
      this.loadDistricts({ value: [stateId] });
    } else if (designation === 'DL') {
      this.filterForm.patchValue({
        stateId: [stateId],
        districtId: [districtId],
      });
      this.loadBlocks({ value: [districtId] });
    } else if (designation === 'BO') {
      this.filterForm.patchValue({
        stateId: [stateId],
        districtId: [districtId],
        blockId: [blockId],
      });
      this.loadChws({ value: [blockId] });
    } else if (designation === 'CHW') {
      this.filterForm.patchValue({
        stateId: [stateId],
        districtId: [districtId],
        blockId: [blockId],
        chwId: [chwId],
      });
    }

    this.filterForm
      .get('districtId')
      ?.valueChanges.subscribe((districtValue) => {
        if (districtValue) {
          this.filterForm.patchValue({ blockId: [], chwId: [] });
          this.loadBlocks({ value: districtValue });
        }
      });

    this.filterForm.get('blockId')?.valueChanges.subscribe((blockValue) => {
      if (blockValue) {
        this.filterForm.patchValue({ chwId: [] });
        this.loadChws({ value: blockValue });
      }
    });
  }

  loadStates() {
    console.log("inside load states")
    this.stateService.getStates().subscribe((data) => {
      this.states = data;
      this.changeDetector.markForCheck();
    });
  }

  loadDistricts(event: { value: any[] }) {
    const params = { where: { stateId: { inq: event.value } } };
    this.districtService.getDistricts(params).subscribe((data) => {
      this.districts = data;
      this.changeDetector.markForCheck();
    });
  }

  loadBlocks(event: { value: any[] }) {
    const params = { where: { districtId: { inq: event.value } } };
    this.blockService.getBlocks(params).subscribe((data) => {
      this.blocks = data;
      this.changeDetector.markForCheck();
    });
  }
  loadForms() {
    // const params = { where: { districtId: { inq: event.value } } };
    this.formService.getForms('').subscribe((data: any) => {
      console.log('response from form aapi', data);
      this.forms = data;
      this.changeDetector.markForCheck();
    });
  }

  loadVillages(event: { value: any[] }) {
    const params = { where: { blockId: { inq: event.value } } };
    this.villageService.getVillages(params).subscribe((data) => {
      this.villages = data;
      this.changeDetector.markForCheck();
    });
  }

  loadChws(event: { value: any[] }) {
    const params = {
      where: { blockId: { inq: event.value }, designation: 'CHW' },
    };
    this.chwService.getChw(params).subscribe((data) => {
      this.chws = data;
      this.changeDetector.markForCheck();
    });
  }

  toggleAllSelection(
    type: 'state' | 'district' | 'block' | 'village' | 'chwId'
  ) {
    const control = this.filterForm.get(`${type}Id`);
    const allSelected = this.isAllSelected(type);

    if (control) {
      if (allSelected) {
        control.setValue([]); // Deselect all
      } else {
        let allIds: number[] = [];
        if (type === 'state') {
          allIds = this.states.map((s) => s.id);
          control.setValue(allIds);
          this.loadDistricts({ value: allIds });
        } else if (type === 'district') {
          allIds = this.districts.map((d) => d.id);
          control.setValue(allIds);
          this.loadBlocks({ value: allIds });
        } else if (type === 'block') {
          allIds = this.blocks.map((b) => b.id);
          control.setValue(allIds);
          this.loadVillages({ value: allIds });
          this.loadChws({ value: allIds });
        } else if (type === 'chwId') {
          allIds = this.chws.map((c) => c.id);
          control.setValue(allIds);
        }
      }
    }
  }

  isAllSelected(
    type: 'state' | 'district' | 'block' | 'village' | 'chwId'
  ): boolean {
    const controlValue = this.filterForm.get(`${type}Id`)?.value || [];
    const allIds =
      type === 'state'
        ? this.states.map((s) => s.id)
        : type === 'district'
        ? this.districts.map((d) => d.id)
        : type === 'block'
        ? this.blocks.map((b) => b.id)
        : type === 'chwId'
        ? this.chws.map((c) => c.id)
        : [];

    return allIds.every((id) => controlValue.includes(id));
  }

  onClose(filterValues: any) {
    const selectedFromDate = this.filterForm.get('fromDate')?.value;
    const selectedToDate = this.filterForm.get('toDate')?.value;

    // Ensure IDs are arrays
    const formattedValues = {
      stateId: Array.isArray(filterValues.stateId) ? filterValues.stateId : [],
      districtId: Array.isArray(filterValues.districtId)
        ? filterValues.districtId
        : [],
      blockId: Array.isArray(filterValues.blockId) ? filterValues.blockId : [],
      fromTimestamp: selectedFromDate
        ? moment(selectedFromDate).startOf('day').valueOf()
        : 0,
      toTimestamp: selectedToDate
        ? moment(selectedToDate).endOf('day').valueOf()
        : 0,
    };

    this.filterChange.emit(formattedValues);
  }

  triggerChangeDetection() {
    this.changeDetector.detectChanges();
  }

  onDateRangeChange(event: MatRadioChange) {
    this.selectedDateRange = event.value;
    this.showDatePickers =
      this.selectedDateRange === 'default' ||
      this.selectedDateRange === 'quarter';
    this.isDefaultDateRange = this.selectedDateRange === 'default';
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd')!;
  }

  changeView(): void {
    const year = this.activeDate.getFullYear();
    let fromDate: Date;
    let toDate: Date;

    switch (this.viewType) {
      case 'q1':
        fromDate = new Date(year, 0, 1);
        toDate = new Date(year, 2, 31);
        break;
      case 'q2':
        fromDate = new Date(year, 3, 1);
        toDate = new Date(year, 5, 30);
        break;
      case 'q3':
        fromDate = new Date(year, 6, 1);
        toDate = new Date(year, 8, 30);
        break;
      case 'q4':
        fromDate = new Date(year, 9, 1);
        toDate = new Date(year, 11, 31);
        break;
      default:
        fromDate = new Date(year, 0, 1);
        toDate = new Date(year, 11, 31);
    }

    this.filterForm.patchValue({
      fromTimestamp: moment(fromDate).startOf('day').valueOf(),
      toTimestamp: moment(toDate).endOf('day').valueOf(),
      fromDate: fromDate,
      toDate: toDate,
    });
  }

  //Bst filter work
  /**
   * Sends filter parameters to the service.
   * @param params The parameters to send.
   */

  // applyFilter(params: any): void {
  //   console.log('this is the  data from the form', params);
  //   // Ensure timestamps are used
  //   if (params.fromDate) {
  //     params.fromTimestamp = moment(params.fromDate).unix();
  //     params.fromDate = moment(params.fromDate).unix();
  //     delete params.fromDate;
  //   }
  //   if (params.toDate) {
  //     params.toTimestamp = moment(params.toDate).unix();
  //     params.toDate = moment(params.toDate).unix();
  //     delete params.toDate;
  //   }

  //   if (params.formId === 'HHListing') {
  //     console.log('inside HHListing');
  //     this.HHListingService.getFilteredData(params).subscribe(
  //       (res: any) => {
  //         console.log('filtered result to download', res);
  //         console.log('response length', res.length);
  //         this.exportToExcel(res);
  //       },
  //       (err: any) => {
  //         console.log('error occurred in filter api', err);
  //       }
  //     );
  //   }
  //   // HH Questions
  //   else if (params.formId == 'HH Questions') {
  //     this.formService.get_HH_Question_FilteredData(params).subscribe(
  //       (res: any) => {
  //         console.log('filtered result to download HH question', res);

  //         const worksheet = XLSX.utils.json_to_sheet(
  //           res.map((item: any) => ({
  //             appVersion: item.appVersion,
  //             approvedAt: item.approvedAt,
  //             approvedBy: item.approvedBy,
  //             blockId: item.blockId,
  //             boId: item.boId,
  //             cookingFuel: item.cookingFuel,
  //             createdBy: item.createdBy,
  //             dcrDate: item.dcrDate,
  //             dcrId: item.dcrId,
  //             districtId: item.districtId,
  //             dlId: item.dlId,
  //             formId: item.formId,
  //             geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
  //             handWashCases: JSON.stringify(item.handWashCases),
  //             hhListingId: item.hhListingId,
  //             houseHoldId: item.houseHoldId,
  //             id: item.id,
  //             isApproval: item.isApproval,
  //             isDefectedInOpen: item.isDefectedInOpen,
  //             isReject: item.isReject,
  //             isSoapAvailable: item.isSoapAvailable,
  //             isWashHandsMoreSecs: item.isWashHandsMoreSecs,
  //             lowAwareness: JSON.stringify(item.lowAwareness),
  //             rejectedAt: item.rejectedAt,
  //             rejectedBy: item.rejectedBy,
  //             rejectedMsg: item.rejectedMsg,
  //             smId: item.smId,
  //             sourceOfWater: item.sourceOfWater,
  //             stateId: item.stateId,
  //             submissionDate: item.submissionDate,
  //             waterFetching: item.waterFetching,
  //             waterStorage: item.waterStorage,
  //           }))
  //         );

  //         const workbook = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(
  //           workbook,
  //           worksheet,
  //           'HH Questions Data'
  //         );

  //         // Trigger the Excel download
  //         XLSX.writeFile(workbook, 'hhQuestions_data.xlsx');
  //       },
  //       (err: any) => {
  //         console.log('error occurred in filter api', err);
  //       }
  //     );
  //   }

  //   else if (params.formId == 'Mothers registration') {
  //     this.formService.get_MotherReg_FilterData(params).subscribe(
  //       (res) => {
  //         console.log('response from res mothereeg', res);
  //         const worksheet = XLSX.utils.json_to_sheet(
  //           res.map((item: any) => ({
  //             age: item.age,
  //             appVersion: item.appVersion,
  //             approvedAt: item.approvedAt,
  //             approvedBy: item.approvedBy,
  //             blockId: item.blockId,
  //             boId: item.boId,
  //             childBelowAge6Count: item.childBelowAge6Count,
  //             createdBy: item.createdBy,
  //             dcrDate: item.dcrDate,
  //             dcrId: item.dcrId,
  //             districtId: item.districtId,
  //             dlId: item.dlId,
  //             firstName: item.firstName,
  //             geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`, 
  //             hhListingId: item.hhListingId,
  //             houseHoldId: item.houseHoldId,
  //             id: item.id,
  //             isApproval: item.isApproval,
  //             isAyushmanCard: item.isAyushmanCard,
  //             isPW: item.isPW,
  //             isReject: item.isReject,
  //             lastName: item.lastName,
  //             lowAwareness: JSON.stringify(item.lowAwareness), 
  //             mobile: item.mobile,
  //             motherUUId: item.motherUUId,
  //             rejectedAt: item.rejectedAt,
  //             rejectedBy: item.rejectedBy,
  //             rejectedMsg: item.rejectedMsg,
  //             smId: item.smId,
  //             stateId: item.stateId,
  //             submissionDate: item.submissionDate,
  //           }))
  //         );

  //         const workbook = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(workbook, worksheet, 'Mother Registration Data');

  //         // Trigger the Excel download
  //         XLSX.writeFile(workbook, 'MotherReg_data.xlsx');
  //       },
  //       (err) => {
  //         console.log('error occured', err);
  //       }
  //     );
  //   }


  //   else if (params.formId == 'PW specific questionnaire'){
  //       this.formService.get_MotherReg_FilterData(params).subscribe((res)=>{
  //          console.log("response of mother pw", res)
  //          const worksheet = XLSX.utils.json_to_sheet(
  //            res.map((item: any) => ({
  //              age: item.age,
  //              appVersion: item.appVersion,
  //              approvedAt: item.approvedAt,
  //              approvedBy: item.approvedBy,
  //              blockId: item.blockId,
  //              boId: item.boId,
  //              childBelowAge6Count: item.childBelowAge6Count,
  //              createdBy: item.createdBy,
  //              dcrDate: item.dcrDate,
  //              dcrId: item.dcrId,
  //              districtId: item.districtId,
  //              dlId: item.dlId,
  //              firstName: item.firstName,
  //              geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`, // Adjust based on geoLocation format
  //              hhListingId: item.hhListingId,
  //              houseHoldId: item.houseHoldId,
  //              id: item.id,
  //              isApproval: item.isApproval,
  //              isAyushmanCard: item.isAyushmanCard,
  //              isPW: item.isPW,
  //              isReject: item.isReject,
  //              lastName: item.lastName,
  //              lowAwareness: JSON.stringify(item.lowAwareness), // Assuming you want to keep it as a JSON string
  //              mobile: item.mobile,
  //              motherUUId: item.motherUUId,
  //              rejectedAt: item.rejectedAt,
  //              rejectedBy: item.rejectedBy,
  //              rejectedMsg: item.rejectedMsg,
  //              smId: item.smId,
  //              stateId: item.stateId,
  //              submissionDate: item.submissionDate,
  //            }))
  //          );

  //          const workbook = XLSX.utils.book_new();
  //          XLSX.utils.book_append_sheet(workbook, worksheet, 'Mother PW Data');

  //          // Trigger the Excel download
  //          XLSX.writeFile(workbook, 'motherPW_data.xlsx');
  //       }, (err)=>{
  //        console.log("error occured in mother PW")
  //       })
  //   }

  //   else if(params.formId=="Mother common questionnaire"){
  //    this.formService.get_MotherCQ_FilterData(params).subscribe((res:any)=>{
  //      console.log("response mother CQ", res)
  //      const worksheet = XLSX.utils.json_to_sheet(
  //        res.map((item: any) => ({
  //          absorbentUsedMC: item.absorbentUsedMC,
  //          appVersion: item.appVersion,
  //          approvedAt: item.approvedAt,
  //          approvedBy: item.approvedBy,
  //          blockId: item.blockId,
  //          boId: item.boId,
  //          createdBy: item.createdBy,
  //          dcrDate: item.dcrDate,
  //          dcrId: item.dcrId,
  //          dispose_pads_options: item.dispose_pads_options,
  //          districtId: item.districtId,
  //          dlId: item.dlId,
  //          dryCloth: item.dryCloth,
  //          familyPlanningAwareness: item.familyPlanningAwareness,
  //          foodAwareness: JSON.stringify(item.foodAwareness), // Assuming foodAwareness is an object
  //          formId: item.formId,
  //          freqChangeSanitaryNapkins: item.freqChangeSanitaryNapkins,
  //          geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
  //          hhListingId: item.hhListingId,
  //          houseHoldId: item.houseHoldId,
  //          id: item.id,
  //          infoReceivedAdoptFamilyPlanning: JSON.stringify(
  //            item.infoReceivedAdoptFamilyPlanning
  //          ), 
  //          isAnyMentalStress: item.isAnyMentalStress,
  //          isApproval: item.isApproval,
  //          isAwareFamilyPlanningMethods: JSON.stringify(
  //            item.isAwareFamilyPlanningMethods
  //          ),  
  //          isAwareMalariaSpread: JSON.stringify(item.isAwareMalariaSpread),  
  //          isAwareMalariaSymptoms: JSON.stringify(item.isAwareMalariaSymptoms),  
  //          isAwareOfGovtHelpLine: item.isAwareOfGovtHelpLine,
  //          isAwareOfYoga: item.isAwareOfYoga,
  //          isAwareUTI: JSON.stringify(item.isAwareUTI),  
  //          isDemoMEC: item.isDemoMEC,
  //          isMalariaCanPrevent: item.isMalariaCanPrevent,
  //          isMalariaCanPreventOptions: JSON.stringify(
  //            item.isMalariaCanPreventOptions
  //          ),  
  //          isMalariaFatal: item.isMalariaFatal,
  //          isPreventUTI: JSON.stringify(item.isPreventUTI),  
  //          isReject: item.isReject,
  //          isWashHands: item.isWashHands,
  //          lowAwareness: JSON.stringify(item.lowAwareness),  
  //          motherId: item.motherId,
  //          motherName: item.motherName,
  //          motherUUId: item.motherUUId,
  //          refredBirthSpacingOptions: item.refredBirthSpacingOptions,
  //          rejectedAt: item.rejectedAt,
  //          rejectedBy: item.rejectedBy,
  //          rejectedMsg: item.rejectedMsg,
  //          showProteQTLink: item.showProteQTLink,
  //          smId: item.smId,
  //          stateId: item.stateId,
  //          submissionDate: item.submissionDate,
  //        }))
  //      );

  //      const workbook = XLSX.utils.book_new();
  //      XLSX.utils.book_append_sheet(workbook, worksheet, 'Mother Common Questions Data');

  //      // Trigger the Excel download
  //      XLSX.writeFile(workbook, 'MotherCQ_data.xlsx');
  //    }, (err:any)=>{
  //      console.log("error in mother common questions", err)
  //    })
  //   }

  //   else if(params.formId== "Child Registration"){
  //     this.formService.get_chilgReg_FilterData(params).subscribe((res:any)=>{
  //      console.log("response from childReg",res)

  //      const worksheet = XLSX.utils.json_to_sheet(
  //        res.map((item: any) => ({
  //          age: item.age,
  //          appVersion: item.appVersion,
  //          approvedAt: item.approvedAt,
  //          approvedBy: item.approvedBy,
  //          blockId: item.blockId,
  //          boId: item.boId,
  //          childName: item.childName,
  //          childUUId: item.childUUId,
  //          createdBy: item.createdBy,
  //          dcrDate: item.dcrDate,
  //          dcrId: item.dcrId,
  //          districtId: item.districtId,
  //          dlId: item.dlId,
  //          dob: item.dob,
  //          formId: item.formId,
  //          gender: item.gender,
  //          geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`, // Assuming geoLocation exists
  //          guardianName: item.guardianName,
  //          hhListingId: item.hhListingId,
  //          houseHoldId: item.houseHoldId,
  //          id: item.id,
  //          isApproval: item.isApproval,
  //          isBreastFeedingContinue: item.isBreastFeedingContinue,
  //          isInitiateBreastfeeding: item.isInitiateBreastfeeding,
  //          isMother: item.isMother,
  //          isReject: item.isReject,
  //          isVaccinationTaken: item.isVaccinationTaken,
  //          lowAwareness: JSON.stringify(item.lowAwareness), // Assuming lowAwareness is an object
  //          motherUUId: item.motherUUId,
  //          rejectedAt: item.rejectedAt,
  //          rejectedBy: item.rejectedBy,
  //          rejectedMsg: item.rejectedMsg,
  //          smId: item.smId,
  //          stateId: item.stateId,
  //          strChildAge: item.strChildAge,
  //          submissionDate: item.submissionDate,
  //          timelyInitiateBreastfeedingImp: item.timelyInitiateBreastfeedingImp,
  //          vaccinationTaken: JSON.stringify(item.vaccinationTaken), // Assuming vaccinationTaken is an object
  //          whoGaveAdvice: item.whoGaveAdvice,
  //        }))
  //      );

  //      const workbook = XLSX.utils.book_new();
  //      XLSX.utils.book_append_sheet(workbook, worksheet, 'HHListing Data');

  //      // Trigger the Excel download
  //      XLSX.writeFile(workbook, 'hhlisting_data.xlsx');
  //     }, (err:any)=>{
  //     console.log("error occured in child reg", err)
  //     })
  //   }else if(params.formId== "Child Diarrhoea"){
  //     this.formService.get_chilgDiarrhea_FilterData(params).subscribe((res:any)=>{
  //        console.log("the response from child diarrhea", res)
  //         const worksheet = XLSX.utils.json_to_sheet(
  //           res.map((item: any) => ({
  //             age: item.age,
  //             appVersion: item.appVersion,
  //             approvedAt: item.approvedAt,
  //             approvedBy: item.approvedBy,
  //             blockId: item.blockId,
  //             boId: item.boId,
  //             childName: item.childName,
  //             childUUId: item.childUUId,
  //             createdBy: item.createdBy,
  //             dcrDate: item.dcrDate,
  //             dcrId: item.dcrId,
  //             districtId: item.districtId,
  //             dlId: item.dlId,
  //             dob: item.dob,
  //             formId: item.formId,
  //             gender: item.gender,
  //             geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`, // Assuming geoLocation exists
  //             guardianName: item.guardianName,
  //             hhListingId: item.hhListingId,
  //             houseHoldId: item.houseHoldId,
  //             id: item.id,
  //             isApproval: item.isApproval,
  //             isBreastFeedingContinue: item.isBreastFeedingContinue,
  //             isInitiateBreastfeeding: item.isInitiateBreastfeeding,
  //             isMother: item.isMother,
  //             isReject: item.isReject,
  //             isVaccinationTaken: item.isVaccinationTaken,
  //             lowAwareness: JSON.stringify(item.lowAwareness), // Assuming lowAwareness is an object
  //             motherUUId: item.motherUUId,
  //             rejectedAt: item.rejectedAt,
  //             rejectedBy: item.rejectedBy,
  //             rejectedMsg: item.rejectedMsg,
  //             smId: item.smId,
  //             stateId: item.stateId,
  //             strChildAge: item.strChildAge,
  //             submissionDate: item.submissionDate,
  //             timelyInitiateBreastfeedingImp:
  //               item.timelyInitiateBreastfeedingImp,
  //             vaccinationTaken: JSON.stringify(item.vaccinationTaken), // Assuming vaccinationTaken is an object
  //             whoGaveAdvice: item.whoGaveAdvice,
  //           }))
  //         );

  //         const workbook = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(workbook, worksheet, 'Child Diarrhea Data');

  //         // Trigger the Excel download
  //         XLSX.writeFile(workbook, 'childDiarrhea_data.xlsx');
  //     }, (err:any)=>{
  //       console.log("error occured in child diarrhea", err)
  //     })
  //   }else if(params.formId== "Child Pneumonia"){
  //     this.formService.get_childPneumonia_FilterData(params).subscribe((res:any)=>{
  //        console.log("response from pheumonia", res)
  //         const worksheet = XLSX.utils.json_to_sheet(
  //           res.map((item: any) => ({
  //             appVersion: item.appVersion,
  //             approvedAt: item.approvedAt,
  //             approvedBy: item.approvedBy,
  //             blockId: item.blockId,
  //             boId: item.boId,
  //             childId: item.childId,
  //             childUUId: item.childUUId,
  //             createdBy: item.createdBy,
  //             dcrDate: item.dcrDate,
  //             dcrId: item.dcrId,
  //             districtId: item.districtId,
  //             dlId: item.dlId,
  //             formId: item.formId,
  //             geoLocation: `${item.geoLocation.latitude || ''}, ${
  //               item.geoLocation.longitude || ''
  //             }`, // Combining lat/lon
  //             hhListingId: item.hhListingId,
  //             houseHoldId: item.houseHoldId,
  //             id: item.id,
  //             isApproval: item.isApproval,
  //             isCold: item.isCold,
  //             isReject: item.isReject,
  //             lowAwareness: JSON.stringify(item.lowAwareness), // Serializing nested object
  //             pneumoniaPrevention: JSON.stringify(item.pneumoniaPrevention), // Serializing nested object
  //             possibleSymptoms: JSON.stringify(item.possibleSymptoms), // Serializing nested object
  //             rejectedAt: item.rejectedAt,
  //             rejectedBy: item.rejectedBy,
  //             rejectedMsg: item.rejectedMsg,
  //             smId: item.smId,
  //             stateId: item.stateId,
  //             submissionDate: item.submissionDate,
  //             symptomsOfPneumonia: JSON.stringify(item.symptomsOfPneumonia),
  //           }))
  //         );

  //         const workbook = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(workbook, worksheet, 'Child Pneumonia Data');

  //         // Trigger the Excel download
  //         XLSX.writeFile(workbook, 'childPneumonia_data.xlsx');
  //     },(err:any)=>{
  //       console.log("error occured in child pneumonia", err)
  //     })
  //   }else if (params.formId == 'Mother Meeting'){
  //     this.formService.get_MotherMeeting_FilterData(params).subscribe((res)=>{
  //       console.log('response from mother meeting', res)
  //        const worksheet = XLSX.utils.json_to_sheet(
  //          res.map((item: any) => ({
  //            appVersion: item.appVersion,
  //            approvedAt: item.approvedAt,
  //            approvedBy: item.approvedBy,
  //            blockId: item.blockId,
  //            boId: item.boId,
  //            createdBy: item.createdBy,
  //            dcrDate: item.dcrDate,
  //            dcrId: item.dcrId,
  //            districtId: item.districtId,
  //            dlId: item.dlId,
  //            endTime: item.endTime,
  //            formId: item.formId,
  //            geoLocation:
  //              item.geoLocation.latitude && item.geoLocation.longitude
  //                ? `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`
  //                : '', // Handling empty geoLocation
  //            id: item.id,
  //            isApproval: item.isApproval,
  //            isReject: item.isReject,
  //            meetingDate: item.meetingDate,
  //            meetingPlace: item.meetingPlace,
  //            meetingTopics: JSON.stringify(item.meetingTopics), // Serializing nested object
  //            mobile: item.mobile,
  //            participantsList: item.participantsList
  //              .map((participant: any) => participant.name)
  //              .join(', '), // Concatenating participant names
  //            rejectedAt: item.rejectedAt,
  //            rejectedBy: item.rejectedBy,
  //            rejectedMsg: item.rejectedMsg,
  //            smId: item.smId,
  //            specifingMeetingPlaceName: item.specifingMeetingPlaceName,
  //            specifyingStakeholderPresent: item.specifyingStakeholderPresent,
  //            stakeholderPresent: JSON.stringify(item.stakeholderPresent), // Serializing nested object
  //            startTime: item.startTime,
  //            stateId: item.stateId,
  //            submissionDate: item.submissionDate,
  //          }))
  //        );

  //        const workbook = XLSX.utils.book_new();
  //        XLSX.utils.book_append_sheet(workbook, worksheet, 'HHListing Data');

  //        // Trigger the Excel download
  //        XLSX.writeFile(workbook, 'hhlisting_data.xlsx');
  //     }, (err)=>{
  //      console.log('error occured', err)
  //     })
  //   }else if (params.formId == 'IEC Materia Dismentiation'){
  //     this.formService.get_Iecdissemination_FilterData(params).subscribe((res:any)=>{
  //         console.log("response from iecdissminaiton api", res)
  //         const worksheet = XLSX.utils.json_to_sheet(
  //           res.map((item: any) => ({
  //             appVersion: item.appVersion,
  //             approvedAt: item.approvedAt,
  //             approvedBy: item.approvedBy,
  //             blockId: item.blockId,
  //             boId: item.boId,
  //             createdBy: item.createdBy,
  //             dcrDate: item.dcrDate,
  //             dcrId: item.dcrId,
  //             districtId: item.districtId,
  //             dlId: item.dlId,
  //             formId: item.formId,
  //             geoLocation:
  //               item.geoLocation.latitude && item.geoLocation.longitude
  //                 ? `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`
  //                 : '', // Handling missing geoLocation
  //             hhListingId: item.hhListingId,
  //             houseHoldId: item.houseHoldId,
  //             id: item.id,
  //             iecMaterial: JSON.stringify(item.iecMaterial), // Serialize nested iecMaterial object
  //             inTime: item.inTime,
  //             isApproval: item.isApproval,
  //             isReject: item.isReject,
  //             outTime: item.outTime,
  //             rejectedAt: item.rejectedAt,
  //             rejectedBy: item.rejectedBy,
  //             rejectedMsg: item.rejectedMsg,
  //             remark: item.remark,
  //             smId: item.smId,
  //             stateId: item.stateId,
  //             submissionDate: item.submissionDate,
  //           }))
  //         );

  //         const workbook = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(workbook, worksheet, 'HHListing Data');

  //         // Trigger the Excel download
  //         XLSX.writeFile(workbook, 'hhlisting_data.xlsx');
  //     },(err:any)=>{
  //     console.log("Error occured in IEC", err)
  //     })
  //   }else if(params.formId=="Diarreoh Follow-up"){
  //     this.formService.get_childDiarrheaFollowUp_FilterData(params).subscribe((res:any)=>{
  //     console.log("response from child diarrhea", res)

  //     const worksheet = XLSX.utils.json_to_sheet(
  //       res.map((item: any) => ({
  //         blockId: item.blockId,
  //         boId: item.boId,
  //         childAge: item.childAge,
  //         childId: item.childId,
  //         childName: item.childName,
  //         createdAt: item.createdAt,
  //         createdBy: item.createdBy,
  //         diarreahType: item.diarreahType,
  //         districtId: item.districtId,
  //         dlId: item.dlId,
  //         followUpNumber: item.followUpNumber,
  //         formId: item.formId,
  //         hhListingId: item.hhListingId,
  //         houseHoldId: item.houseHoldId,
  //         id: item.id,
  //         isApproval: item.isApproval,
  //         scheduleTimestamp: item.scheduleTimestamp,
  //         scheduledDate: item.scheduledDate,
  //         smId: item.smId,
  //         stateId: item.stateId,
  //         status: item.status,
  //         type: item.type,
  //       }))
  //     );

  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, 'HHListing Data');

  //     // Trigger the Excel download
  //     XLSX.writeFile(workbook, 'hhlisting_data.xlsx');
       
  //     }, (err:any)=>{
  //     console.log("error occured in diarrhea fllowup", err)
  //     })
  //   }else if(params.formId== "Pneumonia Follow-up"){
  //     this.formService.get_childPneumoniaFollowUp_FilterData(params).subscribe((res:any)=>{
  //      console.log("response  form child pneumonia followup", res)
  //       const worksheet = XLSX.utils.json_to_sheet(
  //         res.map((item: any) => ({
  //           anyoneAtHome: item.anyoneAtHome,
  //           appVersion: item.appVersion,
  //           blockId: item.blockId,
  //           boId: item.boId,
  //           childAge: item.childAge,
  //           childId: item.childId,
  //           childName: item.childName,
  //           childPneumoniaId: item.childPneumoniaId,
  //           childTreatmentStatus: item.childTreatmentStatus,
  //           createdAt: item.createdAt,
  //           createdBy: item.createdBy,
  //           dcrDate: item.dcrDate,
  //           dcrId: item.dcrId,
  //           districtId: item.districtId,
  //           dlId: item.dlId,
  //           followUpNumber: item.followUpNumber,
  //           formId: item.formId,
  //           geoLocation: JSON.stringify(item.geoLocation || {}), // Convert object to string
  //           hhListingId: item.hhListingId,
  //           houseHoldId: item.houseHoldId,
  //           id: item.id,
  //           isApproval: item.isApproval,
  //           isChildAdmitted: item.isChildAdmitted,
  //           isCold: item.isCold,
  //           pneumoniaType: item.pneumoniaType,
  //           pneumoniaDetails: JSON.stringify(item.pneumonia || {}), // Convert object to string
  //           scheduleTimestamp: item.scheduleTimestamp,
  //           scheduledDate: item.scheduledDate,
  //           smId: item.smId,
  //           stateId: item.stateId,
  //           status: item.status,
  //           submissionDate: item.submissionDate,
  //           symptomsOfPneumonia: item.symptomsOfPneumonia,
  //           type: item.type,
  //           whereAdmitted: item.whereAdmitted,
  //         }))
  //       );

  //       const workbook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workbook, worksheet, 'ChildPneumoniaFollowup Data');

  //       // Trigger the Excel download
  //       XLSX.writeFile(workbook, 'ChildPneumoniaFollowup_data.xlsx');
  //     }, (err)=>{
  //      console.log("error occured in pneumonia followup", err)
  //     })
  //   }else if (params.formId == 'Mother ANC Follow up') {
  //     this.formService.get_AncFollowUp_FilterData(params).subscribe((res:any)=>{
  //       console.log("response from ANC followup",res )
  //         const worksheet = XLSX.utils.json_to_sheet(
  //           res.map((item: any) => ({
  //             anyoneAtHome: item.anyoneAtHome,
  //             appVersion: item.appVersion,
  //             blockId: item.blockId,
  //             boId: item.boId,
  //             childAge: item.childAge,
  //             childId: item.childId,
  //             childName: item.childName,
  //             childPneumoniaId: item.childPneumoniaId,
  //             childTreatmentStatus: item.childTreatmentStatus,
  //             createdAt: item.createdAt,
  //             createdBy: item.createdBy,
  //             dcrDate: item.dcrDate,
  //             dcrId: item.dcrId,
  //             districtId: item.districtId,
  //             dlId: item.dlId,
  //             followUpNumber: item.followUpNumber,
  //             formId: item.formId,
  //             geoLocation: JSON.stringify(item.geoLocation || {}), // Convert object to string
  //             hhListingId: item.hhListingId,
  //             houseHoldId: item.houseHoldId,
  //             id: item.id,
  //             isApproval: item.isApproval,
  //             isChildAdmitted: item.isChildAdmitted,
  //             isCold: item.isCold,
  //             pneumoniaType: item.pneumoniaType,
  //             pneumoniaDetails: JSON.stringify(item.pneumonia || {}), // Convert object to string
  //             scheduleTimestamp: item.scheduleTimestamp,
  //             scheduledDate: item.scheduledDate,
  //             smId: item.smId,
  //             stateId: item.stateId,
  //             status: item.status,
  //             submissionDate: item.submissionDate,
  //             symptomsOfPneumonia: item.symptomsOfPneumonia,
  //             type: item.type,
  //             whereAdmitted: item.whereAdmitted,
  //           }))
  //         );

  //         const workbook = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(workbook, worksheet, 'MotherANC Data');

  //         // Trigger the Excel download
  //         XLSX.writeFile(workbook, 'motherANC_data.xlsx');
  //     }, (err:any)=>{
  //       console.log("error in  ANC followup", err)
  //     })
  //   }else if (params.formId == 'Child Vaccine Follow up'){
  //      this.formService.get_VaccinationFollowUp_FilterData(params).subscribe((res:any)=>{
  //         console.log("response from vaccination", res)
  //         const worksheet = XLSX.utils.json_to_sheet(
  //           res.map((item: any) => ({
  //             anyoneAtHome: item.anyoneAtHome,
  //             appVersion: item.appVersion,
  //             blockId: item.blockId,
  //             boId: item.boId,
  //             childAge: item.childAge,
  //             childId: item.childId,
  //             childName: item.childName,
  //             childPneumoniaId: item.childPneumoniaId,
  //             childTreatmentStatus: item.childTreatmentStatus,
  //             createdAt: item.createdAt,
  //             createdBy: item.createdBy,
  //             dcrDate: item.dcrDate,
  //             dcrId: item.dcrId,
  //             districtId: item.districtId,
  //             dlId: item.dlId,
  //             followUpNumber: item.followUpNumber,
  //             formId: item.formId,
  //             geoLocation: JSON.stringify(item.geoLocation || {}), // Convert object to string
  //             hhListingId: item.hhListingId,
  //             houseHoldId: item.houseHoldId,
  //             id: item.id,
  //             isApproval: item.isApproval,
  //             isChildAdmitted: item.isChildAdmitted,
  //             isCold: item.isCold,
  //             pneumoniaType: item.pneumoniaType,
  //             pneumoniaDetails: JSON.stringify(item.pneumonia || {}), // Convert object to string
  //             scheduleTimestamp: item.scheduleTimestamp,
  //             scheduledDate: item.scheduledDate,
  //             smId: item.smId,
  //             stateId: item.stateId,
  //             status: item.status,
  //             submissionDate: item.submissionDate,
  //             symptomsOfPneumonia: item.symptomsOfPneumonia,
  //             type: item.type,
  //             whereAdmitted: item.whereAdmitted,
  //           }))
  //         );

  //         const workbook = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(workbook, worksheet, 'VaccinationFollowup Data');

  //         // Trigger the Excel download
  //         XLSX.writeFile(workbook, 'VaccinationFollowup_data.xlsx');

          


  //      }, (err)=>{
  //        console.log("error occured in Vaccination", err)
  //      })
  //   }else if(params.formId== "Nukkad Natak Form"){
  //     this.formService.get_NukkadNatak_FilterData(params).subscribe((res:any)=>{
  //     console.log("response from Nukkad Natak Form", res)
  //     }, (err)=>{
  //      console.log("error occured", err)
  //     })
  //   }




  //     this.overviewDashboardService.filterApplyService(params);
  //   console.log(
  //     'Filter parameters sent from filter component:',
  //     JSON.stringify(params)
  //   );
  // }

  applyFilter(params: any): void {
    console.log("params without passing", params)
    if(params.formId){
       this.downloadIndicatorChanged.emit(true);
    }
  
      
    
    
    console.log('this is the  data from the form', params);

    if (params.fromDate) {
      params.fromTimestamp = moment(params.fromDate).valueOf(); // Convert to milliseconds
      delete params.fromDate;
    }
    if (params.toDate) {
      params.toTimestamp = moment(params.toDate).valueOf(); // Convert to milliseconds
      delete params.toDate;
    }

    if (params.formId === 'HHListing') {
      console.log('inside HHListing');
      this.HHListingService.getFilteredData(params).subscribe(
        (res: any) => {
        
          console.log('filtered result to download', res);
          console.log('response length', res.length);
          if (res.length > 0) {
           
            this.exportToExcel(res);
          } else {
            alert('no data found in this filter, use diffrent date');
          }
        },
        (err: any) => {
          console.log('error occurred in filter api', err);
        }
      );
    }
    // HH Questions
    else if (params.formId == 'HH Questions') {
      this.formService.get_HH_Question_FilteredData(params).subscribe(
        (res: any) => {
          console.log('filtered result to download HH question', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                appVersion: item.appVersion,
                approvedAt: item.approvedAt,
                approvedBy: item.approvedBy,
                blockId: item.blockId,
                boId: item.boId,
                cookingFuel: item.cookingFuel,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                formId: item.formId,
                geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
                handWashCases: JSON.stringify(item.handWashCases),
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                isDefectedInOpen: item.isDefectedInOpen,
                isReject: item.isReject,
                isSoapAvailable: item.isSoapAvailable,
                isWashHandsMoreSecs: item.isWashHandsMoreSecs,
                lowAwareness: JSON.stringify(item.lowAwareness),
                rejectedAt: item.rejectedAt,
                rejectedBy: item.rejectedBy,
                rejectedMsg: item.rejectedMsg,
                smId: item.smId,
                sourceOfWater: item.sourceOfWater,
                stateId: item.stateId,
                submissionDate: item.submissionDate,
                waterFetching: item.waterFetching,
                waterStorage: item.waterStorage,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              'HH Questions Data'
            );

            XLSX.writeFile(workbook, 'hhQuestions_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err: any) => {
          console.log('error occurred in filter api', err);
        }
      );
    } else if (params.formId == 'Mothers registration') {
      this.formService.get_MotherReg_FilterData(params).subscribe(
        (res) => {
          console.log('response from res mothereeg', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                age: item.age,
                appVersion: item.appVersion,
                approvedAt: item.approvedAt,
                approvedBy: item.approvedBy,
                blockId: item.blockId,
                boId: item.boId,
                childBelowAge6Count: item.childBelowAge6Count,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                firstName: item.firstName,
                geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                isAyushmanCard: item.isAyushmanCard,
                isPW: item.isPW,
                isReject: item.isReject,
                lastName: item.lastName,
                lowAwareness: JSON.stringify(item.lowAwareness),
                mobile: item.mobile,
                motherUUId: item.motherUUId,
                rejectedAt: item.rejectedAt,
                rejectedBy: item.rejectedBy,
                rejectedMsg: item.rejectedMsg,
                smId: item.smId,
                stateId: item.stateId,
                submissionDate: item.submissionDate,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              'Mother Registration Data'
            );

            XLSX.writeFile(workbook, 'MotherReg_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err) => {
          console.log('error occured', err);
        }
      );
    } else if (params.formId == 'PW specific questionnaire') {
      this.formService.get_MotherReg_FilterData(params).subscribe(
        (res) => {
          console.log('response of mother pw', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                age: item.age,
                appVersion: item.appVersion,
                approvedAt: item.approvedAt,
                approvedBy: item.approvedBy,
                blockId: item.blockId,
                boId: item.boId,
                childBelowAge6Count: item.childBelowAge6Count,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                firstName: item.firstName,
                geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                isAyushmanCard: item.isAyushmanCard,
                isPW: item.isPW,
                isReject: item.isReject,
                lastName: item.lastName,
                lowAwareness: JSON.stringify(item.lowAwareness),
                mobile: item.mobile,
                motherUUId: item.motherUUId,
                rejectedAt: item.rejectedAt,
                rejectedBy: item.rejectedBy,
                rejectedMsg: item.rejectedMsg,
                smId: item.smId,
                stateId: item.stateId,
                submissionDate: item.submissionDate,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Mother PW Data');

            XLSX.writeFile(workbook, 'motherPW_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err) => {
          console.log('error occured in mother PW');
        }
      );
    } else if (params.formId == 'Mother common questionnaire') {
      this.formService.get_MotherCQ_FilterData(params).subscribe(
        (res: any) => {
          console.log('response mother CQ', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                absorbentUsedMC: item.absorbentUsedMC,
                appVersion: item.appVersion,
                approvedAt: item.approvedAt,
                approvedBy: item.approvedBy,
                blockId: item.blockId,
                boId: item.boId,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                dispose_pads_options: item.dispose_pads_options,
                districtId: item.districtId,
                dlId: item.dlId,
                dryCloth: item.dryCloth,
                familyPlanningAwareness: item.familyPlanningAwareness,
                foodAwareness: JSON.stringify(item.foodAwareness),
                formId: item.formId,
                freqChangeSanitaryNapkins: item.freqChangeSanitaryNapkins,
                geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                infoReceivedAdoptFamilyPlanning: JSON.stringify(
                  item.infoReceivedAdoptFamilyPlanning
                ),
                isAnyMentalStress: item.isAnyMentalStress,
                isApproval: item.isApproval,
                isAwareFamilyPlanningMethods: JSON.stringify(
                  item.isAwareFamilyPlanningMethods
                ),
                isAwareMalariaSpread: JSON.stringify(item.isAwareMalariaSpread),
                isAwareMalariaSymptoms: JSON.stringify(
                  item.isAwareMalariaSymptoms
                ),
                isAwareOfGovtHelpLine: item.isAwareOfGovtHelpLine,
                isAwareOfYoga: item.isAwareOfYoga,
                isAwareUTI: JSON.stringify(item.isAwareUTI),
                isDemoMEC: item.isDemoMEC,
                isMalariaCanPrevent: item.isMalariaCanPrevent,
                isMalariaCanPreventOptions: JSON.stringify(
                  item.isMalariaCanPreventOptions
                ),
                isMalariaFatal: item.isMalariaFatal,
                isPreventUTI: JSON.stringify(item.isPreventUTI),
                isReject: item.isReject,
                isWashHands: item.isWashHands,
                lowAwareness: JSON.stringify(item.lowAwareness),
                motherId: item.motherId,
                motherName: item.motherName,
                motherUUId: item.motherUUId,
                refredBirthSpacingOptions: item.refredBirthSpacingOptions,
                rejectedAt: item.rejectedAt,
                rejectedBy: item.rejectedBy,
                rejectedMsg: item.rejectedMsg,
                showProteQTLink: item.showProteQTLink,
                smId: item.smId,
                stateId: item.stateId,
                submissionDate: item.submissionDate,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              'Mother Common Questions Data'
            );

            XLSX.writeFile(workbook, 'MotherCQ_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err: any) => {
          console.log('error in mother common questions', err);
        }
      );
    } else if (params.formId == 'Child Registration') {
      this.formService.get_chilgReg_FilterData(params).subscribe(
        (res: any) => {
          console.log('response from childReg', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                age: item.age,
                appVersion: item.appVersion,
                approvedAt: item.approvedAt,
                approvedBy: item.approvedBy,
                blockId: item.blockId,
                boId: item.boId,
                childName: item.childName,
                childUUId: item.childUUId,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                dob: item.dob,
                formId: item.formId,
                gender: item.gender,
                geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
                guardianName: item.guardianName,
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                isBreastFeedingContinue: item.isBreastFeedingContinue,
                isInitiateBreastfeeding: item.isInitiateBreastfeeding,
                isMother: item.isMother,
                isReject: item.isReject,
                isVaccinationTaken: item.isVaccinationTaken,
                lowAwareness: JSON.stringify(item.lowAwareness),
                motherUUId: item.motherUUId,
                rejectedAt: item.rejectedAt,
                rejectedBy: item.rejectedBy,
                rejectedMsg: item.rejectedMsg,
                smId: item.smId,
                stateId: item.stateId,
                strChildAge: item.strChildAge,
                submissionDate: item.submissionDate,
                timelyInitiateBreastfeedingImp:
                  item.timelyInitiateBreastfeedingImp,
                vaccinationTaken: JSON.stringify(item.vaccinationTaken),
                whoGaveAdvice: item.whoGaveAdvice,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Child Reg Data');

            XLSX.writeFile(workbook, 'childReg.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err: any) => {
          console.log('error occured in child reg', err);
        }
      );
    } else if (params.formId == 'Child Diarrhoea') {
      this.formService.get_chilgDiarrhea_FilterData(params).subscribe(
        (res: any) => {
          console.log('the response from child diarrhea', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                age: item.age,
                appVersion: item.appVersion,
                approvedAt: item.approvedAt,
                approvedBy: item.approvedBy,
                blockId: item.blockId,
                boId: item.boId,
                childName: item.childName,
                childUUId: item.childUUId,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                dob: item.dob,
                formId: item.formId,
                gender: item.gender,
                geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
                guardianName: item.guardianName,
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                isBreastFeedingContinue: item.isBreastFeedingContinue,
                isInitiateBreastfeeding: item.isInitiateBreastfeeding,
                isMother: item.isMother,
                isReject: item.isReject,
                isVaccinationTaken: item.isVaccinationTaken,
                lowAwareness: JSON.stringify(item.lowAwareness),
                motherUUId: item.motherUUId,
                rejectedAt: item.rejectedAt,
                rejectedBy: item.rejectedBy,
                rejectedMsg: item.rejectedMsg,
                smId: item.smId,
                stateId: item.stateId,
                strChildAge: item.strChildAge,
                submissionDate: item.submissionDate,
                timelyInitiateBreastfeedingImp:
                  item.timelyInitiateBreastfeedingImp,
                vaccinationTaken: JSON.stringify(item.vaccinationTaken),
                whoGaveAdvice: item.whoGaveAdvice,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              'Child Diarrhea Data'
            );

            XLSX.writeFile(workbook, 'childDiarrhea_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err: any) => {
          console.log('error occured in child diarrhea', err);
        }
      );
    } else if (params.formId == 'Child Pneumonia') {
      this.formService.get_childPneumonia_FilterData(params).subscribe(
        (res: any) => {
          console.log('response from pheumonia', res);

          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                appVersion: item.appVersion,
                approvedAt: item.approvedAt,
                approvedBy: item.approvedBy,
                blockId: item.blockId,
                boId: item.boId,
                childId: item.childId,
                childUUId: item.childUUId,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                formId: item.formId,
                geoLocation: `${item.geoLocation.latitude || ''}, ${
                  item.geoLocation.longitude || ''
                }`,
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                isCold: item.isCold,
                isReject: item.isReject,
                lowAwareness: JSON.stringify(item.lowAwareness),
                pneumoniaPrevention: JSON.stringify(item.pneumoniaPrevention),
                possibleSymptoms: JSON.stringify(item.possibleSymptoms),
                rejectedAt: item.rejectedAt,
                rejectedBy: item.rejectedBy,
                rejectedMsg: item.rejectedMsg,
                smId: item.smId,
                stateId: item.stateId,
                submissionDate: item.submissionDate,
                symptomsOfPneumonia: JSON.stringify(item.symptomsOfPneumonia),
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              'Child Pneumonia Data'
            );

            XLSX.writeFile(workbook, 'childPneumonia_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err: any) => {
          console.log('error occured in child pneumonia', err);
        }
      );
    } else if (params.formId == 'Mother Meeting') {
      this.formService.get_MotherMeeting_FilterData(params).subscribe(
        (res) => {
          console.log('response from mother meeting', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                appVersion: item.appVersion,
                approvedAt: item.approvedAt,
                approvedBy: item.approvedBy,
                blockId: item.blockId,
                boId: item.boId,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                endTime: item.endTime,
                formId: item.formId,
                geoLocation:
                  item.geoLocation.latitude && item.geoLocation.longitude
                    ? `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`
                    : '',
                id: item.id,
                isApproval: item.isApproval,
                isReject: item.isReject,
                meetingDate: item.meetingDate,
                meetingPlace: item.meetingPlace,
                meetingTopics: JSON.stringify(item.meetingTopics),
                mobile: item.mobile,
                participantsList: item.participantsList
                  .map((participant: any) => participant.name)
                  .join(', '),
                rejectedAt: item.rejectedAt,
                rejectedBy: item.rejectedBy,
                rejectedMsg: item.rejectedMsg,
                smId: item.smId,
                specifingMeetingPlaceName: item.specifingMeetingPlaceName,
                specifyingStakeholderPresent: item.specifyingStakeholderPresent,
                stakeholderPresent: JSON.stringify(item.stakeholderPresent),
                startTime: item.startTime,
                stateId: item.stateId,
                submissionDate: item.submissionDate,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              'Mother Meeting Data'
            );

            XLSX.writeFile(workbook, 'mothermeeting_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err) => {
          console.log('error occured', err);
        }
      );
    } else if (params.formId == 'IEC Materia Dismentiation') {
      this.formService.get_Iecdissemination_FilterData(params).subscribe(
        (res: any) => {
          console.log('response from iecdissminaiton api', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                appVersion: item.appVersion,
                approvedAt: item.approvedAt,
                approvedBy: item.approvedBy,
                blockId: item.blockId,
                boId: item.boId,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                formId: item.formId,
                geoLocation:
                  item.geoLocation.latitude && item.geoLocation.longitude
                    ? `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`
                    : '',
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                iecMaterial: JSON.stringify(item.iecMaterial),
                inTime: item.inTime,
                isApproval: item.isApproval,
                isReject: item.isReject,
                outTime: item.outTime,
                rejectedAt: item.rejectedAt,
                rejectedBy: item.rejectedBy,
                rejectedMsg: item.rejectedMsg,
                remark: item.remark,
                smId: item.smId,
                stateId: item.stateId,
                submissionDate: item.submissionDate,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'IEC Data');

            XLSX.writeFile(workbook, 'IEC_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err: any) => {
          console.log('Error occured in IEC', err);
        }
      );
    } else if (params.formId == 'Diarreoh Follow-up') {
      this.formService.get_childDiarrheaFollowUp_FilterData(params).subscribe(
        (res: any) => {
          console.log('response from child diarrhea', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                blockId: item.blockId,
                boId: item.boId,
                childAge: item.childAge,
                childId: item.childId,
                childName: item.childName,
                createdAt: item.createdAt,
                createdBy: item.createdBy,
                diarreahType: item.diarreahType,
                districtId: item.districtId,
                dlId: item.dlId,
                followUpNumber: item.followUpNumber,
                formId: item.formId,
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                scheduleTimestamp: item.scheduleTimestamp,
                scheduledDate: item.scheduledDate,
                smId: item.smId,
                stateId: item.stateId,
                status: item.status,
                type: item.type,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              'DiarrheaFollowup Data'
            );

            XLSX.writeFile(workbook, 'DiarrheaFollowup_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err: any) => {
          console.log('error occured in diarrhea fllowup', err);
        }
      );
    } else if (params.formId == 'Pneumonia Follow-up') {
      this.formService.get_childPneumoniaFollowUp_FilterData(params).subscribe(
        (res: any) => {
          console.log('response  form child pneumonia followup', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                anyoneAtHome: item.anyoneAtHome,
                appVersion: item.appVersion,
                blockId: item.blockId,
                boId: item.boId,
                childAge: item.childAge,
                childId: item.childId,
                childName: item.childName,
                childPneumoniaId: item.childPneumoniaId,
                childTreatmentStatus: item.childTreatmentStatus,
                createdAt: item.createdAt,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                followUpNumber: item.followUpNumber,
                formId: item.formId,
                geoLocation: JSON.stringify(item.geoLocation || {}),
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                isChildAdmitted: item.isChildAdmitted,
                isCold: item.isCold,
                pneumoniaType: item.pneumoniaType,
                pneumoniaDetails: JSON.stringify(item.pneumonia || {}),
                scheduleTimestamp: item.scheduleTimestamp,
                scheduledDate: item.scheduledDate,
                smId: item.smId,
                stateId: item.stateId,
                status: item.status,
                submissionDate: item.submissionDate,
                symptomsOfPneumonia: item.symptomsOfPneumonia,
                type: item.type,
                whereAdmitted: item.whereAdmitted,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              'ChildPneumoniaFollowup Data'
            );

            XLSX.writeFile(workbook, 'ChildPneumoniaFollowup_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err) => {
          console.log('error occured in pneumonia followup', err);
        }
      );
    } else if (params.formId == 'Mother ANC Follow up') {
      this.formService.get_AncFollowUp_FilterData(params).subscribe(
        (res: any) => {
          console.log('response from ANC followup', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                anyoneAtHome: item.anyoneAtHome,
                appVersion: item.appVersion,
                blockId: item.blockId,
                boId: item.boId,
                childAge: item.childAge,
                childId: item.childId,
                childName: item.childName,
                childPneumoniaId: item.childPneumoniaId,
                childTreatmentStatus: item.childTreatmentStatus,
                createdAt: item.createdAt,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                followUpNumber: item.followUpNumber,
                formId: item.formId,
                geoLocation: JSON.stringify(item.geoLocation || {}),
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                isChildAdmitted: item.isChildAdmitted,
                isCold: item.isCold,
                pneumoniaType: item.pneumoniaType,
                pneumoniaDetails: JSON.stringify(item.pneumonia || {}),
                scheduleTimestamp: item.scheduleTimestamp,
                scheduledDate: item.scheduledDate,
                smId: item.smId,
                stateId: item.stateId,
                status: item.status,
                submissionDate: item.submissionDate,
                symptomsOfPneumonia: item.symptomsOfPneumonia,
                type: item.type,
                whereAdmitted: item.whereAdmitted,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'MotherANC Data');

            XLSX.writeFile(workbook, 'motherANC_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err: any) => {
          console.log('error in  ANC followup', err);
        }
      );
    } else if (params.formId == 'Child Vaccine Follow up') {
      this.formService.get_VaccinationFollowUp_FilterData(params).subscribe(
        (res: any) => {
          console.log('response from vaccination', res);
          if (res.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
              res.map((item: any) => ({
                anyoneAtHome: item.anyoneAtHome,
                appVersion: item.appVersion,
                blockId: item.blockId,
                boId: item.boId,
                childAge: item.childAge,
                childId: item.childId,
                childName: item.childName,
                childPneumoniaId: item.childPneumoniaId,
                childTreatmentStatus: item.childTreatmentStatus,
                createdAt: item.createdAt,
                createdBy: item.createdBy,
                dcrDate: item.dcrDate,
                dcrId: item.dcrId,
                districtId: item.districtId,
                dlId: item.dlId,
                followUpNumber: item.followUpNumber,
                formId: item.formId,
                geoLocation: JSON.stringify(item.geoLocation || {}),
                hhListingId: item.hhListingId,
                houseHoldId: item.houseHoldId,
                id: item.id,
                isApproval: item.isApproval,
                isChildAdmitted: item.isChildAdmitted,
                isCold: item.isCold,
                pneumoniaType: item.pneumoniaType,
                pneumoniaDetails: JSON.stringify(item.pneumonia || {}),
                scheduleTimestamp: item.scheduleTimestamp,
                scheduledDate: item.scheduledDate,
                smId: item.smId,
                stateId: item.stateId,
                status: item.status,
                submissionDate: item.submissionDate,
                symptomsOfPneumonia: item.symptomsOfPneumonia,
                type: item.type,
                whereAdmitted: item.whereAdmitted,
              }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              'VaccinationFollowup Data'
            );

            XLSX.writeFile(workbook, 'VaccinationFollowup_data.xlsx');
            this.downloadIndicatorChanged.emit(false);
          } else {
            alert('no data found');
          }
        },
        (err) => {
          console.log('error occured in Vaccination', err);
        }
      );
    } else if (params.formId == 'Nukkad Natak Form') {
      this.formService.get_NukkadNatak_FilterData(params).subscribe(
        (res: any) => {
          console.log('response from Nukkad Natak Form', res);
        },
        (err) => {
          console.log('error occured', err);
        }
      );
    }else if(params.formId=='Regular Followup'){
       this.formService.get_regular_followup_FilterData(params).subscribe(
         (res: any) => {
           console.log('response from regular followup Form', res);
           if(res.length>0){
             const worksheet = XLSX.utils.json_to_sheet(
               res.map((item: any) => ({
                 blockId: item.blockId,
                 boId: item.boId,
                 createdAt: item.createdAt,
                 createdBy: item.createdBy,
                 districtId: item.districtId,
                 dlId: item.dlId,
                 formId: item.formId,
                 hhListingId: item.hhListingId,
                 id: item.id,
                 lowAwareness: item.lowAwareness,
                 motherCommonQuestionId: item.motherCommonQuestionId,
                 motherId: item.motherId,
                 scheduleTimestamp: item.scheduleTimestamp,
                 scheduledDate: item.scheduledDate,
                 smId: item.smId,
                 stateId: item.stateId,
                 status: item.status,
                 subType: item.subType,
                 submissionDate: item.submissionDate,
                 type: item.type,
               }))
             );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Regular Followup Data');

    XLSX.writeFile(workbook, 'regularFollowup_data.xlsx');
    this.downloadIndicatorChanged.emit(false);
           }else{
            alert("no data found")
           }
         },
         (err) => {
           console.log('error occured', err);
         }
       );
    }else if (params.formId == 'Community Meetings'){
       this.formService.get_communityMeeting_FilterData(params).subscribe(
         (res: any) => {
           console.log('response from community meeting Form', res);
           const worksheet = XLSX.utils.json_to_sheet(
             res.map((item: any) => ({
               appVersion: item.appVersion,
               approvedAt: item.approvedAt,
               approvedBy: item.approvedBy,
               blockId: item.blockId,
               boId: item.boId,
               createdBy: item.createdBy,
               dcrDate: item.dcrDate,
               dcrId: item.dcrId,
               districtId: item.districtId,
               dlId: item.dlId,
               endTime: item.endTime,
               femaleParticipantsCount: item.femaleParticipantsCount,
               formId: item.formId,
               geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
               id: item.id,
               isApproval: item.isApproval,
               isReject: item.isReject,
               maleParticipantsCount: item.maleParticipantsCount,
               meetingDate: item.meetingDate,
               meetingPlace: item.meetingPlace,
               meetingTopics: item.meetingTopics,
               meetingType: item.meetingType,
               participantsList: item.participantsList, 
               rejectedAt: item.rejectedAt,
               rejectedBy: item.rejectedBy,
               rejectedMsg: item.rejectedMsg,
               smId: item.smId,
               specifingMeetingPlaceName: item.specifingMeetingPlaceName,
               startTime: item.startTime,
               stateId: item.stateId,
               submissionDate: item.submissionDate,
               villId: item.villId,
             }))
           );

           const workbook = XLSX.utils.book_new();
           XLSX.utils.book_append_sheet(workbook, worksheet, 'Community Meeting Data');

           XLSX.writeFile(workbook, 'communityMeeting_data.xlsx');
           this.downloadIndicatorChanged.emit(false);
         },
         (err) => {
           console.log('error occured', err);
         }
       );
    }
      this.overviewDashboardService.filterApplyService(params);
    console.log(
      'Filter parameters sent from filter component:',
      JSON.stringify(params)
    );
  }


  // Convert the data to Excel format
  exportToExcel(data: any): void {
    const worksheet = XLSX.utils.json_to_sheet(
      data.map((item: any) => ({
        createdBy: item.createdBy,
        firstName: item.firstName,
        hhAddress: item.hhAddress,
        maleCount: item.maleCount,
        femaleCount: item.femaleCount,
        childBelowAge6Count: item.childBelowAge6Count,
        childAboveAge6Count: item.childAboveAge6Count,
        mobile: item.mobile,
        geoLocation: `${item.geoLocation.latitude}, ${item.geoLocation.longitude}`,
        appVersion: item.appVersion,
        isAgreePhotoClick: item.isAgreePhotoClick,
        isParticpateQuestion: item.isParticpateQuestion,
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'HHListing Data');

    XLSX.writeFile(workbook, 'hhlisting_data.xlsx');
    this.downloadIndicatorChanged.emit(false);
  }

  // applyFilter(params: any): void {
  //   // Extract dates from params if they exist
  //   const fromTimestamp = params.fromTimestamp || (params.fromDate ? moment(params.fromDate).startOf('day').valueOf() : 0);
  //   const toTimestamp = params.toTimestamp || (params.toDate ? moment(params.toDate).endOf('day').valueOf() : 0);

  //   // Create formatted params with arrays and timestamps
  //   const formattedParams = {
  //     stateId: Array.isArray(params.stateId) ? params.stateId : [],
  //     districtId: Array.isArray(params.districtId) ? params.districtId : [],
  //     blockId: Array.isArray(params.blockId) ? params.blockId : [],
  //     fromTimestamp,
  //     toTimestamp
  //   };
  //   this.overviewDashboardService.filterApplyService(formattedParams);
  //   console.log('Filter parameters sent from filter component:', JSON.stringify(formattedParams));
  // }
}
