// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  body {
    font-family: 'Arial', sans-serif;
    margin: 20px;
    background-color: #f7f8fc;
  }

  .styled-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.8em;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  .styled-table thead tr {
    background-color: #4CAF50;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
  }

  .styled-table th,
  .styled-table td {
    padding: 6px 7px;
    border: 1px solid #ddd;
    text-align: center;
  }

  .styled-table th {
    background-color: #333;
    color: #ffffff;
  }

  .styled-table tbody tr {
    border-bottom: 0.5px solid #dddddd;
  }

  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  .styled-table tbody tr:last-of-type {
    border-bottom: 1px solid #4CAF50;
  }

  .styled-table tbody tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  .indicator {
    background-color: #f4f4f4;
    font-weight: bold;
    text-align: left;
    padding-left: 5px;
  }

 
.table-container {
  overflow-x: auto; 
  margin-bottom: 20px; 
}

`, "",{"version":3,"sources":["webpack://./src/app/team-performance-table/team-performance-table.component.css"],"names":[],"mappings":";EACE;IACE,gCAAgC;IAChC,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,wCAAwC;IACxC,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;;IAEE,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,cAAc;EAChB;;EAEA;IACE,kCAAkC;EACpC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,yBAAyB;IACzB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;EACnB;;;AAGF;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["\r\n  body {\r\n    font-family: 'Arial', sans-serif;\r\n    margin: 20px;\r\n    background-color: #f7f8fc;\r\n  }\r\n\r\n  .styled-table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    font-size: 0.8em;\r\n    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);\r\n    background-color: #ffffff;\r\n  }\r\n\r\n  .styled-table thead tr {\r\n    background-color: #4CAF50;\r\n    color: #ffffff;\r\n    text-align: center;\r\n    font-weight: bold;\r\n  }\r\n\r\n  .styled-table th,\r\n  .styled-table td {\r\n    padding: 6px 7px;\r\n    border: 1px solid #ddd;\r\n    text-align: center;\r\n  }\r\n\r\n  .styled-table th {\r\n    background-color: #333;\r\n    color: #ffffff;\r\n  }\r\n\r\n  .styled-table tbody tr {\r\n    border-bottom: 0.5px solid #dddddd;\r\n  }\r\n\r\n  .styled-table tbody tr:nth-of-type(even) {\r\n    background-color: #f3f3f3;\r\n  }\r\n\r\n  .styled-table tbody tr:last-of-type {\r\n    border-bottom: 1px solid #4CAF50;\r\n  }\r\n\r\n  .styled-table tbody tr:hover {\r\n    background-color: #f1f1f1;\r\n    cursor: pointer;\r\n  }\r\n\r\n  .indicator {\r\n    background-color: #f4f4f4;\r\n    font-weight: bold;\r\n    text-align: left;\r\n    padding-left: 5px;\r\n  }\r\n\r\n \r\n.table-container {\r\n  overflow-x: auto; \r\n  margin-bottom: 20px; \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
