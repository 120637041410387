import { Component, OnInit, NgZone, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { FilterComponent } from '../filter/filter.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { environment } from '../../environment/environment.local';
import { HttpClient } from '@angular/common/http';
import { BirthSpaceTreeComponent } from '../components/birth-space-tree/birth-space-tree.component';
import { TreeNode } from 'primeng/api';

interface IndicatorData {
  name: string;
  value: number;
  //description: string;
}

interface graphData {
  name: string;
  value: number;
  description: string;
}

interface TableData {
    firstName: string;
    age: number;
    mobile: string;
    beneficiaryStatus: string;
    fatherName: string;
    husbandName: string;

}

@Component({
  selector: 'app-hygiene-and-health',
  standalone: true,
  imports: [
    CommonModule,
    FilterComponent,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    BirthSpaceTreeComponent,
  ],
  templateUrl: './hygiene-and-health.component.html',
  styleUrl: './hygiene-and-health.component.css',
})
export class HygieneAndHealthComponent {
   // Input to control visibility of tables
   @Input() showAndHideTablesOfHealthAndHygiene: boolean = true;
  isFilterVisible: boolean = false; // Variable to control visibility
  indicators: IndicatorData[] = [];
  graphData: graphData[] = [
    {
      name: 'Hand hygiene Low Awareness',
      value: 0,
      description: 'Hand hygiene Low Awareness',
    },
    {
      name: 'Hand hygiene Counsling Done',
      value: 0,
      description: 'Hand hygiene Counsling Done',
    },
    {
      name: 'Menstrual hygiene Low Awareness',
      value: 0,
      description: 'Menstrual hygiene Low Awareness',
    },
    {
      name: 'Menstrual hygiene Counsling Done',
      value: 0,
      description: 'Menstrual hygiene Counsling Done',
    },
    {
      name: 'UTI Low Awareness',
      value: 0,
      description: 'UTI Low Awareness',
    },
    {
      name: 'UTI Counseling Done',
      value: 0,
      description: 'UTI Counseling Done',
    },
  ];

  tableData: TableData[] = [
    {
      firstName: 'Nuh',
      age: 40,
      mobile: '20',
      beneficiaryStatus: 'LM',
      fatherName: '20',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Palwal',
      age: 30,
      mobile: '25',
      beneficiaryStatus: 'LM',
      fatherName: '5',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Rohtak',
      age: 50,
      mobile: '30',
      beneficiaryStatus: 'LM',
      fatherName: '20',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Sirsa',
      age: 38,
      mobile: '33',
      beneficiaryStatus: 'LM',
      fatherName: '5',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Sonipat',
      age: 37,
      mobile: '30',
      beneficiaryStatus: 'LM',
      fatherName: '7',
      husbandName: 'Saroj Kumar',
    },
  ];

  dataSource: MatTableDataSource<TableData>;
  displayedColumns2: string[] = ['sn', 'name', 'age', 'fatherName', 'mobile'];
  dataSource2: MatTableDataSource<TableData> = new MatTableDataSource(
    this.tableData
  ); // Added dataSource2 initialization

  private root!: am5.Root;

  private apiUrl = environment.apiUrl;
  HygieneAndHealthTreeData: any;

  treeColumnsHygineAndHealth = [
    { field: 'name', header: 'Name' },
    { field: 'isWashHands', header: 'Hand hygiene' },
    { field: 'AwarenessonUTIRTI', header: 'Awareness on UTI / RTI' },
    { field: 'MenstrualHygiene', header: 'Menstrual Hygiene' },
    { field: 'showProteQTLink', header: 'ProteQT app' },
  ];
  params = {};
  stateId = [];
  districtId = [];
  blockId = [];
  villageId = [];

  constructor(private zone: NgZone, private http: HttpClient) {
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngOnInit(): void {
    let allUser = localStorage.getItem('currentUser');

    if (allUser) {
      let currentUser: any = JSON.parse(allUser);

      if (currentUser.user.designation === 'SM') {
        this.stateId = currentUser.user['stateId'];
      } else if (currentUser.user.designation === 'DL') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
      } else if (currentUser.user.designation === 'BO') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
      } else if (currentUser.user.designation === 'CHW') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
        this.villageId = currentUser.user['villageId'];
      }
    }
    let currentUser = localStorage.getItem('currentUser');
    let parsedUser = currentUser ? JSON.parse(currentUser) : null;

    this.params = {
      state_Id: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      district_Id: Array.isArray(this.districtId)
        ? this.districtId
        : [this.districtId],
      block_Id: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      village_Id: Array.isArray(this.villageId)
        ? this.villageId
        : [this.villageId],
      userLevel: parsedUser?.user?.designation,
    };
    // this.dataSource2=this.tableData
    // this.dataSource2.paginator = this.paginator;
    this.getHygieneHealthTreeData(this.params);
    this.getHealthHygineData();
    this.getHygineAndHealthGraphData(this.params);
  }
  // Method to toggle the visibility of the filter component
  toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }

  getHygieneHealthTreeData(params:any) {
    console.log("params in hygine api", params)
    this.http
      .get(
        `${this.apiUrl}MotherCommonQues/getHygieneHealthTreeData`   +'?params=' +
          JSON.stringify(params)
      )
      .subscribe(
        (res: any) => {
          this.HygieneAndHealthTreeData = this.transformData(res.data);
        },
        (err) => {
          console.log('error from getHygieneHealthTreeData', err);
        }
      );
  }

  getHealthHygineData() {
    this.http
      .get(`${this.apiUrl}MotherCommonQues/getHealthHygineData`)
      .subscribe(
        (res: any) => {
          this.indicators = res.data;
        },
        (err) => {
          console.log('error from getHealthHygineData', err);
        }
      );
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.createChart();
    });
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  getHygineAndHealthGraphData(params: any) {
    this.http
      .get(
        `${this.apiUrl}/Mothercommonques/getHygineAndHealthGraphData/` +
          JSON.stringify(params)
      )
      .subscribe(
        (res: any) => {
          this.graphData.forEach((graphItem) => {
            const matchingIndicator = res.data.find(
              (indicator: any) => indicator.name === graphItem.name
            );

            if (matchingIndicator) {
              graphItem.value = matchingIndicator.value;
            }
          });

          this.graphData.sort((a, b) => b.value - a.value);

          this.updateChartData();
        },
        (err) => {
          console.log('Error from getHygineAndHealthGraphData', err);
        }
      );
  }

  updateChartData() {
    if (this.root) {
      const chart = this.root.container.children.getIndex(0) as am5xy.XYChart;

      if (chart) {
        const xAxis = chart.xAxes.getIndex(0);
        const series = chart.series.getIndex(0);

        if (xAxis && series) {
          xAxis.data.setAll(this.graphData);
          series.data.setAll(this.graphData);
        } else {
          console.log('Unable to update chart: xAxis or series not found.');
        }
      } else {
        console.log('Chart not found.');
      }
    }
  }

  transformData(input: any): TreeNode[] {
    const transform = (node: any, level: string, index: number): TreeNode => {
      const { parent, ...restNode } = node;

      const newNode: TreeNode = {
        key: `${level}-${index}`,
        data: { ...restNode },
        children: [],
      };

      if (node.districts) {
        newNode.children = node.districts.map((district: any, i: number) =>
          transform(district, 'district', i)
        );
      } else if (node.blocks) {
        newNode.children = node.blocks.map((block: any, i: number) =>
          transform(block, 'block', i)
        );
      } else if (node.chwList || node.chwlist) {
        if (node.chwList) {
          newNode.children = node.chwList.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        } else {
          newNode.children = node.chwlist.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        }
      }

      return newNode;
    };

    const result = input.map((item: any, index: number) =>
      transform(item, 'state', index)
    );

    return result;
  }

  createChart() {
    this.root = am5.Root.new('chartdiv');
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );

    let cursor = chart.set('cursor', am5xy.XYCursor.new(this.root, {}));
    cursor.lineY.set('visible', false);

    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      rotation: -90, // Rotate labels 90 degrees to the left
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fontSize: 10,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0.3,
        categoryField: 'name',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(this.root, {
      strokeOpacity: 0.1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      })
    );

    let series = chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        sequencedInterpolation: true,
        categoryXField: 'name',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{valueY}',
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
    });
    series.columns.template.adapters.add('fill', (fill, target) => {
      const colors = chart.get('colors');
      return colors ? colors.getIndex(series.columns.indexOf(target)) : fill;
    });

    series.columns.template.adapters.add('stroke', (stroke, target) => {
      const colors = chart.get('colors');
      return colors ? colors.getIndex(series.columns.indexOf(target)) : stroke;
    });

    xAxis.data.setAll(this.indicators);
    series.data.setAll(this.indicators);

    series.appear(1000);
    chart.appear(1000, 100);
  }

  onFilterChange(filterData: any): void {
    // Implement filter logic here
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  export(): void {
    // Implement export functionality
  }
}
