import { Injectable } from '@angular/core';
import { environment } from '../../environment/environment.local';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CommunityInterventionService {

  private readonly endpoint = `${environment.apiUrl}MotherMeetings`;
  private readonly endpointofNukkadNataks = `${environment.apiUrl}NukkadNataks`;

  constructor(private http: HttpClient) {}
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  getNukkadNatakTotalData(): Observable<any> {
    const url = `${this.endpointofNukkadNataks}/nukkadNatakTotal`;
    return this.http.get(url, { headers: this.headers });
  }

  getNukkadNatakCoverageData(): Observable<any> {
    const url = `${this.endpointofNukkadNataks}/nukkadNatakCoverage`;
    return this.http.get(url, { headers: this.headers });
  }
  
  getMotherMeetingsTotalData(): Observable<any> {
    const url = `${this.endpoint}/countByMotherMeetingsTotal`;
    return this.http.get(url, { headers: this.headers });
  }

  getMotherMeetingsCoverageData(): Observable<any> {
    const url = `${this.endpoint}/countByMotherMeetingsCoverage`;
    return this.http.get(url, { headers: this.headers });
  }
  
  VHSNDTotalData(): Observable<any> {
    const url = `${this.endpoint}/countByVHSNDTotal`;
    return this.http.get(url, { headers: this.headers });
  }

  VHSNDCoverageData(): Observable<any> {
    const url = `${this.endpoint}/countByVHSNDCoverage`;
    return this.http.get(url, { headers: this.headers });
  }


}
