import { ChangeDetectorRef, Component, ComponentRef, ElementRef, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterComponent } from '../filter/filter.component';
interface Option {
  value: string;
  label: string;
  selected: boolean;
}

@Component({
  selector: 'app-line-list',
  standalone: true,
  imports: [FilterComponent, FormsModule, CommonModule],
  templateUrl: './line-list.component.html',
  styleUrl: './line-list.component.css',
})
export class LineListComponent {
  @ViewChild('dynamicContainerofFilter', {
    read: ViewContainerRef,
    static: true,
  })
  filterContainer!: ViewContainerRef;
  showDownloadIndicator: boolean = false;
  countdown = 10;
  intervalId: any;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  // ngOnInit() {
  //   this.loadFilterComponent();
  // }

  // loadFilterComponent(): void {
  //   this.filterContainer.clear();

  //   const componentRef: ComponentRef<FilterComponent> =
  //     this.filterContainer.createComponent(FilterComponent);

  //   const instance = componentRef.instance;

  //   instance.downloadIndicatorChanged.subscribe((value: boolean) => {
  //     this.onDownloadIndicatorChange(value);
  //   });
  // }

  onDownloadIndicatorChange(value: boolean): void {
    this.showDownloadIndicator = value;
    this.startDownloadCountdown();
    console.log('Download Indicator Updated:', this.showDownloadIndicator);
    this.changeDetectorRef.detectChanges();
  }

  // Start the countdown
  startDownloadCountdown() {
    this.countdown = 10; 
    this.intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.stopDownloadCountdown();
        
      }
    }, 1500);
  }

  // Stop the countdown
  stopDownloadCountdown() {
    if (this.intervalId) {
      clearInterval(this.intervalId); 
      this.intervalId = null;
    }
  }

 
}