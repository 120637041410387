import { Component, OnInit, NgZone, AfterViewInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { FilterComponent } from '../filter/filter.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { environment } from '../../environment/environment.local';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';
import { MalariaTreeComponent } from '../components/malaria-tree/malaria-tree.component';
import { VectorBorneDiseasesService } from '../Services/vector-borne-diseases.service';

interface IndicatorData {
  name: string;
  value: number;
  description: string;
}

interface TableData {
    blockName: string;
    diarrhoeaCases: number;
    treatedAtHome: string;
    beneficiaryStatus: string;
    treatedAtFacility: string;
    husbandName: string;

}
@Component({
  selector: 'app-vector-borne-diseases',
  standalone: true,
  imports: [
    CommonModule,
    FilterComponent,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSort,
    MatSortModule,
    MatPaginator,
    MalariaTreeComponent,
  ],
  templateUrl: './vector-borne-diseases.component.html',
  styleUrl: './vector-borne-diseases.component.css',
})
export class VectorBorneDiseasesComponent {
  //hide table in a mother-sixpillar-dashboard component
  @Input() showAndHideTablesOfVectorBornDiseases: boolean = true;
  isFilterVisible: boolean = false; // Variable to control visibility
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private apiUrl = environment.apiUrl;
  // 22 september
  stateId = [];
  districtId = [];
  blockId = [];
  chwId = [];
  currentUser: any;
  indicators: Array<{ name: string; value: number }> = [];
  params = {};

  // indicators: IndicatorData[] = [
  //   {
  //     name: 'Complication awareness in pregnancy',
  //     value: 75,
  //     description: '% women with early registration of pregnancy',
  //   },
  //   {
  //     name: 'VBD Prevalance',
  //     value: 82,
  //     description: '% women with completed ANC as per schedule',
  //   },
  //   {
  //     name: 'Women with VBD during pregnancy ',
  //     value: 68,
  //     description: '% women who had institutional delivery',
  //   },
  //   {
  //     name: 'Case management of malaria',
  //     value: 91,
  //     description: '% women received ANC in different facilities',
  //   },
  // ];

  tableData: TableData[] = [
    {
      blockName: 'Aklimpur',
      diarrhoeaCases: 11,
      treatedAtHome: '9',
      beneficiaryStatus: 'LM',
      treatedAtFacility: '2',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Palra',
      diarrhoeaCases: 15,
      treatedAtHome: '11',
      beneficiaryStatus: 'LM',
      treatedAtFacility: '4',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Babupur',
      diarrhoeaCases: 25,
      treatedAtHome: '20',
      beneficiaryStatus: 'LM',
      treatedAtFacility: '5',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Bamroli',
      diarrhoeaCases: 10,
      treatedAtHome: '5',
      beneficiaryStatus: 'LM',
      treatedAtFacility: '5',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Gairatpur Bas',
      diarrhoeaCases: 8,
      treatedAtHome: '6',
      beneficiaryStatus: 'LM',
      treatedAtFacility: '2',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Hayatpur',
      diarrhoeaCases: 10,
      treatedAtHome: '7',
      beneficiaryStatus: 'LM',
      treatedAtFacility: '3',
      husbandName: 'Saroj Kumar',
    },
    {
      blockName: 'Garhi Harsaru',
      diarrhoeaCases: 12,
      treatedAtHome: '10',
      beneficiaryStatus: 'LM',
      treatedAtFacility: '2',
      husbandName: 'Saroj Kumar',
    },
  ];

  malariaTreeData: any;

  treeColumnsMalaria = [
    // {
    //   field: 'Id',
    //   header: 'id',
    // },
    {
      field: 'name',
      header: 'Name',
    },
    {
      field: 'isMalariaCanPrevent',
      header: 'Malaria Symptoms Awareness',
    },
    {
      field: 'isAwareMalariaSpread',
      header: 'Malaria Spread Awareness',
    },
    {
      field: 'isMalariaCanPreventOptions',
      header: 'Malaria Prevention Practice',
    },
    {
      field: 'isMalariaFatal',
      header: 'Awareness of Malaria Complications and Fatility',
    },
  ];
  villageId=[]

  dataSource: MatTableDataSource<TableData>;
  displayedColumns2: string[] = [
    'sn',
    'blockName',
    'diarrhoeaCases',
    'treatedAtFacility',
    'treatedAtHome',
  ];
  dataSource2: MatTableDataSource<TableData> = new MatTableDataSource(
    this.tableData
  ); // Added dataSource2 initialization

  private root!: am5.Root;

  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private vectorBorneDiseasesService: VectorBorneDiseasesService
  ) {
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngOnInit(): void {
    let allUser = localStorage.getItem('currentUser');

    if (allUser) {
      let currentUser: any = JSON.parse(allUser);

      if (currentUser.user.designation === 'SM') {
        this.stateId = currentUser.user['stateId'];
      } else if (currentUser.user.designation === 'DL') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
      } else if (currentUser.user.designation === 'BO') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
      } else if (currentUser.user.designation === 'CHW') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
        this.villageId = currentUser.user['villageId'];
      }
    }
    let currentUser = localStorage.getItem('currentUser');
    let parsedUser = currentUser ? JSON.parse(currentUser) : null;

    this.params = {
      state_Id: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      district_Id: Array.isArray(this.districtId)
        ? this.districtId
        : [this.districtId],
      block_Id: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      village_Id: Array.isArray(this.villageId)
        ? this.villageId
        : [this.villageId],
      userLevel: parsedUser?.user?.designation,
    };
    // this.dataSource2=this.tableData
    // this.dataSource2.paginator = this.paginator;
    this.getMalariaTreeData(this.params);
    this.getDataFromLocalStorage(); // 22 september
  }

  ngAfterViewInit(): void {
    this.dataSource2.paginator = this.paginator;
    this.dataSource2.sort = this.sort;

    this.zone.runOutsideAngular(() => {
      this.createChart();
    });
  }
  // Method to toggle the visibility of the filter component
  toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }

  transformData(input: any): TreeNode[] {
    const transform = (node: any, level: string, index: number): TreeNode => {
      const { parent, ...restNode } = node;

      const newNode: TreeNode = {
        key: `${level}-${index}`,
        data: { ...restNode },
        children: [],
      };

      if (node.districts) {
        newNode.children = node.districts.map((district: any, i: number) =>
          transform(district, 'district', i)
        );
      } else if (node.blocks) {
        newNode.children = node.blocks.map((block: any, i: number) =>
          transform(block, 'block', i)
        );
      } else if (node.chwList || node.chwlist) {
        if (node.chwList) {
          newNode.children = node.chwList.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        } else {
          newNode.children = node.chwlist.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        }
      }

      return newNode;
    };

    const result = input.map((item: any, index: number) =>
      transform(item, 'state', index)
    );

    return result;
  }

  getMalariaTreeData(params: any) {
    this.http
      .get<any>(
        `${this.apiUrl}MotherCommonQues/getMalariaTreeData` +
          '?params=' +
          JSON.stringify(params)
      )
      .subscribe(
        (response) => {
          console.log('response from malaria tree api', response);
          this.malariaTreeData = this.transformData(response.data);
          console.log('Transformed tree data', this.malariaTreeData);
        },
        (error) => {
          console.error('Error fetching Malaria data:', error);
        }
      );
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  createChart() {
    this.root = am5.Root.new('chartdiv');
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );

    let cursor = chart.set('cursor', am5xy.XYCursor.new(this.root, {}));
    cursor.lineY.set('visible', false);

    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0.3,
        categoryField: 'name',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(this.root, {
      strokeOpacity: 0.1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      })
    );

    let series = chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        sequencedInterpolation: true,
        categoryXField: 'name',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{valueY}',
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
    });
    series.columns.template.adapters.add('fill', (fill, target) => {
      const colors = chart.get('colors');
      return colors ? colors.getIndex(series.columns.indexOf(target)) : fill;
    });

    series.columns.template.adapters.add('stroke', (stroke, target) => {
      const colors = chart.get('colors');
      return colors ? colors.getIndex(series.columns.indexOf(target)) : stroke;
    });

    xAxis.data.setAll(this.indicators);
    series.data.setAll(this.indicators);

    series.appear(1000);
    chart.appear(1000, 100);
  }

  onFilterChange(filterValues: any): void {
    // Set the params based on filter values
    let params: any = {
      stateId: filterValues.state_Id ? filterValues.state_Id : undefined,
      districtId: filterValues.district_Id
        ? filterValues.district_Id
        : undefined,
      blockId: filterValues.block_Id ? filterValues.block_Id : undefined,
      chwId: filterValues.chw_Id ? filterValues.chw_Id : undefined,
      userLevel: this.currentUser?.user.designation, // Ensure currentUser is initialized
    };

    // Conditionally add date range if available
    if (filterValues.from_date && filterValues.to_date) {
      params['fromDate'] = filterValues.from_date;
      params['toDate'] = filterValues.to_date;
    }

    // Call the service with the params
    this.getAllData(params);
    this.getMalariaTreeData(params);
  }

  // 22 september
  getDataFromLocalStorage(): void {
    let allUser = localStorage.getItem('currentUser');

    if (allUser) {
      this.currentUser = JSON.parse(allUser);
      if (this.currentUser.user.designation === 'SM') {
        this.stateId = this.currentUser.user['stateId'];
      } else if (this.currentUser.user.designation === 'DL') {
        this.stateId = this.currentUser.user['stateId'];
        this.districtId = this.currentUser.user['districtId'];
      } else if (this.currentUser.user.designation === 'BO') {
        this.stateId = this.currentUser.user['stateId'];
        this.districtId = this.currentUser.user['districtId'];
        this.blockId = this.currentUser.user['blockId'];
      } else if (this.currentUser.user.designation === 'CHW') {
        this.stateId = this.currentUser.user['stateId'];
        this.districtId = this.currentUser.user['districtId'];
        this.blockId = this.currentUser.user['blockId'];
        this.chwId = this.currentUser.user['chwId'];
      }
    }

    // Initialize params based on user's access level
    this.params = {
      stateId: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      districtId: Array.isArray(this.districtId)
        ? this.districtId
        : [this.districtId],
      blockId: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      chwId: Array.isArray(this.chwId) ? this.chwId : [this.chwId],
      userLevel: this.currentUser?.user.designation,
    };

    this.getAllData(this.params);
  }

  getAllData(paramsValue: any): void {
    this.vectorBorneDiseasesService
      .getCountMalariaAwareness(paramsValue)
      .subscribe(
        (response) => {
          const data = response.data;
          console.log(`This is my data: ${JSON.stringify(data)}`);

          // Map API response to the Indicator format
          this.indicators = [
            // { name: 'Total Mothers', value: data.totalCount },
            {
              name: 'Can Malaria be Prevented?',
              value: data.isMalariaCanPrevent,
            },
            {
              name: 'Awareness of Malaria Spread (Combined)',
              value: data.isAwareMalariaSpread_combined,
            },
            {
              name: 'Malaria Can be Prevent Options (Combined)',
              value: data.isMalariaCanPreventOptions_combined,
            },
            { name: 'Is Malaria Fatal?', value: data.isMalariaFatal },
          ];

          // console.log(this.indicators); // Debugging output
        },
        (error) => {
          console.error('Error fetching food awareness data:', error);
        }
      );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  export(): void {
    // Implement export functionality
  }
}
