import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  NgZone,
  PLATFORM_ID,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.css'],
  standalone: true,
})
export class ColumnChartComponent implements OnInit, OnDestroy, OnChanges {
  private root!: am5.Root;
  private exporting?: am5plugins_exporting.Exporting;
  private legend?: am5.Legend;

  @Input() id!: string;
  @Input() data!: any;
  @Input() type!: string;
  @Input() colors!: string[];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initializeChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && !changes['data'].firstChange) {
      this.updateChart();
    }
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  private browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => f());
    }
  }

  private initializeChart() {
    this.browserOnly(() => {
      this.root = am5.Root.new(this.id);
      this.root._logo?.dispose();
      this.root.setThemes([am5themes_Animated.new(this.root)]);

      const chart = this.createChartStructure();
      this.legend = this.createLegend(chart);
      this.createSeries(chart);
      this.setupExporting();
    });
  }

  private createChartStructure(): am5xy.XYChart {
    const chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: this.root.verticalLayout,
      })
    );

    const xRenderer = am5xy.AxisRendererX.new(this.root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: true,
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'month',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    xRenderer.grid.template.setAll({ location: 1 });
    xAxis.data.setAll(this.data);

    chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, { strokeOpacity: 0.1 }),
      })
    );

    return chart;
  }

  private createLegend(chart: am5xy.XYChart): am5.Legend {
    return chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
      })
    );
  }

  private createSeries(chart: am5xy.XYChart) {
    const colorList = this.colors || [];
    const xAxis = chart.xAxes.getIndex(0);
    const yAxis = chart.yAxes.getIndex(0);

    if (!xAxis || !yAxis) {
      console.error('xAxis or yAxis is undefined.');
      return;
    }

    const makeSeries = (
      name: string,
      fieldName: string,
      color: string,
      type:string
    ) => {
      let tooltip='{name} : {valueY.formatNumber("#.#")}%'
        let texttip='{valueY.formatNumber("#.#")} %'
        if(type==='availability'){
          tooltip='{name} : {valueY.formatNumber("#.#")}/{totalEntries.formatNumber("#.#")}'
          texttip='{valueY.formatNumber("#.#")}'
        }
      const series = chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name,
          xAxis,
          yAxis,
          valueYField: fieldName,
          categoryXField: 'month',
        })
      );
    
      series.columns.template.setAll({
        tooltipText: tooltip,
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
        fill: am5.color(color),
      });
    
      series.bullets.push(() => {
                return am5.Bullet.new(this.root, {
                  locationY: 1, // Position the label slightly above the column
                  sprite: am5.Label.new(this.root, {
                    text: '{valueY.formatNumber("#.#")}', // Round off to 1 decimal place
                    fill: am5.color('#000000'), // Text color
                    fontSize: 10,
                    centerX: am5.p50, // Horizontally center the label
                    centerY: am5.p100, // Align the bottom of the label with the top of the column
                    populateText: true,
                  }),
                });
              });
    
      series.data.setAll(this.data);
    
      if (this.legend) {
        this.legend.data.push(series);
      }
    
      series.appear();
    };
    

    // Define series logic based on the type
    // Example series creation based on the type
    if (this.type === 'districtwise-monthwise') {
      makeSeries('Dhule', 'Dhule', '#66B2FF', 'target');
      makeSeries('Washim', 'Washim', '#007BFF', 'target');
      makeSeries('Bhavnagar', 'Bhavnagar', '#F39C12', 'target');
      makeSeries('Girsomnath', 'Girsomnath', '#F5A623', 'target');
      makeSeries('Rajsamand', 'Rajsamand', '#28B463', 'target');
    } else if (this.type === 'statewise-monthwise') {
      makeSeries('Maharashtra', 'Maharashtra', '#0056B3', 'target');
      makeSeries('Gujarat', 'Gujarat', '#D26D00', 'target');
      makeSeries('Rajasthan', 'Rajasthan', '#1F7A3D', 'target');
    } else if (this.type === 'availabiliAtFacility') {
      makeSeries('Av-Iron', 'Av-Iron', '#90de9b', 'availability');        // Dark navy blue
      makeSeries('Av-Amox', 'Av-Amox', '#cdd435', 'availability');        // Medium royal blue
      makeSeries('Av-ORS', 'Av-ORS', '#e8a03d', 'availability');          // Standard blue
      makeSeries('Av-Calcium', 'Av-Calcium', '#7fdcdc', 'availability');  // Light cerulean blue
      makeSeries('Av-VitaminC', 'Av-VitaminC', colorList[4] || '#f4c3d8', 'availability'); // Sky blue
      makeSeries('Av-Zinc', 'Av-Zinc', '#d0d2ff', 'availability');        // Pale blue
    } else if (this.type === 'awarepractice') {
      makeSeries('Early Registration', 'Early Registration', '#aab5c3', 'target');     // Dark teal blue
      makeSeries('ANC Awareness', 'ANC Awareness', '#dcae96', 'target');              // Deep ocean blue
      makeSeries('Malaria Awareness', 'Malaria Awareness', '#3498db', 'target');      // Standard blue
      makeSeries('Wash Hands', 'Wash Hands', '#98ff98', 'target');                    // Light cerulean blue
      makeSeries('Family Planning Awareness', 'Family Planning Awareness', '#fdd0ff', 'target');
    } else if (this.type == 'blockwise-monthwise') {
      makeSeries('Sakri', 'Sakri', '#A3CFFF', 'target');
      makeSeries('Malegaon', 'Malegaon', '#66AFFF', 'target');
      makeSeries('Bhavnagar', 'Bhavnagar', '#F9B84C', 'target');
      makeSeries('Palitana', 'Palitana', '#F9D68D', 'target');
      makeSeries('Mahuva', 'Mahuva', '#FCD58A', 'target');
      makeSeries('Talala', 'Talala', '#FCE29F', 'target');
      makeSeries('Veraval', 'Veraval', '#F9E3B5', 'target');
      makeSeries('Una', 'Una', '#F7E8B6', 'target');
      makeSeries('Rajsamand', 'Rajsamand', '#A2D8A5', 'target');
      makeSeries('Kumbhalgarh', 'Kumbhalgarh', '#C3E6B1', 'target');
    }
    else if (this.type == 'chwwise-monthwise') {
      makeSeries('Salma Pathan', 'Salma Pathan', colorList[0], 'target');
      makeSeries('Anjana Valvi', 'Anjana Valvi', colorList[0], 'target');
      makeSeries('Hira Valvi', 'Hira Valvi', colorList[0], 'target');
      makeSeries('Vaishali Bagul', 'Vaishali Bagul', colorList[0], 'target');
      makeSeries('Kalgi Bagul', 'Kalgi Bagul', colorList[0], 'target');
      makeSeries('Kalpana Vasave', 'Kalpana Vasave', colorList[0], 'target');
      makeSeries('Vaishnavi  Narwade', 'Vaishnavi  Narwade', colorList[0], 'target');
      makeSeries('Shital Nagale', 'Shital Nagale', colorList[0], 'target');
      makeSeries('Mohini Uike', 'Mohini Uike', colorList[0], 'target');
      makeSeries('Shubha Ingle', 'Shubha Ingle', colorList[0], 'target');
      makeSeries('Kajal Rathod ', 'Kajal Rathod ', colorList[0], 'target');
      makeSeries('Jaideep Shrimali', 'Jaideep Shrimali', colorList[0], 'target');
      makeSeries('Rohit Paliwal', 'Rohit Paliwal', colorList[0], 'target');
      makeSeries('Kanku Purbia', 'Kanku Purbia', colorList[0], 'target');
      makeSeries('Meena Paliwal', 'Meena Paliwal', colorList[0], 'target');
      makeSeries('Manju Meghwal', 'Manju Meghwal', colorList[0], 'target');
      makeSeries('Hemlata Joshi', 'Hemlata Joshi', colorList[0], 'target');
      makeSeries('Bhagyavanti Sen', 'Bhagyavanti Sen', colorList[0], 'target');
      makeSeries('Renuka Gohil', 'Renuka Gohil', colorList[0], 'target');

      makeSeries('Ami  Chauhan', 'Ami  Chauhan', colorList[0], 'target');
      makeSeries('Vilas Shiyal', 'Vilas Shiyal', colorList[0], 'target');
      makeSeries('Aksha  Darjada', 'Aksha  Darjada', colorList[0], 'target');
      makeSeries('Sumera Majgul', 'Sumera Majgul', colorList[0], 'target');
      makeSeries('Hansa Jethva', 'Hansa Jethva', colorList[0], 'target');
      makeSeries('Hetal  Makwana', 'Hetal  Makwana', colorList[0], 'target');
      makeSeries('Asmita Vasave', 'Asmita Vasave', colorList[0], 'target');
      makeSeries(' Dharmnath Katole', ' Dharmnath Katole', colorList[0], 'target');

    }
  }

  private setupExporting() {
    this.exporting = am5plugins_exporting.Exporting.new(this.root, {
      filePrefix: 'chart',
      dataSource: this.data,
      pdfOptions: { addURL: false },
      csvOptions: { addColumnNames: true, separator: ',' },
    });

    am5plugins_exporting.ExportingMenu.new(this.root, {
      exporting: this.exporting,
    });
  }

  private updateChart() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      this.initializeChart();
    });
  }
}
