import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TreeTableComponent } from '../tree-table/tree-table.component';
import { TreeTableDataService } from '../Services/tree-data.service';
import { TreeNode } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tree-table-page',
  standalone: true,
  imports: [CommonModule, RouterModule, TreeTableComponent],
  templateUrl: './tree-table-page.component.html',
  styleUrls: ['./tree-table-page.component.css']
})
export class TreeTablePageComponent implements OnInit {
  treeTableData: TreeNode[] = [];
  treeTableCols: any[] = [];

  constructor(
    private treeTableDataService: TreeTableDataService,
    private location: Location
  ) {}

  ngOnInit() {
    this.treeTableDataService.getTreeTableData().subscribe((data: TreeNode[]) => {
      this.treeTableData = data;
    });

    this.treeTableDataService.getTreeTableCols().subscribe((cols: any[]) => {
      this.treeTableCols = cols;
    });
  }

  goBack() {
    this.location.back();
  }
}