import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment/environment.local';
export const apiEndPoint = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class PneumoniaSelfCareService {

  private readonly endpoint = `${environment.apiUrl}ChildPneumonia`;

  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });
  constructor(private http: HttpClient) {}

  getChildPneumoniaCountFun(filter: any): Observable<any> {
    let obj = {
      designation: filter.designation,
      stateId: filter.stateId,
      districtId: filter.districtId,
      blockId: filter.blockId,
      villId: filter.village_Id,
      type: filter.type,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
    };
    const url =
      `${apiEndPoint}ChildPneumonia/getChildPneumoniaCount` +
      '?params=' +
      JSON.stringify(obj);
    return this.http.get(url, { headers: this.headers });
  }
  getChildPneumoniaTreatedStatus(filter: any): Observable<any> {
    let obj = {
      designation: filter.designation,
      stateId: filter.state_Id,
      blockId: filter.block_Id,
      districtId: filter.district_Id,
      villId: filter.village_Id,
      type: filter.type,
      fromDate: filter.from_date,
      toDate: filter.to_date,
    };
    const url =
      `${apiEndPoint}/ChildPneumonia/getChildPneumoniaTreatedStatus` +
      '?params=' +
      JSON.stringify(obj);

    return this.http.get(url, { headers: this.headers });
  }
  getChildPneumoniaTreatedDataHome(filter: any): Observable<any> {
    let obj = {
      designation: filter.designation,
      stateId: filter.state_Id,
      blockId: filter.block_Id,
      districtId: filter.district_Id,
      villId: filter.village_Id,
      type: filter.type,
      fromDate: filter.from_date,
      toDate: filter.to_date,
      isReferredHF: 'No',
    };
    const url =
      `${apiEndPoint}ChildPneumonia/getChildPneumoniaTreatedDataHome` +
      '?params=' +
      JSON.stringify(obj);

    return this.http.get(url, { headers: this.headers });
  }
  getChildPneumoniaTreatedDataFacility(filter: any): Observable<any> {
    let obj = {
      designation: filter.designation,
      stateId: filter.stateId,
      districtId: filter.districtId,
      blockId: filter.blockId,
      villId: filter.village_Id,
      type: filter.type,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      isReferredHF: 'Yes',
    };
    const url =
      `${apiEndPoint}ChildPneumonia/getChildPneumoniaTreatedDataFacility` +
      '?params=' +
      JSON.stringify(obj);

    return this.http.get(url, { headers: this.headers });
  }

  getChildStackChartDataHome(filter: any): Observable<any> {
    let obj = {
      designation: filter.designation,
      stateId: filter.state_Id,
      blockId: filter.block_Id,
      districtId: filter.district_Id,
      villId: filter.village_Id,
      type: filter.type,
      fromDate: filter.from_date,
      toDate: filter.to_date,
    };

    const url =
      `${apiEndPoint}ChildPneumonia/getPneumoniaAwarenessStats` +
      '?params=' +
      JSON.stringify(obj);

    return this.http.get(url, { headers: this.headers });
  }

  getApprovedPneumoniaCases(filter: any): Observable<any> {
    let obj = {
      designation: filter.designation,
      stateId: filter.state_Id,
      blockId: filter.block_Id,
      districtId: filter.district_Id,
      villId: filter.village_Id,
      type: filter.type,
      fromDate: filter.from_date,
      toDate: filter.to_date,
    };

    const url =
      `${apiEndPoint}/ChildPneumonia/getApprovedPneumoniaCases` +
      '?params=' +
      JSON.stringify(obj);

    return this.http.get(url, { headers: this.headers });
  }

  getPneumoniaTreeTableDataApi(params: {}): Observable<any> {
    console.log("this is the params passing now", params)
    const url =
      `${apiEndPoint}/ChildPneumonia/getPneumoniaTreeTableData/` +
      '?params=' +
      JSON.stringify(params);
    return this.http.get(url, { headers: this.headers });
  }

  lowAwarenessVsCouncil(): Observable<any> {
    const url = `${apiEndPoint}/ChildPneumonia/getlowAwarenessVsCouncil`;
    return this.http.get(url, { headers: this.headers });
  }

  getPneumoniaHierarchyData(params:any):Observable<any>{
  //  alert("inside service")
   const url = `${apiEndPoint}/ChildPneumonia/getPneumoniaHierarchyData/` + '?params='+ JSON.stringify(params);
   return this.http.get(url, {headers:this.headers})
  }

  getPneuCasesBifurcation(params:{}): Observable<any> {
    const url = `${this.endpoint}/getPneuCasesBifurcation`;
    return this.http.get(url, { headers: this.headers });
  }
}
