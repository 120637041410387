import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TreeTableDataService {
  private treeTableDataSubject = new BehaviorSubject<TreeNode[]>([]);
  private treeTableColsSubject = new BehaviorSubject<any[]>([]);
  private teamTrackerDataSubject = new BehaviorSubject<TreeNode[]>([]);
  private teamTrackerColsSubject = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient) {
    this.initializeTreeTable();
    this.initializeTeamTracker();
  }

  getTreeTableData(): Observable<TreeNode[]> {
    return this.treeTableDataSubject.asObservable();
  }

  getTreeTableCols(): Observable<any[]> {
    return this.treeTableColsSubject.asObservable();
  }

  getTeamTrackerData(): Observable<any[]> {
    return this.http.get<any[]>('http://localhost:3000/api/States/getAllStatesWithAggregation');
    // return this.teamTrackerDataSubject.asObservable();
  }

  getTeamTrackerCols(): Observable<any[]> {
    return this.teamTrackerColsSubject.asObservable();
  }

  private initializeTreeTable() {
    const cols = [
      { field: 'name', header: 'State' },
      { field: 'hhTarget', header: 'HH Target' },
      { field: 'hhCovered', header: 'HH Covered' },
      { field: 'momTarget', header: 'Mom Target' },
      { field: 'momCovered', header: 'Mom Covered' },
      { field: 'childWithin2YTarget', header: 'Child 0-2Y Target' },
      { field: 'childWithin2YCovered', header: 'Child 0-2Y Covered' },
      { field: 'childWithin2To4YTarget', header: 'Child 2-4Y Target' },
      { field: 'childWithin2To4YCovered', header: 'Child 2-4Y Covered' },
    ];

    const data: TreeNode[] = [
      {
        data: {
          name: 'Total',
          hhTarget: 1000,
          hhCovered: 800,
          momTarget: 500,
          momCovered: 450,
          childWithin2YTarget: 300,
          childWithin2YCovered: 280,
          childWithin2To4YTarget: 200,
          childWithin2To4YCovered: 180,
        },
        children: [
          {
            data: {
              name: 'Maharashtra',
              hhTarget: 500,
              hhCovered: 400,
              momTarget: 250,
              momCovered: 230,
              childWithin2YTarget: 150,
              childWithin2YCovered: 140,
              childWithin2To4YTarget: 100,
              childWithin2To4YCovered: 90,
            },
            children: [
              {
                data: {
                  name: 'Dhule',
                  hhTarget: 250,
                  hhCovered: 200,
                  momTarget: 125,
                  momCovered: 115,
                  childWithin2YTarget: 75,
                  childWithin2YCovered: 70,
                  childWithin2To4YTarget: 50,
                  childWithin2To4YCovered: 45,
                },
              },
              {
                data: {
                  name: 'Malegaon',
                  hhTarget: 250,
                  hhCovered: 200,
                  momTarget: 125,
                  momCovered: 115,
                  childWithin2YTarget: 75,
                  childWithin2YCovered: 70,
                  childWithin2To4YTarget: 50,
                  childWithin2To4YCovered: 45,
                },
              },
            ],
          },
          {
            data: {
              name: 'Rajasthan',
              hhTarget: 500,
              hhCovered: 400,
              momTarget: 250,
              momCovered: 220,
              childWithin2YTarget: 150,
              childWithin2YCovered: 140,
              childWithin2To4YTarget: 100,
              childWithin2To4YCovered: 90,
            },
            children: [
              {
                data: {
                  name: 'Jaipur',
                  hhTarget: 250,
                  hhCovered: 200,
                  momTarget: 125,
                  momCovered: 110,
                  childWithin2YTarget: 75,
                  childWithin2YCovered: 70,
                  childWithin2To4YTarget: 50,
                  childWithin2To4YCovered: 45,
                },
              },
              {
                data: {
                  name: 'Jodhpur',
                  hhTarget: 250,
                  hhCovered: 200,
                  momTarget: 125,
                  momCovered: 110,
                  childWithin2YTarget: 75,
                  childWithin2YCovered: 70,
                  childWithin2To4YTarget: 50,
                  childWithin2To4YCovered: 45,
                },
              },
            ],
          },
          {
            data: {
              name: 'Odisa',
              hhTarget: 500,
              hhCovered: 420,
              momTarget: 260,
              momCovered: 230,
              childWithin2YTarget: 160,
              childWithin2YCovered: 150,
              childWithin2To4YTarget: 120,
              childWithin2To4YCovered: 110,
            },
            children: [
              {
                data: {
                  name: 'Jaipur',
                  hhTarget: 300,
                  hhCovered: 250,
                  momTarget: 160,
                  momCovered: 150,
                  childWithin2YTarget: 90,
                  childWithin2YCovered: 85,
                  childWithin2To4YTarget: 70,
                  childWithin2To4YCovered: 65,
                },
                children: [
                  {
                    data: {
                      name: 'CHW 1',
                      hhTarget: 150,
                      hhCovered: 130,
                      momTarget: 80,
                      momCovered: 75,
                      childWithin2YTarget: 45,
                      childWithin2YCovered: 42,
                      childWithin2To4YTarget: 35,
                      childWithin2To4YCovered: 33,
                    },
                  },
                  {
                    data: {
                      name: 'CHW 2',
                      hhTarget: 150,
                      hhCovered: 120,
                      momTarget: 80,
                      momCovered: 75,
                      childWithin2YTarget: 45,
                      childWithin2YCovered: 43,
                      childWithin2To4YTarget: 35,
                      childWithin2To4YCovered: 32,
                    },
                  },
                ],
              },
              {
                data: {
                  name: 'Udaipur',
                  hhTarget: 200,
                  hhCovered: 170,
                  momTarget: 100,
                  momCovered: 80,
                  childWithin2YTarget: 70,
                  childWithin2YCovered: 65,
                  childWithin2To4YTarget: 50,
                  childWithin2To4YCovered: 45,
                },
                children: [
                  {
                    data: {
                      name: 'CHW 3',
                      hhTarget: 100,
                      hhCovered: 90,
                      momTarget: 50,
                      momCovered: 40,
                      childWithin2YTarget: 35,
                      childWithin2YCovered: 33,
                      childWithin2To4YTarget: 25,
                      childWithin2To4YCovered: 22,
                    },
                  },
                  {
                    data: {
                      name: 'CHW 4',
                      hhTarget: 100,
                      hhCovered: 80,
                      momTarget: 50,
                      momCovered: 40,
                      childWithin2YTarget: 35,
                      childWithin2YCovered: 32,
                      childWithin2To4YTarget: 25,
                      childWithin2To4YCovered: 23,
                    },
                  },
                ],
              },
            ],
          },
          
        ],
      },
    ];

    this.treeTableColsSubject.next(cols);
    this.treeTableDataSubject.next(data);
  }
  private initializeTeamTracker() {
    const cols = [
      { field: 'name', header: 'Name' },
      { field: 'hhTarget', header: 'HH Target' },
      { field: 'hhCovered', header: 'HH Covered' },
      { field: 'momTarget', header: 'Mom Target' },
      { field: 'momCovered', header: 'Mom Covered' },
      { field: 'childrenTarget', header: 'Children Target' },
      { field: 'childrenCovered', header: 'Children Covered' },
      { field: 'diarrhoea', header: 'Diarrhoea' },
      { field: 'pneumonia', header: 'Pneumonia' },
    ];

    const data: TreeNode[] = [
      {
        data: {
          name: 'Total',
          hhTarget: 1000,
          hhCovered: 800,
          momTarget: 500,
          momCovered: 450,
          childrenTarget: 300,
          childrenCovered: 280,
          diarrhoea: 20,
          pneumonia: 15,
        },
        children: [
          {
            data: {
              name: 'Gujarat',
              hhTarget: 500,
              hhCovered: 400,
              momTarget: 250,
              momCovered: 225,
              childrenTarget: 150,
              childrenCovered: 140,
              diarrhoea: 10,
              pneumonia: 8,
            },
            children: [
              {
                data: {
                  name: 'Ahmedabad',
                  hhTarget: 250,
                  hhCovered: 200,
                  momTarget: 125,
                  momCovered: 112,
                  childrenTarget: 75,
                  childrenCovered: 70,
                  diarrhoea: 5,
                  pneumonia: 4,
                },
                children: [
                  {
                    data: {
                      name: 'CHW 1',
                      hhTarget: 125,
                      hhCovered: 100,
                      momTarget: 62,
                      momCovered: 56,
                      childrenTarget: 37,
                      childrenCovered: 35,
                      diarrhoea: 3,
                      pneumonia: 2,
                    },
                  },
                  {
                    data: {
                      name: 'CHW 2',
                      hhTarget: 125,
                      hhCovered: 100,
                      momTarget: 63,
                      momCovered: 56,
                      childrenTarget: 38,
                      childrenCovered: 35,
                      diarrhoea: 2,
                      pneumonia: 2,
                    },
                  },
                ],
              },
              {
                data: {
                  name: 'Surat',
                  hhTarget: 250,
                  hhCovered: 200,
                  momTarget: 125,
                  momCovered: 113,
                  childrenTarget: 75,
                  childrenCovered: 70,
                  diarrhoea: 5,
                  pneumonia: 4,
                },
                children: [
                  {
                    data: {
                      name: 'CHW 3',
                      hhTarget: 125,
                      hhCovered: 100,
                      momTarget: 62,
                      momCovered: 57,
                      childrenTarget: 37,
                      childrenCovered: 35,
                      diarrhoea: 3,
                      pneumonia: 2,
                    },
                  },
                  {
                    data: {
                      name: 'CHW 4',
                      hhTarget: 125,
                      hhCovered: 100,
                      momTarget: 63,
                      momCovered: 56,
                      childrenTarget: 38,
                      childrenCovered: 35,
                      diarrhoea: 2,
                      pneumonia: 2,
                    },
                  },
                ],
              },
            ],
          },
          {
            data: {
              name: 'Madhya Pradesh',
              hhTarget: 500,
              hhCovered: 400,
              momTarget: 250,
              momCovered: 225,
              childrenTarget: 150,
              childrenCovered: 140,
              diarrhoea: 10,
              pneumonia: 7,
            },
            children: [
              {
                data: {
                  name: 'Indore',
                  hhTarget: 250,
                  hhCovered: 200,
                  momTarget: 125,
                  momCovered: 112,
                  childrenTarget: 75,
                  childrenCovered: 70,
                  diarrhoea: 5,
                  pneumonia: 3,
                },
                children: [
                  {
                    data: {
                      name: 'CHW 5',
                      hhTarget: 125,
                      hhCovered: 100,
                      momTarget: 62,
                      momCovered: 56,
                      childrenTarget: 37,
                      childrenCovered: 35,
                      diarrhoea: 2,
                      pneumonia: 1,
                    },
                  },
                  {
                    data: {
                      name: 'CHW 6',
                      hhTarget: 125,
                      hhCovered: 100,
                      momTarget: 63,
                      momCovered: 56,
                      childrenTarget: 38,
                      childrenCovered: 35,
                      diarrhoea: 3,
                      pneumonia: 2,
                    },
                  },
                ],
              },
              {
                data: {
                  name: 'Bhopal',
                  hhTarget: 250,
                  hhCovered: 200,
                  momTarget: 125,
                  momCovered: 113,
                  childrenTarget: 75,
                  childrenCovered: 70,
                  diarrhoea: 5,
                  pneumonia: 4,
                },
                children: [
                  {
                    data: {
                      name: 'CHW 7',
                      hhTarget: 125,
                      hhCovered: 100,
                      momTarget: 62,
                      momCovered: 56,
                      childrenTarget: 37,
                      childrenCovered: 35,
                      diarrhoea: 2,
                      pneumonia: 1,
                    },
                  },
                  {
                    data: {
                      name: 'CHW 8',
                      hhTarget: 125,
                      hhCovered: 100,
                      momTarget: 63,
                      momCovered: 57,
                      childrenTarget: 38,
                      childrenCovered: 35,
                      diarrhoea: 3,
                      pneumonia: 3,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ];

    this.teamTrackerColsSubject.next(cols);
    this.teamTrackerDataSubject.next(data);
  }
}