import { Component, OnInit, NgZone, ElementRef, Inject, PLATFORM_ID, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-guagemeter',
  standalone: true,
  imports: [],
  templateUrl: './guagemeter.component.html',
  styleUrl: './guagemeter.component.css'
})
export class GuagemeterComponent implements OnInit, OnChanges {

  @Input() value: number = 75; // default value
  private root!: am5.Root;
  private chartId: string;
  private axisDataItem: any;
  private axisRange0: any;
  private axisRange1: any;
  private bullet: any;

  constructor(
    private zone: NgZone,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.chartId = 'chartdiv-' + Math.random().toString(36).substr(2, 9);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.createChart();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] && !changes['value'].isFirstChange()) {
      this.updateGaugeValue(this.value);
    }
  }

  createChart() {
    const chartDiv = document.createElement('div');
    chartDiv.style.width = '100%';
    chartDiv.style.height = '100px'; // Adjust as needed
    chartDiv.id = this.chartId;
    this.elementRef.nativeElement.appendChild(chartDiv);

    this.root = am5.Root.new(this.chartId);
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);
    this.root._logo?.dispose();

    let chart = this.root.container.children.push(
      am5radar.RadarChart.new(this.root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360
      })
    );

    chart.getNumberFormatter().set("numberFormat", "#'%'");

    let axisRenderer = am5radar.AxisRendererCircular.new(this.root, {
      innerRadius: -10
    });

    axisRenderer.grid.template.setAll({
      stroke: this.root.interfaceColors.get("background"),
      visible: true,
      strokeOpacity: 0.8
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    this.axisDataItem = xAxis.makeDataItem({});

    let clockHand = am5radar.ClockHand.new(this.root, {
      pinRadius: 50,
      radius: am5.percent(100),
      innerRadius: 50,
      bottomWidth: 0,
      topWidth: 0
    });

    clockHand.pin.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 1,
      strokeDasharray: [2, 2]
    });

    clockHand.hand.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 0.5
    });

    this.bullet = this.axisDataItem.set(
      "bullet",
      am5xy.AxisBullet.new(this.root, {
        sprite: clockHand
      })
    );

    xAxis.createAxisRange(this.axisDataItem);

    let label = chart.radarContainer.children.push(
      am5.Label.new(this.root, {
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1.5em"
      })
    );

    this.axisDataItem.set("value", this.value);
    this.bullet.get("sprite")?.on("rotation", () => {
      let value = this.axisDataItem.get("value");
      if (value !== undefined) {
        label.set("text", Math.round(value).toString() + "%");
      }
    });

    chart.bulletsContainer.set("mask", undefined);

    let colorSet = am5.ColorSet.new(this.root, {});

    this.axisRange0 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 0,
        endValue: 50
      })
    );

    this.axisRange1 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 50,
        endValue: 100
      })
    );

    const axisFill0 = this.axisRange0.get("axisFill");
    if (axisFill0) {
      axisFill0.setAll({
        visible: true,
        fill: colorSet.getIndex(0)
      });
    }

    const label0 = this.axisRange0.get("label");
    if (label0) {
      label0.setAll({
        forceHidden: true
      });
    }

    const axisFill1 = this.axisRange1.get("axisFill");
    if (axisFill1) {
      axisFill1.setAll({
        visible: true,
        fill: colorSet.getIndex(4)
      });
    }

    const label1 = this.axisRange1.get("label");
    if (label1) {
      label1.setAll({
        forceHidden: true
      });
    }

    // Make stuff animate on load
    chart.appear(1000, 100);
  }

  updateGaugeValue(value: number) {
    if (this.axisDataItem) {
      this.axisDataItem.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      this.axisRange0.animate({
        key: "endValue",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      this.axisRange1.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }
}