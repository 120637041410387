import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-awareness-changes',
  standalone: true,
  imports: [],
  templateUrl: './awareness-changes.component.html',
  styleUrls: ['./awareness-changes.component.css']
})
export class AwarenessChangesComponent {
  reportUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://app.powerbi.com/view?r=eyJrIjoiYWFmMTI1YzAtZTczOS00YjIxLTkyZjQtNzg2MzIzZGI3ZDMzIiwidCI6ImRkNjMwMGViLTRmZmUtNDc1Mi1hYThiLTNjNTQ1YzczZTA3MCJ9'
    );
  }

  openInNewWindow() {
    window.open('/awarenessChanges', '_blank');
  }
}
