import { Component } from '@angular/core';

@Component({
  selector: 'app-yoga-mental-health',
  standalone: true,
  imports: [],
  templateUrl: './yoga-mental-health.component.html',
  styleUrl: './yoga-mental-health.component.css'
})
export class YogaMentalHealthComponent {

}
