import {
  Component,
  OnInit,
  Inject,
  NgZone,
  PLATFORM_ID,
  Input,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';


@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.css'],
  standalone: true,
})
export class ColumnChartComponent implements OnInit {
  private root!: am5.Root;
  private exporting?: am5plugins_exporting.Exporting;

  @Input() id!: string;
  @Input() data!: any;
  @Input() type!: string;
  @Input() colors!: string[];  // <-- New input for colors
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone
  ) { }
  ngOnInit(): void { }


  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit() {
    // Chart code goes in here
    this.browserOnly(() => {
      let root = am5.Root.new(this.id);
      // Disable amCharts logo
      root._logo?.dispose();

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          paddingLeft: 0,
          wheelX: 'panX',
          wheelY: 'zoomX',
          layout: root.verticalLayout,
        })
      );
      let data = this.data;
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        })
      );
      let xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minorGridEnabled: true,
      });

      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'month',
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      xRenderer.grid.template.setAll({
        location: 1,
      });

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1,
          }),
        })
      );
      const colorList = this.colors || []; // Fallback if colors are not provided

      function makeSeries(name: string, fieldName: string, color: string) {
        let series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: 'month',
          })
        );

        series.columns.template.setAll({
          tooltipText: '{name}, {categoryX}:{valueY}',
          width: am5.percent(90),
          tooltipY: 0,
          strokeOpacity: 0,
          fill: am5.color(color), // <-- Set color
        });
        console.log("get availability at facility", data)
        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              text: '{valueY}',
              fill: root.interfaceColors.get('alternativeText'),
              centerY: 0,
              centerX: am5.p50,
              populateText: true,
            }),
          });
        });
        legend.data.push(series);
      }
      console.log("inside view  init", this.type)
      if (this.type === 'districtwise-monthwise') {
        makeSeries('Dhule', 'Dhule',  '#66B2FF');
        makeSeries('Washim', 'Washim',  '#007BFF');
        makeSeries('Bhavnagar', 'Bhavnagar', '#F39C12');
        makeSeries('Girsomnath', 'Girsomnath', '#F5A623');
        makeSeries('Rajsamand', 'Rajsamand', '#28B463');
      } else if (this.type === 'statewise-monthwise') {
        makeSeries('Maharashtra', 'Maharashtra', '#0056B3');
        makeSeries('Gujarat', 'Gujarat', '#D26D00');
        makeSeries('Rajasthan', 'Rajasthan', '#1F7A3D');
      } else if (this.type === 'availabiliAtFacility') {
        makeSeries('Av-Iron', 'Av-Iron','#90de9b');        // Dark navy blue
        makeSeries('Av-Amox', 'Av-Amox','#cdd435');        // Medium royal blue
        makeSeries('Av-ORS', 'Av-ORS', '#e8a03d');          // Standard blue
        makeSeries('Av-Calcium', 'Av-Calcium','#7fdcdc');  // Light cerulean blue
        makeSeries('Av-VitaminC', 'Av-VitaminC', colorList[4] || '#f4c3d8'); // Sky blue
        makeSeries('Av-Zinc', 'Av-Zinc', '#d0d2ff');        // Pale blue
      } else if (this.type === 'awarepractice') {
        makeSeries('Early Registration', 'Early Registration', '#aab5c3');     // Dark teal blue
        makeSeries('ANC Awareness', 'ANC Awareness','#dcae96');              // Deep ocean blue
        makeSeries('Malaria Awareness', 'Malaria Awareness','#3498db');      // Standard blue
        makeSeries('Wash Hands', 'Wash Hands', '#98ff98');                    // Light cerulean blue
        makeSeries('Family Planning Awareness', 'Family Planning Awareness', '#fdd0ff');
      }else if(this.type == 'blockwise-monthwise'){
        makeSeries('Sakri', 'Sakri',  '#A3CFFF'); 
        makeSeries('Malegaon', 'Malegaon','#66AFFF'); 
        makeSeries('Bhavnagar', 'Bhavnagar', '#F9B84C'); 
        makeSeries('Palitana', 'Palitana', '#F9D68D');
        makeSeries('Mahuva', 'Mahuva', '#FCD58A');
        makeSeries('Talala', 'Talala', '#FCE29F'); 
        makeSeries('Veraval', 'Veraval', '#F9E3B5'); 
        makeSeries('Una', 'Una', '#F7E8B6'); 
         makeSeries('Rajsamand', 'Rajsamand', '#A2D8A5'); 
         makeSeries('Kumbhalgarh', 'Kumbhalgarh', '#C3E6B1'); 

      }

      this.root = root;
    });

    // Initialize exporting
this.exporting = am5plugins_exporting.Exporting.new(this.root, {
  filePrefix: 'funnel-chart',
  dataSource: this.data,
  pdfOptions: {
    addURL: false,
    disabled: false,
  },
  csvOptions: {
    addColumnNames: true,
    separator: ',',
  },
});

// Create and set up the export menu
const exportMenu = am5plugins_exporting.ExportingMenu.new(this.root, {
  exporting: this.exporting, // Link the menu to the exporting instance
});
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }
}
