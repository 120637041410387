import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../environment/environment.local"; // Corrected import

@Injectable({
  providedIn: "root"
})
export class StateService {
  private readonly endpoint = `${environment.apiUrl}States`; // Update endpoint if necessary

  constructor(private http: HttpClient) {}

  getStates(params: {} = {}): Observable<any[]> { // Default empty params
    const httpParams = new HttpParams().set(
      'obj',
      JSON.stringify(params)
    );

    return this.http.get<any[]>(this.endpoint, { params: httpParams })
      .pipe(tap(response => response)); // Consider handling the response or any side effects
  }
}
