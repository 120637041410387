import { Component } from '@angular/core';

@Component({
  selector: 'app-self-care',
  standalone: true,
  imports: [],
  templateUrl: './self-care.component.html',
  styleUrl: './self-care.component.css'
})
export class SelfCareComponent {

}
