import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

interface ChartData {
  _id: string;
  'Av-Iron': number;
  'Av-Amox': number;
  'Av-ORS': number;
  'Av-Zinc': number;
  'Av-VitaminC': number;
  'Av-Calcium': number;
  Total: number;
}

@Component({
  selector: 'app-stackchart',
  standalone: true,
  templateUrl: './stackchart.component.html',
  styleUrls: ['./stackchart.component.css']
})
export class StackchartComponent implements OnInit, OnDestroy {
  @Input() data: ChartData[] = []; // Typed input with default empty array

  private root!: am5.Root;

  ngOnInit(): void {
    console.log('Received data for chart:', this.data);
    this.createChart();
  }

  createChart(): void {
    // Initialize chart root
    this.root = am5.Root.new('chartdiv');

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Create chart container
    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        paddingLeft: 0,
        layout: this.root.verticalLayout,
      })
    );

    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: '_id',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    xAxis.data.setAll(this.data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(this.root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    // Add series to the chart
    const seriesConfig = [
      { name: 'Av-Amox', field: 'Av-Amox' },
      { name: 'Av-Zinc', field: 'Av-Zinc' },
      { name: 'Av-VitaminC', field: 'Av-VitaminC' },
      { name: 'Av-ORS', field: 'Av-ORS' },
      { name: 'Av-Calcium', field: 'Av-Calcium' },
      { name: 'Av-Iron', field: 'Av-Iron' }
    ];

    seriesConfig.forEach(config => {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: config.name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: config.field,
          categoryXField: '_id',
          stacked: true,
        })
      );

      series.columns.template.setAll({
        tooltipText: '{name}, {categoryX}: {valueY}',
        width: am5.percent(90),
        tooltipY: am5.percent(10),
      });

      series.data.setAll(this.data);

      // Add labels to columns
      series.bullets.push(() => {
        return am5.Bullet.new(this.root, {
          sprite: am5.Label.new(this.root, {
            text: '{valueY}',
            centerY: am5.percent(50),
            centerX: am5.percent(50),
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    });

    // Animate chart on load
    chart.appear(1000, 100);
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}