import { Component, OnInit, OnDestroy, ElementRef, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

interface ChartData {
  month: string;
  [key: string]: string | number;
}

interface SeriesConfig {
  name: string;
  field: string;
}

@Component({
  selector: 'app-community-intervention-stack-graph',
  standalone: true,
  templateUrl: './community-intervention-stack-graph.component.html',
  styleUrls: ['./community-intervention-stack-graph.component.css']
})
export class CommunityInterventionStackGraphComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() chartId: string = 'chartdiv';
  @Input() data: ChartData[] = [];
  @Input() seriesData: SeriesConfig[] = [];
  @Input() height: string = '500px';
  @Input() seriesColors: string[] = [];  // Add this input for dynamic colors
  private exporting: am5plugins_exporting.Exporting | undefined;
  
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  private legend!: am5.Legend;
 private xAxis!: am5xy.CategoryAxis<am5xy.AxisRendererX>;
  private yAxis!: am5xy.ValueAxis<am5xy.AxisRendererY>;
  private series!: am5xy.ColumnSeries;
  // private series!: am5xy.ColumnSeries;

  constructor() { }

  ngAfterViewInit(): void {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['data'] || changes['series'] || changes['seriesColors']) && this.root) {
      this.updateChart();
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  private createChart(): void {
    // Create root element
    this.root = am5.Root.new(this.chartId);

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    
    // Disable amCharts logo
    this.root._logo?.dispose();

    // Create chart
    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      panX: false,
      panY: false,
      paddingLeft: 0,
      wheelX: 'panX',
      wheelY: 'zoomX',
      layout: this.root.verticalLayout
    }));

    // Create and configure legend
    this.legend = this.chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 15,
        marginBottom: 15
      })
    );

    // Style the legend
    this.legend.labels.template.setAll({
      fontSize: 13,
      fontWeight: "500"
    });

    this.legend.markers.template.setAll({
      width: 18,
      height: 18
    });

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      cellStartLocation: 0.1,
  cellEndLocation: 0.9,
  minorGridEnabled: true,
  minGridDistance: 20  // Adjust this value to display all month labels
    });
    xRenderer.labels.template.setAll({
      // rotation: -45,  // Optional: rotate to fit more labels, adjust as needed
      centerY: am5.p50,
      centerX: am5.p50,
      fontSize: 12,
    });
    
    let xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
      categoryField: "month",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(this.root, {})
    }));

    xRenderer.grid.template.setAll({
      location: 1
    });

    let yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: am5xy.AxisRendererY.new(this.root, {
        strokeOpacity: 0.1


      })
    }));
    
    //reduced font size of y axis legents 
    yAxis.get("renderer").labels.template.setAll({
      fontSize: 10  ,
      
    });
    this.legend.valueLabels.template.set("text", "{valueX}")
    this.updateChart();

     // Initialize exporting
    this.exporting = am5plugins_exporting.Exporting.new(this.root, {
      filePrefix: "community-intervention-stack",
      dataSource: this.data,
      pdfOptions: {
        addURL: false,
        disabled: false
      },
      csvOptions: {
        addColumnNames: true,
        separator: ",",
      },
    });

    // Create and set up the export menu
    const exportMenu = am5plugins_exporting.ExportingMenu.new(this.root, {
      exporting: this.exporting, // Link the menu to the exporting instance
    });
  }

  private updateChart(): void {
    if (!this.chart || !this.data || !this.seriesData) return;

    // Clear existing series
    this.chart.series.clear();
    
    // Update x-axis data
    const xAxis = this.chart.xAxes.getIndex(0);
    if (xAxis) {
      xAxis.data.setAll(this.data);
    }

    // Clear existing legend data
    this.legend.data.clear();

    // Create series for each configuration
    this.seriesData.forEach((seriesConfig, index) => {
      const color = this.seriesColors[index] || "#000000";  // Default to black if no color provided
      this.makeSeries(seriesConfig.name, seriesConfig.field, color); // Pass the color to makeSeries
    });

    this.chart.appear(1000, 100);
  }

  private makeSeries(name: string, fieldName: string, color: string): void {
    const xAxis = this.chart.xAxes.getIndex(0);
    const yAxis = this.chart.yAxes.getIndex(0);
  
    if (!xAxis || !yAxis) {
      throw new Error('X or Y Axis is undefined');
    }
  
    let series = this.chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: fieldName,
      categoryXField: "month",
      legendLabelText: "{name}",  // Explicitly set legend label
      legendValueText: "{valueY}" // Show value in legend
    }));
  
    series.columns.template.setAll({
      tooltipText: "{name}, {categoryX}: {valueY}",
      width: am5.percent(90),
      tooltipY: 0,
      strokeOpacity: 0,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      fill: am5.color(color)  // Apply the color using am5.color()
    });
  
    series.data.setAll(this.data);
  
    // Configure series animations
    series.appear();
  
    // Add bullet for showing the value just above each column
    series.bullets.push(() => {
      return am5.Bullet.new(this.root, {
        locationY: 1, // Position it at the top of the column
        sprite: am5.Label.new(this.root, {
          text: "{valueY}",
          fill: am5.color("#000000"),  // Set text color to black
          fontSize: 10,  // Reduce font size
          centerY: 0,
          centerX: am5.p50,
          populateText: true,
        })
      });
    });
    
  
    // Add to legend with enhanced styling
    this.legend.data.push(series);
  }
  
  
}
