import { Component, AfterViewInit, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-pie-chart',
  standalone: true,
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css'],
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() data!: any[];
  @Input() chartId!: string; // Unique identifier for the chart
  @Input() colors: string[] = []; // New input property for colors

  private chart: am4charts.PieChart3D | null = null;

  constructor() {}

  ngOnInit(): void {
    console.log(`Pie chart data for ${this.chartId}:`, this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Recreate chart if data changes
    if ((changes['data'] && !changes['data'].firstChange) || (changes['colors'] && !changes['colors'].firstChange)) {
      this.destroyChart();
      this.createChart();
    }
  }

  ngAfterViewInit() {
    this.createChart();
  }

  private createChart() {
    // Ensure we have a chartId and data
    if (!this.chartId || !this.data || this.data.length === 0) {
      console.warn(`Cannot create chart: missing chartId or data for ${this.chartId}`);
      return;
    }
  
    // Apply animated theme
    am4core.useTheme(am4themes_animated);
  
    // Create chart instance using the dynamic chartId
    this.chart = am4core.create(this.chartId, am4charts.PieChart3D);
    this.chart.hiddenState.properties.opacity = 0; // Initial fade-in effect
    
    // Disable the default logo
  this.chart.logo.disabled = true;
  
    // Set up the chart's legend
    this.chart.legend = new am4charts.Legend();
  
    this.chart.legend.labels.template.fontSize = 14; 
    this.chart.legend.valueLabels.template.fontSize = 14; 
  
    // Reduce the space between label and value in the legend
    this.chart.legend.valueLabels.template.marginLeft = -25; 
    this.chart.legend.labels.template.paddingRight = 10; 
  
  
    // Define the chart's data
    this.chart.data = this.data;
  
    // Create a 3D Pie series
    let series = this.chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = 'value';
    series.dataFields.category = 'name';
  
    // Format labels to show actual values with percentages
    series.labels.template.text = "{category}: {value} ({value.percent.formatNumber('#.0')}%)";
    series.slices.template.tooltipText = "{category}: {value} ({value.percent.formatNumber('#.0')}%)";
    // series.labels.template.maxWidth = 130;
    series.labels.template.wrap = true;
    series.labels.template.fontSize = 14; // Reduced font size for slice labels
  
    // Use passed colors if available, otherwise fallback to default colors
    const colors = this.colors.length > 0 ? this.colors : ["#568203", "#ffc72c", "#d2122e", "#662d91", "#0000ff"];
  
    series.slices.template.adapter.add("fill", (fill, target) => {
      const index = target.dataItem?.index;
      if (index !== undefined) {
        return am4core.color(colors[index % colors.length]);
      }
      return fill; // Fallback to the default color if index is undefined
    });
  
    series.slices.template.adapter.add("stroke", (stroke, target) => {
      const index = target.dataItem?.index;
      if (index !== undefined) {
        return am4core.color(colors[index % colors.length]);
      }
      return stroke; // Fallback to the default stroke if index is undefined
    });
  
    this.chart.exporting.menu = new am4core.ExportMenu();
  }
  

  // Cleanup method to prevent memory leaks
  ngOnDestroy() {
    this.destroyChart();
  }

  private destroyChart() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }
}