import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule } from '@angular/forms';
import { StateService } from '../Services/HierarchyService/state-service.service';
import { DistrictService } from '../Services/HierarchyService/district-service.service';
import { BlockService } from '../Services/HierarchyService/block-service.service';
import { VillageService } from '../Services/HierarchyService/village-service.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AuthenticationService } from '../Services/authentication.service';
import { DatePipe } from '@angular/common';


interface Approval {
  value: boolean;
  viewValue: string;
}
interface SelectValues {
  value: boolean;
  viewValue: string;
}


@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRadioModule,
    FormsModule,
    MatButtonToggleModule,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, // Provide MAT_DATE_LOCALE correctly
    DatePipe // Correctly provide DatePipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent implements OnInit {
  showDatePickers: boolean = false;
  filterForm: FormGroup;
  @ViewChild('allSelected') private allSelected!: MatOption;
  @ViewChild('alSelected') private alSelected!: MatOption;
  @Output() filterChange = new EventEmitter<any>();

  states: any[] = [];
  districts: any[] = [];
  blocks: any[] = [];
  chws: any[] = [];
  villages: any[] = [];
  currentUser: any;
  fromDate: Date | any[] = [];
  toDate: Date | any[] = [];
  showText: string = "Select date";
  isDefaultDateRange = false;
  selectedDateRange: string = 'default';
  byDefaultChecked: boolean = true;
  isApproval:boolean = false;  // 12 sept

  // Date form control
  dateForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl()
  });

  approvalData  = new FormGroup({
    isApproval : new FormControl(),
  });

  approval: Approval[] = [
    { value: true, viewValue: 'Approved' },
    { value: false, viewValue: 'All' },
  ];

  selectOptions:SelectValues[] = [
    { value: true, viewValue: 'Diarrhoea' },
    { value: true, viewValue: 'Pneumonia' },
    { value: true, viewValue: 'WHO 7 Point' },
    { value: true, viewValue: 'Mothers' },
    { value: true, viewValue: 'Meetings' }
  ];
  viewType = '';
  calType: 'year' | 'month' = 'month';
  activeDate = new Date();
  quarterStartDate: Date | null = null;
  quarterStartDate1!: Date;
  quarterStartDate2!: Date;
  quarterStartDate3!: Date;
  quarterStartDate4!: Date;
  quarterEndDate: Date | null = null;
  quarterEndDate1: Date | null = null;
  quarterEndDate2: Date | null = null;
  quarterEndDate3: Date | null = null;
  quarterEndDate4: Date | null = null;
 selectedValue!: string;

  constructor(
    private fb: FormBuilder,
    private blockService: BlockService,
    private districtService: DistrictService,
    private stateService: StateService,
    private villageService: VillageService,
    private chwService:AuthenticationService,
    private changeDetector: ChangeDetectorRef,
    private datePipe: DatePipe,
  ) {
    this.filterForm = this.fb.group({
      stateId: [''],
      districtId: [''],
      blockId: [''],
      village_Id: [''],
      chwId: [''],
      fromDate: [''],
      toDate: [''],
      quarterDate: [''],
      isApproval: [this.isApproval],
    });
    this.dateForm = this.fb.group({
      fromDate: [''],
      toDate: ['']
    });
    this.approvalData = this.fb.group({
      isApproval: ['']
    });
  }

  ngOnInit() {
    const userData = localStorage.getItem('currentUser');
    if (userData) {
      this.currentUser = JSON.parse(userData);
      this.initializeFormForUserAccess(this.currentUser);
    }
    this.filterForm.valueChanges.subscribe((values) => {
      this.onClose(values);
    });
  }

  initializeFormForUserAccess(user: any) {
    const { designation, stateId, districtId, blockId, chwId } = user.user;

    // Load states, districts, blocks, and CHWs based on user designation
    if (designation === 'Admin') {
      this.loadStates();
    } else if (designation === 'SM') {
      this.filterForm.patchValue({ stateId: [stateId] });
      this.loadDistricts({ value: [stateId] });
    } else if (designation === 'DL') {
      this.filterForm.patchValue({
        stateId: [stateId],
        districtId: [districtId]
      });
      this.loadBlocks({ value: [districtId] });
    } else if (designation === 'BO') {
      this.filterForm.patchValue({
        stateId: [stateId],
        districtId: [districtId],
        blockId: [blockId],
      });
      this.loadChws({ value: [blockId] });
    } else if (designation === 'CHW') {
      this.filterForm.patchValue({
        stateId: [stateId],
        districtId: [districtId],
        blockId: [blockId],
        chwId: [chwId],
      });
    }

    // Subscribe to changes in districtId to load blocks and reset CHW selection
    this.filterForm.get('districtId')?.valueChanges.subscribe(districtValue => {
      if (districtValue) {
        this.filterForm.patchValue({ blockId: null, chwId: null });
        this.loadBlocks({ value: districtValue });
      }
    });

    // Subscribe to changes in blockId to load CHWs
    this.filterForm.get('blockId')?.valueChanges.subscribe(blockValue => {
      if (blockValue) {
        this.filterForm.patchValue({ chwId: null });
        this.loadChws({ value: blockValue });
      }
    });
  }

  loadStates() {
    this.stateService.getStates().subscribe((data) => {
      this.states = data;
      this.changeDetector.markForCheck();
    });
  }

  loadDistricts(event: { value: any[] }) {
    const params = { where: { stateId: { inq: event.value } } };
    this.districtService.getDistricts(params).subscribe((data) => {
      this.districts = data;
      this.changeDetector.markForCheck();
    });
  }

  loadBlocks(event: { value: any[] }) {
    const params = { where: { districtId: { inq: event.value } } };
    this.blockService.getBlocks(params).subscribe((data) => {
      this.blocks = data;
      this.changeDetector.markForCheck(); 
    });
  }

  loadVillages(event: { value: any[] }) {
    const params = { where: { blockId: { inq: event.value } } };
    this.villageService.getVillages(params).subscribe(data => {
      this.villages = data;
      this.changeDetector.markForCheck();
    });
  }

  loadChws(event: { value: any[] }) {
    const params = { where: { blockId: { inq: event.value }, designation: 'CHW' } };

    this.chwService.getChw(params).subscribe(data => {
      this.chws = data;
      this.changeDetector.markForCheck();
    });
}

  
  toggleAllSelection(type: 'state' | 'district' | 'block' | 'village' | 'chwId') {
    const control = this.filterForm.get(`${type}Id`);
    const allSelected = this.isAllSelected(type);
  
    if (control) {
      if (allSelected) {
        control.setValue([]); // Deselect all
      } else {
        let allIds: number[] = [];
        if (type === 'state') {
          allIds = this.states.map((s) => s.id);
          control.setValue(allIds); // Select all states
          this.loadDistricts({ value: allIds });
        } else if (type === 'district') {
          allIds = this.districts.map((d) => d.id);
          control.setValue(allIds);
          this.loadBlocks({ value: allIds });
        } else if (type === 'block') {
          allIds = this.blocks.map((b) => b.id);
          control.setValue(allIds);
          this.loadVillages({ value: allIds });
          this.loadChws({ value: allIds }); // Load CHWs
        } else if (type === 'chwId') {
          allIds = this.chws.map((c) => c.id);
          control.setValue(allIds); // Select all CHWs
        }
      }
    }
  }
  
  isAllSelected(type: 'state' | 'district' | 'block' | 'village' | 'chwId'): boolean {
    const controlValue = this.filterForm.get(`${type}Id`)?.value || [];
    const allIds =
      type === 'state'
        ? this.states.map((s) => s.id)
        : type === 'district'
          ? this.districts.map((d) => d.id)
          : type === 'block'
            ? this.blocks.map((b) => b.id)
            : type === 'chwId'
              ? this.chws.map((c) => c.id)
              : [];
  
    return allIds.every((id) => controlValue.includes(id));
  }
  
  // Helper method for date formatting
  formatDateToString(date: Date | null): string {
    if (!date) return '';
    return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
  }
  
  onClose(filterValues: any) {
    const formattedValues = {
      ...filterValues,
      fromDate: filterValues.fromDate ? this.formatDateToString(filterValues.fromDate) : '',
      toDate: filterValues.toDate ? this.formatDateToString(filterValues.toDate) : ''
    };
    this.filterChange.emit(formattedValues);
  }
  
  triggerChangeDetection() {
    this.changeDetector.detectChanges();
  }
  
  onDateRangeChange(event: MatRadioChange) {
    this.selectedDateRange = event.value;
    this.showDatePickers = this.selectedDateRange === 'default' || this.selectedDateRange === 'quarter';
    this.isDefaultDateRange = this.selectedDateRange === 'default';
  }
  
  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd')!;
  }
  
  // Code to handle the view change based on quarters and patch date values in the form
  changeView(): void {
    const year = this.activeDate.getFullYear();
    let fromDate: Date;
    let toDate: Date;
  
    switch (this.viewType) {
      case 'q1':
        fromDate = new Date(year, 0, 1);
        toDate = new Date(year, 2, 31);
        break;
      case 'q2':
        fromDate = new Date(year, 3, 1);
        toDate = new Date(year, 5, 30);
        break;
      case 'q3':
        fromDate = new Date(year, 6, 1);
        toDate = new Date(year, 8, 30);
        break;
      case 'q4':
        fromDate = new Date(year, 9, 1);
        toDate = new Date(year, 11, 31);
        break;
      default:
        fromDate = new Date(year, 0, 1);
        toDate = new Date(year, 11, 31);
    }
  
    this.filterForm.patchValue({
      fromDate: fromDate,
      toDate: toDate
    });
  
    // Format dates for the template display
    const formattedFromDate = this.formatDateToString(fromDate);
    const formattedToDate = this.formatDateToString(toDate);
  
    this.dateForm.patchValue({
      fromDate: formattedFromDate,
      toDate: formattedToDate
    });
  
    this.approvalData.patchValue({
      isApproval:this.isApproval
    });
  }
}