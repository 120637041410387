import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment.local';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NewOverviewService {
  
  private readonly endpoint = `${environment.apiUrl}HHListings`;
  private childDataSubject = new BehaviorSubject<any>(null);
  childData$ = this.childDataSubject.asObservable();
  constructor(private http: HttpClient) {}

  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  //get Reach Data From Api
  getReachData(params: any): Observable<any[]> {
    let httpParams = new HttpParams();
  
    // Loop over the keys of the params object and append them to httpParams
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '') {
        // If the parameter is an array, append each value
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
  
    const url = `${this.endpoint}/getAllCountsReach`;
    return this.http.get<any[]>(url, { headers: this.headers, params: httpParams });
  }

  // Get Informed data from Api
  getInformedData(params: any): Observable<any[]> {
    let httpParams = new HttpParams();
  
    // Loop over the keys of the params object and append them to httpParams
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '') {
        // If the parameter is an array, append each value
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
  
    const url = `${this.endpoint}/getAllCountsInformed`;
    return this.http.get<any[]>(url, { headers: this.headers, params: httpParams });
  }

  // Get Engaged data from Api
  getEngagedData(params: any): Observable<any[]> {
    let httpParams = new HttpParams();
  
    // Loop over the keys of the params object and append them to httpParams
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '') {
        // If the parameter is an array, append each value
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
  
    const url = `${this.endpoint}/getAllCountsEngaged`;
    return this.http.get<any[]>(url, { headers: this.headers, params: httpParams });
  }


  // Get Pneumonia data from Api 
  getPneumoniaCounts(params: any): Observable<any> {
    let httpParams = new HttpParams();
  
    // Loop over the keys of the params object and append them to httpParams
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '') {
        // If the parameter is an array, append each value
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
  
    const url = `${this.endpoint}/getAllCountsPnuemonia`;
    return this.http.get<any>(url, { headers: this.headers, params: httpParams });
  }

  // Get Diarrhoea data from Api 
  getDiarrhoeaCounts(params: any): Observable<any> {
    let httpParams = new HttpParams();
  
    // Loop over the keys of the params object and append them to httpParams
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '') {
        // If the parameter is an array, append each value
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
  
    const url = `${this.endpoint}/getAllCountsDiarrhea`;
    return this.http.get<any>(url, { headers: this.headers, params: httpParams });
  }

  //Awareness and Practice api call "combined-data"
  getAwarenessAndPractice(params: any): Observable<any> {
    let httpParams = new HttpParams();
  
    // Loop over the keys of the params object and append them to httpParams
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '') {
        // If the parameter is an array, append each value
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
  
    const url = `${this.endpoint}/monthly-data`;
    return this.http.get<any[]>(url, { headers: this.headers, params: httpParams });
  }

   // Call the HH coverage api in this service file function 
   getHHCoverage(params: any): Observable<any> {
    let httpParams = new HttpParams();
  
    // Loop over the keys of the params object and append them to httpParams
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '') {
        // If the parameter is an array, append each value
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
  
    const url = `${this.endpoint}/counts-by-month-and-state`;
    return this.http.get<any[]>(url, { headers: this.headers, params: httpParams });
  }

   // Call the HH coverage api in this service file function 
   getMothersData(params: any): Observable<any> {
    let httpParams = new HttpParams();
  
    // Loop over the keys of the params object and append them to httpParams
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '') {
        // If the parameter is an array, append each value
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
  
    const url = `${this.endpoint}/mother-reg-counts-by-month-and-state`;
    return this.http.get<any[]>(url, { headers: this.headers, params: httpParams });
  }

   // Call the HH coverage api in this service file function 
   getChildData(params: any): Observable<any> {
    let httpParams = new HttpParams();
  
    // Loop over the keys of the params object and append them to httpParams
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '') {
        // If the parameter is an array, append each value
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
  
    const url = `${this.endpoint}/child-reg-counts-by-month-and-state`;
    return this.http.get<any[]>(url, { headers: this.headers, params: httpParams });
  }
}
