import { Component, OnInit, OnDestroy } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { OverviewDashboardService } from '../Services/overview-dashboard.service';
import { StackedColumnChartComponent } from '../stacked-column-chart/stacked-column-chart.component';
import { StepChartComponent } from "../step-chart/step-chart.component";

import { DiarrheaSelfCareService } from '../Services/diarrhea-self-care.service';
import { PieChartComponent } from "../pie-chart/pie-chart.component";
import { CommonModule } from '@angular/common';
export interface ChartDataPoint {
  name: string;
  value: number;
  color: string; // Ensure this is a string type
}

@Component({
  selector: 'app-diarrhoea-funnel-chart',
  standalone: true,
  imports: [StackedColumnChartComponent, StepChartComponent, PieChartComponent,CommonModule],
  templateUrl: './diarrhoea-funnel-chart.component.html',
  styleUrls: ['./diarrhoea-funnel-chart.component.css']
})
export class DiarrhoeaFunnelChartComponent implements OnInit, OnDestroy {
  private root!: am5.Root;
  otherIndicatorsData: any[] = [];
  // bellow for calling diarrhoea service and pass params to it from here
  stateId: any[] = [];
  districtId: any[] = [];
  blockId: any[] = [];
  villageId: any[] = [];
  chwId: any[] = [];
  params = {};
  currentUser: any;
  // bellow for calling diarrhoea service and pass params to it to here
  
  isStepChartVisible: boolean = false;
  chartData2: ChartDataPoint[] = [];
  constructor(private overviewDashboardService: OverviewDashboardService, private diarrheaService: DiarrheaSelfCareService ) {}

  ngOnInit() {
  
    this.createFunnelChart();
    this.loadStepChartData();
    this.initializeComponent();
    // this.getDataFromLocalStorage();
  }
  private initializeComponent(): void {
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUser = currentUserString ? JSON.parse(currentUserString) : null;

    this.getDataFromLocalStorage();
  }
  private createFunnelChart() {
    this.root = am5.Root.new("chartFunneldiv1");
    this.root.setThemes([am5themes_Animated.new(this.root)]);
  
    const chart = this.root.container.children.push(
      am5percent.SlicedChart.new(this.root, {
        layout: this.root.verticalLayout
      })
    );
  
    const series = chart.series.push(
      am5percent.FunnelSeries.new(this.root, {
        alignLabels: false,
        orientation: "vertical",
        valueField: "value",
        categoryField: "category"
      })
    );
  
    series.slices.template.setAll({
      strokeOpacity: 0,
      fillGradient: am5.LinearGradient.new(this.root, {
        rotation: 0,
        stops: [{ brighten: -0.4 }, { brighten: 0.4 }, { brighten: -0.4 }]
      }),
      cursorOverStyle: "pointer"
    });
  
    // Update the label to display the category and value
    series.labels.template.setAll({
      text: "{category} : {value}", // Show category and value
      fill: am5.color(0x000000), // Change text color to white
    });
  
    series.slices.template.events.on("click", (ev) => {
      const dataItem = ev.target.dataItem;
      if (dataItem && series.slices.indexOf(ev.target) === 3) {
        this.toggleStepChart();
      }
    });
  
    // Set the data for the funnel chart
    series.data.setAll([
      { value: 6576, category: `Child (0-6)` },
      { value: 3450, category: "Child (0-2)" },
      { value: 3506, category: "Child Covered" },
      { value: 2263, category: "Diarrhoea" },
      { value: 2355, category: "Home" },
      { value: 1238, category: "Facility" }
    ]);
  
    // Create a legend and set it to display values
    const legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 15,
        marginBottom: 15
      })
    );
  
    // Set legend data and customize the legend labels to show only values
    legend.data.setAll(series.dataItems);
    legend.labels.template.setAll({
      text: "{category}: {value}", // Show category and value in legend
      fill: am5.color(0x000000) // Change legend text color to white
    });
  
    // Ensure the series and chart appear
    series.appear();
    chart.appear(1000, 100);
  }
  

  private loadStepChartData(): void {
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUser = currentUserString ? JSON.parse(currentUserString) : null;
    this.getDiarrheaOtherIndicator();
  }

  getDiarrheaOtherIndicator(params: any = this.params): void {
    this.overviewDashboardService.getOtherIndicatorData().subscribe(
      (res: any) => {
        console.log("Received data:", res);
        this.otherIndicatorsData = this.transformOtherIndicatorChartData(res);
      },
      (err: any) => {
        console.error('Error occurred in getDiarrheaOtherIndicator:', err);
      }
    );
  }

  toggleStepChart() {
    this.isStepChartVisible = !this.isStepChartVisible;
  }

  transformOtherIndicatorChartData(data: any): any[] {
    const categories = [
      { key: 'vaccinationStats', name: 'Vaccination', percentageKey: 'percentageVaccinatedUnder12Months' },
      { key: 'breastfeedingStats', name: 'Early Breastfeeding', percentageKey: 'percentageBreastfeedingIn1HrUnder6Months' },
      { key: 'breastfeedingContinueStats', name: 'Exclusive Breastfeeding', percentageKey: 'percentageBreastfeedingContinueUnder6Months' },
      { key: 'defectedInOpenStats', name: 'Safe sanitation/ open defecation', percentageKey: 'percentageDefectedInOpen' },
      { key: 'soapAndHandWashStats', name: 'Hand Hygiene', percentageKey: 'percentageSoapAndHandWash' },
      { key: 'washHandsMoreSecsStats', name: 'Hand Hygiene (>20 seconds)', percentageKey: 'percentageWashHandsMoreSecs' },
      { key: 'waterSourceStats', name: 'Safe and Clean Water', percentageKey: 'percentageValidWaterSource' },
      { key: 'waterSourceStats', name: 'Zinc', percentageKey: 'percentageValidWaterSource' },
      { key: 'defectedInOpenStats', name: 'ORS', percentageKey: 'percentageDefectedInOpen' },
    ];

    return categories.map(category => {
      const percentageString = data[category.key][category.percentageKey];
      const value = parseFloat(percentageString.replace('%', ''));
      return { name: category.name, value: isNaN(value) ? 0 : value };
    }).sort((a, b) => b.value - a.value);
  }

// for diarrhoea chart
getDataFromLocalStorage(): void {
  const allUser = localStorage.getItem('currentUser');

  if (allUser) {
    const currentUser: any = JSON.parse(allUser);
    this.currentUser = currentUser;

    switch (currentUser.user.designation) {
      case 'SM':
        this.stateId = currentUser.user['stateId'];
        break;
      case 'DL':
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        break;
      case 'BO':
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
        break;
      case 'CHW':
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
        this.villageId = currentUser.user['villageId'];
        this.chwId = currentUser.user['chwId'];
        break;
    }
  }

  this.params = {
    stateId: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
    districtId: Array.isArray(this.districtId) ? this.districtId : [this.districtId],
    blockId: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
    villageId: Array.isArray(this.villageId) ? this.villageId : [this.villageId],
    chwId: Array.isArray(this.chwId) ? this.chwId : [this.chwId],
    userLevel: this.currentUser?.user.designation,
  };

  // Call the API to fetch data
  this.getAllData(this.params);
}

getAllData(paramsValue: any): void {
  this.diarrheaService.getTreatedAtFacilityApi(paramsValue).subscribe(
    (dataValue: any) => {
      this.setChartData2(dataValue);
      this.createPieChart(); // Call pie chart creation after data is received
    },
    (error) => {
      console.error('Error fetching data:', error);
    }
  );
}

setChartData2(apiData1: any): void {
  this.chartData2 = [
    { name: 'Loss To F/U', value: apiData1.LossToFollowUp, color: '#D2122E' },
    { name: '% Recover', value: apiData1.Recovered, color: '#568203' },
    { name: '% LAMA', value: apiData1.LAMA, color: '#FFC72C' },
    { name: 'UT', value: apiData1.TreatmentInProcess, color: '#662d91' },
    { name: '% Deaths', value: apiData1.Fatalities, color: '#0000FF' },
  ];
  // console.log(`hello bccbb ${JSON.stringify(this.chartData2)}`);
  
  // // Call to create pie chart with new data
  // this.createPieChart();
}



createPieChart(): void {
  // Create root element
  let root = am5.Root.new("chartdiveeeeee");

  // Set themes
  root.setThemes([
    am5themes_Animated.new(root)
  ]);

  // Create chart
  let chart = root.container.children.push(am5percent.PieChart.new(root, {
    layout: root.verticalLayout
  }));

  // Create series
  let series = chart.series.push(am5percent.PieSeries.new(root, {
    valueField: "value",
    categoryField: "name",
    colors: am5.ColorSet.new(root, {
      step: 2,
      // Use the colors defined in chartData2
      colors: this.chartData2.map(item => am5.color(item.color)) // Dynamic color assignment
    })
  }));

  // Set data based on chartData2
  series.data.setAll(this.chartData2);

  // Create legend
  let legend = chart.children.push(am5.Legend.new(root, {
    centerX: am5.percent(50),
    x: am5.percent(50),
    marginTop: 15,
    marginBottom: 15
  }));

  legend.data.setAll(series.dataItems);

  // Play initial series animation
  series.appear(1000, 100);

  // Save root for disposal
  this.root = root;
}



ngOnDestroy(): void {
  if (this.root) {
    this.root.dispose();
  }
}
}
