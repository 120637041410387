import { Component, OnInit } from '@angular/core';
import { CommunityInterventionService } from '../Services/community-intervention.service';
import { CommunityInterventionStackGraphComponent } from "../community-intervention-stack-graph/community-intervention-stack-graph.component";
import { ClusterCommunityInterventionStackChartComponent } from "../cluster-community-intervention-stack-chart/cluster-community-intervention-stack-chart.component";
import { CommonModule } from '@angular/common';

// Define structure for monthly regional data
interface RegionalMonthlyData {
  monthName: string;
  year: string;
  Gujarat: number;
  Rajasthan: number;
  Maharashtra: number;
}

// Define structure for chart data with month and region values
interface ChartDatabst {
  month: string;
  [key: string]: string | number;
}

// Define structure for chart data with month and region values
interface ChartDatabst2 {
  month: string[];
  [key: string]: any; // Allows dynamic keys for state data
}

// Configuration for chart series including names, fields, and colors
interface SeriesConfig {
  name: string;
  field: string;
  color: string;
}

interface TableRow {
  month: string;
  state: string;
  totalChild: number;
  totalFemale: number;
  totalMale: number;
}

@Component({
  selector: 'app-community-intervention',
  standalone: true,
  imports: [
    CommunityInterventionStackGraphComponent,
    ClusterCommunityInterventionStackChartComponent,
    CommonModule
  ],
  templateUrl: './community-intervention.component.html',
  styleUrls: ['./community-intervention.component.css'],
})
export class CommunityInterventionComponent implements OnInit {
  // Total CHW count by state
  totalChwByState: { [key: string]: number } = {
    'Maharashtra': 17,
    'Rajasthan': 7,
    'Gujarat': 7
  };

  // Default chart colors for the graphs
  chartColors = ['#ef9b13', '#ece804', '#94d6ea'];

  // Arrays to hold data for each type of community intervention
  monthsmothermeetingtotal: string[] = [];
  statesmothermeetingtotal: string[] = [];
  stateValuesMotherMeetingTotal: any[] = [];

  monthsmothermeetingcoverage: string[] = [];
  statesmothermeetingcoverage: string[] = [];
  stateValuesmothermeetingcoverage: any[] = [];

  monthsVHSNDtotal: string[] = [];
  statesVHSNDtotal: string[] = [];
  stateValuesVHSNDtotal: any[] = [];

  monthsVHSNDcoverage: string[] = [];
  statesVHSNDcoverage: string[] = [];
  stateValuesVHSNDcoverage: any[] = [];

  motherMeetingTotalData: ChartDatabst[] = [];
  motherMeetingCoverageData: ChartDatabst[] = [];
  VHSNDTotalData: ChartDatabst[] = [];
  VHSNDCoverageData: ChartDatabst[] = [];

  nukkadNatakDataDummy: ChartDatabst[] = [{
    "month": "Oct-2024",
    "monthName": "Oct",
    "monthNum": 10,
    "year": 2024,
    "Rajasthan": 17,
    "Maharashtra": 8
  }];

  // Original data
  nukkadNatakCoverageDummy: ChartDatabst2[] = [
    {
      month: ['oct'],
      'Total Male Rajasthan': 89,
      'Total Female Rajasthan': 253,
      'Total Child Rajasthan': 20,
      'Total Male Maharashtra': 44,
      'Total Female Maharashtra': 16,
      'Total Child Maharashtra': 19,
    },
  ];

  // Parsed data to match the table format
  parsedData: TableRow[] = [];

  nukkadNatakData: ChartDatabst[] = [];
  nukkadNatakCoverage: ChartDatabst[] = [];

  // Configurations for each region in the chart
  seriesConfig: SeriesConfig[] = [
    { name: "Gujarat", field: "32", color: "7" },
    { name: "Rajasthan", field: "55", color: "7" },
    { name: "Maharashtra", field: "43", color: "13" }
  ];

  constructor(private motherservice: CommunityInterventionService) {}

  // Method to get total CHW for a specific state
  getTotalChwForState(state: string): number {
    return this.totalChwByState[state] || 27; // Default to 27 if state not found
  }

  // Lifecycle hook for component initialization
  ngOnInit(): void {
    this.initializeData();
    this.processData();
  }

  // Initialize data loading for all community interventions
  private initializeData(): void {
    this.fetchMotherMeetingData();
    this.fetchVhsndData();
    this.fetchNukkadNatakData();
  }

  /**
   * Fetches total and coverage data for Mother Meetings
   * and maps it into the required chart format.
   */

  private extractDataOfMotherMeetingTotal(): void {
    // Extract months and states from the data
    this.monthsmothermeetingtotal = this.motherMeetingTotalData.map(item => item.month);
    this.statesmothermeetingtotal = Object.keys(this.motherMeetingTotalData[0]).filter(key => key !== 'month' && key !== 'monthName' && key !== 'monthNum' && key !== 'year');
    
    // Extract values for each state
    this.stateValuesMotherMeetingTotal = this.statesmothermeetingtotal.map(state => 
      this.motherMeetingTotalData.map(item => item[state])
    );
  }
  private extractDataOfMotherMeetingCOverage(): void {
    // Extract months and states from the data
    this.monthsmothermeetingcoverage = this.motherMeetingCoverageData.map(item => item.month);
    this.statesmothermeetingcoverage = Object.keys(this.motherMeetingCoverageData[0]).filter(key => key !== 'month' && key !== 'monthName' && key !== 'monthNum' && key !== 'year');
    
    // Extract values for each state
    this.stateValuesmothermeetingcoverage = this.statesmothermeetingtotal.map(state => 
      this.motherMeetingCoverageData.map(item => item[state])
    );
  }

  private extractDataOfHHSNDCoverage(): void {
    // Extract months and states from the data
    this.monthsVHSNDtotal = this.VHSNDTotalData.map(item => item.month);
    this.statesVHSNDtotal = Object.keys(this.VHSNDTotalData[0]).filter(key => key !== 'month' && key !== 'monthName' && key !== 'monthNum' && key !== 'year');
    
    // Extract values for each state
    this.stateValuesVHSNDtotal = this.statesVHSNDtotal.map(state => 
      this.VHSNDTotalData.map(item => item[state])
    );
  }
  private extractVHSNDCoverage(): void {
    // Extract months and states from the data
    this.monthsVHSNDcoverage = this.VHSNDCoverageData.map(item => item.month);
    this.statesVHSNDcoverage = Object.keys(this.VHSNDCoverageData[0]).filter(key => key !== 'month' && key !== 'monthName' && key !== 'monthNum' && key !== 'year');
    
    // Extract values for each state
    this.stateValuesVHSNDcoverage = this.statesVHSNDcoverage .map(state => 
      this.VHSNDCoverageData.map(item => item[state])
    );
  }

 // Helper method to get the state value for a particular month
 getStateValue(state: string, month: string): any {
  const monthIndex = this.monthsmothermeetingtotal.indexOf(month);
  if (monthIndex !== -1) {
    const dataForMonth = this.motherMeetingTotalData[monthIndex];
    return dataForMonth[state];
  }
  return '-'; // If the value does not exist, return '-'
}
 // Helper method to get the state value for a particular month
 getStateCoverageValue(state: string, month: string): any {
  const monthIndex = this.monthsmothermeetingcoverage.indexOf(month);
  if (monthIndex !== -1) {
    const dataForMonth = this.motherMeetingCoverageData[monthIndex];
    return dataForMonth[state];
  }
  return '-'; // If the value does not exist, return '-'
}

 getStateVHSNDtotal(state: string, month: string): any {
  const monthIndex = this.monthsVHSNDtotal.indexOf(month);
  if (monthIndex !== -1) {
    const dataForMonth = this.VHSNDTotalData[monthIndex];
    return dataForMonth[state];
  }
  return '-'; // If the value does not exist, return '-'
}

 getStateVHSNDcoverage(state: string, month: string): any {
  const monthIndex = this.monthsVHSNDcoverage.indexOf(month);
  if (monthIndex !== -1) {
    const dataForMonth = this.VHSNDCoverageData[monthIndex];
    return dataForMonth[state];
  }
  return '-'; // If the value does not exist, return '-'
}


  private fetchMotherMeetingData(): void {
    this.motherservice.getMotherMeetingsTotalData().subscribe(response => {
      if (response && response.data) {
        this.motherMeetingTotalData = this.mapMeetingData(response.data);
        this.extractDataOfMotherMeetingTotal();
      }
    });

    this.motherservice.getMotherMeetingsCoverageData().subscribe(response => {
      if (response && response.data) {
        this.motherMeetingCoverageData = this.mapMeetingData(response.data);
        this.extractDataOfMotherMeetingCOverage();
      }
    });
  }

  /**
   * Fetches total and coverage data for VHSND
   * and maps it into the required chart format.
   */
  private fetchVhsndData(): void {
    this.motherservice.VHSNDTotalData().subscribe(response => {
      if (response && response.data) {
      this.VHSNDTotalData = this.mapMeetingData(response.data);
      this.extractDataOfHHSNDCoverage();
      }
    });

    this.motherservice.VHSNDCoverageData().subscribe(response => {
      if (response && response.data) {
      this.VHSNDCoverageData = this.mapMeetingData(response.data);
      this.extractVHSNDCoverage();
      }
    });
  }

  /**
   * Fetches total and coverage data for Nukkad Natak
   * and maps it into the required chart format.
   */
  private fetchNukkadNatakData(): void {
    this.motherservice.getNukkadNatakTotalData().subscribe(response => {
      this.nukkadNatakData = this.mapMeetingData(response.data);
    });
    this.motherservice.getNukkadNatakCoverageData().subscribe(response => {
      this.nukkadNatakCoverage = response?.data ?? [];
    });
  }

  /**
   * Maps raw monthly data into chart-compatible format.
   * @param data - array of RegionalMonthlyData from the API
   * @returns - array of ChartDatabst formatted for charts
   */
  private mapMeetingData(data: RegionalMonthlyData[]): ChartDatabst[] {
    return data.map(item => ({
      month: item.monthName,
      year:item.year,
      Gujarat: item.Gujarat,
      Rajasthan: item.Rajasthan,
      Maharashtra: item.Maharashtra
    }));
  }


  processData(): void {
    const rawData = this.nukkadNatakCoverageDummy[0];
    const month = rawData.month[0];

    Object.keys(rawData).forEach((key) => {
      // Match keys for states dynamically
      const match = key.match(/Total (.+?) (.+)/); // Example: "Total Male Rajasthan"

      if (match) {
        const category = match[1]; // Male/Female/Child
        const state = match[2]; // Rajasthan/Maharashtra

        // Find or initialize row for this state
        let row = this.parsedData.find((r) => r.state === state);

        if (!row) {
          row = { month, state, totalChild: 0, totalFemale: 0, totalMale: 0 };
          this.parsedData.push(row);
        }

        // Update the appropriate category
        if (category === 'Male') row.totalMale = rawData[key];
        if (category === 'Female') row.totalFemale = rawData[key];
        if (category === 'Child') row.totalChild = rawData[key];
      }
    });
  }
}
