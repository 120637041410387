import { Component, ElementRef, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterComponent } from '../filter/filter.component';
interface Option {
  value: string;
  label: string;
  selected: boolean;
}

@Component({
  selector: 'app-line-list',
  standalone: true,
  imports: [
    FilterComponent,
    FormsModule,
    CommonModule
  ],
  templateUrl: './line-list.component.html',
  styleUrl: './line-list.component.css'
})
export class LineListComponent {
  @ViewChild('dynamicContainerofFilter', { read: ViewContainerRef, static: true }) 
  filterContainer!: ViewContainerRef;
  

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.loadFilterComponent();
  }

  loadFilterComponent(): void {
    this.filterContainer.clear();
    this.filterContainer.createComponent(FilterComponent);
  }

 





}