import { Component, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

@Component({
  selector: 'app-family-planning-stack-chart',
  standalone: true,
  templateUrl: './family-planning-stack-chart.component.html',
  styleUrls: ['./family-planning-stack-chart.component.css']
})
export class FamilyPlanningStackChartComponent implements AfterViewInit, OnChanges {
  @Input() chartData!: any[];  // Input property to receive data
  @Input() chartId!: string;   // Input property to receive dynamic ID
  @Input() colors!: string[];  // New input property for dynamic colors

  private root!: am5.Root;
  private chart!: am5xy.XYChart;

  ngAfterViewInit() {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['chartData'] || changes['colors']) && this.root) {
      this.updateChart();
    }
  }

  private createChart() {
    this.root = am5.Root.new(this.chartId);

    this.root.setThemes([am5themes_Animated.new(this.root)]);

      // Disable amCharts logo
      this.root._logo?.dispose();
      
    // Create chart
    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      paddingLeft: 0,
      layout: this.root.verticalLayout
    }));

    // Set custom colors if provided
    if (this.colors && this.colors.length > 0) {
      this.chart.set("colors", am5.ColorSet.new(this.root, {
        colors: this.colors.map(color => am5.color(color))
      }));
    }

    // Add scrollbar
    this.chart.set("scrollbarX", am5.Scrollbar.new(this.root, { 
      orientation: "horizontal",
      marginTop: 20
    }));

    // Create axes with updated configuration
    let xRenderer = am5xy.AxisRendererX.new(this.root, { 
      minGridDistance: 30,
      minorGridEnabled: true
    });

    // Configure x-axis for better month display
    let xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
      categoryField: "month",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(this.root, { labelText: "{categoryX}" }),
      layout: this.root.horizontalLayout
    }));

    // Customize x-axis labels
    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15
    });

    xRenderer.grid.template.setAll({ 
      location: 1,
      visible: true
    });

    xAxis.data.setAll(this.chartData);

    let yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      min: 0,
      max: 100,
      numberFormat: "#'%'",
      strictMinMax: true,
      calculateTotals: true,
      renderer: am5xy.AxisRendererY.new(this.root, {
        strokeOpacity: 0.1
      })
    }));

    // Add legend with updated positioning
    let legend = this.chart.children.push(am5.Legend.new(this.root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      marginTop: 15,
      marginBottom: 15
    }));

    // Function to create series
    const makeSeries = (name: string, fieldName: string) => {
      let series = this.chart.series.push(am5xy.ColumnSeries.new(this.root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "month"
      }));

      series.data.setAll(this.chartData);

      // Enhanced tooltip
      series.columns.template.setAll({
        tooltipText: "{name}\n{categoryX}: {valueY}%",
        tooltipY: am5.percent(10),
        cornerRadiusTL: 5,
        cornerRadiusTR: 5
      });

      // Add bullet labels with improved positioning
      series.bullets.push(() => {
        return am5.Bullet.new(this.root, {
          sprite: am5.Label.new(this.root, {
            text: "{valueY.formatNumber('#.#')}%",
            fill: this.root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true,
            fontSize: 12
          })
        });
      });

      series.appear(1000);
      legend.data.push(series);
    }

    // Create series for each category
    makeSeries("Injectable", "injectable");
    makeSeries("IUC", "iuc");
    makeSeries("Emergency", "emergency");
    makeSeries("Weekly", "weekly");
    makeSeries("Daily", "daily");
    makeSeries("Condoms", "condoms");

    // Animate chart appearance
    this.chart.appear(1000, 100);
  }

  private updateChart() {
    if (this.chart && this.chartData) {
      // Update data
      this.chart.xAxes.getIndex(0)?.data.setAll(this.chartData);
      this.chart.series.each((series) => {
        series.data.setAll(this.chartData);
      });

      // Update colors if provided
      if (this.colors && this.colors.length > 0) {
        this.chart.set("colors", am5.ColorSet.new(this.root, {
          colors: this.colors.map(color => am5.color(color))
        }));
      }
    }
  }
}