import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../../Services/authentication.service';

@Component({
  selector: 'app-awareness-changes',
  standalone: true,
  imports: [],
  templateUrl: './awareness-changes.component.html',
  styleUrls: ['./awareness-changes.component.css']
})
export class AwarenessChangesComponent implements OnInit {
  private readonly DEFAULT_DIARRHOEA_URL = 'https://app.powerbi.com/view?r=eyJrIjoiNzZiYTMwOTctNzFkOS00YWU0LWIxM2UtNThkOGZmNjJmNDVhIiwidCI6ImZkMzBmNTBkLTViNzItNDc2OC04NTBmLWNmOTAwMzhjOWJmOSJ9';
  private readonly DEFAULT_PNEUMONIA_URL = 'https://app.powerbi.com/view?r=eyJrIjoiM2JlN2Q4ZjktYTA5Yi00NDNkLTk5ZTItMTFlMTZiOTYyNDhhIiwidCI6ImZkMzBmNTBkLTViNzItNDc2OC04NTBmLWNmOTAwMzhjOWJmOSJ9';

  diarrhoeaUrl: string = this.DEFAULT_DIARRHOEA_URL;
  pneumoniaUrl: string = this.DEFAULT_PNEUMONIA_URL;
  currentReportUrl: SafeResourceUrl | undefined;
  currentUser: any;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    const storedUser = localStorage.getItem('currentUser');
    this.currentUser = storedUser ? JSON.parse(storedUser) : null;

    if (this.currentUser?.user.designation === 'Admin') {
      this.currentReportUrl = this.sanitizeUrl(this.diarrhoeaUrl);
    } else if (this.currentUser?.user?.id) {
      const userId = this.currentUser.user.id;

      this.authService.getPowerBi({ "where": { "userId": userId } }).subscribe(
        (response: any) => {
          if (response?.length > 0 && response[0].Child_Pen && response[0].child_Dia) {
            this.diarrhoeaUrl = response[0].child_Dia;
            this.pneumoniaUrl = response[0].Child_Pen;
          }
          this.currentReportUrl = this.sanitizeUrl(this.diarrhoeaUrl);
        },
        (error) => {
          console.error('Error fetching Power BI URLs:', error);
          this.currentReportUrl = this.sanitizeUrl(this.diarrhoeaUrl);
        }
      );
    } else {
      this.currentReportUrl = this.sanitizeUrl(this.diarrhoeaUrl);
    }
  }

  switchReport(report: 'diarrhoea' | 'pneumonia'): void {
    const url = report === 'diarrhoea' ? this.diarrhoeaUrl : this.pneumoniaUrl;
    this.currentReportUrl = this.sanitizeUrl(url);
  }

  private sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openInNewWindow(): void {
    window.open('/awarenessChanges', '_blank');
  }
}
