import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { GoogleMapsModule, MapInfoWindow, MapAdvancedMarker } from '@angular/google-maps';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-map-chart',
  standalone: true,
  imports: [GoogleMapsModule, CommonModule],
  templateUrl: './map-chart.component.html',
  styleUrls: ['./map-chart.component.css']
})
export class MapChartComponent implements OnInit, OnChanges {
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  @Input() mapData: any[] = [];

  options: google.maps.MapOptions = {
    mapId: '2f36401cb1331f8f',
    zoom: 7,
  };

  ngOnInit() {
    console.log("mapData : ",this.mapData);
    this.updateMapData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mapData']) {
      this.updateMapData();
    }
  }

  updateMapData() {
    if (this.mapData && this.mapData.length > 0) {
      const parser = new DOMParser();
      const svgString = `<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FF5733" stroke="#FFFFFF" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z" clip-rule="evenodd"/>
                        </svg>`;

      this.mapData.forEach((location) => {
        if (location.type === 'svg') {
          location.content = parser.parseFromString(svgString, "image/svg+xml").documentElement;
        } else if (location.type === 'image') {
          let imgTag = document.createElement("img");
          imgTag.src = location.iconUrl;
          location.content = imgTag;
        }
      });

      // Calculate the center of the map based on the data
      const bounds = new google.maps.LatLngBounds();
      this.mapData.forEach(location => {
        bounds.extend(new google.maps.LatLng(location.lat, location.lng));
      });
      this.options = {
        ...this.options,
        center: bounds.getCenter().toJSON(),
      };
    }
  }

  onMarkerClick(marker: MapAdvancedMarker) {
    this.infoWindow.openAdvancedMarkerElement(marker.advancedMarker, marker.advancedMarker.title);
  }
}