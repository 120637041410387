import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../Services/authentication.service';

@Component({
  selector: 'app-mother-sixpillars-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './mother-sixpillars-dashboard.component.html',
  styleUrls: ['./mother-sixpillars-dashboard.component.css']
})
export class MotherSixpillarsDashboardComponent implements OnInit {
  safePbiUrl: SafeResourceUrl | undefined;
  baseUrl: string = 'https://app.powerbi.com/view?r=eyJrIjoiNGFmMDNhZjgtNWFhZC00NjYxLWJlOTctNTI5NDkzYjVlNmVmIiwidCI6ImZkMzBmNTBkLTViNzItNDc2OC04NTBmLWNmOTAwMzhjOWJmOSJ9';
  currentUser: any;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    // Parse currentUser from localStorage
    const storedUser = localStorage.getItem('currentUser');
    this.currentUser = storedUser ? JSON.parse(storedUser) : null;

    if (this.currentUser && this.currentUser.user.designation === 'Admin') {
      this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl);
    } else if (this.currentUser && this.currentUser.id) {
      const userId = this.currentUser.user.id;
      
      this.authService.getPowerBi({"where":{"userId":userId} }).subscribe(
        (response: any) => {
          if (response && response.length > 0 && response[0].Page4) {
            console.log(response);
            this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response[0].Page4);
          } else {
            this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl); // Fallback if no URL is found
          }
        },
        (error) => {
          console.error('Error fetching Power BI URL:', error);
          this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl);
        }
      );
    } else {
      this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl);
    }
  }
}
