import { Component, OnInit, NgZone, Inject, PLATFORM_ID, ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { PyramidChartComponent } from '../pyramid-chart/pyramid-chart.component';
import { MatCardModule } from '@angular/material/card';
import { GuagemeterComponent } from '../guagemeter/guagemeter.component';
import { FilterComponent } from '../filter/filter.component';
import { PneumoniaSelfCareService } from '../Services/pneumonia-self-care.service';
import { DiarrheaSelfCareService } from '../Services/diarrhea-self-care.service';
import { OverviewDashboardService } from '../Services/overview-dashboard.service'
import { AwarenessChangesComponent } from "../components/awareness-changes/awareness-changes.component";

interface DashboardItem {
  title: string;
  total: number;
}

interface TileCount {
  title: string;
  total: number;
  male: number;
  female: number;
}
interface PneumoniaTileCount {
  title: string;
  male: number;
  female: number;
  total?: number;
}

interface PneumoniaTileTopStat {
  covered: number;
  title: string;
  target: number;
}
interface FilterValues {
  state_Id?: string | string[];
  district_Id?: string | string[];
  block_Id?: string | string[];
  village_Id?: string | string[];
  type?: string;
  fromDate?: string;
  toDate?: string;
}

interface TileTopStat2 {
  title: string;
  target: number;
  covered: number;
}

interface BeneficiaryStats {
  target: number;
  covered: number;
  percentage: number;
}

interface PopulationRegistered {
  target: number;
  covered: number;
  percentage: number;
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    PyramidChartComponent,
    MatCardModule,
    GuagemeterComponent,
    CommonModule,
    FilterComponent,
    AwarenessChangesComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  isFilterVisible: boolean = false; // Variable to control visibility
  diarrheaTileCounts: TileCount[] = [];
  diarrheaDetails: DashboardItem[] = [];
  pneumoniaDetails: DashboardItem[] = [];
  pneumoniaTileCounts: PneumoniaTileCount[] = [];
  pneumoniaTileTopStats: PneumoniaTileTopStat[] = [];
  params: any = {};
  private root2!: am5.Root;
  currentUser: any;
  BeneficiariesRegistered: BeneficiaryStats = {
    target: 0,
    covered: 0,
    percentage: 0,
  };
  MonRegistered: BeneficiaryStats = { target: 0, covered: 0, percentage: 0 };
  ChildRegistered: BeneficiaryStats = { target: 0, covered: 0, percentage: 0 };
  PopulationRegistered: PopulationRegistered = {
    target: 0,
    covered: 0,
    percentage: 0,
  };
  isLoading: boolean = false;
  error: string = '';

  constructor(
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private pneumoniaService: PneumoniaSelfCareService,
    private diarrheaService: DiarrheaSelfCareService,
    private overviewDashboardService: OverviewDashboardService,
    private viewContainerRef: ViewContainerRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient
  ) {}

  ngOnInit() {
      // Initially hide the component
      this.isFilterVisible = false;
    this.loadAwarenessChangesComponent(); // 4 oct 
    const userData = localStorage.getItem('currentUser');
    if (userData) {
      this.currentUser = JSON.parse(userData);
      this.setupUserParams();
      this.fetchDiarrheaData(this.params);
      this.fetchPneumoniaData(this.params);
      this.fetchMotherRegData(this.params);
      this.fetchHHlistingData(this.params);
    }

    this.getOverviewGraphData();

  }
  // 4 oct 
  loadAwarenessChangesComponent(): void {
    this.viewContainerRef.clear(); // Clear anything inside this container

    this.viewContainerRef.createComponent(AwarenessChangesComponent); // Create and inject the component
  }
   // Method to toggle the visibility of the filter component
   toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }

  

  private setupUserParams() {
    if (this.currentUser) {
      const user = this.currentUser.user;
      this.params = {
        stateId: user.stateId
          ? Array.isArray(user.stateId)
            ? user.stateId
            : [user.stateId]
          : [],
        districtId: user.districtId
          ? Array.isArray(user.districtId)
            ? user.districtId
            : [user.districtId]
          : [],
        blockId: user.blockId
          ? Array.isArray(user.blockId)
            ? user.blockId
            : [user.blockId]
          : [],
        villageId: user.villageId
          ? Array.isArray(user.villageId)
            ? user.villageId
            : [user.villageId]
          : [],
        userLevel: user.designation,
      };
    }
  }

  private fetchDiarrheaData(paramsValue: any) {
    this.isLoading = true;
    this.diarrheaService.getChildDiarrheaCountFun(paramsValue).subscribe(
      (dataValue: any) => {
        this.processDiarrheaData(dataValue);
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      (error) => {
        this.error = 'Failed to load diarrhea data';
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    );
  }

  private processDiarrheaData(dataValue: any) {
    const transformedTileTopStats = dataValue.tileTopStats
      .filter((tile: TileTopStat2) => tile.title === 'Child Coverage')
      .map((tile: TileTopStat2) => ({
        title: tile.title,
        total: tile.covered,
        male: 0,
        female: 0,
      }));

    this.diarrheaTileCounts = [
      ...transformedTileTopStats,
      ...dataValue.tileCounts.filter((tile: TileCount) =>
        ['Total Diarrhoea Cases', 'Referred', 'Deaths (0-2 years)', 'Deaths (2-6 years)'].includes(tile.title))
    ] as TileCount[];

    this.diarrheaDetails = this.diarrheaTileCounts.map(item => ({
      title: this.transformDiarrheaTitle(item.title),
      total: item.total
    }));
  }

  private fetchPneumoniaData(paramsValue: any) {
    paramsValue['type'] = 'filter';
    this.pneumoniaService.getChildPneumoniaCountFun(paramsValue).subscribe(
      (res: any) => {
        this.processPneumoniaData(res);
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.error('Error fetching pneumonia details:', err);
      }
    );
  }

  private processPneumoniaData(data: any): void {
    const desiredTitles = [
      'Children Covered',
      'Suspected Pneumonia',
      'Treated At Home',
      'Repeat Pneumonia',
      'Referred To Facility'
    ];

    this.pneumoniaTileTopStats = data.tileCounts
      .filter((tile: PneumoniaTileCount) => desiredTitles.includes(tile.title))
      .map((tile: PneumoniaTileCount) => ({
        ...tile,
        total: tile.male + tile.female
      }));

   // this.pneumoniaTileTopStats = data.tileTopStats;

    this.cdr.detectChanges();
  }


  getOverviewGraphData() {
    this.overviewDashboardService.getOverviewGraphData().subscribe(
      (res: any) => {
        const formattedData = this.formatGraphData(res.data);
        this.createChart2(formattedData);
      },
      (err: any) => {
        console.error('Error fetching overview graph data:', err);
      }
    );
  }

  private formatGraphData(data: any) {
    const graphData = [];

    data.DiarreaResult.forEach((item: any) => {
      graphData.push({ category: item.year, value: item.la, type: 'Diarrhea' });
    });

    data.PneumoniaResult.forEach((item: any) => {
      graphData.push({
        category: item.year,
        value: item.la,
        type: 'Pneumonia',
      });
    });

    data.ANCResult.forEach((item: any) => {
      graphData.push({ category: item.name, value: item.value, type: 'ANC' });
    });

    data.HealthHygineResult.forEach((item: any) => {
      graphData.push({
        category: item.name,
        value: item.value,
        type: 'Health & Hygiene',
      });
    });

    const yogaData = data.YogaResult;
    graphData.push({
      category: 'Yoga Count',
      value: yogaData.yogaCount,
      type: 'Yoga',
    });
    graphData.push({
      category: 'Mental Stress Count',
      value: yogaData.mentalStressCount,
      type: 'Yoga',
    });
    graphData.push({
      category: 'Govt Helpline Count',
      value: yogaData.govtHelplineCount,
      type: 'Yoga',
    });

    data.BirthSpacingResult.forEach((item: any) => {
      graphData.push({
        category: item.name,
        value: item.value,
        type: 'Birth Spacing',
      });
    });

    return graphData;
  }

  onFilterChange(filterValues: FilterValues) {
    filterValues['type'] = 'filter';
    this.params = {
      stateId: filterValues.state_Id
        ? Array.isArray(filterValues.state_Id)
          ? filterValues.state_Id
          : [filterValues.state_Id]
        : this.params.stateId || '',
      districtId: filterValues.district_Id
        ? Array.isArray(filterValues.district_Id)
          ? filterValues.district_Id
          : [filterValues.district_Id]
        : this.params.districtId || '',
      blockId: filterValues.block_Id
        ? Array.isArray(filterValues.block_Id)
          ? filterValues.block_Id
          : [filterValues.block_Id]
        : this.params.blockId || '',
      villageId: filterValues.village_Id
        ? Array.isArray(filterValues.village_Id)
          ? filterValues.village_Id
          : [filterValues.village_Id]
        : this.params.villageId || '',
      fromDate: filterValues.fromDate || '',
      toDate: filterValues.toDate || '',
    };

    this.fetchDiarrheaData(this.params);
    this.fetchPneumoniaData(this.params);
    this.fetchMotherRegData(this.params);
    this.fetchHHlistingData(this.params);
  }

  private transformDiarrheaTitle(title: string): string {
    const titleMap: { [key: string]: string } = {
      'Treated At Home': 'Diarrhea Treated At Home',
      'Admitted To Health Facility': 'Diarrhea Treated At Facility',
      'Referred': 'Referred To Facility',
      'Total Diarrhoea Cases': 'Diarrhoea Cases',
      'Deaths (0-2 years)': 'Diarrhoeal Deaths (0-2 years)',
      'Deaths (2-6 years)': 'Diarrhoeal Deaths (2-6 years)',
    };
    return titleMap[title] || title;
  }

  fetchHHlistingData(params: any) {
    this.overviewDashboardService.getHHListingData(params).subscribe(
      (resp: any) => {
        if (resp && resp.result) {
          this.PopulationRegistered = {
            target: 646627,
            covered: resp.result[0].totalMembers,
            percentage: (resp.result[0].totalMembers / 646627) * 100,
          };
        }
      },
      (err: any) => {
        console.error('Error fetching HHlisting Total Members counts data:', err);
      }
    );
  }

  fetchMotherRegData(params: any) {
    this.overviewDashboardService.getMotherRegData(params).subscribe(
      (res: any) => {
        if (res && res.data) {
          const motherData = res.data;

          this.BeneficiariesRegistered = {
            target: motherData[0].target,
            covered: motherData[0].count,
            percentage: (motherData[0].count / motherData[0].target) * 100,
          };

          this.MonRegistered = {
            target: 97447,
            covered: motherData[1].count,
            percentage: (motherData[1].count / 97447) * 100,
          };

          this.ChildRegistered = {
            target: 68513,
            covered: motherData[2].count,
            percentage: (motherData[2].count / 68513) * 100,
          };
        }
      },
      (err: any) => {
        console.error('Error fetching mother registration data:', err);
      }
    );
  }

  private createChart2(data: any) {
    if (!isPlatformBrowser(this.platformId)) return;

    this.root2 = am5.Root.new('chartdiv2');
    this.root2._logo?.dispose();
    data.sort((a: any, b: any) => b.value - a.value);

    let chart = this.root2.container.children.push(
      am5xy.XYChart.new(this.root2, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: this.root2.verticalLayout,
      })
    );

    let colorSet = am5.ColorSet.new(this.root2, {});

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root2, {
        categoryField: 'category',
        renderer: am5xy.AxisRendererX.new(this.root2, {
          minGridDistance: 30,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9
        }),
      })
    );
    
    xAxis.get('renderer').labels.template.setAll({
      fontSize: '12px',
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15
    });
  
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root2, {
        renderer: am5xy.AxisRendererY.new(this.root2, {}),
      })
    );
    yAxis.get('renderer').labels.template.setAll({
      fontSize: '15px',
    });
  
    let series = chart.series.push(
      am5xy.ColumnSeries.new(this.root2, {
        name: 'Value',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        categoryXField: 'category',
      })
    );

    series.columns.template.setAll({
      tooltipText: '{categoryX} ({type}): {valueY}',
      tooltipY: 0,
      strokeOpacity: 0,
    });

    series.columns.template.adapters.add('fill', (fill, target) => {
      const dataItem = target.dataItem?.dataContext as any;
      if (dataItem) {
        const index = data.indexOf(dataItem);
        return colorSet.getIndex(index);
      }
      return fill;
    });

    series.data.setAll(data);
  }
}