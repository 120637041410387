import { Injectable } from '@angular/core';
import { environment } from '../../environment/environment.local';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YogaMentalHealthService {

  private readonly endpoint = `${environment.apiUrl}MotherCommonQues`;

  constructor(private http: HttpClient) {}
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  getCountAwarenessForYogaMentalHealth(params: any): Observable<any> {
    const url =
    `${this.endpoint}/count-awareness` +
    '?params=' +
    JSON.stringify(params); 

    console.log(` i am from yoga and health service ${url}`);
    
  return this.http.get(url, { headers: this.headers });
  }
}
