// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.anc-global-color{
    color: #393738 !important;
  }`, "",{"version":3,"sources":["webpack://./src/app/anc/anc.component.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;EAC3B","sourcesContent":["\r\n.anc-global-color{\r\n    color: #393738 !important;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
