import { Component, OnInit, OnDestroy, AfterViewInit, Inject, PLATFORM_ID, Input } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

@Component({
  selector: 'app-horizontal-multi-column-generate',
  standalone: true,
  imports: [],
  templateUrl: './horizontal-multi-column-generate.component.html',
  styleUrl: './horizontal-multi-column-generate.component.css'
})
export class HorizontalMultiColumnGenerateComponent implements OnInit, AfterViewInit, OnDestroy {
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  private xAxis!: am5xy.ValueAxis<am5xy.AxisRenderer>;
  private yAxis!: am5xy.CategoryAxis<am5xy.AxisRenderer>;

  @Input() chartId!: string;
  @Input() outsidData: number[] = [];
  @Input() insideData: number[] = [];
  @Input() outside_Data: number[] = [];
  @Input() inside_Data: number[] = [];
  @Input() investmentsData: number[] = []; // New data input for outside_
  @Input() colors: { utsidDataColor: string, insideDataColor: string, inside_DataColor: string, outside_DataColor: string } = {
    utsidDataColor: '#0000FF',
    insideDataColor: '#FF0000',
    inside_DataColor: '#00FF00',
    outside_DataColor: '#FFA500' // New color for outside_
  };
  @Input() months: string[] = [];
 
  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    // Chart will be initialized in ngAfterViewInit
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.root = am5.Root.new(this.chartId);
      this.root.setThemes([am5themes_Animated.new(this.root)]);

      this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
        panY: false,
        layout: this.root.verticalLayout,
        paddingRight: 50
      }));

      // Create yAxis (formerly xAxis)
      this.yAxis = this.chart.yAxes.push(am5xy.CategoryAxis.new(this.root, {
        categoryField: "month",
        renderer: am5xy.AxisRendererY.new(this.root, {
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          minorGridEnabled: true
        }),
        tooltip: am5.Tooltip.new(this.root, {})
      }));

      // Modify the xAxis configuration (formerly yAxis)
      this.xAxis = this.chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererX.new(this.root, {
          strokeOpacity: 0.1,
          minGridDistance: 50,
        }),
        min: 0,
        max: 100,
        strictMinMax: true,
        numberFormat: "#'%'",
        calculateTotals: true
      }));

      // Set data
      this.yAxis.data.setAll(this.getData());

      // Add series for Gujarat, Maharashtra, Rajasthan, and Investments
      this.createSeries("outsid", "Outside the in without sunlight", this.colors.utsidDataColor);
      this.createSeries("inside", "Inside the house without sunlight", this.colors.insideDataColor);
      this.createSeries("inside_", "Inside the house where sunrays are coming", this.colors.inside_DataColor);
      this.createSeries("outside_", "Outside the house under sunlight", this.colors.outside_DataColor); // New series for outside_

      // Add legend
      const legend = this.chart.children.push(am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      legend.data.setAll(this.chart.series.values);

      // Add cursor
      const cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
        behavior: "zoomY"
      }));
      cursor.lineX.set("forceHidden", true);
      cursor.lineY.set("forceHidden", true);

      // Animate chart
      this.chart.appear(1000, 100);
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  private getData() {
    return this.months.map((month, index) => ({
      month: month,
      outsid: this.outsidData[index] || 0,
      inside: this.insideData[index] || 0,
      inside_: this.inside_Data[index] || 0,
      outside_: this.outside_Data[index] || 0 // New field for outside_
    }));
  }

  private createSeries(field: string, name: string, color: string) {
    const series = this.chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: name,
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      valueXField: field,
      categoryYField: "month",
      sequencedInterpolation: true,
      tooltip: am5.Tooltip.new(this.root, {
        pointerOrientation: "horizontal",
        labelText: "[bold]{name}[/]\n{categoryY}: {valueX.formatNumber('0.0')}%"
      })
    }));

    series.columns.template.setAll({
      height: am5.p100,
      strokeOpacity: 0,
      fill: am5.color(color)
    });

    series.bullets.push(() => am5.Bullet.new(this.root, {
      locationX: 0.5,
      locationY: 0.5,
      sprite: am5.Label.new(this.root, {
        centerX: am5.p50,
        centerY: am5.p50,
        text: "{valueX.formatNumber('0.0')}%",
        populateText: true,
        fontSize: 12,
        fill: am5.color(0x000000)
      })
    }));

    series.data.setAll(this.getData());
    series.appear();
  }
}
