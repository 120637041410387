// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient-border {
    border-image-slice: 1;
    border-width: 2px;
    border-style: solid;
    border-image-source: linear-gradient(to left, #fff1f2, #fb7185);
  }
  
  .shadow-class{
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
  }
  .text-base{
    font-size: 1.05rem;
    font-weight: 400px;
    color: #393738;
  }
  .target-custom-class{
    font-size: 1rem;
    font-weight: 400px;
    color: #393738;
  }
  .achieve-custom-class{
    font-size: 1rem;
    font-weight: bold;
    color: #36b845;

  }
  `, "",{"version":3,"sources":["webpack://./src/app/dashboard/dashboard.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,+DAA+D;EACjE;;EAEA;IACE,qDAAqD;EACvD;EACA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;EAChB;EACA;IACE,eAAe;IACf,kBAAkB;IAClB,cAAc;EAChB;EACA;IACE,eAAe;IACf,iBAAiB;IACjB,cAAc;;EAEhB","sourcesContent":[".gradient-border {\r\n    border-image-slice: 1;\r\n    border-width: 2px;\r\n    border-style: solid;\r\n    border-image-source: linear-gradient(to left, #fff1f2, #fb7185);\r\n  }\r\n  \r\n  .shadow-class{\r\n    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;\r\n  }\r\n  .text-base{\r\n    font-size: 1.05rem;\r\n    font-weight: 400px;\r\n    color: #393738;\r\n  }\r\n  .target-custom-class{\r\n    font-size: 1rem;\r\n    font-weight: 400px;\r\n    color: #393738;\r\n  }\r\n  .achieve-custom-class{\r\n    font-size: 1rem;\r\n    font-weight: bold;\r\n    color: #36b845;\r\n\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
