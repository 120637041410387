import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuagemeterComponent } from '../guagemeter/guagemeter.component';
import { RouterOutlet, RouterModule } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-diarrhea-impact',
  standalone: true,
  imports: [CommonModule,GuagemeterComponent,RouterOutlet,RouterModule],
  templateUrl: './diarrhea-impact.component.html',
  styleUrl: './diarrhea-impact.component.css',
  animations: [
    trigger('routeAnimations', [
      transition('* <=> *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class DiarrheaImpactComponent {
  value3 = 85;
  value1 = 40;
  value2 = 70;
  cards: any[] = [
    { header: 'Household Reached', target: 1000, achieved: 200 },
    { header: 'Moms Reached', target: 1000, achieved: 200 },
    { header: 'Children Reached', target: 1000, achieved: 130 }
  ];

  constructor() { }

  ngOnInit(): void { }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
