import { Component } from '@angular/core';

@Component({
  selector: 'app-anc-impact',
  standalone: true,
  imports: [],
  templateUrl: './anc-impact.component.html',
  styleUrl: './anc-impact.component.css'
})
export class AncImpactComponent {

}
