import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environment/environment.local';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TargetService {
  private readonly endpoint = `${environment.apiUrl}targets`;
  targetVsAchievment = new BehaviorSubject([]);

  http: HttpClient = inject(HttpClient);

  getTargetVsAchivementMonthOnMonth(params: any) {
    console.log('params passed to the graph', params);
    let url = `${this.endpoint}/getTargetVsAchievement`;
    return this.http.post(url, params);
  }

  getTargetVsAchievementTable(params: any) {
    let url = `${this.endpoint}/getTargetVsAchievementTable`;
    return this.http.post(url, params);
  }
  getTargetVsAchievementDistrictWiseTable(params: any) {
    let url = `${this.endpoint}/getTargetVsAchievementDistrictWiseTable`;
    return this.http.post(url, params);
  }
  getTargetVsAchievementBlockWiseTable(params:any){
      let url = `${this.endpoint}/getTargetVsAchievementBlockWiseTable`;
      return this.http.post(url, params);
  }
}
