import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeTableModule } from 'primeng/treetable';
import { TreeNode } from 'primeng/api';
// import { ContentObserver } from '@angular/cdk/observers';
// import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
// import { MatTableDataSource, MatTableModule } from '@angular/material/table';
// import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
// import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-diarrhea-tree-table',
  standalone: true,
  imports: [CommonModule, TreeTableModule,],
  templateUrl: './diarrhea-tree-table.component.html',
  styleUrl: './diarrhea-tree-table.component.css'
})
export class DiarrheaTreeTableComponent implements OnInit {
  @Input() dataSource: TreeNode[] = [];
  @Input() cols: any[] = [];
  tableData: any = [];
  constructor(
  ) {
    this.tableData = this.dataSource;
  }
  ngOnInit(): void {
    this.tableData = this.dataSource;
  }
}

