import { Component, OnInit, Input, OnDestroy, AfterViewInit, Inject, PLATFORM_ID, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface SingleDataPoint {
  year: string;
  value: number;
  color?: string;
}

@Component({
  selector: 'app-single-column-chart-generated',
  standalone: true,
  imports: [CommonModule],
  template: '<div [id]="chartId" style="width: 100%; height: 350px;"></div>',
  styles: []
})
export class SingleColumnChartGeneratedComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() chartId: string = 'singleColumnChart';
  @Input() data: SingleDataPoint[] = [];
  @Input() legendName: string = 'Data Value'; // Input for legend name

  private chart?: am5xy.XYChart;
  private root?: am5.Root;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    console.log('SingleColumnChartGeneratedComponent ngOnInit called');
  }

  ngAfterViewInit(): void {
    console.log('SingleColumnChartGeneratedComponent ngAfterViewInit called');
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.createChart();
        this.cdr.detectChanges();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('SingleColumnChartGeneratedComponent ngOnChanges called', changes);
    if (isPlatformBrowser(this.platformId) && changes['data'] && !changes['data'].firstChange) {
      console.log('Data changed, updating chart');
      this.updateChartData();
    }
    if (changes['legendName'] && this.chart) {
      this.updateLegendName();
    }
  }

  private createChart() {
    console.log('Creating single column chart');
    if (!isPlatformBrowser(this.platformId)) return;

    const chartElement = document.getElementById(this.chartId);
    if (!chartElement) {
      console.error(`Element with id ${this.chartId} not found`);
      return;
    }

    this.root = am5.Root.new(this.chartId);
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.root._logo?.dispose();

    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        layout: this.root.verticalLayout
      })
    );

    // Create X-axis
    let xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: "year",
        renderer: am5xy.AxisRendererX.new(this.root, {
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          minGridDistance: 30
        }),
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 10,
      fontSize: 12
    });

    // Create Y-axis
    let yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {})
      })
    );

    // Create single series
    let singleSeries = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: this.legendName, // Use dynamic name
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "year",
        tooltip: am5.Tooltip.new(this.root, {
          labelText: "{name}: {valueY}"
        })
      })
    );

    singleSeries.columns.template.setAll({
      tooltipY: 0,
      tooltipText: "{name}: {valueY}",
      width: am5.percent(90),
      cornerRadiusTL: 5,
      cornerRadiusTR: 5
    });

    // Add legend
    let legend = this.chart.children.push(am5.Legend.new(this.root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: this.root.horizontalLayout
    }));

    legend.data.setAll(this.chart.series.values);

    // Set data
    this.updateChartData();

    // Make stuff animate on load
    this.chart.appear(1000, 100);
  }

  private updateLegendName(): void {
    if (this.chart) {
      const series = this.chart.series.values;
      if (series.length > 0) {
        series[0].set("name", this.legendName);
      }
    }
  }

  private updateChartData(): void {
    console.log('Updating single column chart data', this.data);
    if (!this.chart) {
      console.error('Chart not initialized');
      return;
    }

    if (!Array.isArray(this.data) || this.data.length === 0) {
      console.error('Invalid or empty data', this.data);
      return;
    }

    // Set data
    this.chart.xAxes.getIndex(0)?.data.setAll(this.data);
    this.chart.series.getIndex(0)?.data.setAll(this.data);

    // Set colors for the series if provided
    if (this.data[0]?.color) {
      this.chart.series.getIndex(0)?.set("fill", am5.color(this.data[0].color));
      this.chart.series.getIndex(0)?.set("stroke", am5.color(this.data[0].color));
    }
  }

  ngOnDestroy() {
    console.log('SingleColumnChartGeneratedComponent ngOnDestroy called');
    if (this.root) {
      this.root.dispose();
    }
  }
}
