import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../environment/environment.local"; // Corrected import

@Injectable({
  providedIn: "root"
})
export class BlockService {
  private readonly endpoint = `${environment.apiUrl}Blocks`;

  constructor(private http: HttpClient) {}

  getBlocks(params:any): Observable<any[]> {
    const httpParams = new HttpParams().set(
      'filter',
      JSON.stringify(params)
    );

    return this.http.get<any[]>(this.endpoint, { params: httpParams })
      .pipe(tap(response => response)); // Consider handling the response or any side effects
  }
}
