// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.tree-table {
    width: 100%; /* Full width */
}


.header-row, .body-row {
    display: flex;
}

.header-cell, .body-cell {
    flex: 1; /* Distribute space equally */
    padding: 8px; /* Add padding for readability */
    border: 1px solid #000000; /* Add borders */
}

.header-cell {
    font-size: 1.05rem !important;
    color: #fff9fc !important;
    background-color: #e787b7;
    font-weight: 400;
}
.body-cell {
        font-weight: 400;
        color: #393738 !important;
        background-color: #ffffff; 
}
.body-row:hover .body-cell {
    background-color: #dde0e2 ; /* Blue hover effect */

}
.header-cell::first-letter {
    text-transform: capitalize !important;
}

/* Increase width of first column and ensure text stays on a single line */
table > thead > tr > th:first-child,
table > tbody > tr > td:first-child {
    padding-right: 100px !important;
    white-space: nowrap; /* Prevent text wrapping */
    width: 150px !important; /* Increase the width as needed */
}
.header-cell, .body-cell{
    min-width: 100px;
}
table>tbody.p-treetable-tbody>tr>td:first-child {
    width: 500px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/diarrhea-tree-table/diarrhea-tree-table.component.css"],"names":[],"mappings":";;AAEA;IACI,WAAW,EAAE,eAAe;AAChC;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,OAAO,EAAE,6BAA6B;IACtC,YAAY,EAAE,gCAAgC;IAC9C,yBAAyB,EAAE,gBAAgB;AAC/C;;AAEA;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,yBAAyB;IACzB,gBAAgB;AACpB;AACA;QACQ,gBAAgB;QAChB,yBAAyB;QACzB,yBAAyB;AACjC;AACA;IACI,0BAA0B,EAAE,sBAAsB;;AAEtD;AACA;IACI,qCAAqC;AACzC;;AAEA,0EAA0E;AAC1E;;IAEI,+BAA+B;IAC/B,mBAAmB,EAAE,0BAA0B;IAC/C,uBAAuB,EAAE,iCAAiC;AAC9D;AACA;IACI,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":["\r\n\r\n.tree-table {\r\n    width: 100%; /* Full width */\r\n}\r\n\r\n\r\n.header-row, .body-row {\r\n    display: flex;\r\n}\r\n\r\n.header-cell, .body-cell {\r\n    flex: 1; /* Distribute space equally */\r\n    padding: 8px; /* Add padding for readability */\r\n    border: 1px solid #000000; /* Add borders */\r\n}\r\n\r\n.header-cell {\r\n    font-size: 1.05rem !important;\r\n    color: #fff9fc !important;\r\n    background-color: #e787b7;\r\n    font-weight: 400;\r\n}\r\n.body-cell {\r\n        font-weight: 400;\r\n        color: #393738 !important;\r\n        background-color: #ffffff; \r\n}\r\n.body-row:hover .body-cell {\r\n    background-color: #dde0e2 ; /* Blue hover effect */\r\n\r\n}\r\n.header-cell::first-letter {\r\n    text-transform: capitalize !important;\r\n}\r\n\r\n/* Increase width of first column and ensure text stays on a single line */\r\ntable > thead > tr > th:first-child,\r\ntable > tbody > tr > td:first-child {\r\n    padding-right: 100px !important;\r\n    white-space: nowrap; /* Prevent text wrapping */\r\n    width: 150px !important; /* Increase the width as needed */\r\n}\r\n.header-cell, .body-cell{\r\n    min-width: 100px;\r\n}\r\ntable>tbody.p-treetable-tbody>tr>td:first-child {\r\n    width: 500px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
