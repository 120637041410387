import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { AuthenticationService } from '../Services/authentication.service';
import { NgFor, CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, MatCardModule, NgFor, CommonModule, MatIconModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  dropdownOpenIndex: number | null = null;
  activeParentLink: string | null = null;
  isDropdownOpen = false;  // for login and log out 
  documentClickListener!: () => void;
  defaultSixpillarLabel: string = 'Six Pillars';
  selectedSixpillarLabel: string = this.defaultSixpillarLabel;
  isMenuOpen = false;
  private routerSubscription: Subscription | null = null;

  subHeaderLinks = [
    { label: 'Overview', url: '/app-new-dashboard' },
    { label: 'Child', url: '/child-diarrhoea-pneumonia' },
    { label: 'Mothers', url: '/mother-sixpillars-dashboard' },
    { label: 'Team Performance', url: '/team-tracker' },
    //{ label: 'Family Planning & Awareness', url: '/family-planningUsage-and-awareness' },
    { label: 'Community Interventions', url: '/community-intervention' },
    { label: 'Algorithms', url: '/algorithm' },
    // { label: 'Hygiene & Health', url: '/new-hygiene-and-health' },

    // { label: 'Funnel Chart', url: '/diarrhoea-funnel-chart' },
    // { label: 'Awareness Changes', url: '/awarenessChanges' },
    // {
    //   label: this.selectedSixpillarLabel,
    //   children: [
    //     { label: 'ANC', url: '/anc' },
    //     { label: 'Food & Health', url: '/food-health' },
    //     { label: 'Vector Borne Diseases', url: '/vectorBorneDiseases' },
    //     { label: 'Hygiene & Health', url: '/hygiene-health' },
    //     { label: 'Yoga & Mental Health', url: '/yoga-mental-health' },
    //     { label: 'Birth Spacing', url: '/birth-spacing' },
    //   ],
    // },
    // Team Tracker is dynamically added if the user is an admin
    // { label: 'Impact', url: '/impact' },
    // { label: 'Beneficiary Tracker', url: '/beneficiary-tracker' },
  ];

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.selectedSixpillarLabel = this.defaultSixpillarLabel;
  }

  ngOnInit(): void {
    this.checkForTeamTracker();
    this.initRouterListener();
    this.initClickOutsideListener();
  }

  private initRouterListener(): void {
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // this.updateActiveLink();
    });
  }

  // private updateActiveLink(): void {
  //   const currentUrl = this.router.url;
  //   const sixPillarsLink = this.subHeaderLinks.find(link => link.children);
    
  //   if (sixPillarsLink && sixPillarsLink.children) {
  //     const activeChild = sixPillarsLink.children.find(child => currentUrl.startsWith(child.url));
  //     if (activeChild) {
  //       this.selectedSixpillarLabel = activeChild.label;
  //     } else {
  //       this.selectedSixpillarLabel = this.defaultSixpillarLabel;
  //     }
  //   }

  //   if (!this.isSixPillarsActive()) {
  //     this.selectedSixpillarLabel = this.defaultSixpillarLabel;
  //   }
  // }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onChildRouteSelect(label: string) {
    this.selectedSixpillarLabel = label; // Update the selected label
    // Optionally, navigate to the selected route
    // this.router.navigate([url]);
    this.closeAllDropdowns();
  }

 /**
   * Initializes the listener to detect clicks outside the dropdown
   * to close it if clicked outside.
   */
  private initClickOutsideListener(): void {
    this.documentClickListener = this.renderer.listen('document', 'click', (event: Event) => {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        this.closeAllDropdowns();
      }
    });
  }

 /**
   * Removes the click listener to prevent memory leaks.
   */
  private removeClickOutsideListener(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }

 /**
   * Checks if the logged-in user is an admin and adds
   * 'Team Tracker' link to subHeaderLinks if true.
   */
  private checkForTeamTracker(): void {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const parsedData = JSON.parse(currentUser);
      const username = parsedData?.user?.username || 'No username';
      const password = parsedData?.user?.viewPassword || 'No password';

      // if (username === 'admin' && password === 'admin') {
      //   this.subHeaderLinks.splice(3, 0, { label: 'Team Tracker', url: '/team-tracker' });
      // } else {
      //   console.log('Not an admin user');
      // }
    }
  }

 /**
   * Navigates to the specified URL.
   * @param url - The target URL
   */
  navigateTo(url: string): void {
    this.router.navigate([url]);
  }
   /**
   * Toggles the dropdown for a specified index.
   * @param index - Index of the dropdown
   */

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  
  openDropdown(index: number): void {
    if (this.dropdownOpenIndex === index) {
      this.closeAllDropdowns();
    } else {
      this.closeAllDropdowns();
      this.dropdownOpenIndex = index;
    }
  }
  
  closeAllDropdowns(): void {
    this.dropdownOpenIndex = null;
  }
  
  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  
  ngOnDestroy(): void {
    this.removeClickOutsideListener();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  isSixPillarsActive(): boolean {
    const currentUrl = this.router.url;
    // const sixPillarsLink = this.subHeaderLinks.find(link => link.children);
    // if (sixPillarsLink && sixPillarsLink.children) {
    //   return sixPillarsLink.children.some(child => currentUrl.startsWith(child.url));
    // }
    return false;
  }
}