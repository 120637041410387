import { CommonModule } from '@angular/common';
import { GuagemeterComponent } from '../../guagemeter/guagemeter.component';
import { Component } from '@angular/core';
interface Indicator {
  name: string;
  description: string;
  value: number;
  measurement: string;
}

interface ControlPoint {
  title: string;
  indicators: Indicator[][];
}
interface impactIndicator {
  name: string;
  description: string;
  value: number;
  change: number;
  measurement: string;
}
interface controlChangePoints {
  title: string;
  indicators: impactIndicator[][];
}
@Component({
  selector: 'app-diarrhea-impact-engaged',
  standalone: true,
  imports: [CommonModule, GuagemeterComponent],
  templateUrl: './diarrhea-impact-engaged.component.html',
  styleUrl: './diarrhea-impact-engaged.component.css'
})
export class DiarrheaImpactEngagedComponent {
  value3 = 85;
  value1 = 40;
  value2 = 70;
  cards: any[] = [
    { header: 'Household Reached', target: 1000, achieved: 200 },
    { header: 'Moms Reached', target: 1000, achieved: 200 },
    { header: 'Children Reached', target: 1000, achieved: 130 }
  ];
  controlPoints: ControlPoint[] = [
    {
      title: '1. Fluid replacement to prevent dehydration',
      indicators: [
        [
          {
            name: 'ORS Awareness',
            description: '% households aware that ORS should be given in diarrhoea',
            value: 65,
            measurement: 'Total "Give ORS" responses'
          },
          {
            name: 'ORS Usage',
            description: '% households who have used ORS in diarrhoea',
            value: 58,
            measurement: 'Total "ORS powder" responses'
          }
        ]
      ]
    },
    {
      title: '2. Zinc treatment',
      indicators: [
        [
          {
            name: 'Zinc Awareness',
            description: '% households aware of Zinc for diarrhoea treatment',
            value: 42,
            measurement: 'Total "Zinc tablet/syrup" responses'
          },
          {
            name: 'Zinc Usage',
            description: '% households who have used Zinc in diarrhoea',
            value: 35,
            measurement: 'Total "Zinc tablets" or "Zinc syrup" responses'
          }
        ]
      ]
    },
    {
      title: '3. Rotavirus and measles vaccinations',
      indicators: [
        [
          {
            name: 'Rotavirus Awareness',
            description: '% women aware of rotavirus vaccine for diarrhoea prevention',
            value: 38,
            measurement: 'Total "Rotavirus vaccination" responses'
          },
          {
            name: 'Measles Vaccination',
            description: '% children vaccinated against measles',
            value: 82,
            measurement: 'Total "Yes" responses for measles vaccination'
          },
          {
            name: 'Rotavirus Vaccination',
            description: '% children with complete rotavirus vaccination',
            value: 70,
            measurement: '"Yes" responses + Complete vaccination (3 doses)'
          }
        ]
      ]
    },
    {
      title: '4. Promotion of breastfeeding and vitamin A supplementation',
      indicators: [
        [
          {
            name: 'Early Breastfeeding Practice',
            description: '% women who practiced early breastfeeding',
            value: 62,
            measurement: 'Responses: "Within half an hour" and "minutes"'
          },
          {
            name: 'Early Breastfeeding Awareness',
            description: '% women aware of early breastfeeding',
            value: 70,
            measurement: 'Responses: "Within half an hour" and "minutes"'
          },
          {
            name: 'Exclusive Breastfeeding Practice',
            description: '% women practicing exclusive breastfeeding for 6 months',
            value: 55,
            measurement: 'Total "No" responses to other foods in first 6 months'
          },
          {
            name: 'Exclusive Breastfeeding Awareness',
            description: '% women aware of exclusive breastfeeding for diarrhoea prevention',
            value: 60,
            measurement: 'Responses: "Exclusive breastfeeding for first six months"'
          }
        ]
      ]
    },
    {
      title: '5. Promotion of handwashing with soap',
      indicators: [
        [
          {
            name: 'Handwashing Importance',
            description: '% households who think handwashing is important',
            value: 88,
            measurement: 'Total "Yes" responses to handwashing importance'
          },
          {
            name: 'Proper Handwashing',
            description: '% households using soap for handwashing',
            value: 72,
            measurement: 'Total "soap" responses for handwashing'
          }
        ]
      ]
    },
    {
      title: '6. Improved water supply quantity and quality',
      indicators: [
        [
          {
            name: 'Improved Water Supply',
            description: '% households with improved water supply',
            value: 78,
            measurement: 'Responses meeting improved water supply criteria'
          },
          {
            name: 'Basic Water Supply',
            description: '% households with basic water supply',
            value: 85,
            measurement: 'Responses meeting basic water supply criteria'
          },
          {
            name: 'Limited Water Supply',
            description: '% households with limited water supply',
            value: 10,
            measurement: 'Responses meeting limited water supply criteria'
          },
          {
            name: 'Other than Improved',
            description: '% households with water from non-improved sources',
            value: 7,
            measurement: 'Responses indicating non-improved water sources'
          },
          {
            name: 'Safe Water Storage',
            description: '% households practicing safe water storage',
            value: 65,
            measurement: 'Responses meeting safe storage criteria'
          }
        ]
      ]
    },
    {
      title: '7. Community-wide sanitation promotion',
      indicators: [
        [
          {
            name: 'Toilet Facility Usage',
            description: '% households using toilet facilities',
            value: 82,
            measurement: 'Sum of all toilet facility options responses'
          },
          {
            name: 'Safe Child Feces Disposal',
            description: '% households practicing safe disposal of child feces',
            value: 68,
            measurement: 'Sum of safe disposal method responses'
          }
        ]
      ]
    }
  ];
  controlChangePoints: controlChangePoints[] = [
    {
      title: '1. Fluid replacement to prevent dehydration',
      indicators: [
        [
          {
            name: 'ORS Awareness',
            description: '% households aware that ORS should be given in diarrhoea',
            value: 68,
            change: 3,
            measurement: 'Total "Give ORS" responses'
          },
          {
            name: 'ORS Usage',
            description: '% households who have used ORS in diarrhoea',
            value: 61,
            change: 3,
            measurement: 'Total "ORS powder" responses'
          }
        ]
      ]
    },
    {
      title: '2. Zinc treatment',
      indicators: [
        [
          {
            name: 'Zinc Awareness',
            description: '% households aware of Zinc for diarrhoea treatment',
            value: 45,
            change: 3,
            measurement: 'Total "Zinc tablet/syrup" responses'
          },
          {
            name: 'Zinc Usage',
            description: '% households who have used Zinc in diarrhoea',
            value: 40,
            change: 5,
            measurement: 'Total "Zinc tablets" or "Zinc syrup" responses'
          }
        ]
      ]
    },
    {
      title: '3. Rotavirus and measles vaccinations',
      indicators: [
        [
          {
            name: 'Rotavirus Awareness',
            description: '% women aware of rotavirus vaccine for diarrhoea prevention',
            value: 41,
            change: 3,
            measurement: 'Total "Rotavirus vaccination" responses'
          },
          {
            name: 'Measles Vaccination',
            description: '% children vaccinated against measles',
            value: 85,
            change: 3,
            measurement: 'Total "Yes" responses for measles vaccination'
          },
          {
            name: 'Rotavirus Vaccination',
            description: '% children with complete rotavirus vaccination',
            value: 73,
            change: 3,
            measurement: '"Yes" responses + Complete vaccination (3 doses)'
          }
        ]
      ]
    },
    {
      title: '4. Promotion of breastfeeding and vitamin A supplementation',
      indicators: [
        [
          {
            name: 'Early Breastfeeding Practice',
            description: '% women who practiced early breastfeeding',
            value: 65,
            change: 3,
            measurement: 'Responses: "Within half an hour" and "minutes"'
          },
          {
            name: 'Early Breastfeeding Awareness',
            description: '% women aware of early breastfeeding',
            value: 73,
            change: 3,
            measurement: 'Responses: "Within half an hour" and "minutes"'
          },
          {
            name: 'Exclusive Breastfeeding Practice',
            description: '% women practicing exclusive breastfeeding for 6 months',
            value: 58,
            change: 3,
            measurement: 'Total "No" responses to other foods in first 6 months'
          },
          {
            name: 'Exclusive Breastfeeding Awareness',
            description: '% women aware of exclusive breastfeeding for diarrhoea prevention',
            value: 63,
            change: 3,
            measurement: 'Responses: "Exclusive breastfeeding for first six months"'
          }
        ]
      ]
    },
    {
      title: '5. Promotion of handwashing with soap',
      indicators: [
        [
          {
            name: 'Handwashing Importance',
            description: '% households who think handwashing is important',
            value: 91,
            change: 3,
            measurement: 'Total "Yes" responses to handwashing importance'
          },
          {
            name: 'Proper Handwashing',
            description: '% households using soap for handwashing',
            value: 82,
            change: 10,
            measurement: 'Total "soap" responses for handwashing'
          }
        ]
      ]
    },
    {
      title: '6. Improved water supply quantity and quality',
      indicators: [
        [
          {
            name: 'Improved Water Supply',
            description: '% households with improved water supply',
            value: 83,
            change: 5,
            measurement: 'Responses meeting improved water supply criteria'
          },
          {
            name: 'Basic Water Supply',
            description: '% households with basic water supply',
            value: 80,
            change: -5,
            measurement: 'Responses meeting basic water supply criteria'
          },
          {
            name: 'Limited Water Supply',
            description: '% households with limited water supply',
            value: 40,
            change: 30,
            measurement: 'Responses meeting limited water supply criteria'
          },
          {
            name: 'Other than Improved',
            description: '% households with water from non-improved sources',
            value: 75,
            change: 5,
            measurement: 'Responses indicating non-improved water sources'
          },
          {
            name: 'Safe Water Storage',
            description: '% households practicing safe water storage',
            value: 75,
            change: 10,
            measurement: 'Responses meeting safe storage criteria'
          }
        ]
      ]
    },
    {
      title: '7. Community-wide sanitation promotion',
      indicators: [
        [
          {
            name: 'Toilet Facility Usage',
            description: '% households using toilet facilities',
            value: 92,
            change: 10,
            measurement: 'Sum of all toilet facility options responses'
          },
          {
            name: 'Safe Child Feces Disposal',
            description: '% households practicing safe disposal of child feces',
            value: 71,
            change: +3,
            measurement: 'Sum of safe disposal method responses'
          }
        ]
      ]
    }
  ];
  constructor() { }

  ngOnInit(): void { }

  generateGridClass(itemCount: number): string {
    const baseClass = 'grid gap-4 ';
    switch (itemCount) {
      case 1:
        return baseClass + 'grid-cols-1';
      case 2:
        return baseClass + 'grid-cols-1 md:grid-cols-2';
      case 3:
        return baseClass + 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
      case 4:
        return baseClass + 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4';
      default: // 5 or more
        return baseClass + 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5';
    }
  }
}
