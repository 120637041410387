import { Component, OnInit, OnDestroy, AfterViewInit, Inject, PLATFORM_ID, Input } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// chart-data.model.ts
export interface ChartData {
  months: string[];
  gujaratData: number[];
  maharashtraData: number[];
  rajasthanData: number[];
  targetValueGujarat?: number | null;
  targetValueMaharashtra?: number | null;
  targetValueRajasthan?: number | null;
  colors?: {
    incomeColor: string;
    expensesColor: string;
    savingsColor: string;
  };
}

@Component({
  selector: 'app-multiple-stack-column-withline',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './multiple-stack-column-withline.component.html',
  styleUrls: ['./multiple-stack-column-withline.component.css']
})
export class MultipleStackColumnWithlineComponent implements OnInit, AfterViewInit, OnDestroy {
  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  private yAxis!: am5xy.ValueAxis<am5xy.AxisRenderer>;
  private xAxis!: am5xy.CategoryAxis<am5xy.AxisRenderer>;

  @Input() chartId!: string;
  @Input() chartData: ChartData | null = null; // Allow null

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    // Chart will be initialized in ngAfterViewInit

  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.initializeChart();
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  private initializeChart(): void {
    this.root = am5.Root.new(this.chartId);
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      panX: false,
      panY: false,
      wheelX: "zoomX",
      wheelY: "panY",
      paddingTop: 0,
      paddingBottom: 0,
      layout: this.root.verticalLayout
    }));

    this.createAxes();
    this.createSeries();
    this.addLegend();
    this.addCursor();

    this.chart.appear(1000, 100);
  }

  private createAxes(): void {
    this.xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
      categoryField: "year",
      renderer: am5xy.AxisRendererX.new(this.root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minorGridEnabled: true
      })
    }));

    this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: am5xy.AxisRendererY.new(this.root, {
        strokeOpacity: 0.1,
        minGridDistance: 50,
        opposite: false,
      }),
      min: 0,
      max: 110,
      strictMinMax: true,
      numberFormat: "#'%'",
      calculateTotals: true
    }));

    this.yAxis.get("renderer").labels.template.setAll({
      paddingTop: 0,
      paddingBottom: 0,
      centerY: am5.p50
    });

    // Ensure chartData is not null before accessing it
    if (this.chartData) {
      this.xAxis.data.setAll(this.getData());
    }
  }

  private createSeries(): void {
    // Ensure chartData is not null before accessing it
    if (this.chartData) {
      this.createSeriesForState("income", "Gujarat", this.chartData.colors?.incomeColor || '#0000FF');
      this.createSeriesForState("expenses", "Maharashtra", this.chartData.colors?.expensesColor || '#FF0000');
      this.createSeriesForState("savings", "Rajasthan", this.chartData.colors?.savingsColor || '#00FF00');
    }
  }

  private createSeriesForState(field: string, name: string, color: string): void {
    const series = this.chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: name,
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      valueYField: field,
      categoryXField: "year",
      sequencedInterpolation: true,
      tooltip: am5.Tooltip.new(this.root, {
        pointerOrientation: "vertical",
        labelText: "[bold]{name}[/]\n{categoryX}: {valueY.formatNumber('0.0')}%"
      })
    }));

    series.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      fill: am5.color(color)
    });

    series.bullets.push(() => am5.Bullet.new(this.root, {
      locationX: 0.5,
      locationY: 0.5,
      sprite: am5.Label.new(this.root, {
        centerX: am5.p50,
        centerY: am5.p50,
        text: "{valueY.formatNumber('0.0')}%",
        populateText: true,
        fontSize: 12,
        fill: am5.color(0x000000)
      })
    }));

    series.data.setAll(this.getData());
    series.appear();
  }

  private addLegend(): void {
    const legend = this.chart.children.push(am5.Legend.new(this.root, {
      centerX: am5.p50,
      x: am5.p50
    }));

    legend.data.setAll(this.chart.series.values.filter(series => !series.get("name")?.includes("Target")));
  }

  private addCursor(): void {
    const cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
      behavior: "zoomX"
    }));
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);
  }

  private getData() {
    // Check if chartData is null or has no months
    if (!this.chartData) {
      return []; // Return an empty array if chartData is null
    }
  
    const { months, gujaratData = [], maharashtraData = [], rajasthanData = [], targetValueGujarat = null, targetValueMaharashtra = null, targetValueRajasthan = null } = this.chartData;
  
    // Proceed only if months is available
    if (!months.length) {
      return []; // Return an empty array if months is empty
    }
  
    const data = months.map((month, index) => ({
      year: month,
      income: Math.min(this.calculatePercentage(gujaratData[index], targetValueGujarat), 100),
      expenses: Math.min(this.calculatePercentage(maharashtraData[index], targetValueMaharashtra), 100),
      savings: Math.min(this.calculatePercentage(rajasthanData[index], targetValueRajasthan), 100),
    }));
  
    console.log('Transformed Chart Data:', data); // Debugging statement
    return data;
  }
  
  private calculatePercentage(value: number, target: number | null | undefined): number {
    return target ? (value / target) * 100 : 0;
  }
}
