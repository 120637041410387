import { Component } from '@angular/core';

@Component({
  selector: 'app-hygiene-health-impact',
  standalone: true,
  imports: [],
  templateUrl: './hygiene-health-impact.component.html',
  styleUrl: './hygiene-health-impact.component.css'
})
export class HygieneHealthImpactComponent {

}
