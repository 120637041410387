import { Component, OnInit, NgZone, AfterViewInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { FilterComponent } from '../filter/filter.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BirthSpacingService } from '../Services/birth-spacing.service';
import { TreeNode } from 'primeng/api';
import { BirthSpaceTreeComponent } from '../components/birth-space-tree/birth-space-tree.component';
import { ContentObserver } from '@angular/cdk/observers';

interface IndicatorData {
  name: string;
  value: number;
 // description: string;
}

interface graphData {
  name: string;
  value: number;
  description: string;
}

interface TableData {
    firstName: string;
    age: number;
    mobile: string;
    beneficiaryStatus: string;
    fatherName: string;
    husbandName: string;

}

@Component({
  selector: 'app-birth-spacing',
  standalone: true,
  imports: [
    CommonModule,
    FilterComponent,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    BirthSpaceTreeComponent,
  ],
  templateUrl: './birth-spacing.component.html',
  styleUrl: './birth-spacing.component.css',
})
export class BirthSpacingComponent {
   //hide anc table from mother-six-pillar-dashboard
   @Input() showAndHideTablesOfBirthSpacing: boolean = true;
  isFilterVisible: boolean = false; // Variable to control visibility
  indicators: IndicatorData[] = [];
  currentUser: any;

  graphData: graphData[] = [
    {
      name: 'Birth Spacing Low Awareness',
      value: 0,
      description: ' Birth Spacing Low Awareness',
    },
    {
      name: 'Birth Spacing Counseling Done',
      value: 0,
      description: 'Birth Spacing Counseling Done',
    },
  ];

  tableData: TableData[] = [
    {
      firstName: 'Una',
      age: 30,
      mobile: '25',
      beneficiaryStatus: 'LM',
      fatherName: '5',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Veraval',
      age: 40,
      mobile: '20',
      beneficiaryStatus: 'LM',
      fatherName: '20',
      husbandName: 'Saroj Kumar',
    },
    {
      firstName: 'Girsomnath',
      age: 50,
      mobile: '30',
      beneficiaryStatus: 'LM',
      fatherName: '20',
      husbandName: 'Saroj Kumar',
    },
  ];

  dataSource: MatTableDataSource<TableData>;
  displayedColumns2: string[] = ['sn', 'name', 'age', 'fatherName', 'mobile'];
  dataSource2: MatTableDataSource<TableData> = new MatTableDataSource(
    this.tableData
  ); // Added dataSource2 initialization

  private root!: am5.Root;
  treeBirthSpacingCount: any = [];
  params = {};

  treeColumnsBirthSpacing = [
    // {
    //   field: 'Id',
    //   header: 'id',
    // },
    {
      field: 'name',
      header: 'Name',
    },
    {
      field: 'Birth_Spacing_Usage',
      header: 'Birth Spacing Usage',
    },
    {
      field: 'Eligible_Mothers',
      header: 'Eligible Mothers',
    },
    {
      field: 'Referral_for_Spacing',
      header: 'Referral for Spacing',
    },
    {
      field: 'MEC_Counsel',
      header: 'MEC Counsel',
    },
  ];
  stateId = [];
  districtId = [];
  blockId = [];
  villageId = [];

  constructor(
    private zone: NgZone,
    private birthspaceService: BirthSpacingService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngOnInit(): void {
    let allUser = localStorage.getItem('currentUser');

    if (allUser) {
      let currentUser: any = JSON.parse(allUser);

      if (currentUser.user.designation === 'SM') {
        this.stateId = currentUser.user['stateId'];
      } else if (currentUser.user.designation === 'DL') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
      } else if (currentUser.user.designation === 'BO') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
      } else if (currentUser.user.designation === 'CHW') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
        this.villageId = currentUser.user['villageId'];
      }
    }
    let currentUser = localStorage.getItem('currentUser');
    let parsedUser = currentUser ? JSON.parse(currentUser) : null;

    this.params = {
      state_Id: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      district_Id: Array.isArray(this.districtId)
        ? this.districtId
        : [this.districtId],
      block_Id: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      village_Id: Array.isArray(this.villageId)
        ? this.villageId
        : [this.villageId],
      userLevel: parsedUser?.user?.designation,
    };
    // this.dataSource2=this.tableData
    // this.dataSource2.paginator = this.paginator;
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUser = currentUserString ? JSON.parse(currentUserString) : null;
    this.birthSpacingData();
    this.getBirthSpacingTreeData(this.params);
    this.getBirthSpacingGraphData(this.params);
  }
  // Method to toggle the visibility of the filter component
  toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }

  birthSpacingData() {
    this.birthspaceService.getbirthSpacingData().subscribe(
      (res: any) => {
        this.indicators = res.data;
      },
      (err: any) => {
        console.log('error ossured', err);
      }
    );
  }

  updateChartData() {
    if (this.root) {
      const chart = this.root.container.children.getIndex(0) as am5xy.XYChart;

      if (chart) {
        const xAxis = chart.xAxes.getIndex(0);
        const series = chart.series.getIndex(0);

        if (xAxis && series) {
          xAxis.data.setAll(this.graphData);
          series.data.setAll(this.graphData);
        } else {
          console.log('Unable to update chart: xAxis or series not found.');
        }
      } else {
        console.log('Chart not found.');
      }
    }
  }

  getBirthSpacingTreeData(params: any) {
    this.birthspaceService.getBirthSpacingTreeData(params).subscribe(
      (res: any) => {
        this.treeBirthSpacingCount = this.transformData(res.data);
      },
      (err: any) => {
        console.log('error ossured', err);
      }
    );
  }

  getBirthSpacingGraphData(params: any) {
    this.birthspaceService.getBirthSpacingGraphData(params).subscribe(
      (res: any) => {
        this.graphData.forEach((graphItem) => {
          const matchingIndicator = res.data.find(
            (indicator: any) => indicator.name === graphItem.name
          );

          if (matchingIndicator) {
            graphItem.value = matchingIndicator.value;
          }
        });
        this.updateChartData();
      },
      (err: any) => {
        console.log('error occured', err);
      }
    );
  }

  transformData(input: any): TreeNode[] {
    const transform = (node: any, level: string, index: number): TreeNode => {
      const { parent, ...restNode } = node;

      const newNode: TreeNode = {
        key: `${level}-${index}`,
        data: { ...restNode },
        children: [],
      };

      if (node.districts) {
        newNode.children = node.districts.map((district: any, i: number) =>
          transform(district, 'district', i)
        );
      } else if (node.blocks) {
        newNode.children = node.blocks.map((block: any, i: number) =>
          transform(block, 'block', i)
        );
      } else if (node.chwList || node.chwlist) {
        if (node.chwList) {
          newNode.children = node.chwList.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        } else {
          newNode.children = node.chwlist.map((chw: any, i: number) =>
            transform(chw, 'chw', i)
          );
        }
      }

      return newNode;
    };

    const result = input.map((item: any, index: number) =>
      transform(item, 'state', index)
    );

    return result;
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.createChart();
    });
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  createChart() {
    this.root = am5.Root.new('chartdiv');
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );

    let cursor = chart.set('cursor', am5xy.XYCursor.new(this.root, {}));
    cursor.lineY.set('visible', false);

    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fontSize: 10,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0.3,
        categoryField: 'name',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(this.root, {
      strokeOpacity: 0.1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      })
    );

    let series = chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        sequencedInterpolation: true,
        categoryXField: 'name',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{valueY}',
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
    });
    series.columns.template.adapters.add('fill', (fill, target) => {
      const colors = chart.get('colors');
      return colors ? colors.getIndex(series.columns.indexOf(target)) : fill;
    });

    series.columns.template.adapters.add('stroke', (stroke, target) => {
      const colors = chart.get('colors');
      return colors ? colors.getIndex(series.columns.indexOf(target)) : stroke;
    });

    xAxis.data.setAll(this.graphData);
    series.data.setAll(this.graphData);

    series.appear(1000);
    chart.appear(1000, 100);
  }

  onFilterChange(filterValues: any): void {
    // Implement filter logic here
    let params: any = {
      stateId: filterValues.state_Id ? filterValues.state_Id : undefined,
      districtId: filterValues.district_Id
        ? filterValues.district_Id
        : undefined,
      blockId: filterValues.block_Id ? filterValues.block_Id : undefined,
      chwId: filterValues.chw_Id ? filterValues.chw_Id : undefined,
      userLevel: this.currentUser?.user.designation,
    };

    if (filterValues.from_date && filterValues.to_date) {
      params['fromDate'] = filterValues.from_date;
      params['toDate'] = filterValues.to_date;
    }

    this.getBirthSpacingTreeData(params);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  export(): void {
    // Implement export functionality
  }
}
