import { Component, OnInit, NgZone, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as am5 from "@amcharts/amcharts5/index";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

@Component({
  selector: 'app-custom-line-chart',
  standalone: true,
  imports: [],
  templateUrl: './custom-line-chart.component.html',
  styleUrl: './custom-line-chart.component.css'
})
export class CustomLineChartComponent {
  private root!: am5.Root;

  constructor(
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    // Run the chart creation outside Angular zone for better performance
    this.zone.runOutsideAngular(() => {
      this.createChart();
    });
  }

  createChart() {
    if (!isPlatformBrowser(this.platformId)) return;

    let root = am5.Root.new("beneficiaryTrackerChart");
    root.setThemes([am5themes_Animated.new(root)]);
  root._logo?.dispose();
  let chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout,
      pinchZoomX: false,
      paddingLeft: 0
    })
  );

  let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
    behavior: "none"
  }));
  cursor.lineY.set("visible", false);

  let data = [
    { date: "2024-01-10", value: 0 },
    { date: "2024-02-10", value: 31 },
    { date: "2024-03-17", value: 67 },
    { date: "2024-04-25", value: 106 },
    { date: "2024-05-12", value: 123 }
  ];

  let xRenderer = am5xy.AxisRendererX.new(root, {
    minorGridEnabled: true,
    minGridDistance: 70
  });
  xRenderer.grid.template.set("location", 0.5);
  xRenderer.labels.template.setAll({ location: 0.5, multiLocation: 0.5 });

  let xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: "day", count: 1 },
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {}),
      dateFormats: {
        day: "MMM dd",
        month: "MMM yyyy"
      }
    })
  );

  let yRenderer = am5xy.AxisRendererY.new(root, {});
  yRenderer.grid.template.set("forceHidden", true);
  yRenderer.labels.template.set("minPosition", 0.05);

  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      extraMin: 0.1,
      renderer: yRenderer
    })
  );
  
  // Add title to y-axis
  yAxis.children.unshift(
    am5.Label.new(root, {
      text: "Number of Days",
      rotation: -90,
      y: am5.p50,
      centerX: am5.p50,
      paddingTop: 20,
      paddingBottom: 20
    })
  );

  let series = chart.series.push(
    am5xy.LineSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      valueXField: "date",
      maskBullets: false,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "vertical",
        dy: -20,
        labelText: "{valueY} days"
      })
    })
  );

    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"]
    });

    series.strokes.template.setAll({
      strokeDasharray: [3, 3],
      strokeWidth: 2
    });


    let i = -1;
    series.bullets.push(function () {
      i++;

      if (i > 6) {
        i = 0;
      }

      let container = am5.Container.new(root, {
        centerX: am5.p50,
        centerY: am5.p50
      });

      container.children.push(
        am5.Circle.new(root, { radius: 20, fill: series.get("fill") })
      );

      container.children.push(
        am5.Picture.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          width: 23,
          height: 23,
          src: `/assets/lineColimage/linecol${i}.png`
        })
      );
      return am5.Bullet.new(root, {
        sprite: container
      });
    });
    series.data.setAll(data);
    series.appear(1000);

    this.root = root;
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.zone.runOutsideAngular(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }
}
