import { Component, OnInit, OnDestroy, Input, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';


@Component({
  selector: 'app-funnel-verticle-chart',
  standalone: true,
  imports: [CommonModule],
  template: '<div [id]="chartId" style="width: 100%; height: 600px;"></div>',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FunnelVerticleChartComponent implements OnInit, OnDestroy, OnChanges {
  private root: am5.Root | undefined;
  private series: am5percent.FunnelSeries | undefined;
  private exporting?: am5plugins_exporting.Exporting;


  @Input() chartId!: string;
  @Input() chartData: { value: number; category: string }[] = [];
  @Input() colors: string[] = [];
  @Output() itemClick = new EventEmitter<{ index: number }>();

  ngOnInit(): void {
    if (!this.chartId || !this.chartData || this.chartData.length === 0) {
      console.error('Chart ID is missing or chart data is empty.');
      return;
    }

    // Use setTimeout to ensure the DOM is ready
    setTimeout(() => {
      this.initChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.root && this.series) {
      if (changes['chartData']) {
        this.updateChartData();
      }
      if (changes['colors'] && !changes['colors'].firstChange) {
        this.updateColors();
      }
    }
  }

  private initChart(): void {
    // Dispose of the previous chart if it exists
    if (this.root) {
      this.root.dispose();
    }

    // Create root element
    this.root = am5.Root.new(this.chartId);

    // Disable amCharts logo
    this.root._logo?.dispose();

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Create chart
    let chart = this.root.container.children.push(
      am5percent.SlicedChart.new(this.root, {
        layout: this.root.verticalLayout,
      })
    );

    // Create series
    this.series = chart.series.push(
      am5percent.FunnelSeries.new(this.root, {
        alignLabels: false,
        orientation: 'vertical',
        valueField: 'value',
        categoryField: 'category',
      })
    );

    // Customize labels
    this.series.labels.template.setAll({
      text: '{category}: {value}',
    });

    // Customize tooltips
    this.series.slices.template.setAll({
      tooltipText: '{category}: {value}',
    });


   // Add click event listener
this.series.slices.template.events.on("click", (ev) => {
  const dataItem = ev.target.dataItem as am5.DataItem<am5percent.IFunnelSeriesDataItem>;
  if (dataItem) {
    const index = this.series?.dataItems.indexOf(dataItem);
    if (index !== undefined && index >= 0) {
      this.onItemClicked(index);
    }
  }
});


    // Set data and apply colors
    this.series.data.setAll(this.chartData);
    this.applyColors();

    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 15,
        marginBottom: 15,
      })
    );

    // Play initial series animation
    this.series.appear(1000, 100);

    // Initialize exporting
this.exporting = am5plugins_exporting.Exporting.new(this.root, {
  filePrefix: 'funnel-chart',
  dataSource: this.chartData,
  pdfOptions: {
    addURL: false,
    disabled: false,
  },
  csvOptions: {
    addColumnNames: true,
    separator: ',',
  },
});

// Create and set up the export menu
const exportMenu = am5plugins_exporting.ExportingMenu.new(this.root, {
  exporting: this.exporting, // Link the menu to the exporting instance
});

  }

  private applyColors(): void {
    if (!this.series || !this.colors || this.colors.length === 0) {
      return;
    }

    this.series.slices.template.setAll({
      fillGradient: undefined,
      strokeWidth: 0
    });

    this.chartData.forEach((_, index) => {
      const color = this.colors[index % this.colors.length];
      if (color) {
        const dataItem = this.series?.dataItems[index];
        if (dataItem) {
          dataItem.set("fill", am5.color(color));
        }
      }
    });
  }

  private updateChartData(): void {
    if (this.series) {
      this.series.data.setAll(this.chartData);
      this.applyColors();
    }
  }

  private updateColors(): void {
    this.applyColors();
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  onItemClicked(index: number): void {
    this.itemClick.emit({ index });
  }
}