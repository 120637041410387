import { Component, OnInit, AfterViewInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';


@Component({
  selector: 'app-cluster-community-intervention-stack-chart',
  standalone: true,
  templateUrl: './cluster-community-intervention-stack-chart.component.html',
  styleUrls: ['./cluster-community-intervention-stack-chart.component.css']
})
export class ClusterCommunityInterventionStackChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() data: any[] = [];  // Data to be passed from the parent component
  @Input() chartId: string = '';  // Chart ID to identify different charts
  @Input() seriesColors: string[] = [];  // Accepts an array of colors for series
  private exporting: am5plugins_exporting.Exporting | undefined;

  private root!: am5.Root;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data && this.data.length > 0) {
      this.initializeChart();
    }
  }

  ngAfterViewInit(): void {
    if (!this.data || this.data.length === 0 || !this.chartId) {
      console.error('Data or Chart ID is missing');
      return;
    }

    this.initializeChart();
  }

  initializeChart(): void {
    if (this.root) {
      this.root.dispose();  // Dispose of previous chart instance to prevent memory leaks
    }

    // Create root element
    this.root = am5.Root.new(this.chartId);

    // Set themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

     // Disable amCharts logo
     this.root._logo?.dispose();

    // Create chart
    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        paddingLeft: 0,
        layout: this.root.verticalLayout
      })
    );

    // Add legend with margin from left
    let legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerY: am5.p50,       // Vertically center the legend
        x: am5.p100,            // Position the legend at the right (100% of the width)
        centerX: am5.p100,      // Horizontally center it at the right edge
        marginLeft: 20,         // Optional: Add some space from the chart
        marginRight: 20,        // Optional: Add some space from the right edge
   
      })
    );

    // Customize font size of legend labels
    legend.labels.template.setAll({
      fontSize: 12 // Set the font size of the legend labels to a smaller value
    });

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: true
    });
    xRenderer.labels.template.setAll({
      // rotation: -45,  // Optional: rotate to fit more labels, adjust as needed
      centerY: am5.p50,
      centerX: am5.p50,
      fontSize: 12,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: "month",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );

    xRenderer.grid.template.setAll({ location: 1 });
    xAxis.data.setAll(this.data);  // Bind the data to the xAxis

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(this.root, { strokeOpacity: 0.1 })
      })
    );

    //reduced font size of y axis legents 
    yAxis.get("renderer").labels.template.setAll({
      fontSize: 10  
    });

    // Function to create series
    const makeSeries = (name: string, fieldName: string, stacked: boolean, color: string, isLegend: boolean) => {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          stacked,
          name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "month"
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        width: am5.percent(90),
        tooltipY: am5.percent(10),
        fill: am5.color(color)  // Apply the color to the series
      });

      series.data.setAll(this.data);  // Bind the data to the series
      series.appear();

      // Create bullets to show the value on the column
      series.bullets.push(() => {
        return am5.Bullet.new(this.root, {
          locationY: 0.5,
          sprite: am5.Label.new(this.root, {
            text: "{valueY}",
            fill: this.root.interfaceColors.get("alternativeText"),
            centerY: am5.percent(50),
            centerX: am5.percent(50),
            populateText: true,
            fontSize: "0.8rem" // Reduced font size for bullet values
          })
        });
      });

      // Add the series to the legend only if `isLegend` is true
      if (isLegend) {
        legend.data.push(series);
      }
    };

    // Check if seriesColors array is provided, else use default colors
    const defaultColors = [
      "#00B5E2", "#F4A261", "#2A9D8F", "#E76F51", "#264653"
    ];

    const colors = this.seriesColors.length > 0 ? this.seriesColors : defaultColors;
    
     // Initialize exporting
     this.exporting = am5plugins_exporting.Exporting.new(this.root, {
      filePrefix: "funnel-chart",
      dataSource: this.data,
      pdfOptions: {
        addURL: false,
        disabled: false
      },
      csvOptions: {
        addColumnNames: true,
        separator: ",",
      },
    });

    // Create and set up the export menu
    const exportMenu = am5plugins_exporting.ExportingMenu.new(this.root, {
      exporting: this.exporting, // Link the menu to the exporting instance
    });

    // Create series for first three data points to show in the legend
    makeSeries("Total Child Maharashtra", "Total Child Maharashtra", false, colors[0], true);
    makeSeries("Total Female Maharashtra", "Total Female Maharashtra", true, colors[1], true);
    makeSeries("Total Male Maharashtra", "Total Male Maharashtra", true, colors[2], true);

    // Create remaining series without adding them to the legend
    makeSeries("Total Child Rajasthan", "Total Child Rajasthan", false, colors[0], true);
    makeSeries("Total Female Rajasthan", "Total Female Rajasthan", true, colors[1], true);
    makeSeries("Total Male Rajasthan", "Total Male Rajasthan", true, colors[2], true);

    makeSeries("Total Child Gujrat", "Total Child Gujrat", false, colors[0], true);
    makeSeries("Total Female Gujrat", "Total Female Gujrat", true, colors[1], true);
    makeSeries("Total Male Gujrat", "Total Male Gujrat", true, colors[2], true);

    chart.appear(1000, 100);
}



  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
