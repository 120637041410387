import { Component } from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {FormControl} from '@angular/forms'

@Component({
  selector: 'app-user-request-form',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatSelectModule,MatButtonModule, MatDividerModule, MatIconModule,ReactiveFormsModule,],
  templateUrl: './user-request-form.component.html',
  styleUrl: './user-request-form.component.css'
})
export class UserRequestFormComponent {

  profileForm = new FormGroup({
    userName: new FormControl(''),
    phoneNumber: new FormControl(''),
  });

  onSubmit(){
    console.warn(this.profileForm.value);
  }
}
