import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { CustomLineChartComponent } from '../custom-line-chart/custom-line-chart.component';
import { StackedColumnChartComponent } from '../stacked-column-chart/stacked-column-chart.component';
import { PieChartComponent } from '../pie-chart/pie-chart.component';
import { TreeTableComponent } from '../tree-table/tree-table.component';
import { TreeTableDataService } from '../Services/tree-data.service';
import { TreeNode } from 'primeng/api';
import { DiarrheaSelfCareService } from '../Services/diarrhea-self-care.service';
import { FilterComponent } from "../filter/filter.component";
import { DiarrheaTreeTableComponent } from '../components/diarrhea-tree-table/diarrhea-tree-table.component';
import { DiarrheaCountTreeTableComponent } from '../components/diarrhea-count-tree-table/diarrhea-count-tree-table.component';
import { StepChartComponent } from '../step-chart/step-chart.component';
import { DonutChartComponent } from '../donut-chart/donut-chart.component';
import { OverviewDashboardService } from '../Services/overview-dashboard.service'
// Interfaces
import { DiarrhoeaFirstPoint, DiarrhoeaAwareness, Dummy_data_Indicators, ChartDataPoint, BeneficiaryData, TileTopStat, TileCount, TileTopStats, StackedDataPoint } from './diarrhea.interfaces';
import { FunnelVerticleChartComponent } from '../funnel-verticle-chart/funnel-verticle-chart.component';
import { NewOverviewService } from '../Services/new-OverviewService/new-overview.service';
import { DonutChartGeneratorComponent } from "../donut-chart-generator/donut-chart-generator.component";

@Component({
  selector: 'app-diarrhea',
  standalone: true,
  imports: [
    CommonModule,
    TreeTableComponent,
    CustomLineChartComponent,
    PieChartComponent,
    StackedColumnChartComponent,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    FilterComponent,
    DiarrheaTreeTableComponent,
    DiarrheaCountTreeTableComponent,
    DonutChartComponent,
    StepChartComponent,
    FunnelVerticleChartComponent,
    DonutChartGeneratorComponent,
  ],
  templateUrl: './diarrhea.component.html',
  styleUrls: ['./diarrhea.component.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DiarrheaComponent implements OnInit, AfterViewInit {
  // Input to control visibility of tables
  @Input() showAndHideTablesOfDiarrhoea: boolean = true;

  isFilterVisible: boolean = false; // Variable to control visibility
  followUpStatuTopValue: number = 55;
  dummyDataIndicators: Dummy_data_Indicators[] = [];
  dummyDataIndicators2: Dummy_data_Indicators[] = [];
  dummyDataIndicators3: Dummy_data_Indicators[] = [];
  awarenessData: DiarrhoeaFirstPoint[] = [];
  otherIndicatorsData: DiarrhoeaAwareness[] = [];
  dataForChartOfDiarrhoea: any= [];
  variableToStoreDiarrhoea0IndexLabel: any; // Change the type as per your requirement
  variableToStoreDiarrhoea0IndexCount: any; // Change the type as per your requirement
  colorsForDiarrhoeaFunnelChart: string[] = ['#ADD8E6', '#FFD700', '#98FB98', '#D3D3D3'];
  chartColors = ['#d2122e', '#568203', '#ffc72c', '#662d91', '#0000ff'];
  

  isLoadingforDiarrhoea = true;
  isBifurcationDiarrhoeaLoading: boolean = true;
  
  treeColumns = [
    { field: 'name', header: 'Name' },
    { field: 'totalDiarrheaCases', header: 'Diarrhea Cases' },
    { field: 'totalTreatedAtHome', header: 'Home management' },
    { field: 'totalTreatedAtFacility', header: 'Referred to Facility' },
    { field: 'totalAdmittedToGeneral', header: 'Admitted To General' },
    { field: 'totalAdmittedToNICU', header: 'Admitted To NICU' },
    { field: 'totalRepeatDiarrhea', header: 'Repeated Case' },
    // { field: 'totalDeathsLT0_2', header: 'Child 0-2' },
    // { field: 'totalDeathsLT2_6', header: 'Child 2-6' },
  ];

  treeColumnsDiarrhea = [
    { field: 'name', header: 'Name' },
    { field: 'no_dehydration', header: 'No Dehydration' },
    { field: 'some_dehydration', header: 'Some Dehydration' },
    { field: 'persistent_diarrhoea', header: 'Persistent Diarrhoea' },
    { field: 'severe_diarrhoea', header: 'Severe Diarrhoea' },
    { field: 'blood_in_stool', header: 'Blood In Stool' },
  ];

  stateId: any[] = [];
  districtId: any[] = [];
  blockId: any[] = [];
  villageId: any[] = [];
  chwId: any[] = [];
  params: any = {};

  colorla: string = '#77E4C8';
  colorcd: string = '#63aaf5';

  tileTopStats: TileTopStats = {
    tileTopStats: [],
    tileCounts: [],
  };
  treeTableDiarrhea: any = [];
  treeDiarrheaCount: any = [];
  currentUser: any;

  chartData2: ChartDataPoint[] = [];
  stackedChartData: StackedDataPoint[] = [];
  chartData: any[] = [];
  firstChartData = [
    { country: 'Yes', sales: 501.9 },
    { country: 'No', sales: 301.9 },
  ];
  donutChartData: any[] = [];
  otherIndicatorData: any[] = [];
  displayedColumns2: string[] = ['sn', 'name', 'age', 'fatherName', 'mobile'];
  dataSource2!: MatTableDataSource<BeneficiaryData>;
  fullyRecoveredDiarrhoeaLabel:any
  fullyRecoveredDiarrhoeaCount:any

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  treeTableData: TreeNode[] = [];
  treeTableCols: any[] = [];
  isLoading: boolean = false;
  error: string | null = null;

  constructor(
    private router: Router,
    private treeTableDataService: TreeTableDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private diarrheaService: DiarrheaSelfCareService,
    private cdr: ChangeDetectorRef,
    private overviewDashboardService: OverviewDashboardService,
    private newOverviewService: NewOverviewService
  ) {}

  ngOnInit(): void {
    this.initializeComponent();
     /** this overviewDashboardService is responsible when i 
 *  click on get data button of 
 *  filter componnet then params on  will be
 *  pass in this function  "function Filter"
 *  BST 18-Nov-2024
**/
this.overviewDashboardService.params$.subscribe((params) => {
    this.params = params; 
    if (params) {
    this.functionFilterFromDiarrhoea(params);
       }
});

  }

  ngAfterViewInit(): void {
    if (this.dataSource2) {
      this.dataSource2.sort = this.sort;
      this.dataSource2.paginator = this.paginator;
    }
  }
  // Method to toggle the visibility of the filter component
  toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }
  private initializeComponent(): void {
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUser = currentUserString ? JSON.parse(currentUserString) : null;

    this.getDataFromLocalStorage();
    this.lowAwarenessVsCouncil();
    this.initializeChartData();
    this.loadTreeTableData();
    this.getDiarrheaOtherIndicator();
    this.getDiarrhoeaData();
  }

  private initializeChartData(): void {
    this.chartData = [
      { country: 'Vaccination completion', value: 443 },
      { country: 'Early Breastfeeding', value: 414 },
      { country: 'Exclusive Breastfeeding', value: 385 },
      { country: 'Clean Indoor fuel', value: 280 },
      { country: 'Hand Hygiene', value: 435 },
      { country: 'Safe sanitation/ open defecation', value: 353 },
      { country: 'Safe and Clean Water', value: 441 },
      { country: 'Awareness child feces disposal', value: 287 },
      { country: 'IEC dissemination', value: 665 },
    ];
  }

  private loadTreeTableData(): void {
    this.treeTableDataService.getTreeTableData().subscribe((data) => {
      this.treeTableData = data;
    });

    this.treeTableDataService.getTreeTableCols().subscribe((cols) => {
      this.treeTableCols = cols;
    });
  }

  getDataFromLocalStorage(): void {
    const allUser = localStorage.getItem('currentUser');

    if (allUser) {
      const currentUser: any = JSON.parse(allUser);

      switch (currentUser.user.designation) {
        case 'SM':
          this.stateId = currentUser.user['stateId'];
          break;
        case 'DL':
          this.stateId = currentUser.user['stateId'];
          this.districtId = currentUser.user['districtId'];
          break;
        case 'BO':
          this.stateId = currentUser.user['stateId'];
          this.districtId = currentUser.user['districtId'];
          this.blockId = currentUser.user['blockId'];
          break;
        case 'CHW':
          this.stateId = currentUser.user['stateId'];
          this.districtId = currentUser.user['districtId'];
          this.blockId = currentUser.user['blockId'];
          this.villageId = currentUser.user['villageId'];
          this.chwId = currentUser.user['chwId'];
          break;
      }
    }

    this.params = {
      stateId: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      districtId: Array.isArray(this.districtId)
        ? this.districtId
        : [this.districtId],
      blockId: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      villageId: Array.isArray(this.villageId)
        ? this.villageId
        : [this.villageId],
      chwId: Array.isArray(this.chwId) ? this.chwId : [this.chwId],
      userLevel: this.currentUser.user.designation,
    };

    this.getDiarrheaBlockData(this.params);
    this.getDiarrheaHierarchyData(this.params);
    this.getAllData(this.params);
  }

  lowAwarenessVsCouncil(): void {
    this.diarrheaService.lowAwarenessVsCouncil().subscribe(
      (res: any) => {
        if (res && res.data) {
          this.stackedChartData = res.data;

          // Check if the data is in the correct format
          if (
            Array.isArray(this.stackedChartData) &&
            this.stackedChartData.length > 0
          ) {
          } else {
            console.error('Stacked Chart Data is not in the expected format');
          }

          // Force change detection
          this.changeDetectorRef.detectChanges();
        } else {
          console.error('API response does not contain expected data');
        }
      },
      (err: any) => {
        console.error('Error occurred in lowAwarenessVsCouncil:', err);
        this.error = 'Failed to load awareness vs council data';
        // Force change detection
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  getDiarrheaCasesBifurcation(params: any = this.params): void {
    this.isBifurcationDiarrhoeaLoading = true;  // Set loading to true before making API call
  
    this.diarrheaService.getDiarrheaCasesBifurcation(params).subscribe(
      (res: any) => {
        console.log('API Response:', res.data.diarrheaCasesCount); // Log the API response for debugging
        this.donutChartData = this.transformDonutChartData(
          res.data.diarrheaCasesCount
        );
        this.isBifurcationDiarrhoeaLoading = false;  // Set loading to false once data is received
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  getDiarrheaOtherIndicator(params: any = this.params): void {
    this.overviewDashboardService.getOtherIndicatorData().subscribe(
      (res: any) => {
        console.log('Received data:', res);
        const data = this.transformOtherIndicatorChartData(res);
        this.otherIndicatorData = data;
        this.changeDetectorRef.detectChanges();
      },
      (err: any) => {
        console.error('Error occurred in getDiarrheaOtherIndicator:', err);
        this.error = 'Failed to load other indicator data';
      }
    );
  }

  transformOtherIndicatorChartData(data: any): any[] {
    const categories = [
      {
        key: 'vaccinationStats',
        name: 'Vaccination',
        percentageKey: 'percentageVaccinatedUnder12Months',
      },
      {
        key: 'breastfeedingStats',
        name: 'Early Breastfeeding',
        percentageKey: 'percentageBreastfeedingIn1HrUnder6Months',
      },
      {
        key: 'breastfeedingContinueStats',
        name: 'Exclusive Breastfeeding',
        percentageKey: 'percentageBreastfeedingContinueUnder6Months',
      },
      {
        key: 'defectedInOpenStats',
        name: 'Safe sanitation/ open defecation',
        percentageKey: 'percentageDefectedInOpen',
      },
      {
        key: 'soapAndHandWashStats',
        name: 'Hand Hygiene',
        percentageKey: 'percentageSoapAndHandWash',
      },
      {
        key: 'washHandsMoreSecsStats',
        name: 'Hand Hygiene (>20 seconds)',
        percentageKey: 'percentageWashHandsMoreSecs',
      },
      {
        key: 'waterSourceStats',
        name: 'Safe and Clean Water',
        percentageKey: 'percentageValidWaterSource',
      },
    ];

    const transformedData = categories.map((category) => {
      const percentageString = data[category.key][category.percentageKey];
      const value = parseFloat(percentageString.replace('%', '')); // Convert percentage string to number
      return {
        name: category.name,
        value: isNaN(value) ? 0 : value,
      };
    });

    // Sort data in descending order
    transformedData.sort((a, b) => b.value - a.value);

    return transformedData;
  }

  transformDonutChartData(data: any): { country: string; sales: number }[] {
    return [
      { country: 'Diarrhoea With No Dehydration', sales: data.no_dehydration || 0 }, // Ensure fallback value
      { country: 'Diarrhoea With Some Dehydration', sales: data.some_dehydration || 0 },
      { country: 'Diarrhoea With Blood In Stool', sales: data.blood_in_stool || 0 },
      { country: 'Diarrhoea With Severe Dehydration', sales: data.severe_diarrhoea || 0 },
      { country: 'Persistent Diarrhoea', sales: data.persistent_diarrhoea|| 0 },
    ];
  }

  getDiarrheaBlockData(paramsValue: any): void {
    this.diarrheaService.getDiarrheaTreeTableDataApi(paramsValue).subscribe(
      (dataValue: any) => {
        this.treeTableDiarrhea = this.transformData(dataValue);
      },
      (error) => {
        console.error('Error occurred in getDiarrheaBlockData:', error);
        this.error = 'Failed to load diarrhea block data';
      }
    );
  }

  getDiarrheaHierarchyData(params: any): void {
    this.diarrheaService.getDiarrheaHierarchyData(params).subscribe(
      (res: any) => {
        let resultArray;
        switch (this.currentUser.user.designation) {
          case 'SM':
            resultArray = res.districts;
            break;
          case 'DL':
            resultArray = res.districts[0].blocks;
            break;
          case 'BO':
            resultArray = res.districts[0].blocks[0].chwlist;
            break;
          default:
            resultArray = res;
        }

        this.treeDiarrheaCount = this.transformData(resultArray);
      },
      (err: any) => {
        console.error('Error occurred in getDiarrheaHierarchyData:', err);
        this.error = 'Failed to load diarrhea hierarchy data';
      }
    );
  }

  getAllData(paramsValue: any): void {
    this.isLoading = true;
    this.error = null;

  

    this.statusOfDiarrhoeaCases(paramsValue);
    this.getDiarrheaFirstPointIndicators(paramsValue);
    this.FirstTilesApiCallOfDiarrhoea(paramsValue);
    this.getDiarrheaCasesBifurcation(paramsValue)

  }


  transformData(input: any): TreeNode[] {
    const transform = (node: any, level: string, index: number): TreeNode => {
      const { parent, ...restNode } = node;

      const newNode: TreeNode = {
        key: `${level}-${index}`,
        data: { ...restNode },
        children: [],
      };

      if (node.districts) {
        newNode.children = node.districts.map((district: any, i: number) =>
          transform(district, 'district', i)
        );
      } else if (node.blocks) {
        newNode.children = node.blocks.map((block: any, i: number) =>
          transform(block, 'block', i)
        );
      } else if (node.chwList || node.chwlist) {
        newNode.children = (node.chwList || node.chwlist).map(
          (chw: any, i: number) => transform(chw, 'chw', i)
        );
      }

      return newNode;
    };

    return input.map((item: any, index: number) =>
      transform(item, 'state', index)
    );
  }

  setChartData2(apiData1: any): void {
    this.chartData2 = [
      { name: 'Loss To F/U', value: apiData1.LossToFollowUp, color: '#D2122E' },
      { name: ' Recover', value: apiData1.Recovered, color: '#568203' },
      { name: 'LAMA', value: apiData1.LAMA, color: '#FFC72C' },
      { name: 'UT', value: apiData1.TreatmentInProcess, color: '#662d91' },
      { name: ' Deaths', value: apiData1.Fatalities, color: '#0000FF' },
      // { name: 'Total', value: apiData1.Total, color: '#FFFFFF' },
    ];
  }

  getDiarrheaFirstPointIndicators(event: any): void {
    this.diarrheaService.diarrhoeaFirstPointIndicator(event).subscribe(
      (dataValue: any) => {
        this.processAwarenessData(dataValue);
        console.log('Calling from filter ');
      },
      (error) => {
        console.error('Error occurred:', error);
        this.error = 'Failed to load diarrhea first point indicators';
      }
    );
  }

  statusOfDiarrhoeaCases(event: any): void {
   this.diarrheaService.getTreatedAtFacilityApi(event).subscribe(
  (dataValue: any) => {
    this.setChartData2(dataValue);
  },
  (error) => {
    this.error = 'Failed to load facility treatment data';
  }
  );
  }

  processAwarenessData(data: any): void {
    this.awarenessData = [
      {
        title: 'Diarrhoea awareness',
        count: data.uniqueMotherCount,
        totalCount: data.totalUniqueMothersCount,
      },
      {
        title: 'Low osmolarity ORS awareness',
        count: data.lowOsmolarityORSMotherCount,
        totalCount: data.totalUniqueMothersCount,
      },
      {
        title: 'Zinc Awareness',
        count: data.zincAwarenessMotherCount,
        totalCount: data.totalUniqueMothersCount,
      },
      {
        title: 'ORS Available At Home',
        count: data.orsAtHomeMotherCount,
        totalCount: data.totalUniqueMothersCount,
      },
      {
        title: 'Zinc Available At Home',
        count: data.zincAvailabilityMotherCount,
        totalCount: data.totalUniqueMothersCount,
      },
      {
        title: 'Zinc Compliance',
        count: data.zincComplianceCount,
        totalCount: data.totalUniqueMothersCount,
      },
    ];
  }

  // Add missing methods
  onFilterChange(event: any): void {
    event['type'] = 'filter'; // 3 oct
    // this.getDiarrheaHierarchyData((this.treeDiarrheaCount = this.params));
    // this.getDiarrheaBlockData((this.treeTableDiarrhea = event));
    // this.getDiarrheaOtherIndicator((this.params = event));
    // this.getAllData((this.params = event));
  }

  trackByFn(index: number, item: any): any {
    return item.id || index; // Use a unique identifier if available, otherwise use the index
  }

  navigateToTreeTable(): void {
    // Implement navigation logic here
    this.router.navigate(['/tree-table']); // Adjust the route as needed
  }

  getDiarrhoeaData(params: any = this.params): void {
    // Set loading to true before making the API call
    this.isLoadingforDiarrhoea = true;
    this.newOverviewService.getDiarrhoeaCounts(params).subscribe(
      (response: any) => {
        if (response && response.data) {
          // Assign data to the chart
          this.dataForChartOfDiarrhoea = response.data.map((item: any) => ({
            value: item.count, // Value for the chart
            category: item.label, // Category name for the chart
          }));
  
          // Store the 0th index value
          if (this.dataForChartOfDiarrhoea.length > 0) {
            this.variableToStoreDiarrhoea0IndexLabel = this.dataForChartOfDiarrhoea[0].category;
            this.variableToStoreDiarrhoea0IndexCount = this.dataForChartOfDiarrhoea[0].value;
          }
  
          // Store the last index value
          if (this.dataForChartOfDiarrhoea.length > 1) {
            const lastIndex = this.dataForChartOfDiarrhoea.length - 1;
            this.fullyRecoveredDiarrhoeaLabel = this.dataForChartOfDiarrhoea[lastIndex].category;
            this.fullyRecoveredDiarrhoeaCount = this.dataForChartOfDiarrhoea[lastIndex].value;
          }
  
          // Optionally, slice the array to show data from 1st index to second-to-last index
          this.dataForChartOfDiarrhoea = this.dataForChartOfDiarrhoea.slice(1, this.dataForChartOfDiarrhoea.length - 1);
  
        } else {
          this.dataForChartOfDiarrhoea = []; // Fallback to empty data if no response
        }
      },
      (error) => {
        this.dataForChartOfDiarrhoea = []; // Ensure the chart is cleared on error
      },
      () => {
        this.isLoadingforDiarrhoea = false;
      }
    );
  }
  
  FirstTilesApiCallOfDiarrhoea(params: any = this.params){
    this.diarrheaService.getChildDiarrheaCountFun(params).subscribe(
      (dataValue: any) => {
        this.tileTopStats = dataValue;
        this.dummyDataIndicators = dataValue.tileCounts.filter((item: any) =>
          ['Total Diarrhoea Cases', 'Home Management By ORS And Zinc'].includes(
            item.title
          )
        );

        this.dummyDataIndicators2 = dataValue.tileCounts.filter((item: any) =>
          ['Referred', 'Admitted', 'NICU', 'General Ward'].includes(item.title)
        );

        this.dummyDataIndicators3 = dataValue.tileCounts.filter((item: any) =>
          [
            'Deaths (0-2 years)',
            'Deaths (2-6 years)',
            'Repeat Diarrhoea Cases',
          ].includes(item.title)
        );

        this.isLoading = false;
      },
      (error) => {
        this.error = 'Failed to load diarrhea data';
        this.isLoading = false;
      }
    );
  }
  

  functionFilterFromDiarrhoea(params: any): void {
   console.log("--------------->",params.stateId,params.districtId)
    const filterParams = {
      // designation: params.designation,
      stateId: params.stateId, // Map 'stateId' from params to 'state_Id' for service
      districtId: params.districtId, // Map 'districtId' from params to 'district_Id' for service
      blockId: params.blockId, // Map 'blockId' from params to 'block_Id' for service
      // village_Id: params.villId, 
      // type: params.type,
      // from_date: params.fromDate, 
      // to_date: params.toDate, 
      fromDate: params.fromTimestamp, // Map 'fromDate' from params to 'from_date' for service
      toDate: params.toTimestamp, // Map 'toDate' from params to 'to_date' for service
    };
  
  
/***
 * Diarrhoea funnel chart service
 */
this.getDiarrhoeaData(filterParams);
/***
 * Diarrhoe first point indicator
 */
this.getDiarrheaFirstPointIndicators(filterParams);

/***
 * Status Of Diarrhoea Cases
 */
this.statusOfDiarrhoeaCases(filterParams);
/***
 * Get service for Diarrhoea Cases Bifurcation
 */
this.getDiarrheaCasesBifurcation(filterParams);

 /***
   * Diaddhoea First Tiles data api response
   */
this.FirstTilesApiCallOfDiarrhoea(filterParams);


  }

 
  
  
  
}
