import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

interface StackedDataPoint {
  year: string;
  la: number;
  cd: number;
  colorla?: string;
  colorcd?: string;
}

@Component({
  selector: 'app-stacked-column-chart',
  standalone: true,
  imports: [CommonModule],
  template: '<div [id]="chartId" style="width: 100%; height: 350px;"></div>',
  styles: [],
})
export class StackedColumnChartComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() chartId: string = 'stackedColumnChart';
  @Input() data: StackedDataPoint[] = [];
  @Input() firstLegendName: string = 'Low Awareness'; // New input for first legend
  @Input() secondLegendName: string = 'Counselling Done'; // New input for second legend

  private chart?: am5xy.XYChart;
  private root?: am5.Root;
  private exporting?: am5plugins_exporting.Exporting;



  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.createChart();
        this.cdr.detectChanges();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      isPlatformBrowser(this.platformId) &&
      changes['data'] &&
      !changes['data'].firstChange
    ) {
      this.updateChartData();
    }
    if (
      (changes['firstLegendName'] || changes['secondLegendName']) &&
      this.chart
    ) {
      this.updateLegendNames();
    }
  }

  // sort data function for grneration columns
  private sortDataDescending(data: StackedDataPoint[]): StackedDataPoint[] {
    return [...data].sort((a, b) => b.la + b.cd - (a.la + a.cd));
  }

  private createChart() {
    if (!isPlatformBrowser(this.platformId)) return;

    const chartElement = document.getElementById(this.chartId);
    if (!chartElement) {
      console.error(`Element with id ${this.chartId} not found`);
      return;
    }

    this.root = am5.Root.new(this.chartId);
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.root._logo?.dispose();

    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        layout: this.root.verticalLayout,
      })
    );

    // Create X-axis
    let xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'year',
        renderer: am5xy.AxisRendererX.new(this.root, {
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
          minGridDistance: 30,
        }),
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    xAxis.get('renderer').labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 10,
      fontSize: 12,
    });

    // Create Y-axis
    let yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {}),
      })
    );

    // Create series
    let laSeries = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: this.firstLegendName, // Use dynamic name
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'la',
        categoryXField: 'year',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{name}: {valueY}',
        }),
      })
    );

    laSeries.columns.template.setAll({
      tooltipY: 0,
      tooltipText: '{name}: {valueY}',
      width: am5.percent(90),
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    });

    // Create second series with dynamic name
    let cdSeries = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: this.secondLegendName, // Use dynamic name
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'cd',
        categoryXField: 'year',
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{name}: {valueY}',
        }),
      })
    );

    cdSeries.columns.template.setAll({
      tooltipY: 0,
      tooltipText: '{name}: {valueY}',
      width: am5.percent(90),
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    });

    // Add legend
    let legend = this.chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: this.root.horizontalLayout,
      })
    );

    legend.data.setAll(this.chart.series.values);

    // Set data
    this.updateChartData();

    // Make stuff animate on load
    this.chart.appear(1000, 100);

    // Initialize exporting
this.exporting = am5plugins_exporting.Exporting.new(this.root, {
  filePrefix: 'funnel-chart',
  dataSource: this.data,
  pdfOptions: {
    addURL: false,
    disabled: false,
  },
  csvOptions: {
    addColumnNames: true,
    separator: ',',
  },
});

// Create and set up the export menu
const exportMenu = am5plugins_exporting.ExportingMenu.new(this.root, {
  exporting: this.exporting, // Link the menu to the exporting instance
});
  }
  private updateLegendNames(): void {
    if (this.chart) {
      const series = this.chart.series.values;
      if (series.length >= 2) {
        series[0].set('name', this.firstLegendName);
        series[1].set('name', this.secondLegendName);
      }
    }
  }

  private updateChartData(): void {
    if (!this.chart) {
      console.error('Chart not initialized');
      return;
    }
    if (!Array.isArray(this.data) || this.data.length === 0) {
      return;
    }

    // Sort the data in descending order
    const sortedData = this.sortDataDescending(this.data);
    this.chart.xAxes.getIndex(0)?.data.setAll(sortedData);
    this.chart.series.getIndex(0)?.data.setAll(sortedData);
    this.chart.series.getIndex(1)?.data.setAll(sortedData);

    // Set colors for each series
    this.chart.series
      .getIndex(0)
      ?.set('fill', am5.color(this.data[0].colorla || ''));
    this.chart.series
      .getIndex(0)
      ?.set('stroke', am5.color(this.data[0].colorla || ''));
    this.chart.series
      .getIndex(1)
      ?.set('fill', am5.color(this.data[0].colorcd || ''));
    this.chart.series
      .getIndex(1)
      ?.set('stroke', am5.color(this.data[0].colorcd || ''));
  }

  ngOnDestroy() {
    console.log('StackedColumnChartComponent ngOnDestroy called');
    if (this.root) {
      this.root.dispose();
    }
  }
}
