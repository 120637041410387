import { Injectable } from '@angular/core';
import { environment } from '../../environment/environment.local';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BirthSpacingService {
  private readonly endpoint = `${environment.apiUrl}Mothercommonques`;
  constructor(private http: HttpClient) {}
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  getbirthSpacingData(): Observable<any> {
    const url = `${this.endpoint}/getbirthSpacingData`;
    return this.http.get(url, { headers: this.headers });
  }

  getBirthSpacingTreeData(params:any): Observable<any> {
    console.log("this is the params passed in birthspacing", params)
    const url = `${this.endpoint}/getBirthSpacingTreeData` +'?params=' +
      JSON.stringify(params);;
    return this.http.get(url, { headers: this.headers });
  }

  getBirthSpacingGraphData(params:any):Observable<any>{
    const url = `${this.endpoint}/getBirthSpacingGraphData/` +  JSON.stringify(params);
     return this.http.get(url, { headers: this.headers });
  }
}
