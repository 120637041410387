import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiEndPoint } from '../authentication.service';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HHListingService {
  constructor(private http: HttpClient) {}
  private apiUrl = `${apiEndPoint}HHListings`;

  getFilteredData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${this.apiUrl}/getFilteredData`, { params: httpParams })
      .pipe(tap((response: any) => response));
  }
}
