import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CustomLineChartComponent } from '../custom-line-chart/custom-line-chart.component';
import { StackedColumnChartComponent } from '../stacked-column-chart/stacked-column-chart.component';
import { MapChartComponent } from '../map-chart/map-chart.component';
import { PieChartComponent } from '../pie-chart/pie-chart.component';
import { Router } from '@angular/router';
import { PneumoniaSelfCareService } from '../Services/pneumonia-self-care.service';
import { FilterComponent } from '../filter/filter.component';
import { DiarrheaTreeTableComponent } from '../components/diarrhea-tree-table/diarrhea-tree-table.component';
import { PneumoniaCountTreeTableComponent } from '../components/pneumonia-count-tree-table/pneumonia-count-tree-table.component';
import { OverviewDashboardService } from '../Services/overview-dashboard.service';
import { StepChartComponent } from '../step-chart/step-chart.component';
import { NewOverviewService } from '../Services/new-OverviewService/new-overview.service';
import { DonutChartGeneratorComponent } from "../donut-chart-generator/donut-chart-generator.component";

//dummy data for Diarrhoea Awareness 25 sept start 
interface DiarrhoeaAwareness {
  title: string;
  total: number;
  boys: number;
  girls: number;
}
//dummy data for Diarrhoea Awareness 25 sept end
export interface otherIndicator {
  year: string;
  la: number;
  cd: number;
  colorla?: string;
  colorcd?: string;
}
interface ChartDataPoint {
  name: string;
  value: number;
  color?: string;
}
interface StackedDataPoint {
  year: string;
  la: number;
  cd: number;
  colorla?: string;
  colorcd?: string;
}

interface TileTopStat {
  title: string;
  target: number;
  covered: number;
}

interface TileCount {
  title: string;
  total: number;
  male: number;
  female: number;
}

interface BeneficiaryData {
  firstName: string;
  age: number;
  mobile: string;
  beneficiaryStatus: string;
  fatherName: string;
  husbandName: string;
}

@Component({
  selector: 'app-pneumonia',
  standalone: true,
  imports: [
    CommonModule,
    CustomLineChartComponent,
    PieChartComponent,
    StackedColumnChartComponent,
    MapChartComponent,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    FilterComponent,
    DiarrheaTreeTableComponent,
    PneumoniaCountTreeTableComponent,
    StepChartComponent,
    DonutChartGeneratorComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './pneumonia.component.html',
  styleUrls: ['./pneumonia.component.css'],
})
export class PneumoniaComponent implements OnInit, AfterViewInit {
  // Input to control visibility of tables
  @Input() showAndHideTablesOfPneumonia: boolean = true;
  @Input() showAndHideTablesOfPneumoniaheading: boolean = false;
  isFilterVisible: boolean = false; // Variable to control visibility
  variableToStorePneumonia0IndexLabel: string = 'N/A';
  variableToStorePneumonia0IndexCount: number = 0;
  variableToStorePneumonia1IndexLabel: string = 'N/A';
  variableToStorePneumonia1IndexCount: number = 0;
  suspectedPneumoniaCases: number = 0;
  referredToFacilityCases: number = 0;
  recoveredPostReferralCases: number = 0;
  notReferredToFacilityCases: number = 0;
  recoveredAtHomeCases: number = 0;
  pneumoniaMortalityCases: number = 0;
  fullyRecoveredPneumoniaLabel: string = 'Fully Recovered';
  fullyRecoveredPneumoniaCount: number = 0; 
  pneumoniaFunnelChartData: any[] = [];
  isLoading: boolean = false;
  donutChartData: any[] = [];
  colors = ['#66ffff', '#ff6666', '#66ff66'];
  chartColors2 = ['#f6a5b2', '#4682B4', '#8a99e6', '#c6f6ef', '#74a0dd'];

  otherIndicatorsData2 = [
    { name: "Vaccination", value: 96.3 },
    { name: "Early Breastfeeding", value: 65.5 },
    { name: "Exclusive Breastfeeding", value: 97.1 },
    { name: "Safe sanitation/ open defecation", value: 55.3 },
    // { name: "Hand Hygiene > 2", value: 77.3 },
    { name: "Safe and Clean Water", value: 15 },
    { name: "ORS", value: 76.51 },
    { name: "Zinc", value: 32.73 },
    // { name: "Soap Available", value: 78.19 },
    { name: "Clean Fuel", value: 51.08 },
  ];
  currentUser = localStorage.getItem('currentUser');
  currentUserJson1 = JSON.parse(String(this.currentUser));
  tileCounts: TileCount[] = [];
  colorla: string = '#f99db3';
  colorcd: string = '#8a99e6';
  mapData: any[] = [];
  chartData: any[] = [];  // for other indicators store
  colorsForPneumoniaFunnelChart: string[] = [
    "#FFB6C1", // Light Pink
    "#F0E68C", // Khaki
    "#87CEEB", // Sky Blue
    "#B0E0E6"  // Powder Blue
  ];

  treeColumns = [
    {
      field: 'name',
      header: 'Name',
    },
    // {
    //   field: 'totalChildrenCovered',
    //   Header: 'Children Covered',
    // },  
    {
      field: 'totalPneumoniaCases',
      header: 'Pneumonia Cases ',
    },
    {
      field: 'totalTreatedAtHome',
      header: 'Treated At Home',
    },
    {
      field: 'totalTreatedAtFacility',
      header: 'Treated At Facility',
    },
    {
      field: 'totalAdmittedToGeneral',
      header: 'Admitted To General',
    },
    {
      field: 'totalAdmittedToNICU',
      header: 'Admitted To NICU ',
    },
    {
      field: 'totalRepeatPneumonia',
      header: 'Repeated Case',
    },
    {
      field: 'totalChildrenCovered',
      header: 'Children Covered',
    },
    {
      field: 'totalDeathsLT0_2',
      header: 'Child 0-2',
    },
    {
      field: 'totalDeathsLT2_6',
      header: 'Child 2-6',
    },
  ];

  funnelChartDataPneumonia = [
    { value: 3938, category: "Child (0-6)" },
    { value: 291, category: "Pneumonia" },
    { value: 245, category: "Home" },
    { value: 46, category: "Facility" },
    { value: 0, category: "Mortality" }
  ];
  treeTablePneumonia: any = [];
  tileTopStats: {
    tileTopStats: TileTopStat[];
    tileCounts: TileCount[];
  } = {
      tileTopStats: [],
      tileCounts: [],
    };
  chartData1: ChartDataPoint[] = [];
  chartData2: ChartDataPoint[] = [];
  loadingTreatmentFacility: boolean = false;
  loadingTreatmentHome: boolean = false;
  loadingburification: boolean = false;
  stackedChartData: StackedDataPoint[] = [];
  displayedColumns2: string[] = [
    'sn',
    'blockName',
    'total',
    'treatedAtHome',
    'treatedAtFacility',
  ];
  dataSource2: MatTableDataSource<BeneficiaryData>;
  exampleData: any;
  exampleDataFor1: any;
  exampleDataFor2: any;
  DataForstack: any;
  stateId = [];
  districtId = [];
  blockId = [];
  villageId = [];
  fromDate = [];
  toDate = [];
  params = {};
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private pueunmiaService: PneumoniaSelfCareService,
    private overviewDashboardService: OverviewDashboardService,
    private changeDetectorRef: ChangeDetectorRef,
    private newOverviewService: NewOverviewService
  ) {
    this.dataSource2 = new MatTableDataSource<BeneficiaryData>([]);
    let currentUserJson;
    if (this.currentUser) {
      currentUserJson = JSON.parse(this.currentUser);
    }

    let filter = {};
    if (currentUserJson.user.designation == 'SM') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'DL') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'BO') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        block_Id: [currentUserJson.user.blockId],
        type: 'default',
      }
    }
  }

  treeColumnsPneumonia = [
    {
      field: 'name',
      header: 'Name',
    },
    {
      field: 'pneumonia',
      header: 'Pneumonia',
    },
    {
      field: 'severe_pneumonia',
      header: 'Severe Pneumonia',
    },
    {
      field: 'no_pneumonia',
      header: 'No Pneumonia',
    },
  ];


  ngOnInit() {
    console.log('fhdjghkjdgfdfdhgh', this.chartData2);
    this.lowAwarenessVsCouncil();
    this.getPneumoniaData(this.params);
    this.getDataFromLocalStorage();
    this.getPneumoniaCasesBifurcation(this.params);


    let currentUserJson;
    if (this.currentUser) {
      currentUserJson = JSON.parse(this.currentUser);
    }


    let filter = {};
    if (currentUserJson.user.designation == 'SM') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'DL') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'BO') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        block_Id: [currentUserJson.user.blockId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'Admin') {
      console.log("admin dec exe")
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [],
        district_Id: [],
        block_Id: [],
        type: 'default',
      };
      console.log("admin filter", filter)
      // this.getPneumoniaBlockData(filter);
    }
    this.pueunmiaService
      .getChildPneumoniaCountFun(filter)
      .subscribe((data: any) => {

        this.tileTopStats = data.tileTopStats;
        this.cdr.detectChanges();
      });
    this.pueunmiaService
      .getChildPneumoniaTreatedStatus(filter)
      .subscribe((data: any) => {
        this.exampleData = data;
        this.dataSource2.data = this.exampleData;
        this.cdr.detectChanges();
        this.generateStackChart(filter);
      });
    this.stackedChartData = [
      {
        year: 'pneumoniaPrevention',
        la: 65,
        cd: 65,
        colorla: '#7ddebe',
        colorcd: '#4a90e2',
      },
      {
        year: 'possibleSymptoms',
        la: 75,
        cd: 55,
        colorla: '#7ddebe',
        colorcd: '#4a90e2',
      },
    ];

    this.chartData = [
      { "country": "Vaccination", "value": 443 },
      { "country": "Early B/F", "value": 414 },
      { "country": "Exclusive B/F", "value": 385 },
      { "country": "Clean  fuel", "value": 280 },
      { "country": "Hand Hygiene", "value": 435 },
      { "country": "Safe sanitation/ OD", "value": 353 },
      { "country": "Safe & Clean Water", "value": 441 },
      { "country": "C. Feces disposal", "value": 287 },
    ];
    console.log("this.initializeStepChartData()", this.chartData);
    this.getDiarrheaOtherIndicator()

    this.overviewDashboardService.params$.subscribe((params) => {
      this.params = params; // Store the received parameters   
      if (params) {
        this.functionFilter(params);
      }
    });
  }
  // Method to toggle the visibility of the filter component
  toggleFilterVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }

  getPneumoniaCasesBifurcation(params: any = this.params): void {
    this.loadingburification = true; // Show spinner before API call
    this.pueunmiaService.getPneuCasesBifurcation(params).subscribe(
      (res: any) => {
        console.log('API Response:', res.data.pneumoniaCasesCount);

        // Transform and assign a new reference to the data
        this.donutChartData = [
          { country: 'Pneumonia', sales: res.data.pneumoniaCasesCount.pneumonia },
          { country: 'Severe Pneumonia', sales: res.data.pneumoniaCasesCount.severe_pneumonia },
          { country: 'No Pneumonia', sales: res.data.pneumoniaCasesCount.no_pneumonia },
        ];

        this.loadingburification = false; // Hide spinner after data loads
        this.changeDetectorRef.detectChanges(); // Trigger change detection
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.loadingburification = false; // Hide spinner on error
      }
    );
  }


  transformDonutChartData(data: any): { country: string; sales: number }[] {

    return [
      { country: 'Pneumonia', sales: data.pneumonia || 0 },
      { country: 'Severe Pneumonia', sales: data.severe_pneumonia || 0 },
      { country: 'No Pneumonia', sales: data.no_pneumonia || 0 },
    ];
  }

  getDataFromLocalStorage() {
    let allUser = localStorage.getItem('currentUser');

    if (allUser) {
      let currentUser: any = JSON.parse(allUser);

      if (currentUser.user.designation === 'SM') {
        this.stateId = currentUser.user['stateId'];
      } else if (currentUser.user.designation === 'DL') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
      } else if (currentUser.user.designation === 'BO') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
      } else if (currentUser.user.designation === 'CHW') {
        this.stateId = currentUser.user['stateId'];
        this.districtId = currentUser.user['districtId'];
        this.blockId = currentUser.user['blockId'];
        this.villageId = currentUser.user['villageId'];
      }
    }
    // filterValues['type'] = "filter"
    const currentUserString = JSON.stringify(this.currentUser);
    const currentUser = JSON.parse(currentUserString);
    this.params = {
      state_Id: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      district_Id: Array.isArray(this.districtId)
        ? this.districtId
        : [this.districtId],
      block_Id: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      village_Id: Array.isArray(this.villageId)
        ? this.villageId
        : [this.villageId],
      userLevel: currentUser?.user?.designation,
    };
  }

  ngAfterViewInit(): void {
    let currentUserJson;
    if (this.currentUser) {
      currentUserJson = JSON.parse(this.currentUser);
      console.log(currentUserJson.user.designation);
    }

    let filter = {};
    if (currentUserJson.user.designation == 'SM') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'DL') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: [currentUserJson.user.stateId],
        district_Id: [currentUserJson.user.districtId],
        type: 'default',
      };
    } else if (currentUserJson.user.designation == 'BO') {
      filter = {
        designation: currentUserJson.user.designation,
        state_Id: currentUserJson.user.stateId,
        district_Id: [currentUserJson.user.districtId],
        block_Id: [currentUserJson.user.blockId],
        type: 'default',
      };
    }

    this.pueunmiaService
      .getChildPneumoniaCountFun(filter)
      .subscribe((data: any) => {
        this.tileTopStats = data;

        this.cdr.detectChanges();
      });
    this.generateChartData(filter);
    this.pueunmiaService
      .getChildPneumoniaTreatedStatus(filter)
      .subscribe((data: any) => {
        this.exampleData = data;
        this.dataSource2.data = this.exampleData;
        this.cdr.detectChanges();
        this.generateStackChart(filter);
      });

    this.pueunmiaService
      .getApprovedPneumoniaCases(filter)
      .subscribe((Geodata: any) => {
        this.generateMap(Geodata);

        this.cdr.detectChanges();
      });

    this.dataSource2.sort = this.sort;
    this.dataSource2.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
    if (this.dataSource2.paginator) {
      this.dataSource2.paginator.firstPage();
    }
  }

  onFilterChange(filterValues: any): void {
    filterValues['type'] = 'filter';
    this.pueunmiaService
      .getChildPneumoniaCountFun(filterValues)
      .subscribe((data: any) => {
        this.tileTopStats = data;
        this.cdr.detectChanges();
      });
    this.pueunmiaService
      .getChildPneumoniaTreatedStatus(filterValues)
      .subscribe((data: any) => {
        this.exampleData = data;
        this.dataSource2.data = this.exampleData;
        this.cdr.detectChanges();
        this.generateChartData(filterValues);
        this.generateStackChart(filterValues);

        this.pueunmiaService
          .getApprovedPneumoniaCases(filterValues)
          .subscribe((Geodata: any) => {
            this.generateMap(Geodata);

            this.cdr.detectChanges();
          });
         });


  }

  export() {
    console.log('Exporting data...');
  }

  async generateStackChart(filter: any) {
    await this.pueunmiaService
      .getChildStackChartDataHome(filter)
      .subscribe((stackdataRes: any) => {
        this.DataForstack = stackdataRes;

        this.cdr.detectChanges();
      });
  }

  generateMap(data: any) {
    let obj = {};
    data.forEach((element: any) => {
      obj = {
        lat: element.geoLocation.latitude,
        lng: element.geoLocation.longitude,
        type: 'svg',
        title: element.block,
      };
      this.mapData.push(obj);
    });
  }

  async generateChartData(filter: any) {

    this.loadingTreatmentHome = true;
    await this.pueunmiaService
      .getChildPneumoniaTreatedDataHome(filter)
      .subscribe((chartdataRes: any) => {
        this.exampleDataFor1 = chartdataRes
        this.chartData1 = [
          {
            name: 'Recovered',
            value: this.exampleDataFor1[0].childRecoveredCount,
            color: '#f6a5b2',
          },
          {
            name: 'Fatalities',
            value: this.exampleDataFor1[0].childFatalitiesCount,
            color: '#8a99e6',
          },
          {
            name: 'U-T',
            value: this.exampleDataFor1[0].childUnderTreatmentCount,
            color: '#77E4C8',
          },
          {
            name: 'LAMA',
            value: this.exampleDataFor1[0].childLAMACount,
            color: '#C6F6EF',
          },
          {
            name: 'Loss To F/U',
            value: this.exampleDataFor1[0].childLossToFollowUpCount,
            color: '#74a0dd',
          },
        ];
        this.cdr.detectChanges();
        this.loadingTreatmentHome = false;
      });

    this.loadingTreatmentFacility = true;
    await this.pueunmiaService
      .getChildPneumoniaTreatedDataFacility(filter)
      .subscribe((chartdata: any) => {
        this.exampleDataFor2 = chartdata
        this.cdr.detectChanges();
        this.chartData2 = [
          {
            name: 'Recovered',
            value: this.exampleDataFor2[0].childRecoveredCount,
            color: '#f6a5b2',
          },
          {
            name: 'Fatalities',
            value: this.exampleDataFor2[0].childFatalitiesCount,
            color: '#8a99e6',
          },
          {
            name: 'U-T',
            value: this.exampleDataFor2[0].childUnderTreatmentCount,
            color: '#77E4C8',
          },
          {
            name: 'LAMA',
            value: this.exampleDataFor2[0].childLAMACount,
            color: '#C6F6EF',
          },
          {
            name: 'Loss To F/U',
            value: this.exampleDataFor2[0].childLossToFollowUpCount,
            color: '#74a0dd',
          },

        ];
        this.cdr.detectChanges();
        this.loadingTreatmentFacility = false
        this.cdr.detectChanges();

      }
      );
  }

  navigateToTreeTable() {
    this.router.navigate(['/tree-table-page']);
  }

  transformData(input: any) {
    const transform = (node: any, key: any) => {
      // Destructure the node, omitting 'parent'
      const { parent, ...restNode } = node;

      const newNode = {
        key: key,
        data: {
          ...restNode, // Copy everything except 'parent'
        },
        children: [],
      };
      if (node.districts) {
        newNode.children = node.districts.map((district: any, index: any) => {
          const districtKey = `${key}-${index}`;
          const transformedDistrict = transform(district, districtKey);
          if (district.blocks) {
            transformedDistrict.children = district.blocks.map(
              (block: any, blockIndex: any) => {
                const blockKey = `${districtKey}-${blockIndex}`;
                return transform(block, blockKey);
              }
            );
          }
          return transformedDistrict;
        });
      } else if (node.blocks) {
        newNode.children = node.blocks.map((district: any, index: any) => {
          const districtKey = `${key}-${index}`;
          const transformedBlocks = transform(district, districtKey);
          return transformedBlocks;
        });
      } else if (node.chwlist || node.chwList) {// in some spelling it is 'L'
        newNode.children = (node.chwlist || node.chwList)?.map((district: any, index: any) => {
          const districtKey = `${key}-${index}`;
          const transformedBlocks = transform(district, districtKey);
          return transformedBlocks;
        }) || [];
      }

      return newNode;
    };

    return input.map((item: any, index: any) =>
      transform(item, index.toString())
    );
  }

  lowAwarenessVsCouncil() {

    this.pueunmiaService.lowAwarenessVsCouncil().subscribe(
      (res: any) => {
        console.log('result of lowawareness', res);
        this.stackedChartData = res.data;
      },
      (err: any) => {
        console.log('Error occured', err);
      }
    );
  }

  getDiarrheaOtherIndicator(params: any = this.params): void {
    this.overviewDashboardService.getOtherIndicatorData().subscribe(
      (res: any) => {
        console.log("Received data:", res);
        //  const data = this.transformOtherIndicatorChartData(res);
        //  this.otherIndicatorsData =data
      },
      (err: any) => {
        console.error('Error occurred in getDiarrheaOtherIndicator:', err);
        // this.error = 'Failed to load other indicator data';
      }
    );
  }

  transformOtherIndicatorChartData(data: any): any[] {
    const categories = [
      { key: 'vaccinationStats', name: 'Vaccination', percentageKey: 'percentageVaccinatedUnder12Months' },
      { key: 'breastfeedingStats', name: 'Early Breastfeeding', percentageKey: 'percentageBreastfeedingIn1HrUnder6Months' },
      { key: 'breastfeedingContinueStats', name: 'Exclusive Breastfeeding', percentageKey: 'percentageBreastfeedingContinueUnder6Months' },
      { key: 'defectedInOpenStats', name: 'Safe sanitation/ open defecation', percentageKey: 'percentageDefectedInOpen' },
      { key: 'soapAndHandWashStats', name: 'Hand Hygiene > 2', percentageKey: 'percentageSoapAndHandWash' },
      { key: 'waterSourceStats', name: 'Safe and Clean Water', percentageKey: 'percentageValidWaterSource' },
      { key: 'waterSourceStats', name: 'ORS', percentageKey: 'percentageValidWaterSource' },
      { key: 'waterSourceStats', name: 'Zinc', percentageKey: 'percentageValidWaterSource' },
      { key: 'defectedInOpenStats', name: 'Soap Available', percentageKey: 'percentageDefectedInOpen' },
      { key: 'defectedInOpenStats', name: 'Clean Fuel', percentageKey: 'percentageDefectedInOpen' },
    ];

    const transformedData = categories.map(category => {
      const percentageString = data[category.key][category.percentageKey];
      const value = parseFloat(percentageString.replace('%', '')); // Convert percentage string to number
      return {
        name: category.name,
        value: isNaN(value) ? 0 : value
      };
    });

    // Sort data in descending order
    transformedData.sort((a, b) => b.value - a.value);

    return transformedData;
  }
  getPneumoniaData(params: any = this.params): void {
    this.isLoading = true;
    this.newOverviewService.getPneumoniaCounts(params).subscribe({
      next: (response) => {
        if (response && response.data) {
          // Create a map for easier data lookup
          const dataMap = response.data.reduce((acc:any, item:any) => {
            acc[item.label] = item.count;
            return acc;
          }, {});
  
          // Populate variables for the first two labels in the grid
          this.variableToStorePneumonia0IndexLabel = response.data[0].label;
          this.variableToStorePneumonia0IndexCount = response.data[0].count;
          this.variableToStorePneumonia1IndexLabel = response.data[1].label;
          this.variableToStorePneumonia1IndexCount = response.data[1].count;
  
          // Populate table data
          this.suspectedPneumoniaCases = dataMap['SuspectedPneumoniaCases'] || 0;
          this.referredToFacilityCases = dataMap['Referred To Facility'] || 0;
          this.pneumoniaMortalityCases = dataMap['Pneumonia Mortality'] || 0;
  
          // Additional derived or default values
          this.recoveredPostReferralCases = dataMap['Treated At Facility'] || 0;  // Not present in the current response
          this.notReferredToFacilityCases = dataMap['Not Referred To Facility'] || 0; // Not present in the current response
          this.recoveredAtHomeCases = dataMap['Treated At Home'] || 0; // Not present in the current response
  
          // Fully recovered section
          this.fullyRecoveredPneumoniaLabel = 'Fully Recovered';
          this.fullyRecoveredPneumoniaCount = dataMap['Fully Recovered'] || 0;
  
          // Store full chart data if needed
          this.pneumoniaFunnelChartData = response.data;
        } else {
          // Reset all variables if no data
          this.resetVariables();
        }
      },
      error: (error) => {
        console.error('Error fetching pneumonia data:', error);
        this.resetVariables();
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
  
  // Helper method to reset all variables
  private resetVariables(): void {
    this.variableToStorePneumonia0IndexLabel = 'N/A';
    this.variableToStorePneumonia0IndexCount = 0;
    this.variableToStorePneumonia1IndexLabel = 'N/A';
    this.variableToStorePneumonia1IndexCount = 0;
  
    this.suspectedPneumoniaCases = 0;
    this.referredToFacilityCases = 0;
    this.recoveredPostReferralCases = 0;
    this.notReferredToFacilityCases = 0;
    this.recoveredAtHomeCases = 0;
    this.pneumoniaMortalityCases = 0;
  
    this.fullyRecoveredPneumoniaLabel = 'Fully Recovered';
    this.fullyRecoveredPneumoniaCount = 0;
  
    this.pneumoniaFunnelChartData = [];
  }
  functionFilter(params: any): void {

    const filterParams = {
      // designation: params.designation,
      stateId: params.stateId, // Map 'stateId' from params to 'state_Id' for service
      districtId: params.districtId, // Map 'districtId' from params to 'district_Id' for service
      blockId: params.blockId, // Map 'blockId' from params to 'block_Id' for service
      // village_Id: params.villId, 
      // type: params.type,
      // from_date: params.fromDate, 
      // to_date: params.toDate, 
      fromDate: params.fromTimestamp, // Map 'fromDate' from params to 'from_date' for service
      toDate: params.toTimestamp, // Map 'toDate' from params to 'to_date' for service
    };

    // Call the service for All Pneumonia counts
    this.getPneumoniaData(filterParams);





    // Call the service for Treatment Refer To Facility
    this.pueunmiaService.getChildPneumoniaTreatedDataFacility(filterParams).subscribe(
      (chartData: any) => {
        console.log('Response for treated data:', chartData);

        this.exampleDataFor2 = chartData;

        // Prepare the chart data
        this.chartData2 = [
          { name: 'Recovered', value: chartData[0]?.childRecoveredCount || 0, color: '#f6a5b2' },
          { name: 'Fatalities', value: chartData[0]?.childFatalitiesCount || 0, color: '#8a99e6' },
          { name: 'U-T', value: chartData[0]?.childUnderTreatmentCount || 0, color: '#77E4C8' },
          { name: 'LAMA', value: chartData[0]?.childLAMACount || 0, color: '#C6F6EF' },
          { name: 'Loss To F/U', value: chartData[0]?.childLossToFollowUpCount || 0, color: '#74a0dd' },
        ];

        // Trigger view updates
        this.cdr.detectChanges();
        this.loadingTreatmentFacility = false;
      }
    );

    // Call the service for  Pneumonia Incidence
    this.pueunmiaService
      .getChildPneumoniaCountFun(filterParams)
      .subscribe((data: any) => {
        this.tileTopStats = data;
        this.cdr.detectChanges();
      });

    this.getPneumoniaCasesBifurcation(filterParams);

  }

}