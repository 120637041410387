import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { apiEndPoint } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private apiUrl = `${apiEndPoint}Forms`;
  constructor(private http: HttpClient) {}

  getForms(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${this.apiUrl}/getAllForms`, { params: httpParams })
      .pipe(tap((response: any) => response));
  }
  // HH- Question
  get_HH_Question_FilteredData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}HHQuestions/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // Mother Registration
  get_MotherReg_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}MotherRegs/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // Mother PW
  get_MotherPW_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}MotherPWs/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // Mother Common Questions
  get_MotherCQ_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}MotherCommonQues/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // CHild Registration
  get_chilgReg_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}ChildRegs/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // child diarrhea
  get_chilgDiarrhea_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}ChildDiarrheas/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // child pneumonia
  get_childPneumonia_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}ChildPneumonia/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // Mother Meeting
  get_MotherMeeting_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}MotherMeetings/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // Iecdissemination
  get_Iecdissemination_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}IECDisseminations/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // chld diarrhea followup
  get_childDiarrheaFollowUp_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}ChildDiarrheaFollowUps/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // chld pneumonia followup
  get_childPneumoniaFollowUp_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}ChildPneumoniaFollowUps/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // AncFollowUp
  get_AncFollowUp_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}AncFollowUps/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  // VaccinationFollowUp
  get_VaccinationFollowUp_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}VaccinationFollowUps/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  //NukkadNatak
  get_NukkadNatak_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}NukkadNataks/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  //Regular followup
  get_regular_followup_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}RegularFollowUps/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
  //Community meeting
  get_communityMeeting_FilterData(params: any): Observable<any[]> {
    const httpParams = new HttpParams().set('filter', JSON.stringify(params));
    return this.http
      .get<any[]>(`${apiEndPoint}CommunityMeetings/getFilteredData`, {
        params: httpParams,
      })
      .pipe(tap((response: any) => response));
  }
}
