// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f0f0; /* Light grey background */
    overflow-x: hidden; /* Prevent horizontal scrollbar */
  }
  .content {
    flex: 1;
    padding: 20px;
    overflow-y: auto; /* Allow vertical scrolling if content exceeds viewport */
  }`, "",{"version":3,"sources":["webpack://./src/app/layout/layout.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB,EAAE,0BAA0B;IACrD,kBAAkB,EAAE,iCAAiC;EACvD;EACA;IACE,OAAO;IACP,aAAa;IACb,gBAAgB,EAAE,yDAAyD;EAC7E","sourcesContent":[".layout-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    min-height: 100vh;\r\n    background-color: #f0f0f0; /* Light grey background */\r\n    overflow-x: hidden; /* Prevent horizontal scrollbar */\r\n  }\r\n  .content {\r\n    flex: 1;\r\n    padding: 20px;\r\n    overflow-y: auto; /* Allow vertical scrolling if content exceeds viewport */\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
